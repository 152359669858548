import React from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import Assessment from "@models/Assessment"
import styles from "../../Styles"
import VexDocumentStyles from "../../VexStyleSheet"
import { Footer, Header } from "./HeaderFooter"

export const scope = [
    ["Identification, Documentation and Management of Software Components", "SBOM analysis involves identifying all software components used in an application or system, including libraries, frameworks, modules, plugins, and any other elements that contribute to the operation of the software."],
    ["Vulnerability scan", "Once a complete SBOM has been generated, a vulnerability scan can be performed to identify potential security gaps in software components."],
    ["Vulnerability Prioritization", "This analysis involves evaluating the severity of each identified vulnerability, followed by the generation of a list ordered by its level of criticality."]
]

const ScopeSection:React.FC<{assessment: Assessment}> = ({ assessment }) => (
    <Page size="A4">

        <Header></Header>
        <View style={{ ...VexDocumentStyles.section, flexDirection: "row" }}>
            <View style={styles.column}>
                <Text style={{ ...styles.titulo, fontFamily: "Bold" }}>2. Alcance</Text>
                <Text style={{ ...styles.textua, marginRight: 30, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }}>En el presente documento se presentan resultados obtenidos tras la ejecución de los test de robustez de comunicaciones sobre la plataforma {assessment.product.name}, en adelante Device Under Test (DUT).</Text>
                <Text style={{ ...styles.textua, marginRight: 30, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }}>El sistema está compuesto por dos dispositivos. El line-EDS- cloud es un Gateway pensado para la integrar equipos de campo con sistemas de monitorización y telegestión ubicados en el Cloud. Este módulo permite leer las variables de cualquier equipo Modbus (TCP o RTU) del mercado y subirlas a plataformas Cloud, tales como AmazonWebServices, Google platform o Azure. Por otro lado, el analizador de redes Line-CVM-D32 es un equipo que mide, calcula y visualiza los principales parámetros eléctricos en redes monofásicas, en sistemas con dos fases con y sin neutro, con medida en ARON o trifásicas equilibradas o desequilibradas. La medida se realiza en verdadero valor eficaz, mediante 3 entradas de tensión CA y 3 entradas de corriente.</Text>
                <Text style={{ ...styles.textua, marginRight: 30, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }}>En el transcurso de este test, se realiza una ejecución completa de todos los tests, aquellos que pertenecen a los de tipo Fuzzing, lo que permite identificar los tests problemáticos, entendiendo como tal aquellos en los que se producen no conformidades graves. Después, se realizan más ejecuciones de esos tests para confirmar y en su caso diagnosticar la causa de los problemas.</Text>
            </View>
            <View style={styles.column}>
                <Image style={styles.rightImageFull} src="./assets/img/report/octogono.jpg" />
            </View>
        </View>
        <Footer></Footer>
    </Page>
)

export { ScopeSection }
