import React from "react"
import { Image, Text, View } from "@react-pdf/renderer"
import styles from "../../Styles"
import VexDocumentStyles from "../../VexStyleSheet"

const Header: React.FC<{}> = () => (
    <View fixed>
        <Image style={styles.image4} src="./assets/img/report/encabezado.jpg" />
        <Text style={{ ...styles.textoEncima }}>SW Composition Analysis</Text>
        <Text>&nbsp;</Text>
    </View>
)

const Footer: React.FC<{}> = () => (
    <View fixed>
        <Text>&nbsp;</Text>
        <View style={{ ...VexDocumentStyles.section, marginLeft: -20, marginRight: -20, paddingTop: 0, marginBottom: 0, backgroundColor: "#fffa37", flexDirection: "row" }}>

            <Text style={{ paddingLeft: 32, paddingRight: 150, paddingBottom: 32, paddingTop: 32, fontSize: 5.5 }}>
            Los derechos de propiedad intelectual e industrial sobre el presente documento son titularidad exclusiva de ORBIK Cybersecurity, S.L. Asimismo, el presente informe y, en su caso, cualquier documento anexo al
mismo, contiene información de carácter confidencial exclusivamente dirigida a su destinatario.
                <Text style={{ fontFamily: "Bold", color: "#FFC000", backgroundColor: "black" }}>TLP-AMBER</Text>  Queda prohibida su divulgación, copia o distribución a terceros sin la previa autorización escrita de
ORBIK Cybersecurity. En el caso de haber recibido este informe por error, proceda a su borrado y notifíquelo inmediatamente a ORBIK Cybersecurity, S.L.
            </Text>
            <Text style={styles.numero} render={({ pageNumber, totalPages }) => `${pageNumber} `} />
        </View>
    </View>
)

export { Header, Footer }
