import { Alert, Box, Button, Divider, Grid, MenuItem, Select, TextField, Typography, useTheme } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ClientSelector } from "@components/client/index"
import { ServicesContext } from "@context/index"
import { AssessmentType } from "@models/index"
import { AbilityContext } from "@components/permissions/index"
import Ticketing, { TicketingStatus, TicketingStatusValueOps, TicketingTypeValueOps } from "@models/Ticketing"
import { StyledTextarea } from "@components/common/textareas"
import { DatePicker } from "@mui/x-date-pickers"
import { generateDateValue, serializeDateToString } from "@utils/date"
import { AssessmentTypeColors } from "@components/common/colors/AssessmentTypeColors"
import { TicketingStatusColors } from "@components/common/colors/TicketingTypeColors"

const initValue = {
    id: "",
    type: AssessmentType.SCA,
    status: TicketingStatus.Requested,
    target_date: null,
    license: null,
    notes: "",
    client_id: ""
}

const TicketingDetail: React.FC = () => {
    const theme = useTheme()
    const ticketingService = useContext(ServicesContext).ticketingService

    const [error, setError] = useState<Error|null>(null)

    const { id } = useParams<{ id: string }>()
    const [data, setData] = useState<Ticketing>(initValue)
    const [formData, setFormData] = useState<Ticketing>(initValue)
    const ability = useContext(AbilityContext)

    const formValid = (): boolean => {
        const isNotNullrules = [
            formData.target_date === undefined,
            formData.target_date === "Invalid date",
            formData.client_id === undefined
        ]
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        const isNotSameOldValues = [
            formData.type === data.type,
            formData.status === data.status,
            formData.notes === data.notes,
            formData.target_date === data.target_date,
            formData.license_validity === data.license_validity,
            formData.client_id === data.client_id
        ]
        if (isNotSameOldValues.every(rule => rule)) {
            return false
        }
        return true
    }

    // Generic change handler for all form fields
    const handleChange = (e:any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }
    // Fetch product data from API
    useEffect(() => {
        const fecthData = async () => {
            try {
                const val = await ticketingService.get(id as string)
                setData(val)
                setFormData(val)
            } catch (e: any) {
                setError({ message: e.error } as Error)
                navigate(-1)
            }
        }
        fecthData()
    }, [])
    // Fetch product data from API - END

    const navigate = useNavigate()
    // API Call save product
    const saveHandler = async () => {
        try {
            await ticketingService.update(formData.id, formData)
            setData(formData)
            setError(null)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
        }
    }
    // API Call save product - END

    if (data.id === "") return (<div>{error && <Alert severity="error">{error.message}</Alert>}<br></br>Loading...</div>)

    return (<Grid item>
        <Grid item xs container flexDirection="column">
            <Typography color={theme.palette.secondary.main} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                Ticketing
            </Typography>
        </Grid>
        <Grid item xs container flexDirection="column" spacing="20px">
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>
                    <Box sx={{ borderRadius: "10px", border: "1px solid #6D6C6F", width: "100%", padding: "25px" }}>
                        {error && <Alert severity="error">{error.message}</Alert>}
                        <Grid container flexDirection="column" spacing="20px">
                            <Grid item flexDirection="row">
                                <Grid container flexDirection="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ marginTop: "10px" }}>
                                    <Grid item>
                                        <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
                                        </Typography>
                                    </Grid>
                                    <Grid item><Button variant="contained" sx={{ color: theme.palette.secondary.contrastText }} onClick={saveHandler} disabled={!formValid()}>Save Changes</Button></Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Divider sx={{ height: "1px", width: "100%", background: theme.palette.secondary.dark }} />
                            </Grid>
                            <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                                <Grid item xs={6}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Client</Typography>
                                    <ClientSelector value={formData.client_id} onSelect={(id) => handleChange({ target: { name: "client_id", value: id } })}></ClientSelector>
                                </Grid>
                            </Grid>
                            <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                                <Grid item xs={12} md={6}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Type</Typography>
                                    <Select name="type" size="small" value={formData.type} onChange={handleChange} fullWidth>
                                        {TicketingTypeValueOps.map((opt, idx) =>
                                            (<MenuItem key={idx} value={opt.value}><Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: AssessmentTypeColors[AssessmentType[opt.label]] }}>{opt.label}</Typography></MenuItem>)
                                        )}
                                    </Select>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Status</Typography>
                                    <Select name="status" size="small" value={formData.status} onChange={handleChange} fullWidth>
                                        {TicketingStatusValueOps.map((opt, idx) =>
                                            (<MenuItem key={idx} value={opt.value}><Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: TicketingStatusColors[TicketingStatus[opt.label]] }}>{opt.label}</Typography></MenuItem>)
                                        )}
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid item container flexDirection="row" alignItems='center' spacing="40px">

                                <Grid item xs={12} md={6}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Due Date</Typography>
                                    <DatePicker
                                        value={generateDateValue(formData.target_date)}
                                        onChange={m => handleChange({ target: { name: "target_date", value: serializeDateToString(m) } })}
                                        slots={{
                                            textField: (params) => <TextField required margin="normal"
                                                {...params} />
                                        }}
                                    />

                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">License Validity Date</Typography>
                                    <DatePicker
                                        value={generateDateValue(formData.license_validity)}
                                        onChange={m => handleChange({ target: { name: "license_validity", value: serializeDateToString(m) } })}
                                        slots={{
                                            textField: (params) => <TextField margin="normal"
                                                {...params} />
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Notes</Typography>
                                <StyledTextarea minRows={3} name="notes" placeholder="Enter notes here..." value={formData.notes} onChange={handleChange} style={{ width: "100%", boxSizing: "border-box", backgroundColor: "transparent" }}/>
                            </Grid>

                        </Grid>
                    </Box>
                </Grid>
            </Grid >
        </Grid >

    </Grid>
    )
}

export { TicketingDetail }
export default TicketingDetail
