import React from "react"
import { Page, Text, View } from "@react-pdf/renderer"
import Template from "@models/Template"
import styles from "./Styles"

const indice = [
    "Introduction..................................................................................3",
    "Scope..........................................................................................4",
    "Executive Summary........................................................................5",
    "Identification of vulnerabilities................................................6"
]

const normativas = [
    { id: "Regulations" },
    { id: "- Open Web Application Security Project (OWASP)" },
    { id: "- IEC 62443-4-1" },
    { id: "- The Penetration Testing Execution Standard Documentation" },
    { id: "- PCI Data Security Standard (PCI DSS)" }
]

const CustomIndex: React.FC<{ template: Template}> = ({ template }) => (
    <Page size="A4">
        <View style={styles.page}>
            <View style={{ ...styles.column, paddingLeft: 10, paddingTop: 300, paddingRight: 10 }}>
                <Text style={{ ...styles.titulo, fontFamily: "Bold" }}>Index</Text>
                <View>
                    {indice.map((point, index) => (
                        <Text key={index} style={{ ...styles.puntuak2, paddingLeft: 40, marginRight: -30, paddingBottom: 5 }}>
                            <Text style={{ ...styles.puntuak, textAlign: "left", fontFamily: "Bold", fontSize: 10.5, marginRight: -20 }}>{index + 1}.  {point}</Text>
                        </Text>
                    ))}
                </View>
                <Text>&nbsp;</Text>
                <View style={{ ...styles.tableScope, width: "100%" }}>
                    <Text style={{ ...styles.tableColScope, textAlign: "left", width: "58.25%", height: 120, fontSize: 10.5 }}>
                        {normativas.map((fila, index) => (
                            index === 0 ? fila.id : `\n\n${fila.id}`
                        ))}
                    </Text>
                </View>
                <FooterIndex></FooterIndex>
            </View>
        </View>
    </Page>
)

const FooterIndex: React.FC<{}> = () => (
    <View>
        <View>
            <Text style={{ ...styles.textua, fontFamily: "Griff", paddingLeft: 32, paddingRight: -50, marginRight: 245, paddingBottom: 32, paddingTop: 230, fontSize: 5.5, position: "absolute", textAlign: "justify" }}>
          Cybersecurity
                <Text style={{ fontFamily: "Bold", color: "#FFC000", backgroundColor: "black" }}>TLP-AMBER</Text> Its disclosure, copy or distribution to third parties is prohibited without the prior written authorization of ORBIK Cybersecurity.
            If you have received this report in error, proceed to delete it and immediately notify ORBIK Cybersecurity, S.L.
            </Text>
        </View>
    </View>
)

export { CustomIndex }
