import Ticketing from "@models/Ticketing"
import { MediaTypes } from "@services/HttpService"
import Service from "@services/abstract/BaseService"

class TicketingService extends Service<Ticketing> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/ticket", MediaTypes.JSON)
    }
}

export { TicketingService }
export default TicketingService
