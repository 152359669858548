import React from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import highchartsAccessibility from "highcharts/modules/accessibility"
import { Typography, useTheme } from "@mui/material"

highchartsAccessibility(Highcharts)
interface Donut2Values {
    title: string;
    data: Array<{name: string, color: string, y: number}>;
    number: number;
    footer: string;
}
const capitalizeFirstLetter = (string: any) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}
const transformName = (name: any) => {
    if (name.toLowerCase() === "crt" || name.toLowerCase() === "sca") {
        return name.toUpperCase()
    }
    return capitalizeFirstLetter(name)
}
const DonutChart2: React.FC<Donut2Values> = (props: Donut2Values) => {
    const theme = useTheme()
    const dataExtended = props.data.map(serie => ({ ...serie, borderRadius: 0, borderColor: theme.palette.secondary.contrastText, name: transformName(serie.name) }))
    const options: Highcharts.Options = {
        title: {
            text: undefined
        },
        chart: {
            type: "pie",
            backgroundColor: "transparent",
            height: 150,
            width: 290,
            spacingTop: 0,
            spacingRight: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            plotBorderWidth: 0,
            margin: [0, -10, 5, -28]// esto barras
        },
        credits: { enabled: false },
        legend: {
            enabled: true,
            title: { text: props.title, style: { fontSize: "20px", fontFamily: "Griff", color: theme.palette.primary.contrastText, fontWeight: "normal" } },
            align: "right",
            layout: "vertical",
            verticalAlign: "top",
            itemStyle: { color: theme.palette.primary.contrastText },
            padding: 3,
            itemMarginTop: 5,
            itemMarginBottom: 5,
            width: 150,
            y: 15.5
        },
        plotOptions: {
            pie: {
                innerSize: "48%",
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    enabled: false
                },
                center: [75, 60]
            },
            series: {
                states: {
                    hover: {
                        halo: null
                    }
                }
            }
        },
        exporting: {
            enabled: false // Esto ocultará el botón de menú de opciones de exportación
        },
        series: [{
            // colorByPoint: true,
            showInLegend: true,
            data: dataExtended,
            type: "pie"
        }],
        tooltip: {
            pointFormatter: function () {
                const total = dataExtended.reduce((acc, point) => acc + point.y, 0)
                const yValue = this.y !== undefined ? this.y : "N/A"
                // const percentage = this.y !== undefined && total !== 0 ? Math.round((this.y / total) * 100) : "N/A"
                const percentage = this.y !== undefined ? Math.round((this.y / total) * 100) : "N/A"
                return `<b>${yValue}</b></br><b>${percentage}%</b>`
            }
        }
    }
    // const leftPositionNumber = props.number ? (props.number < 10 ? "62px" : (props.number < 100 ? "57px" : "49px")) : "49px"
    // const leftPositionFooter = props.footer ? (props.footer.length < 8 ? "58px" : (props.footer.length < 11 ? "50px" : (props.footer.length < 100 ? "47px" : "57px"))) : "57px"
    const leftPositionNumber = props.number < 10 ? "62px" : props.number < 100 ? "57px" : "49px"
    const leftPositionFooter = props.footer.length < 8 ? "58px" : props.footer.length < 11 ? "50px" : props.footer.length < 100 ? "47px" : "57px"

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
            <Typography
                variant="caption"
                component="div"
                color= {theme.palette.primary.contrastText}
                fontSize="8px"
                fontFamily="Griff"

                sx={{ position: "relative", top: "-58px", left: leftPositionFooter }}
            > { props.footer } </Typography>
            <Typography
                variant="caption"
                component="div"
                color={theme.palette.primary.contrastText}
                fontSize="24px"
                fontFamily="Griff"
                fontWeight= "bolder"
                sx={{ position: "relative", top: "-106px", left: leftPositionNumber }}
            >{ props.number}</Typography>
        </div>
    )
}

export { DonutChart2 }
export default DonutChart2
