import { CVSS3Severity } from "@models/Vulnerability"

export const CVSS3SeverityColors: Record<CVSS3Severity, string> = {
    critical: "#7940A5", // baby blue
    high: "#D9534F", // mahogany orange
    medium: "#EC971F", // scarlet red
    low: "#F2CC0C", // malachite green
    none: "A9A9A9", // peach brown
    unknown: "#477db8" // lilac grey
}
