import ServicesContext from "@context/ServicesContext"
import { Alert, FormControlLabel, FormGroup, Grid, Switch, Typography, useTheme } from "@mui/material"
import { GridColDef, GridRowParams } from "@mui/x-data-grid"
import React, { useContext, useEffect, useState } from "react"
import { NavigateFunction, useNavigate, useParams } from "react-router-dom"
import { Component } from "@models/Component"
import { AbilityContext } from "@components/permissions"
import { convertModelDefToGridColDef, GenericTable } from "@components/common/tables"
import ComponentTableDefinition from "@components/component/ComponentTableDefinition"
import { useTrack } from "@components/track/TrackContext"
import { FilterOperation, FilterOption, QueryParameters } from "@utils/queryParams"
import { I18nContext } from "I18nProvider"

type ApiResponse = {
    count: number;
    list: Component[];
}

const ComponentList: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const componentService = useContext(ServicesContext).componentService
    const navigate: NavigateFunction = useNavigate()
    const [cols, setCols] = useState<GridColDef[]>([])

    const ability = useContext(AbilityContext)
    useEffect(() => {
        setCols(convertModelDefToGridColDef(ComponentTableDefinition, ability))
    }, [])

    const { id } = useParams<{ id: string; componentId: string }>()
    const [error, setError] = useState<Error | null>(null)
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "VulnerabilityList" })
    }, [])
    const [loading, setLoading] = useState(false)

    const customActions = (params: GridRowParams<Component>) => {
        const actions: React.JSX.Element[] = []
        return actions
    }

    const [queryParameters, setQueryParameters] = useState<QueryParameters>({})
    const handleSwitch = (event: any) => {
        const { checked } = event.target
        const newFilter: FilterOption = {
            field: "outdated",
            operation: FilterOperation.StringEqual,
            value: checked
        }
        if (checked) {
            setQueryParameters({ ...queryParameters, filters: [newFilter] })
        } else {
            setQueryParameters({ ...queryParameters, filters: [] })
        }
    }

    return (
        <Grid container item className="scroll-container">
            <Typography fontSize="45px" fontWeight="bolder" fontFamily="Griff" sx={{ marginTop: "-20px", marginBottom: "20px" }}>{context.t.translate("product_comp")}</Typography>
            {error && <Alert severity="error">{error.message}</Alert>}
            <Grid container justifyContent="flex-end">
                <FormGroup>
                    <FormControlLabel label="Outdated only" control={<Switch/>} onChange={handleSwitch}/>
                </FormGroup>
            </Grid>
            <Grid item container flexDirection="column" rowGap="35px">
                <GenericTable<Component> entity="Component" columns={cols}
                    dataProvider={(filter) => componentService.getAll(filter)}
                    onEdit={(elem: Component) => navigate("./" + elem.id)}
                    onDelete={(elem: Component) => componentService.delete(elem.id)}
                    customActions={customActions}
                    externalParameters={queryParameters}
                />
            </Grid>

        </Grid>
    )
}
export { ComponentList }
export default ComponentList
