import { getEnumKeys, ModelDefinition } from "@models/utils"
import { format } from "date-fns/format"
import TicketStatusColored from "@components/ticketing/TicketStatusColored"
import AssessmentStateColored from "@components/vulnerability/AssessmentStateColored"
import { AssessmentType } from "./Assessment"

type Ticketing = {
 id: string;
 type: AssessmentType;
 status: TicketingStatus;
 target_date?: string | null;
 license_validity?: string | null;
 notes: string;
 client_id: string;
}

enum TicketingStatus {
    Requested = "requested",
    Pending = "pending",
    Accepted = "accepted",
    Rejected = "rejected",
    Completed = "completed"
   }

   type TicketingExt = Ticketing & {
    NumVulnerability: number;
}

const TicketingTypeKeys = getEnumKeys(AssessmentType)
const TicketingTypeValueOps = TicketingTypeKeys.map((key) => ({ label: key, value: AssessmentType[key] }))

const TicketingStatusKeys = getEnumKeys(TicketingStatus)
const TicketingStatusValueOps = TicketingStatusKeys.map((key) => ({ label: key, value: TicketingStatus[key] }))

const TicketingTableDefinition: ModelDefinition<TicketingExt> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 0.1, isId: true },
        { name: "type", label: "Type", type: "singleSelect", flex: 0.15, allowedValueOptions: TicketingTypeValueOps, render: AssessmentStateColored },
        { name: "status", label: "Status", type: "singleSelect", flex: 0.15, allowedValueOptions: TicketingStatusValueOps, render: TicketStatusColored },
        { name: "target_date", label: "Due Date", type: "string", flex: 0.3, valueGetter: assessment => format(new Date(assessment.target_date as string), "dd/MM/yyyy") },
        { name: "license_validity", label: "License Validity", type: "string", flex: 0.3, valueGetter: assessment => format(new Date(assessment.target_date as string), "dd/MM/yyyy") }
    ]
}

export { TicketingStatus, TicketingTypeKeys, TicketingStatusKeys, TicketingTableDefinition, TicketingStatusValueOps, TicketingTypeValueOps }
export type { Ticketing }
export default Ticketing
