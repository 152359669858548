import { AuthContextProps } from "react-oidc-context"
import { HttpService, MediaTypes } from "@services/index"
import { QueryParameters } from "@utils/index"

abstract class BaseService<E> {
    protected http: HttpService<E>
    protected path: string

    constructor (baseUrl: string|undefined, path: string, type: MediaTypes) {
        this.http = new HttpService<E>(baseUrl, type)
        this.path = path
    }

    public setAuth (auth: AuthContextProps) {
        this.http.setAuth(auth)
    }

    public getAll (params: QueryParameters) {
        return this.http.getMultiple(`/${this.path}`, params)
    }

    public get = (id: string) => {
        return this.http.get(`/${this.path}/${id}`)
    }

    public create = (data: E) => {
        return this.http.post(`/${this.path}`, data)
    }

    public createFile = (date: E, mediaType: MediaTypes) => {
        return this.http.postFile(`/${this.path}`, date, mediaType)
    }

    public update = (id: any, data: E) => {
        return this.http.put(`/${this.path}/${id}`, data)
    }

    public delete = (id: string) => {
        return this.http.delete(`/${this.path}/${id}`)
    }

    // Add more methods here

    public getAllExt (params: QueryParameters) {
        return this.http.getMultiple(`/${this.path}Ext`, params)
    }
}

export { BaseService }
export default BaseService
