import React, { useEffect, useState } from "react"
import Chip from "@mui/material/Chip"
import { AssessmentType } from "@models/Assessment"
import { AssessmentTypeColors } from "@components/common/colors/AssessmentTypeColors"
import { AssessmentTypeKeysValueOps } from ".."

interface ColorAndBg{
    color: string;
    "background-color": string;
}

type StateColorMap = {
  [key in AssessmentType]: ColorAndBg;
};

const getStateColor = (state: AssessmentType): ColorAndBg => {
    const stateColorMap: StateColorMap = {
        [AssessmentType.CRT]: { color: "black", "background-color": AssessmentTypeColors.crt },
        [AssessmentType.FunctionalTest]: { color: "black", "background-color": AssessmentTypeColors.functional_test },
        [AssessmentType.LogicalPentest]: { color: "black", "background-color": AssessmentTypeColors.logical_pentest },
        [AssessmentType.PortScan]: { color: "black", "background-color": AssessmentTypeColors.port_scan },
        [AssessmentType.NetworkScan]: { color: "black", "background-color": AssessmentTypeColors.network_scan },
        [AssessmentType.WebScan]: { color: "black", "background-color": AssessmentTypeColors.web_scan },
        [AssessmentType.SCA]: { color: "black", "background-color": AssessmentTypeColors.sca }
    }
    return stateColorMap[state]
}

interface AssessmentStateColoredProps {
  value: AssessmentType;
}

const AssessmentStateColored: React.FC<AssessmentStateColoredProps> = ({ value }) => {
    const [color, setColor] = useState<ColorAndBg| null>(null)
    const [label, setLabel] = useState<string|undefined>("")

    useEffect(() => {
        if (value !== undefined) {
            setLabel(AssessmentTypeKeysValueOps.find(v => v.value === value)?.label)
            setColor(getStateColor(value))
        }
    }, [value])

    return (
        <>
            {value && (
                <Chip size="small" style={{
                    fontWeight: "bolder",
                    color: color?.color,
                    backgroundColor: color?.["background-color"]
                }} label={label}

                />
            )}
        </>
    )
}

export { AssessmentStateColored }
export default AssessmentStateColored
