import React from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import VexDocumentStyles from "@components/report/VexStyleSheet"
import styles from "@components/report/Styles"
import { Compliance, ComplianceResult, RegistryData } from "@models/Compliance"
import { questions } from "@components/compliance/QuestionData"
import { Footer, Header } from "./HeaderFooter"

const Conclusions: React.FC<{ compliance:Compliance}> = ({ compliance }) => {
    return (
        <Page size="A4">
            <Header></Header>
            <View style={VexDocumentStyles.section} wrap={true}>
                <View style={{ ...styles.tableScope, marginTop: 13, width: "90%" }}>
                    <View style={{ ...styles.tableRowScope, fontFamily: "Bold", textAlign: "center" }}>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "transparent", borderWidth: 0, borderBottomWidth: 0, borderRightWidth: 0, width: "105%" }}></Text>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 1 }}>Result</Text>
                    </View>

                    <View style={{ ...styles.tableRowScope, fontFamily: "Bold", textAlign: "center" }}>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 0, width: "25%" }}>Requirement</Text>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 0 }}>Question</Text>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 0, width: "20%" }}>High</Text>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 0, width: "20%" }}>Medium</Text>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 0, width: "20%" }}>Low</Text>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 1, width: "20%" }}>None</Text>

                    </View>
                    <View style={{ borderBottomWidth: 1 }} >
                        {questions.slice(16, 21).map((question, index) => (
                            <View key={question.id} style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                                <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", width: "34%", justifyContent: "center", fontWeight: "bold" }}>{question.id.toUpperCase()}</Text>
                                <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", width: "109%", justifyContent: "center", fontWeight: "bold", textAlign: "left" }}>{question.title}</Text>
                                <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", justifyContent: "center", fontWeight: "bold", width: "27.2%" }}>{compliance.registry[compliance.registry.length - 1][question.id as keyof RegistryData] === ComplianceResult.FullyCompliant ? <Image src="./assets/img/report/check.png" /> : <></>}</Text>
                                <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", justifyContent: "center", fontWeight: "bold", width: "27.2%" }}>{compliance.registry[compliance.registry.length - 1][question.id as keyof RegistryData] === ComplianceResult.PartiallyCompliant ? <Image src="./assets/img/report/check.png" /> : <></>}</Text>
                                <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", justifyContent: "center", fontWeight: "bold", width: "27.2%" }}>{compliance.registry[compliance.registry.length - 1][question.id as keyof RegistryData] === ComplianceResult.RequiredImprovements ? <Image src="./assets/img/report/check.png" /> : <></>}</Text>
                                <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 1, backgroundColor: "transparent", justifyContent: "center", fontWeight: "bold", width: "27.2%" }}>{compliance.registry[compliance.registry.length - 1][question.id as keyof RegistryData] === ComplianceResult.NonCompliant ? <Image src="./assets/img/report/check.png" /> : <></>}</Text>
                            </View>
                        ))}
                    </View>

                </View>

                <Text style={{ ...styles.textua, paddingLeft: 45, marginTop: 33, fontFamily: "Bold", fontSize: 12, marginRight: 0 }}>3. Conclusions</Text>
                <Text style={{ ...styles.textua, paddingLeft: 45, marginRight: 40, fontFamily: "Griff", fontSize: 10, marginBottom: 7, textAlign: "justify" }}>Based on the results of your cybersecurity self-assessment tool results, your level is (level).

If your level is High, it means you have a strong understanding of cybersecurity principles and best practices. You are well-equipped to recognize and mitigate potential threats, which significantly reduces your risk of cyber incidents.

If your level is Medium, you have a moderate understanding of cybersecurity. You are familiar with many important concepts and practices but might need to improve your knowledge in certain areas to ensure comprehensive protection against cyber threats.

If your level is Low, it indicates a basic awareness of cybersecurity. </Text>
                <Text style={{ ...styles.textua, paddingLeft: 45, marginTop: 15, marginRight: 40, fontFamily: "Griff", fontSize: 10, marginBottom: 84, textAlign: "justify" }}>While you understand some fundamental concepts, there are critical areas that require further learning and attention to protect yourself and your organization effectively.

If your level is None, it suggests a lack of knowledge in cybersecurity. It is crucial to start learning the basics of cybersecurity immediately to safeguard against potential threats and vulnerabilities.

Regardless of your current level, continuous education and vigilance are key to maintaining and enhancing your cybersecurity posture.</Text>

                {compliance.conclusion !== "" ? <Text style={{ ...styles.textua, paddingLeft: 45, marginRight: 40, fontFamily: "Griff", fontSize: 10, marginBottom: 7, textAlign: "justify" }}>{compliance.conclusion}</Text> : <></>}

            </View>

            <Footer></Footer>
        </Page>
    )
}

export { Conclusions }
