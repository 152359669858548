import { AssessmentType } from "@models/Assessment"

export const AssessmentTypeColors: Record<AssessmentType, string> = {
    crt: "#42A5F5", // aquamarine
    logical_pentest: "#FF5722", // jungle green
    functional_test: "#4370a4", // blue iris
    sca: "#ec971f", // lightpink
    port_scan: "#ffffff", // neon orange
    network_scan: "#E1BEE7", // amaranth
    web_scan: "#288d43" // sage green
}
