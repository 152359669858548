import React, { createContext, ReactNode, useContext, useState } from "react"

interface ButtonStateContextProps {
    buttonState: boolean;
    setButtonState: (state: boolean) => void;
    darkMode: boolean;
    setDarkMode: (state: boolean) => void;
}

const ButtonStateContext = createContext<ButtonStateContextProps | undefined>(undefined)

export const useButtonState = (): ButtonStateContextProps => {
    const context = useContext(ButtonStateContext)
    if (!context) {
        throw new Error("useButtonState must be used within a ButtonStateProvider")
    }
    return context
}

export const ButtonStateProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [buttonState, setButtonState] = useState<boolean>(false)
    const [darkMode, setDarkMode] = useState<boolean>(true)

    return (
        <ButtonStateContext.Provider value={{ buttonState, setButtonState, darkMode, setDarkMode }}>
            {children}
        </ButtonStateContext.Provider>
    )
}
