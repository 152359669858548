import React from "react"
import { ValueOptions } from "@mui/x-data-grid"
import { Actions, Subjects } from "@permissions/index"

type PropDefinition<T> = {
    name: string,
    label: string,
    type: "string"|"number"|"singleSelect"|"date"|"struct",
    flex: number,
    valueGetter?: (obj: T)=>any,
    allowedValueOptions?: ValueOptions[],
    permission?: {subject: Subjects, action: Actions},
    render?: React.FC<any>,
    hide?: boolean,
    isId?: boolean
}

type ModelDefinition<T> = {
    properties: PropDefinition<T>[]
}

type ModelRelation = {
    id: string,
    name?: string
}

function getEnumKeys<
    T extends string,
    TEnumValue extends string | number,
> (enumVariable: { [key in T]: TEnumValue }) {
    return Object.keys(enumVariable) as Array<T>
}
export { getEnumKeys }
export type { ModelDefinition, PropDefinition, ModelRelation }
