import React from "react"
import { Page, View } from "@react-pdf/renderer"
import Template from "@models/Template"

const CustomBackPage: React.FC<{template: Template}> = ({ template }) => (
    <Page size="A4" style={{ backgroundColor: template.color }}>
        <View fixed style={{ backgroundColor: template.color }}>
        </View>
    </Page>
)

export { CustomBackPage }
