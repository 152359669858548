import React, { useEffect, useState } from "react"
import { Image, Link, Page, Text, View } from "@react-pdf/renderer"
import { CVSS3CriticalityKeysValueOps } from "@models/Vulnerability"
import { ComponentCycloneDX, VulnerabilityCycloneDX } from "@models/VulnerabilityReport"
import { CWES } from "@models/CWE"
import VexDocumentStyles from "@components/report/VexStyleSheet"
import styles from "@components/report/Styles"
import { Compliance, ComplianceResult, Question, RegistryData } from "@models/Compliance"
import { questions } from "@components/compliance/QuestionData"
import { Footer, Header } from "./HeaderFooter"

const VulnsList: React.FC<{ compliance:Compliance}> = ({ compliance }) => {
    return (
        <Page size="A4">
            <Header></Header>
            <View style={VexDocumentStyles.section} wrap={true}>
                <Text style={{ ...styles.textua, paddingLeft: 40, fontFamily: "Bold", fontSize: 12, marginRight: 0 }}>2. Self-assessment tool results</Text>
                <Text style={{ ...styles.textua, paddingLeft: 40, marginRight: 40, fontFamily: "Griff", fontSize: 10, marginBottom: 7 }}>This table shows the results of the previous self-assessment results. It will show the number of the question, the subject to which it refers and the level of the answer. The level of the answer can be high, medium, low or null.</Text>

                <View style={{ ...styles.tableScope, marginTop: 13, width: "90%" }}>
                    <View style={{ ...styles.tableRowScope, fontFamily: "Bold", textAlign: "center" }}>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "transparent", borderWidth: 0, borderBottomWidth: 0, borderRightWidth: 0, width: "105%" }}></Text>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 1 }}>Result</Text>
                    </View>
                    <View style={{ ...styles.tableRowScope, fontFamily: "Bold", textAlign: "center" }}>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 0, width: "25%" }}>Requirement</Text>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 0 }}>Question</Text>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 0, width: "20%" }}>High</Text>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 0, width: "20%" }}>Medium</Text>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 0, width: "20%" }}>Low</Text>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 1, width: "20%" }}>None</Text>

                    </View>
                    <View style={{ borderBottomWidth: 1 }} >
                        {questions.slice(0, 16).map((question, index) => (
                            <View key={question.id} style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                                <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", width: "34%", justifyContent: "center", fontWeight: "bold" }}>{question.id.toUpperCase()}</Text>
                                <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", width: "109%", justifyContent: "center", fontWeight: "bold", textAlign: "left" }}>{question.title}</Text>
                                <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", justifyContent: "center", fontWeight: "bold", width: "27.2%" }}>{compliance.registry[compliance.registry.length - 1][question.id as keyof RegistryData] === ComplianceResult.FullyCompliant ? <Image src="./assets/img/report/check.png" /> : <></>}</Text>
                                <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", justifyContent: "center", fontWeight: "bold", width: "27.2%" }}>{compliance.registry[compliance.registry.length - 1][question.id as keyof RegistryData] === ComplianceResult.PartiallyCompliant ? <Image src="./assets/img/report/check.png" /> : <></>}</Text>
                                <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", justifyContent: "center", fontWeight: "bold", width: "27.2%" }}>{compliance.registry[compliance.registry.length - 1][question.id as keyof RegistryData] === ComplianceResult.RequiredImprovements ? <Image src="./assets/img/report/check.png" /> : <></>}</Text>
                                <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 1, backgroundColor: "transparent", justifyContent: "center", fontWeight: "bold", width: "27.2%" }}>{compliance.registry[compliance.registry.length - 1][question.id as keyof RegistryData] === ComplianceResult.NonCompliant ? <Image src="./assets/img/report/check.png" /> : <></>}</Text>
                            </View>
                        ))}
                    </View>
                </View>
            </View>
            <Footer></Footer>
        </Page>
    )
}
// TODO: modificar los campos que se muestran en las preguntas
const QuestionRow: React.FC<{ question: Question, index: number }> = ({ question, index }) => (
    <View style={VexDocumentStyles.row} wrap={false}>
        <View style={{ ...VexDocumentStyles.col, width: "10%", borderRightWidth: 0, borderTopWidth: 0 }}>
            <Link src={`#SCA-${String(index + 1).padStart(3, "0")}`} style={{ textDecoration: "none", color: "black" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", borderBottomWidth: 0 }}>{`SCA-${String(index + 1).padStart(3, "0")} `}</Text></Link>
        </View>
    </View>
)

const VulnsListDetail: React.FC<{ vulnerabilities:VulnerabilityCycloneDX[], components: ComponentCycloneDX[] }> = ({ vulnerabilities, components }) => {
    const [componentsByMap, setComponentsByMap] = useState<{[key: string]: ComponentCycloneDX}>({})

    useEffect(() => {
        setComponentsByMap(components.reduce((acc, current) => {
            acc[current["bom-ref"]] = current
            return acc
        }, {} as {[key: string]: ComponentCycloneDX}))
    }, [components])

    return (
        <Page size="A4">
            <Header></Header>
            <View wrap={true}>
                {vulnerabilities.map((vulnerability, index) => (
                    <VulnerabilityHeaderDetail key={index} vulnerability={vulnerability} index={index} component={componentsByMap[vulnerability.affects[0].ref]}/>
                ))}
            </View>
            <Footer></Footer>
        </Page>
    )
}
const VulnerabilityHeaderDetail: React.FC<{ vulnerability: VulnerabilityCycloneDX; index: number, component: ComponentCycloneDX }> = ({ vulnerability, index, component }) => (
    <View key={index + 1} style={{ ...VexDocumentStyles.section }} wrap={false}>
        <Text id={`SCA-${String(index + 1).padStart(3, "0")}`} style={{ ...styles.textua, fontFamily: "Bold", fontSize: 12, marginRight: 0, paddingLeft: 5, paddingBottom: 5 }}>4.2.{index} {`SCA-${String(index + 1).padStart(3, "0")} `}</Text>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 10, paddingBottom: -3, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>CVE</Text></View>
            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 12, paddingBottom: 0, paddingLeft: 5 }}><Link href={`https://nvd.nist.gov/vuln/detail/${vulnerability.id}`} style={{ textDecoration: "none", color: "black" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9 }}> {vulnerability.id} </Text></Link></View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>Name</Text></View>
            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", fontSize: 9, padding: 0, paddingTop: 5, paddingBottom: 0, paddingLeft: 5 }}><Link href={vulnerability.cwes?.length > 0 ? `https://cwe.mitre.org/data/definitions/${vulnerability.cwes[0]}.html` : ""} style={{ textDecoration: "none", color: "black" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9 }}>{vulnerability.cwes?.length > 0 ? CWES[vulnerability.cwes[0]] : "Insufficient Information"}</Text></Link></View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>Criticality</Text></View>
            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 9, marginBottom: 0 }}>{CVSS3CriticalityKeysValueOps.find(v => v.value === vulnerability.ratings.find(r => r.method === "CVSSv3")?.severity)?.label}</Text></View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>CVSS</Text></View>
            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 6, paddingBottom: 0, paddingLeft: 5 }}>
                <View>
                    <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 0 }}>{(vulnerability.ratings as any)[0].score} {(vulnerability.ratings as any)[0].vector}</Text>
                </View>
            </View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>Affected assets</Text></View>
            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 10, paddingBottom: 0, paddingLeft: 5 }}>
                <Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 9, marginBottom: 0 }}>
                    {component != null
                        ? `${component.name} - ${component.version} `
                        : null}
                </Text>
            </View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>Description</Text></View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5, paddingRight: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 2 }}>{vulnerability.description}</Text></View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>Solution</Text></View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5, paddingRight: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 0 }}>N/A</Text></View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>Reference</Text></View>
            <View style={{ ...VexDocumentStyles.col, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 0, paddingLeft: 5 }}><Link href={`https://nvd.nist.gov/vuln/detail/${vulnerability.id}`} style={{ textDecoration: "none", color: "black" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 2 }}> {`https://nvd.nist.gov/vuln/detail/${vulnerability.id}`} </Text></Link></View>
        </View>
    </View>
)

export { VulnsList, VulnsListDetail }
