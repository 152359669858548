
import React from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import HighchartsMore from "highcharts/highcharts-more"
import SolidGauge from "highcharts/modules/solid-gauge"
import { Grid } from "@mui/material"

type GaugeChartProps = {
    value: number;
};

HighchartsMore(Highcharts)
SolidGauge(Highcharts)

const GradiantGauge: React.FC<GaugeChartProps> = ({ value }) => {
    const getLabel = (value: number) => {
        if (value < 5) {
            return "Bad"
        } else if (value < 7) {
            return "Good"
        }
        return "Very Good"
    }
    const options = {
        chart: {
            type: "solidgauge",
            backgroundColor: "#1e1e1f",
            plotBorderWidth: 0,
            margin: [0, 0, 0, 0]
        },
        title: null,
        pane: {
            center: ["50%", "70%"],
            size: "100%",
            startAngle: -90,
            endAngle: 90,
            background: {
                backgroundColor: "#333",
                innerRadius: "60%",
                outerRadius: "100%",
                shape: "arc",
                borderWidth: 0
            }
        },
        yAxis: {
            min: 0,
            max: 10,
            stops: [
                [0.5, {
                    linearGradient: {
                        x1: 0,
                        x2: 0,
                        y1: 0,
                        y2: 1
                    },
                    stops: [
                        [0, "#F7E400"],
                        [1, "#E20606"]
                    ]
                }],
                [0.75, {
                    linearGradient: {
                        x1: 0,
                        x2: 1,
                        y1: 0,
                        y2: 0
                    },
                    stops: [
                        [0, "#E20606"],
                        [0.5, "#F7E400"],
                        [1, "#62BD50"]
                    ]
                }],
                [1, {
                    linearGradient: {
                        x1: 0,
                        x2: 1,
                        y1: 0,
                        y2: 0
                    },
                    stops: [
                        [0, "#E20606"],
                        [0.5, "#F7E400"],
                        [1, "#448438"]
                    ]
                }]],

            tickInterval: 2.5, // Intervalo de ticks principales
            minorTickInterval: "auto", // Intervalo de ticks menores (opcional)
            minorTickWidth: 1,
            minorTickLength: 10,
            minorTickZIndex: 15,
            minorTickPosition: "inside",
            minorTickColor: "white",
            lineWidth: 0,
            tickWidth: 2,
            tickPosition: "inside",
            tickLength: 15,
            tickColor: "white",
            tickAmount: 5,
            tickZIndex: 15,
            zIndex: 100,
            title: {
                y: -70
            },
            labels: {
                y: 16,
                style: {
                    color: "white",
                    textOutline: "none"
                },
                zIndex: 12 // asegurar que las etiquetas estén encima del gráfico
            }
        },
        plotOptions: {
            solidgauge: {
                dataLabels: {
                    y: -25,
                    borderWidth: 0,
                    useHTML: true,
                    style: {
                        color: "white", // color de los data labels,
                        fontSize: "10px",
                        fontFamily: "Griff",
                        fontWeight: "bolder",
                        zIndex: 12
                    }
                }
            }
        },
        series: [{
            name: "Score",
            data: [value], // aquí debes colocar tu valor dinámico o estático
            dataLabels: {
                format: `<div style="text-align:center; margin-top: -60px">
                <span style="font-size:55px;color:white">{y}</span><br/>
               <span style="font-size:18px;color:white">${getLabel(value)}</span>
             </div>`
            },
            tooltip: {
                valueSuffix: " "
            }
        }],
        credits: {
            enabled: false
        },
        legend: {
            enabled: false // deshabilitar la leyenda
        },
        exporting: {
            enabled: false
        }
    }

    const containerStyle = {
        maxHeight: "400px",
        overflow: "hidden",
        maxWidth: "400px"

    }

    return (
        <Grid display="flex" justifyContent="center" style={containerStyle}>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </Grid>
    )
}

export default GradiantGauge
