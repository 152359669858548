import ServicesContext from "@context/ServicesContext"
import Component, { TotalComponents } from "@models/Component"
import { Alert, Box, Divider, Grid, TextField, Typography, useTheme } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"

const initTotalComponents: TotalComponents = {
    none: 0,
    low: 0,
    medium: 0,
    high: 0,
    critical: 0,
    unknown: 0
}
const initValue: Component = {
    id: "",
    bom_ref: "",
    type: "",
    name: "",
    version: "",
    vulns_numbers: initTotalComponents,
    outdated: false
}
const ComponentDetail: React.FC = () => {
    const { componentId } = useParams<{ id: string; componentId: string }>()
    const theme = useTheme()
    const [error, setError] = useState<Error|null>(null)
    const [formData, setFormData] = useState<Component>(initValue)
    const [totalVulns, setTotalVulns] = useState<number>()
    const componentService = useContext(ServicesContext).componentService

    useEffect(() => {
        const fecthData = async () => {
            try {
                const val = await componentService.get(componentId as string)
                setFormData(val)
                const vulns = val.vulns_numbers
                const totalVulnerabilities = (vulns.high || 0) + (vulns.low || 0) + (vulns.medium || 0) + (vulns.critical || 0) + (vulns.none || 0) + (vulns.unknown || 0)
                setTotalVulns(totalVulnerabilities)
            } catch (e: any) {
                setError({ message: e.error } as Error)
            }
        }
        fecthData()
    }, [])

    return (
        <Grid item xs container flexDirection="column" spacing="20px">
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>
                    <Box sx={{ borderRadius: "10px", border: "1px solid #6D6C6F", width: "100%", padding: "25px" }}>
                        {error && <Alert severity="error">{error.message}</Alert>}
                        <Grid container flexDirection="column" spacing="20px">
                            <Grid item flexDirection="row">
                                <Grid container flexDirection="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ marginTop: "10px" }}>
                                    <Grid item>
                                        <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
                                            {formData.name}
                                        </Typography>
                                    </Grid>

                                </Grid>
                            </Grid>

                            <Grid item>
                                <Divider sx={{ height: "1px", width: "100%", background: theme.palette.secondary.dark }} />
                            </Grid>

                            <Grid item container flexDirection="row" spacing="35px">
                                <Grid item xs={3.25}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Reference</Typography>
                                    <TextField name="Product" disabled value={formData.bom_ref} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                </Grid>
                                <Grid item xs={1.5}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Version</Typography>
                                    <TextField name="Version" disabled value={formData.version} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                </Grid>
                                <Grid item xs={2.5}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Type</Typography>
                                    <TextField name="Component" disabled value={`${formData.type}`} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                </Grid>
                                <Grid item xs={2.5}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Total vulnerabilities</Typography>
                                    <TextField name="Total vulnerabilities" disabled value={totalVulns} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Box>

                </Grid>
            </Grid >
        </Grid >
    )
}
export { ComponentDetail }
export default ComponentDetail
