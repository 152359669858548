import React from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import styles from "../../Styles"
import VexDocumentStyles from "../../VexStyleSheet"
import { Footer, Header } from "./HeaderFooter"

export const vectors1 = [
    { id: "Access Vector (AV): ", description: "Local (L), Adjacent (A), Network (N)" },
    { id: "Access Complexity (AC): ", description: "High (H), Medium (M), Low (L)" },
    { id: "Privileges Required (PR): ", description: "High (H), Low (L), None (N)" },
    { id: "User Interaction (UI): ", description: "Required (R), None (N)" },
    { id: "Scope (S): ", description: "Changed (C), Unchanged (U)" },
    { id: "Confidenciality (C): ", description: "None (N), Partial (P), Complete (C)" },
    { id: "Integrity (I): ", description: "None (N), Partial (P), Complete (C)" },
    { id: "Availability (A): ", description: "None (N), Partial (P), Complete (C)" }
]

export const vectors2 = [
    { id: "Access Vector (AV): ", description: "Local (L), Adjacent (A), Network (N)" },
    { id: "Access Complexity (AC): ", description: "High (H), Medium (M), Low (L)" },
    { id: "Authentication (Au): ", description: "Multiple (M), Single (S), None (N)" },
    { id: "Confidenciality Impact (C): ", description: "None (N), Partial (P), Complete (C)" },
    { id: "Integrity Impact (I): ", description: "None (N), Partial (P), Complete (C)" },
    { id: "Availability Impact (A): ", description: "None (N), Partial (P), Complete (C)" }
]

export const protocolo = [
    ["CVSS Score"],
    ["a"],
    ["a"],
    ["a"],
    ["7.0 - 8.9"],
    ["7.0 - 8.9"],
    ["7.0 - 8.9"],
    ["7.0 - 8.9"],
    ["7.0 - 8.9"],
    ["7.0 - 8.9"],
    ["7.0 - 8.9"],
    ["7.0 - 8.9"],
    ["9.0 - 10.0"]
]

export const resumeTable = [
    { id: "Summary Table: ", description: "It is the table where all the vulnerabilities found are summarized in the following format:" },
    { id: "Identificator: ", description: "It will be displayed using the following format XYZ-nnn, where nnn will be a number that reflects the vulnerability number." },
    { id: "Name: ", description: "It will describe the title of the vulnerability found." },
    { id: "Criticality: ", description: "This field will contain the 'Rating' of the vulnerability found." }
]

export const detailTable = [
    { id: "Summary Table. ", description: "This table will contain three types of information:" },
    { id: "Header. ", description: "This type of information will contain the identifier, name, criticality, CVSS value along with its values ​and the affected assets." },
    { id: "Evidencias. ", description: "A descriptive text will be displayed, as well as screenshots of the identified problem." },
    { id: "Solution/Reference. ", description: "A first solution for the problem will be described." }
]

const VulnsSection: React.FC<{}> = () => (
    <Page size="A4">
        <Header></Header>
        <View style={{ ...VexDocumentStyles.section, flexDirection: "row" }}>
            <View style={styles.column}>
                <Text style={{ ...styles.titulo, fontFamily: "Bold" }}>4. Descubrimiento e</Text>
                <Text style={{ ...styles.titulo, fontFamily: "Bold" }}>identificación del sistema</Text>
                <Text style={{ ...styles.textua, marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>El objetivo de esta fase es descubrir e identificar en el DUT, los protocolos objetivos del test.</Text>

                <Text style={{ ...styles.textua, fontFamily: "Bold", marginLeft: 12, fontSize: 12, marginRight: 0 }}>4.1. Servicios publicados</Text>
                <Text style={{ ...styles.textua, marginRight: 20, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }}>En esta fase se descubren y se identifican todos los protocolos en los que se van a realizar los test para el diagnóstico de robustez de comunicaciones en el DUT.</Text>
                <View style={{ ...styles.tableScope, textAlign: "center", marginTop: 13, width: "116%" }}>
                    <View style={{ ...styles.tableRowScope, fontFamily: "Bold" }}>
                        <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 1 }}>Protocolo</Text>

                    </View>
                    <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                        <Text style={{ ...styles.tableColScope, borderBottomWidth: 1, borderRightWidth: 1, backgroundColor: "black", color: "white" }}>Protocolos de la capa de enlace de datos</Text>
                    </View>
                    <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                        <Text style={{ ...styles.tableColScope, borderBottomWidth: 1, borderRightWidth: 1 }}>Ethernet</Text>
                    </View>
                    <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                        <Text style={{ ...styles.tableColScope, borderBottomWidth: 1, borderRightWidth: 1 }}>ARP</Text>
                    </View>
                    <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                        <Text style={{ ...styles.tableColScope, borderBottomWidth: 1, borderRightWidth: 1 }}>LLDP</Text>
                    </View>
                    <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                        <Text style={{ ...styles.tableColScope, borderBottomWidth: 1, borderRightWidth: 1, backgroundColor: "black", color: "white" }}>Protocolos de la capa de red</Text>
                    </View>
                    <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                        <Text style={{ ...styles.tableColScope, borderBottomWidth: 1, borderRightWidth: 1 }}>IP</Text>
                    </View>
                    <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                        <Text style={{ ...styles.tableColScope, borderBottomWidth: 1, borderRightWidth: 1 }}>ICMP</Text>
                    </View>
                    <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                        <Text style={{ ...styles.tableColScope, borderBottomWidth: 1, borderRightWidth: 1 }}>IGMP</Text>
                    </View>
                    <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                        <Text style={{ ...styles.tableColScope, borderBottomWidth: 1, borderRightWidth: 1, backgroundColor: "black", color: "white" }}>Protocolo de la capa de transporte de datos</Text>
                    </View>
                    <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                        <Text style={{ ...styles.tableColScope, borderBottomWidth: 1, borderRightWidth: 1 }}>TCP</Text>
                    </View>
                    <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                        <Text style={{ ...styles.tableColScope, borderBottomWidth: 1, borderRightWidth: 1, backgroundColor: "black", color: "white" }}>Protocolo de la capa de aplicación</Text>
                    </View>
                    <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                        <Text style={{ ...styles.tableColScope, borderBottomWidth: 1, borderRightWidth: 1 }}>HTTP</Text>
                    </View>

                </View>
                <Text style={{ ...styles.textua, marginLeft: 70, marginRight: -80, fontFamily: "Griff", fontSize: 8, marginTop: 3, color: "gray" }} >Tabla 1. Listado de protocolos</Text>
            </View>
            <View style={styles.column}>
                <Text style={{ ...styles.textua, fontFamily: "Bold", marginLeft: 12, fontSize: 12, marginRight: 0 }}>4.2. Puesta en marcha</Text>
                <Text style={{ ...styles.textua, marginRight: 40, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }}>En la puesta en marcha de los tests se han identificado las siguientes anomalías:</Text>
                <Text style={{ ...styles.textua, marginRight: 40, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }}>Ejecución fallida de los tests de TCP por imposibilidad de Achilles de conectarse al DUT. Se ha identificado que los fallos en el monitor T del test Receive Window y Selective Acknowledgement se han producido por no cumplir uno de los requisitos, el servicio TCP Echo, tiene que estar ejecutándose en el DUT durante la prueba. Esto es algo lógico, ya que al no tener ese servicio activo y no ser explotable el test no se puede llevar a cabo. Se ha continuado con el resto de las pruebas con normalidad.</Text>

            </View>
        </View>

        <Footer></Footer>
    </Page>
)

const VulnsSection2: React.FC<{}> = () => (
    <Page size="A4">
        <Header></Header>
        <View style={{ ...VexDocumentStyles.section, flexDirection: "row" }}>
            <View style={styles.column}>
                {resumeTable.map((point, index) => (
                    (index === 2 || index === 3) && (
                        <Text key={index} style={{ ...styles.puntuak, top: 2, marginLeft: 50, marginRight: 50, fontFamily: "Griff", fontSize: 10 }}>
                            <Text style={styles.puntuak}>• &nbsp;</Text>
                            <Text style={{ ...styles.puntuak, marginRight: 20, fontFamily: "Bold", fontSize: 10 }}>{point.id}</Text>
                            <Text style={{ ...styles.puntuak, marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>{point.description}</Text>
                        </Text>
                    )
                ))}
                {detailTable.map((point, index) => (
                    <Text key={index} style={{ ...styles.puntuak, marginRight: 20, marginLeft: index > 0 ? 50 : 0, fontFamily: "Griff", fontSize: 9 }}>
                        <Text style={styles.puntuak}>• &nbsp;</Text>
                        <Text style={{ ...styles.puntuak, marginRight: 20, fontFamily: "Bold", fontSize: 10 }}>{point.id}</Text>
                        <Text style={{ ...styles.puntuak, marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>{point.description}</Text>
                    </Text>
                ))}
                <Text style={{ ...styles.textua, marginRight: 40, fontFamily: "Griff", fontSize: 10 }}>An example of this type of table is shown below:</Text>
                <Image style={{ ...styles.image, width: 300, height: 200, paddingLeft: 40 }} src="./assets/img/report/tableDetail2.jpg" />
                <Text style={{ ...styles.textua, marginRight: 50, fontFamily: "Griff", fontSize: 8, marginTop: 8, color: "gray" }} >Figure 1. Detail table format</Text>
            </View>
            <View style={styles.column}>
                <Text style={{ ...styles.textua, marginRight: 40, fontFamily: "Griff", fontSize: 10 }}>In the following sections, the summary table will be shown first, and then for each vulnerability its corresponding detail table.</Text>
            </View>
        </View>
        <Footer></Footer>
    </Page>
)

export { VulnsSection, VulnsSection2 }
