
import { Assessment } from "@models/index"
import { MediaTypes } from "@services/index"
import Service from "@services/abstract/BaseService"

class AssessmentService extends Service<Assessment> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/assessment", MediaTypes.JSON)
    }
}

export { AssessmentService }
export default AssessmentService
