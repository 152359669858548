import React, { useContext, useEffect, useState } from "react"
import {
    Alert,
    Box,
    Button,
    Chip,
    Grid,
    Typography,
    useTheme
} from "@mui/material"
import { Compliance, ComplianceResult, FunctionalRequirementCategory, RegistryData } from "@models/Compliance"
import { ComplianceIndicator } from "@components/common/indicator/ComplianceIndicator"
import { useNavigate } from "react-router-dom"
import { FaFileExport } from "react-icons/fa"
import { pdf } from "@react-pdf/renderer"
import VulnReportDocumentCompliance from "@components/report/VulnReportDocumentCompliance"
import ServicesContext from "@context/ServicesContext"
import { IterableList } from "@models/iterable"
import Can from "@components/permissions/Can"
import TextareaDialog from "@components/dialogs/TextareaDialog"
import QuizIcon from "@mui/icons-material/Quiz"
import EditIcon from "@mui/icons-material/Edit"
import { QueryParameters } from "@utils/queryParams"
import GradiantGauge from "@components/charts/gauge/GradientGauge"
import { I18nContext } from "I18nProvider"
import { questions } from "../../components/compliance/QuestionData"

const ComplianceSummary: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const navigate = useNavigate()
    const [score, setScore] = useState(0)
    const initRegistry: RegistryData = Object.fromEntries(
        ["Time", "q1", "q2", "q3", "q4", "q5", "q6", "q7", "q8", "q9", "q10", "q11", "q12"].map(key => [key, ""])
    ) as RegistryData
    const initValue = {
        id: "",
        registry: [initRegistry],
        conclusion: "",
        clientID: ""
    }
    type ColorMapCategoryType = {
        [key in FunctionalRequirementCategory]: string;
      };
    const colorCategoryMap : ColorMapCategoryType = {
        [FunctionalRequirementCategory.Security]: theme.palette.primary.main,
        [FunctionalRequirementCategory.Vulnerability]: theme.palette.primary.main
    }
    // TODO: por ahora solo se visualiza el primer elemento de result, queda guardar el resultado de cada pregunta

    const [error, setError] = useState<Error|null>(null)
    const [openConclusionDialog, setOpenConclusionDialog] = React.useState(false)
    const [data, setData] = useState<Compliance>(initValue)
    const [conclusion, setConclusion] = useState<string>("")

    const complianceService = useContext(ServicesContext).complianceService

    const calculateTotalScore = (compliance: Compliance): number => {
        let total = 0
        questions.forEach((question) => {
            compliance.registry.forEach((entry) => {
                if (Object.prototype.hasOwnProperty.call(entry, question.id)) {
                    const resultValue = compliance.registry[0][question.id as keyof RegistryData] as ComplianceResult || ComplianceResult.NonCompliant
                    const resultEnumValue = question.results.find((r) => r.result === resultValue)

                    if (resultEnumValue) {
                        total += resultEnumValue.value
                    }
                }
            })
        })

        return total
    }
    // Fetch product data from API
    useEffect(() => {
        const fetchData = async () => {
            try {
                const filters: QueryParameters = { sortField: "created_at", sortMode: "asc" }
                const val: IterableList<Compliance> = await complianceService.getAll(filters)
                const complianceArray = val.list
                if (complianceArray.length > 0) {
                    setData(complianceArray[0])
                    setError(null)
                    setScore(calculateTotalScore(complianceArray[0]))
                } else {
                    setError({ message: "No data found" } as Error)
                }
            } catch (e: any) {
                setError({ message: e.message } as Error)
            }
        }
        fetchData()
    }, [])

    const handleCloseDialog = () => {
        setOpenConclusionDialog(false)
    }

    const handleConfirmDialog = (newText: string) => {
        setConclusion(newText)
    }

    const HandleExport = async () => {
        try {
            const report = pdf(<VulnReportDocumentCompliance compliance={data}/>)
            const blob = await report.toBlob()
            const url = window.URL.createObjectURL(blob)
            window.open(url)
        } catch (error) {
            console.error(error)
        }
    }
    return (
        <Grid item>
            <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }}>
                <Can key="read" I="ownership" an="Compliance" ><Button variant="contained" sx={{ position: "absolute", top: "-40px", right: "273px", color: theme.palette.secondary.contrastText }} onClick={() => setOpenConclusionDialog(true)} startIcon={<EditIcon />}>{context.t.translate("CRA_conclusion")}</Button></Can>
                <Can key="read" I="read" an="Compliance" ><Button variant="contained" sx={{ position: "absolute", top: "-40px", right: "140px", color: theme.palette.secondary.contrastText }} onClick={HandleExport} startIcon={<FaFileExport />} >{context.t.translate("dash_export")}</Button></Can>
                <Can key="read" I="read" an="Compliance" ><Button variant="contained" sx={{ position: "absolute", top: "-40px", right: "-20px", color: theme.palette.secondary.contrastText }} onClick={() => navigate("./quiz")} startIcon={<QuizIcon />}>{context.t.translate("CRA_quiz")}</Button></Can>

                <Grid container direction="column" spacing={2} rowGap="35px" style={{ height: "100%" }}>
                    <Grid item >
                        {error && <Alert severity="error" sx={{ marginTop: "20px" }}>{error.message}</Alert>}
                        <Box sx={{ borderRadius: "10px", border: "1px solid #6D6C6F", padding: "25px", marginTop: "15px" }}>
                            {/* Parte superior: Resumen de las preguntas */}
                            <Grid container item justifyContent="space-between">
                                <Grid container item xs={12} justifyContent="space-between" >
                                    <Grid item xs={4} >
                                        <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
                                            {context.t.translate("CRA_title")}
                                        </Typography>
                                        <Chip
                                            label={FunctionalRequirementCategory.Security}
                                            sx={{
                                                backgroundColor: colorCategoryMap[FunctionalRequirementCategory.Security],
                                                color: theme.palette.secondary.contrastText,
                                                fontWeight: "bold",
                                                borderRadius: "4px",
                                                marginTop: "15px",
                                                marginBottom: "15px"

                                            }}/>
                                        {questions.filter((question) => question.category === FunctionalRequirementCategory.Security).map((question, index) => (
                                            <Box key={question.id} marginBottom={2}>
                                                <Typography variant="subtitle1">
                        ( {question.id.toUpperCase()} ) {question.title}:
                                                </Typography>
                                                <Box key={question.id} >
                                                    <ComplianceIndicator result={data.registry[data.registry.length - 1][question.id as keyof RegistryData] as ComplianceResult || ComplianceResult.NonCompliant }/>
                                                </Box>

                                            </Box>
                                        ))}
                                    </Grid>

                                    <Grid item xs={4} sx={{ marginTop: "45px" }}>
                                        <Chip
                                            label={FunctionalRequirementCategory.Vulnerability}
                                            sx={{
                                                backgroundColor: colorCategoryMap[FunctionalRequirementCategory.Vulnerability],
                                                color: theme.palette.secondary.contrastText,
                                                fontWeight: "bold",
                                                borderRadius: "4px",
                                                marginTop: "25px",
                                                marginBottom: "15px"
                                            }}/>
                                        {questions.filter((question) => question.category === FunctionalRequirementCategory.Vulnerability).map((question, index) => (
                                            <Box key={question.id} marginBottom={2}>
                                                <Typography variant="subtitle1" marginBottom={2}>
            ( {question.id.toUpperCase()} ) {question.title}:
                                                    <ComplianceIndicator result={data.registry[0][question.id as keyof RegistryData] as ComplianceResult || ComplianceResult.NonCompliant}/>
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Grid>
                                    <Grid item xs={4} sx={{ marginTop: "45px" }}>
                                        <Box sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: "10px",
                                            backgroundColor: "#1e1e1f",
                                            borderRadius: "10px",
                                            border: `1px solid ${theme.palette.primary.main}`,
                                            marginBottom: "20px"
                                        }}>
                                            <Grid container direction="column" alignItems="center">
                                                <Grid item xs={12} textAlign="center">
                                                    <Typography fontSize="34px" fontFamily="Griff" color={theme.palette.primary.contrastText} fontWeight="bolder">
                                                        {context.t.translate("CRA_total")}                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
                                                    <GradiantGauge value={parseInt((score * 100 / 210).toFixed(4))} />
                                                </Grid>
                                            </Grid>

                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    {/* <Grid item sx={{ flexGrow: 1, display: "flex" }} >
                        <Box sx={{ borderRadius: "10px", border: "1px solid #6D6C6F", width: "100%", padding: "25px" }}>
                            <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
          Details
                            </Typography>
                            <Grid item sx={{
                                maxHeight: "450px", // Altura máxima del contenedor de las tarjetas
                                overflowY: "auto", // Hacer que sea scrollable verticalmente
                                paddingRight: "2px"
                            }}>

                                {questions.map((question) => (
                                    <Card key={question.id} sx={{ marginBottom: "10px" }}>
                                        <CardHeader title={question.id.toUpperCase() + " : " + question.title} />
                                        <Divider></Divider>
                                        <CardContent>
                                            <Typography variant="body2" color="textSecondary" gutterBottom>
                                                {question.description}
                                            </Typography>
                                            <List>

                                                <Box key={question.id} marginBottom={2}>
                                                    <Typography variant="subtitle1" marginBottom={2}>
                                                        <ListItem key={question.id}>
                                                            <ListItemText
                                                                primary={
                                                                    <Box sx={{ mb: "20px" }}>
                                                                        <ComplianceIndicator result={data.registry[0][question.id as keyof RegistryData] as ComplianceResult || ComplianceResult.NonCompliant}/>
                                                                    </Box>}
                                                                // TODO:
                                                                secondary={<React.Fragment>
                                                                    <Typography
                                                                        sx={{ display: "inline" }}
                                                                        component="span"
                                                                        variant="body2"
                                                                        color="text.primary"
                                                                    >
                                                                        {question.results.filter((r) => r.result === data.registry[data.registry.length - 1][question.id as keyof RegistryData] as ComplianceResult || ComplianceResult.NonCompliant)[0].text}
                                                                    </Typography>
                                                                </React.Fragment>}
                                                            />
                                                        </ListItem>
                                                    </Typography>
                                                </Box>
                                            </List>
                                        </CardContent>
                                    </Card>
                                ))}
                            </Grid>
                        </Box>
                    </Grid> */}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <TextareaDialog
                    open={openConclusionDialog}
                    initialText={context.t.translate("CRA_text")}
                    title={context.t.translate("CRA_edit")}
                    handleCloseDialog={handleCloseDialog}
                    handleConfirmDialog={handleConfirmDialog}
                />
            </Grid>
        </Grid>
    )
}

export default ComplianceSummary
