import moment from "moment"

const TIME_FORMAT = "YYYY-MM-DDTHH:mm:ssZ"

const generateDateValue = (val: string|null|undefined) =>
    val != null ? moment(val, TIME_FORMAT) : null

const serializeDateToString = (val: moment.Moment|null) =>
    val?.format(TIME_FORMAT)

export { generateDateValue, serializeDateToString }
