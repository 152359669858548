import { ProductType } from "@models/Product"

export const ProductTypeColors: Record<ProductType, string> = {
    application: "orange", // Violet
    library: "#fdffb5", // Wisteria
    firmware: "#8dd3c7", // Gamboge
    "operating-system": "#477db8", // spring green
    container: "#4db04a", // electric blue
    device: "#b4de6c" // lincoln green
}
