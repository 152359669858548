import React from "react"
import { Image, Text, View } from "@react-pdf/renderer"
import VexDocumentStyles from "@components/report/VexStyleSheet"
import styles from "@components/report/Styles"

const Header: React.FC<{}> = () => (
    <View fixed>
        <Image style={styles.image4} src="./assets/img/report/encabezado.jpg" />
        <Text style={{ ...styles.textoEncima }}>SW Composition Analysis</Text>
        <Text>&nbsp;</Text>
    </View>
)

const Footer: React.FC<{}> = () => (
    <View fixed>
        <Text>&nbsp;</Text>
        <View style={{ ...VexDocumentStyles.section, marginLeft: -20, marginRight: -20, paddingTop: 0, marginBottom: 0, backgroundColor: "#fffa37", flexDirection: "row" }}>

            <Text style={{ paddingLeft: 32, paddingRight: 150, paddingBottom: 32, paddingTop: 32, fontSize: 5.5 }}>
            The intellectual and industrial property rights over this document are the exclusive property of ORBIK Cybersecurity, S.L.
            Likewise, this report and, where applicable, any document annexed to it, contains confidential information exclusively addressed to its recipient.
                <Text style={{ fontFamily: "Bold", color: "#FFC000", backgroundColor: "black" }}>TLP-AMBER</Text> Its disclosure, copy or distribution to third parties is prohibited without the prior written authorization of ORBIK Cybersecurity.
            If you have received this report in error, proceed to delete it and immediately notify ORBIK Cybersecurity, S.L.
            </Text>
            <Text style={styles.numero} render={({ pageNumber, totalPages }) => `${pageNumber} `} />
        </View>
    </View>
)

export { Header, Footer }
