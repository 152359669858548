import { Grid, MenuItem, TextField, Typography, useTheme } from "@mui/material"
import React, { useContext, useEffect } from "react"
import HighchartsReact from "highcharts-react-official"
import Highcharts from "highcharts"
import HighchartsMore from "highcharts/highcharts-more"
import HighchartsExporting from "highcharts/modules/exporting"
import HighchartsExportData from "highcharts/modules/export-data"
import HighchartsAccessibility from "highcharts/modules/accessibility"
import { CVSS3SeverityColors } from "@components/common/colors/CVSS3SeverityColors"
import ServicesContext from "@context/ServicesContext"
import { BenchmarkLifeCycle } from "@models/Benchmark"

HighchartsMore(Highcharts)
HighchartsExporting(Highcharts)
HighchartsExportData(Highcharts)
HighchartsAccessibility(Highcharts)

const VulnerabilityEvolutionChart: React.FC = () => {
    const theme = useTheme()

    const benchmarkService = useContext(ServicesContext).benchmarkService
    const [data, setData] = React.useState<BenchmarkLifeCycle[]>([])
    const [interval, setInterval] = React.useState("monthly")
    useEffect(() => {
        const fetchData = async () => {
            const data = await benchmarkService.getBenchmarkLifeCycle(interval)
            setData(data)
        }
        fetchData()
    }, [interval])

    const options: Highcharts.Options = {
        chart: {
            type: "line",
            backgroundColor: theme.palette.secondary.contrastText, // Set the background color of the chart area
            spacingTop: 10,
            spacingRight: 10,
            spacingBottom: 10,
            spacingLeft: 10,
            plotBorderWidth: 1,
            plotBorderColor: theme.palette.secondary.contrastText, // Set the border color of the chart area
            margin: [0, 0, 90, 0]
        },
        title: {
            text: "",
            style: {
                color: theme.palette.primary.contrastText // Set title text color to white
            }
        },
        subtitle: { },
        xAxis: {
            type: "datetime",
            categories: data.map(item => item.vulnerability_detection),
            labels: {
                style: {
                    color: theme.palette.primary.contrastText
                }
            }
        },
        yAxis: {
            title: {
                text: "Number of Vulnerabilities",
                style: {
                    color: theme.palette.primary.contrastText
                }
            },
            labels: {
                style: {
                    color: theme.palette.primary.contrastText
                },
                formatter: function () {
                    return this.value.toString()
                }
            },
            tickInterval: 1
        },
        legend: {
            layout: "horizontal",
            align: "center",
            verticalAlign: "bottom",
            itemMarginBottom: -10,
            itemStyle: {
                color: theme.palette.primary.contrastText // Set legend text color to white
            }
        },
        plotOptions: {
            line: {
                marker: {
                    enabled: true,
                    symbol: "circle", // Use circle as marker symbol
                    radius: 6 // Set marker size to 6
                }
            }
        },
        series: [{
            type: "line",
            name: "Critical",
            data: data.map(item => item.critical_vulnerabilities),
            color: CVSS3SeverityColors.critical
        }, {
            type: "line",
            name: "High",
            data: data.map(item => item.high_vulnerabilities),
            color: CVSS3SeverityColors.high
        }, {
            type: "line",
            name: "Medium",
            data: data.map(item => item.medium_vulnerabilities),
            color: CVSS3SeverityColors.medium
        }, {
            type: "line",
            name: "Low",
            data: data.map(item => item.low_vulnerabilities),
            color: CVSS3SeverityColors.low
        }, {
            type: "line",
            name: "None",
            data: data.map(item => item.none_vulnerabilities),
            color: "gray"
        }],
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        layout: "horizontal",
                        align: "center",
                        verticalAlign: "bottom"
                    }
                }
            }]
        },
        exporting: {
            enabled: false // Disable the context chart menu
        },
        navigator: {
            enabled: true,
            adaptToUpdatedData: true
        },
        scrollbar: {
            enabled: true
        },
        rangeSelector: {
            enabled: false
        }
    }

    return (

        <div>
            <Grid item xs={12} sm={4}>
                <TextField margin="normal" select required fullWidth variant="filled" name="frequency" value={interval} onChange={(event) => setInterval(event.target.value)}>
                    <MenuItem value="daily" ><Typography fontFamily="Griff" fontWeight="bolder">Daily</Typography></MenuItem>
                    {/* <MenuItem value="weekly" ><Typography fontFamily="Griff" fontWeight="bolder">Weekly</Typography></MenuItem> */}
                    <MenuItem value="monthly" ><Typography fontFamily="Griff" fontWeight="bolder">Monthly</Typography></MenuItem>
                    <MenuItem value="yearly" ><Typography fontFamily="Griff" fontWeight="bolder">Yearly</Typography></MenuItem>
                </TextField>
            </Grid>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    )
}

export { VulnerabilityEvolutionChart }
export default VulnerabilityEvolutionChart
