import { VulnerabilityState } from "@models/Vulnerability"

export const VulnerabilityStateColors: Record<VulnerabilityState, string> = {
    detected: "#8dd3c7", // baby blue
    in_triage: "#e49029", // mahogany orange
    exploitable: "#7940A5", // scarlet red
    resolved: "#4db04a", // malachite green
    false_positive: "orange", // peach brown
    not_affected: "#A9A9A9" // lilac grey
}
