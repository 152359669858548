import React from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import highchartsAccessibility from "highcharts/modules/accessibility"
import { Typography, useTheme } from "@mui/material"
import { CVSS3Severity } from "@models/index"

highchartsAccessibility(Highcharts)

interface Donut3Values {
    title: string;
    values: { [property in CVSS3Severity]: number };
    number: number;
}

const DonutChart3: React.FC<Donut3Values> = (props: Donut3Values) => {
    const theme = useTheme()
    const { title, values, number } = props
    const seriesData = [
        { name: "Critical", y: values.critical, color: "#7940A5" },
        { name: "High", y: values.high, color: "#D9534F" },
        { name: "Medium", y: values.medium, color: "#EC971F" },
        { name: "Low", y: values.low, color: "#F2CC0C" },
        { name: "None", y: values.none, color: "#A9A9A9" }
    ].filter(data => data.y > 0) // Filtra las categorías con valores > 0

    const options: Highcharts.Options = {
        title: {
            text: undefined
        },
        chart: {
            type: "pie",
            backgroundColor: "transparent",
            height: 150,
            width: 290,
            spacingTop: 0,
            spacingRight: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            plotBorderWidth: 0, // esto barras
            margin: [0, -10, 5, -20]
        },
        credits: { enabled: false },
        legend: {
            enabled: true,
            title: {
                text: title,
                style: {
                    fontSize: "20px",
                    fontFamily: "Griff",
                    color: theme.palette.primary.contrastText,
                    fontWeight: "normal"
                }
            },
            align: "right",
            layout: "vertical",
            verticalAlign: "top",
            itemStyle: { color: theme.palette.primary.contrastText },
            padding: 3,
            itemMarginTop: 5,
            itemMarginBottom: 5,
            width: 140,
            y: -6
        },
        plotOptions: {
            pie: {
                innerSize: "48%",
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    enabled: false
                },
                center: [75, 60]
            },
            series: {
                states: {
                    hover: {
                        halo: null
                    }
                }
            }
        },
        exporting: {
            enabled: false
        },
        series: [{
            showInLegend: true,
            data: seriesData,
            type: "pie",
            borderColor: theme.palette.secondary.contrastText
        }],
        tooltip: {
            pointFormatter: function () {
                const yValue = this.y !== undefined ? this.y : "N/A"
                const total = values.critical + values.high + values.medium + values.low + values.none
                const percentage = this.y !== undefined ? (this.y / total * 100).toFixed(2) : "N/A"
                return `<b>${yValue}</b></br><b>${percentage}%</b>`
            }
        }
    }
    const leftPositionNumber = props.number < 10 ? "70px" : props.number < 100 ? "65px" : "60px"
    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
            <Typography
                component="span"
                fontSize="24px"
                lineHeight="55px"
                fontFamily="Griff"
                sx ={{ position: "relative", top: "-100px", left: leftPositionNumber }}
            >    {props.number}</Typography>
        </div>
    )
}

export { DonutChart3 }
export default DonutChart3
