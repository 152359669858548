import React, { useContext, useEffect, useState } from "react"
import { Alert, Box, Button, Divider, Grid, TextField, Typography, useTheme } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { ServicesContext } from "@context/index"
import { Client } from "@models/index"
import Products from "@views/home/Products"

const initValue = {
    id: "",
    name: "",
    description: ""
}

const ClientDetail: React.FC = () => {
    const theme = useTheme()
    const clientService = useContext(ServicesContext).clientService

    const [error, setError] = useState<Error|null>(null)

    const { id } = useParams<{ id: string }>()

    const [data, setData] = useState<Client>(initValue)
    const [formData, setFormData] = useState<Client>(initValue)
    const formValid = !Object.keys(formData).every((key) => (formData as any)[key] === (data as any)[key])

    const handleChange = (e:any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    useEffect(() => {
        const fecthData = async () => {
            try {
                const val = await clientService.get(id as string)
                setData(val)
                setFormData(val)
            } catch (e) {
                setError(e as Error)
            }
        }
        fecthData()
    }, [])

    const navigate = useNavigate()

    const saveHandler = async () => {
        try {
            await clientService.update(formData.id, formData)
            setData(formData)
            setError(null)
            navigate(-1)
        } catch (e) {
            setError(e as Error)
        }
    }

    return (<Grid item>
        <Grid item xs container flexDirection="column">
            <Typography color={theme.palette.secondary.main} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                Client
            </Typography>
        </Grid>
        <Grid item xs container flexDirection="column" spacing="20px">
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>
                    <Box sx={{ borderRadius: "10px", border: "1px solid #6D6C6F", width: "100%", padding: "25px" }}>
                        {error && <Alert severity="error">{error.message}</Alert>}
                        <Grid container flexDirection="column" spacing="20px">
                            <Grid item flexDirection="row">
                                <Grid container flexDirection="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ marginTop: "10px" }}>
                                    <Grid item>
                                        <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
                                        </Typography>
                                    </Grid>
                                    <Grid item><Button variant="contained" sx={{ color: theme.palette.secondary.contrastText }} onClick={saveHandler} disabled={!formValid}>Save Changes</Button></Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Divider sx={{ height: "1px", width: "100%", background: theme.palette.secondary.dark }} />
                            </Grid>

                            <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                                <Grid item xs={3}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Name</Typography>
                                    <TextField name="name" value={formData.name} onChange={handleChange} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Description</Typography>
                                <TextField margin="normal" required fullWidth variant="standard" name="description"
                                    value={formData.description} onChange={handleChange}/>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid >
        </Grid >
        <Grid item xs container flexDirection="column" spacing="20px">
            <Grid item container flexDirection="column" paddingTop="0 !important" rowGap="35px">
                <Products></Products>
            </Grid >
        </Grid >
    </Grid>
    )
}

export { ClientDetail }
export default ClientDetail
