import React, { useEffect, useState } from "react"
import { Autocomplete, CircularProgress, TextField } from "@mui/material"
import { useFetch } from "@hooks/index"

interface Props<E>{
    label: string
    margin: "dense" | "normal" | "none"
    required?: boolean
    variant: "standard" | "filled" | "outlined"
    value?: string
    disabled?: boolean
    requester: (filter: string)=>Promise<E[]>
    requesterById: (filter: string)=>Promise<E[]>
    getterID: (elem: E)=>any
    getterTitle: (elem: E)=>string
    onClickElem: (elem: E|null)=>void
}

function SearchInput<E> (
    { label, required, margin, variant, value, disabled, requester, requesterById, getterID, getterTitle, onClickElem }: Props<E>
) {
    const [open, setOpen] = useState(false)

    const [queryValue, setQueryValue] = useState<string>("")
    const [inputValue, setInputValue] = useState<E|null>(null)
    const [options, err, status] = useFetch(queryValue, requester, true, 1000)
    const [valById, errId, statusId] = useFetch(value as string, requesterById, false, 0)
    const [error, setError] = useState<Error|null>(null)
    const loading = open && (status === "debounced" || status === "fetching")
    useEffect(() => {
        if (valById.length > 0) {
            setInputValue(valById[0])
        }
    }, [valById])

    useEffect(() => {
        if (status === "error") {
            setError(err as Error)
            return
        } else if (status === "idle") {
            if (statusId === "error") {
                setError(errId as Error)
                return
            }
        }
        setError(null)
    }, [status, statusId])

    const updateValue = (newValue: string) => {
        setQueryValue(newValue)
    }

    return (
        <Autocomplete
            fullWidth
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            isOptionEqualToValue={(option, value) => getterID(option) === getterID(value)}
            getOptionLabel={(option) => getterTitle(option)}
            options={options}
            loading={loading}
            value={inputValue}
            disabled={disabled}
            onInputChange={(_: any, newValue: string) => {
                updateValue(newValue)
            }}
            onChange={(_: any, newValue: E | null) => {
                setInputValue(newValue)
                onClickElem(newValue)
            }}
            renderInput={(params) => (
                <TextField
                    error={error != null}
                    helperText={error?.message}
                    {...params}
                    label={label}
                    variant={variant}
                    required={required}
                    margin={margin}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                />
            )}
        />

    )
}

export { SearchInput }
export default SearchInput
