import Box from "@mui/material/Box"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { DataGrid, GridActionsCellItem, type GridColDef, GridFilterModel, GridRowParams, GridSortModel } from "@mui/x-data-grid"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import { Alert, Tooltip } from "@mui/material"
import { AbilityContext } from "@components/permissions/index"
import { convertGridFilterModelToQueryParameters } from "@components/common/tables/index"
import { IterableList } from "@models/iterable"
import { QueryParametersToURL } from "@services/index"
import { Subjects } from "@permissions/ability"
import { QueryParameters } from "@utils/index"
interface GenericTableProps<E> {
    entity: Subjects,
    columns: GridColDef[],
    withTags?: boolean,
    dataProvider: (filter: QueryParameters)=>Promise<IterableList<E>>
    onEdit?: (elem: E)=>void,
    onDelete?: (elem: E)=> Promise<any>,
    customActions?: (params: GridRowParams<any>)=>React.JSX.Element[],
    handleAddTagOpen?: (params: GridRowParams<any>)=>void
    handleVisualizeTagOpen?: (elem: E)=>void
    chips?: { label: string; style: React.CSSProperties }[],
    dataGridProps?: any, // TODO: Eliminar
    externalParameters?: QueryParameters
}

function GenericTable<E> ({ entity, columns, withTags, dataProvider, onEdit = () => {}, onDelete = async () => true, customActions, handleAddTagOpen, handleVisualizeTagOpen, chips, dataGridProps, externalParameters }: GenericTableProps<E>) {
    const ability = useContext(AbilityContext)
    const [error, setError] = useState<Error|null>(null)

    const [queryOptions, setQueryOptions] = useState<GridFilterModel|null>(null)
    const [sortOptions, setSortOptions] = useState<GridSortModel>([])
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    })
    const [loading, setLoading] = useState(false)
    const [rows, setRows] = useState<E[]>([])
    const [rowCountState, setRowCountState] = React.useState(0)
    const [queryString, setQueryString] = useState<string>(QueryParametersToURL(convertGridFilterModelToQueryParameters(queryOptions, sortOptions, paginationModel)).join("&"))

    const onFilterChange = useCallback((filterModel: GridFilterModel) => {
        setQueryOptions({ ...filterModel })
    }, [])

    const [previousFilters, setPreviousFilters] = useState<GridFilterModel|null>(queryOptions)
    useEffect(() => {
        if (externalParameters && externalParameters.filters && externalParameters.filters.length > 0) {
            const aux = queryOptions ? Object.assign({}, queryOptions) : null
            setPreviousFilters(aux)
            const newFilter: GridFilterModel = {
                items: externalParameters.filters.map((filter) => ({
                    field: filter.field,
                    operator: filter.operation,
                    value: filter.value
                }))
            }
            if (aux && aux.items && aux.items.length > 0) {
                const aux2 = Object.assign({}, aux)
                newFilter.items.forEach((item) => {
                    aux2.items = aux2.items.filter((i) => i.field !== item.field).concat(item)
                })
                setQueryOptions(aux2)
            } else {
                setQueryOptions(newFilter)
            }
        } else {
            setQueryOptions(previousFilters)
        }
    }, [externalParameters])

    const onSortChange = useCallback((sortModel: GridSortModel) => {
        setSortOptions([...sortModel])
    }, [])

    const fetchData = async () => {
        try {
            setLoading(true)
            const { list, count } = await dataProvider(convertGridFilterModelToQueryParameters(queryOptions, sortOptions, paginationModel))
            setRows(list)
            setRowCountState(count)
            setLoading(false)

            if (count < (paginationModel.page * paginationModel.pageSize)) {
                setPaginationModel({ page: paginationModel.page - 1, pageSize: paginationModel.pageSize })
            }
        } catch (e) {
            setError(e as Error)
        }
    }

    useEffect(() => {
        fetchData()
    }, [queryString])

    useEffect(() => {
        setQueryString(QueryParametersToURL(convertGridFilterModelToQueryParameters(queryOptions, sortOptions, paginationModel)).join("&"))
    }, [queryOptions, sortOptions, paginationModel])

    const [fColumns, setFColumns] = useState(columns)

    useEffect(() => {
        const cols = [...columns]

        // Lógica para agregar la columna de "Actions"
        if (ability.can("update", entity) || ability.can("delete", entity) || customActions != null) {
            let count = 0
            if (ability.can("update", entity)) count++
            if (ability.can("delete", entity)) count++
            if (customActions != null) count += customActions({ id: "", row: {}, columns: [] }).length

            cols.push({
                field: "Actions",
                type: "actions",
                headerName: "Actions",
                flex: count * 0.1,
                minWidth: count <= 2 ? 100 : 210,
                getActions: (params) => {
                    let acts: React.JSX.Element[] = []
                    if (customActions != null) {
                        acts = [...customActions(params)]
                    }
                    if (ability.can("update", entity)) {
                        acts.push(
                            <GridActionsCellItem
                                key="Edit"
                                icon={<Tooltip title="Edit"><EditIcon /></Tooltip>}
                                label="Edit"
                                onClick={() => onEdit(params.row)}
                            />
                        )
                    }
                    if (ability.can("delete", entity)) {
                        acts.push(
                            <GridActionsCellItem
                                key="Delete"
                                icon={<Tooltip title="Delete"><DeleteIcon /></Tooltip>}
                                label="Delete"
                                onClick={async () => {
                                    await onDelete(params.row)
                                    fetchData()
                                }}
                            />
                        )
                    }
                    return acts
                }
            })
        }

        // Establecer las nuevas columnas
        setFColumns(cols)
    }, [columns])

    const [columnVisibility, setColumnVisibility] = useState({})
    useEffect(() => {
        setColumnVisibility(columns.reduce((result, { field, hide }: any) => {
            return hide ? { ...result, [field]: false } : { ...result }
        }, {}))
    }, [columns])
    return (
        <Box sx={{ height: (paginationModel.pageSize + 1) * 52 + 59, width: "100%" }}>
            {error && <Alert severity="error">{error.message}</Alert>}
            <DataGrid
                rows={rows}
                columns={fColumns}
                columnVisibilityModel={columnVisibility}
                onColumnVisibilityModelChange={setColumnVisibility}
                filterMode="server"
                sortingMode="server"
                paginationMode="server"
                onFilterModelChange={onFilterChange}
                onSortModelChange={onSortChange}
                onPaginationModelChange={setPaginationModel}
                rowCount={rowCountState}
                loading={loading && !error}
                pageSizeOptions={[5, 10, 25]}
                paginationModel={paginationModel}
                // checkboxSelection
                disableRowSelectionOnClick
                getRowId={(row) => row.id}
                sx={{
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
                        outline: "none "
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": { outline: "none" }
                }}
                {...dataGridProps}
            />
        </Box>
    )
}

export { GenericTable }
export default GenericTable
