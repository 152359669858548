import { AssessmentStatus, Client, ClientSector } from "@models/index"
import { getEnumKeys, ModelDefinition } from "@models/utils"
import ClientSectorColored from "./ClientSectorColored"

const ClientSectorKeys = getEnumKeys(ClientSector)
const ClientSectorKeysValueOps = ClientSectorKeys.map((key) => ({ label: key, value: ClientSector[key] }))

const AssessmentStatusKeys = getEnumKeys(AssessmentStatus)
const AssessmentStatusKeysValueOps = AssessmentStatusKeys.map((key) => ({ label: key, value: AssessmentStatus[key] }))

const ClientTableDefinition: ModelDefinition<Client> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 0.1, isId: true },
        { name: "name", label: "Name", type: "string", flex: 0.4 },
        { name: "description", label: "Description", flex: 1, type: "string" },
        { name: "sector", label: "Sector", type: "singleSelect", flex: 0.3, allowedValueOptions: ClientSectorKeysValueOps, render: ClientSectorColored },
        { name: "license_validation_date", label: "License Validation Date", type: "string", flex: 0.2 }
    ]
}

export { ClientTableDefinition, ClientSectorKeysValueOps, AssessmentStatusKeysValueOps }
export default ClientTableDefinition
