import { getEnumKeys } from "@models/utils"

type Client = {
 id: string;
 name: string;
 description: string;
 sector?: ClientSector;
 license_validation_date?: string;
}

enum ClientSector {
    Industry = "industry",
    EnergyAndElectricNetworks = "energy_and_electric_networks",
    Transport = "transport",
    Elevator = "elevator",
    IOTDevice = "iot_device",
    Automotive = "automotive",
    HealthCare = "health_care"
}

const splitPascalCase = (input: string): string => input.replace(/(?<!^)([A-Z][a-z])/g, " $1")
const ClientSectorKeys = getEnumKeys(ClientSector)
const ClientSectorKeysValueOps = ClientSectorKeys.map((key) => ({ label: splitPascalCase(key), value: ClientSector[key] }))

export { ClientSector }
export type { Client }
export default Client
