import React, { useEffect, useRef, useState } from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import sunburst from "highcharts/modules/sunburst"
import highchartsAccessibility from "highcharts/modules/accessibility"
import { Grid, Typography } from "@mui/material"
import SunburstChartBreadCrumb from "./SunburstChartBreadCrumb"

highchartsAccessibility(Highcharts)
sunburst(Highcharts)

interface SunburstChartProps {
    data: Highcharts.PointOptionsObject[],
    legend: React.JSX.Element
}

const SunburstChart: React.FC<SunburstChartProps> = (props: SunburstChartProps) => {
    const dataInstance = props.data
    const chartComponent = useRef<HighchartsReact.RefObject>(null)
    const chartText = useRef<HTMLElement>(null)
    const selectedPoint = useRef<null | string>(null)
    const [selectedPath, setSelectPath] = useState<Highcharts.Point[]>([])
    const [totalDevices, setTotalDevices] = useState<number>(0)

    useEffect(() => {
        const devices = dataInstance.reduce((acc, point) => acc + (point.value === null || point.value === undefined ? 0 : point.value), 0)
        setTotalDevices(devices)
    }, [dataInstance])

    const paintNode = (chart: undefined | null | Highcharts.Chart, id: string) => {
        if (id !== "") {
            /* if (chartText.current !== null) {
                chartText.current.innerHTML = ReactDomServer.renderToStaticMarkup(
                    <Grid container flexDirection="column" spacing="20px">
                        <Grid paddingTop="15px !important" item>
                            <Typography fontSize="34px" fontFamily="Griff" color="primary" fontWeight="bolder">All devices</Typography>
                        </Grid>
                        <Grid item>{totalDevices}</Grid>
                    </Grid>
                )
            } */
        }
        const points = chart?.series[0].points.reduce((acc: Highcharts.Point[], point) => {
            const isParentOrMyself = id === "" || ((point as any).id !== undefined && id.startsWith((point as any).id))
            const isGreyed = (point.color as any).length === 9
            if (isParentOrMyself) {
                if (isGreyed) {
                    point.update({ color: (point.color as any).substr(0, 7) })
                }
                acc.push(point)
            } else {
                if (!isGreyed) {
                    point.update({ color: (point.color as any) + "33" })
                }
            }
            return acc
        }, [])
        const path: undefined | Highcharts.Point[] = points?.sort((a: any, b: any) => a?.id.length - b?.id.length)
        setSelectPath(path !== undefined && id !== "" ? path : [])
    }

    const [chartOptions, setChartOptions] = useState<Highcharts.Options>({
        title: {
            text: undefined
        },
        chart: {
            height: "750px",
            backgroundColor: "transparent",
            spacingTop: 0,
            spacingRight: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            plotBorderWidth: 0,
            margin: [0, 0, 0, 0],
            events: {
                load: function (event) {
                    const chart = this
                    const renderer = chart.renderer
                    const centerX = chart.plotWidth / 2
                    const centerY = chart.plotHeight / 2
                    const height = chart.plotHeight / 3
                    const width = chart.plotWidth / 3
                    const text = "Inner Ring Label"

                    // Calculate the position based on your desired location
                    const x = centerX - width / 2
                    const y = centerY - height / 2

                    // Add the label using the renderer function
                    const html = renderer.createElement("foreignObject")
                        .attr({
                            x,
                            y,
                            width,
                            height
                        })
                        .add()
                    const ct = chartText as any
                    ct.current = html.element
                }
            }
        },
        credits: { enabled: false },
        plotOptions: {
            sunburst: {
                thickness: 150,
                allowPointSelect: true,
                allowTraversingTree: false,
                dataLabels: {
                    enabled: false
                }
            },
            series: {
                point: {
                    events: {
                        mouseOver: function () {
                            if (selectedPoint.current === null) {
                                const id: string = (this as any).id
                                paintNode(this.series.chart, id)
                            }
                        },
                        click: function () {
                            const id: string = (this as any).id
                            if (selectedPoint.current === id) {
                                selectedPoint.current = null
                            } else {
                                paintNode(this.series.chart, id)
                                selectedPoint.current = id
                            }
                        }
                    }
                }
            }
        },

        series: [{
            type: "sunburst",
            data: dataInstance,
            borderColor: "#000000"
        }]
    })

    const chart = chartComponent.current
    let height = 0
    let width = 0
    let x = 0
    let y = 0
    if (chart !== null) {
        const centerX = chart.chart.plotWidth / 2
        const centerY = chart.chart.plotHeight / 2
        height = chart.chart.plotHeight / 3
        width = chart.chart.plotWidth / 3
        x = centerX - width / 2
        y = centerY - height / 2
    }
    const specificPoint: any = selectedPath.length > 0 ? selectedPath[selectedPath.length - 1] : null
    return (
        <div onMouseLeave={() => {
            selectedPoint.current = null
            paintNode(chartComponent.current?.chart, "")
        }}>
            <Grid container>
                <Grid item xs={8}>
                    <div style={{ position: "relative" }}>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={chartOptions}
                            ref={chartComponent}
                        />
                        <Grid sx={{
                            position: "absolute",
                            top: y,
                            left: x,
                            height,
                            width
                        }} container flexDirection="column" justifyContent="center">
                            <Grid item container flexDirection="column">
                                <Grid item margin="auto">
                                    <Typography component="span" fontSize="34px" fontFamily="Griff" fontWeight="bolder" margin="auto" borderBottom="1px solid #6D6C6F">
                                        {specificPoint === null
                                            ? "All devices"
                                            : (selectedPath.length === 4 ? selectedPath[2].name : "") + " " + specificPoint.name}
                                    </Typography>
                                </Grid>
                                <Grid item container flexDirection="column">
                                    <Grid item margin="auto">
                                        <Typography component="span" margin="auto" textTransform="uppercase">
                                            {specificPoint === null
                                                ? "Total"
                                                : "Number of devices"}
                                        </Typography>
                                    </Grid>
                                    <Grid item margin="auto">
                                        <Typography component="span" fontSize="55px" fontFamily="Griff" margin="auto" >
                                            {specificPoint === null
                                                ? totalDevices
                                                : `${specificPoint.value} / ${((specificPoint.value / totalDevices) * 100).toFixed(2)}%`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item container xs={4} flexDirection="column">

                    <Grid item container flexDirection="column" marginBottom="auto">
                        <SunburstChartBreadCrumb totalDevices={totalDevices} selectedPath={selectedPath}></SunburstChartBreadCrumb>
                    </Grid>
                    <Grid item>
                        {props.legend}
                    </Grid>

                </Grid>
            </Grid>
        </div>
    )
}

export default SunburstChart
