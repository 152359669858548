
import ProductTypeColored from "@components/product/ProductTypeColored"
import { getEnumKeys, ModelDefinition, ModelRelation } from "@models/utils"
import ProductNameNavigate from "@components/product/ProductNameNavigate"

type Product = {
 id: string;
 name: string;
 type: ProductType;
 description: string;
 version: string;
 hierarchy?: string;
 client: ModelRelation;
 created_at: string;
}

type ProductExt = Product & {
    NumVulnerability: number;
}

enum ProductType {
 Application = "application",
 Library = "library",
 Firmware = "firmware",
 OS = "operating-system",
 Container = "container",
 Device = "device",
}

const ProductTypeKeys = getEnumKeys(ProductType)
const ProductTypeValueOps = ProductTypeKeys.map((key) => ({ label: key, value: ProductType[key] }))
const ProductDefinition: ModelDefinition<ProductExt> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 0.1, isId: true },
        { name: "name", label: "Name", type: "string", flex: 0.2, render: ProductNameNavigate },
        { name: "version", label: "Version", type: "string", flex: 0.15 },
        { name: "type", label: "Type", type: "singleSelect", flex: 0.15, allowedValueOptions: ProductTypeValueOps, render: ProductTypeColored },
        { name: "description", label: "Description", type: "string", flex: 0.3 },
        { name: "num_vulnerability", label: "Total Vulnerability", type: "number", flex: 0.15 },
        { name: "client.name", label: "Client", type: "struct", flex: 0.15, valueGetter: product => product.client.name, permission: { subject: "Multitenant", action: "read" } }
    ]
}

export { ProductType, ProductDefinition, ProductTypeKeys, ProductTypeValueOps }
export type { Product }
export default Product
