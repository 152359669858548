import React from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import moment from "moment"
import Assessment from "@models/Assessment"
import Template from "@models/Template"
import styles from "./Styles"

const CustomFrontPage: React.FC<{assessment: Assessment, template: Template}> = ({ assessment, template }) => {
    let imagen
    if (template.logo != null) {
        const byteArray = Uint8Array.from(atob(template.logo.toString()), c => c.charCodeAt(0))
        const blob = new Blob([byteArray], { type: "image/png" })
        imagen = URL.createObjectURL(blob)
    }
    return (
        <Page size="A4">
            <View fixed>
                <View fixed>
                    {(imagen !== undefined) ? (<Image style={{ width: 596, height: 270, position: "relative" }} src={imagen}/>) : (<Image style={{ width: 596, height: 270, position: "relative", backgroundColor: template.color }} src="./assets/img/report/custom_goiburu.png" />)}
                </View>
                <Image style={{ width: "100%", height: "100%" }} src="./assets/img/report/custom_portada.jpeg" />
                <View style={styles.tablea}>
                    <View style={{ ...styles.tableRowa, borderWidth: 0, borderColor: "white" }}>
                        <View style={{ ...styles.tableCola2, borderRightWidth: 0, borderColor: "white" }}>
                            <Text style={{ ...styles.titulopage1, fontFamily: "Bold", color: "white" }}>{assessment.client.name}</Text>
                            <Text style={{ ...styles.titulo2page1, fontFamily: "Griff", color: "white" }}>Cybersecurity</Text>
                            <Text style={{ ...styles.titulo2page1, fontFamily: "Griff", color: "white" }}>Vulnerability Report: Software Composition Analysis (SCA)</Text>
                        </View>
                        <View style={{ ...styles.tableCola2, borderColor: "white" }}> <View style={{ ...styles.tableRowa, borderWidth: 0 }}>
                            <View style={{ ...styles.column, marginLeft: 0, marginTop: 0 }}>
                                <Text style={{ ...styles.titulo2page1, marginLeft: 0, marginTop: -25, paddingBottom: 0, fontFamily: "Griff", color: "white" }}>Month and year:</Text>
                            </View>
                            <View style={{ ...styles.column, marginLeft: -45, marginTop: -25 }}>
                                <Text style={{ ...styles.titulo2page1, paddingBottom: 2, fontFamily: "Griff", color: "white" }}>{moment(assessment.endDate).format("MMMM YYYY")}</Text>
                            </View>
                        </View>
                        </View>
                    </View>
                </View>
                <FooterFrontPage></FooterFrontPage>
            </View>
        </Page>
    )
}

const FooterFrontPage: React.FC<{}> = () => (
    <View>
        <View>
            <Text style={{ paddingLeft: 32, fontFamily: "Griff", paddingRight: 260, paddingBottom: 32, paddingTop: -80, fontSize: 5.5, position: "absolute", textAlign: "justify", color: "white" }}>
            The intellectual and industrial property rights over this document are the exclusive property of ORBIK Cybersecurity, S.L.
            Likewise, this report and, where applicable, any document annexed to it, contains confidential information exclusively addressed to its recipient.
                <Text style={{ fontFamily: "Bold", color: "#FFC000", backgroundColor: "black" }}>TLP-AMBER</Text>  Its disclosure, copy or distribution to third parties is prohibited without the prior written authorization of ORBIK Cybersecurity.
            If you have received this report in error, proceed to delete it and immediately notify ORBIK Cybersecurity, S.L.
            </Text>
        </View>
    </View>
)

export { CustomFrontPage }
