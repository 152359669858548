
import { ComplianceResult, FunctionalRequirementCategory, Question, QuestionType } from "../../models/Compliance"

export const questions: Question[] = [
    {
        id: "q1",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        title: "Design, development and production of products with digital elements",
        description: "Products with digital elements shall be designed, developed and produced in such a way as to ensure an adequate level of cybersecurity based on existing risks.",
        totalValue: 0,
        results: [
            {
                result: ComplianceResult.NonCompliant,
                text: "The system does not meet the compliance requirements. Immediate action is needed to address the non-compliance issues and bring the system up to the required standards.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.RequiredImprovements,
                text: "The system requires improvements to meet compliance. Certain aspects do not fulfill the necessary criteria, and steps must be taken to enhance these areas to achieve full compliance.",
                comment: "",
                value: 2
            },
            {
                result: ComplianceResult.PartiallyCompliant,
                text: "The system meets the compliance requirements. However, there are still some minor issues that could be optimized to ensure better performance and security.",
                comment: "",
                value: 5
            },
            {
                result: ComplianceResult.FullyCompliant,
                text: "The system is completely compliant with all requirements. No further action is necessary, and the system operates at optimal levels of performance and security.",
                comment: "",
                value: 9
            }
        ]
    },
    {
        id: "q2",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        title: "Products with digital elements without known vulnerabilities",
        description: "Products with digital elements will be delivered without any known vulnerability that can be exploited.",
        totalValue: 0,
        results: [
            {
                result: ComplianceResult.NonCompliant,
                text: "Products with digital elements will be delivered with multiple known vulnerabilities that are easy to exploit.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.RequiredImprovements,
                text: "The digital products will be delivered with some known vulnerabilities already fixed.",
                comment: "",
                value: 2
            },
            {
                result: ComplianceResult.PartiallyCompliant,
                text: "Products with digital components will be released with most of the known vulnerabilities resolved to prevent their exploitation.",
                comment: "",
                value: 5
            },
            {
                result: ComplianceResult.FullyCompliant,
                text: "Products with digital elements will be delivered without any known vulnerability that can be exploited.",
                comment: "",
                value: 9
            }
        ]
    },
    {
        id: "q3",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        title: "Protection against unauthorized access",
        description: "On the basis of the risk assessment referred to in Article 10(2), and where appropriate, products with digital elements shall ensure protection against unauthorized access by appropriate control mechanisms, including, inter alia, authentication, identity or access management systems.",
        totalValue: 0,
        results: [
            {
                result: ComplianceResult.NonCompliant,
                text: "Based on the risk assessment in Article 10(2), products with digital elements must allow unauthorized access to facilitate their use.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.RequiredImprovements,
                text: "According to the Article 10(2) risk assessment, products with digital elements must have some control mechanism to prevent unauthorized access, such as authentication or access management.",
                comment: "",
                value: 2
            },
            {
                result: ComplianceResult.PartiallyCompliant,
                text: "Based on the risk assessment referred to in Article 10(2), and where appropriate, products with digital elements shall be protected against unauthorized access by appropriate control mechanisms, such as authentication and identity or access management.",
                comment: "",
                value: 5
            },
            {
                result: ComplianceResult.FullyCompliant,
                text: "Based on the risk assessment referred to in Article 10(2), and where appropriate, products with digital elements shall ensure protection against unauthorized access through adequate control mechanisms, including, inter alia, authentication, identity or access management systems.",
                comment: "",
                value: 9
            }
        ]
    },
    {
        id: "q4",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        title: "Secure configuration by default",
        description: "On the basis of the risk assessment referred to in Article 10(2), and where appropriate, products with digital elements shall be delivered with a secure default configuration, including the possibility to reset the product to its original state.",
        totalValue: 0,
        results: [
            {
                result: ComplianceResult.NonCompliant,
                text: "Based on the risk assessment of Article 10(2), products with digital elements shall be delivered without any default settings and without the possibility of resetting.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.RequiredImprovements,
                text: "According to the Article 10(2) risk assessment, products with digital elements must have a secure default configuration and some option to reset.",
                comment: "",
                value: 2
            },
            {
                result: ComplianceResult.PartiallyCompliant,
                text: "Based on the risk assessment referred to in Article 10(2), and where necessary, products with digital elements should come with a secure default configuration, and should allow the product to be reset to its initial state.",
                comment: "",
                value: 5
            },
            {
                result: ComplianceResult.FullyCompliant,
                text: "Based on the risk assessment referred to in Article 10(2), and where appropriate, products with digital elements shall be delivered with a secure default configuration, including the possibility to reset the product to its original state.",
                comment: "",
                value: 9
            }
        ]
    },
    {
        id: "q5",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        title: "Confidentiality of data",
        description: "On the basis of the risk assessment referred to in Article 10(2), and where appropriate, products with digital elements shall protect the confidentiality of personal or other data stored, transmitted or otherwise processed by, for example, encrypting relevant data at rest or in transit by means of state-of-the-art mechanisms.",
        totalValue: 0,
        results: [
            {
                result: ComplianceResult.NonCompliant,
                text: "Based on the risk assessment in Article 10(2), products with digital elements do not need to protect the confidentiality of personal data or encrypt them at rest or in transit.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.RequiredImprovements,
                text: "According to the Article 10(2) risk assessment, products with digital elements must protect some personal and other important data by encryption, whether stored or transmitted.",
                comment: "",
                value: 2
            },
            {
                result: ComplianceResult.PartiallyCompliant,
                text: "Based on the risk assessment referred to in Article 10(2), and where appropriate, products with digital elements should protect the confidentiality of personal data and other data, whether stored, transmitted or processed, using, for example, encryption of data at rest or in transit with advanced mechanisms.",
                comment: "",
                value: 5
            },
            {
                result: ComplianceResult.FullyCompliant,
                text: "Based on the risk assessment referred to in Article 10(2), and where appropriate, products with digital elements shall protect the confidentiality of personal data or other data stored, transmitted or otherwise processed by, for example, encrypting relevant data at rest or in transit using state-of-the-art mechanisms.",
                comment: "",
                value: 9
            }
        ]
    },
    {
        id: "q6",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        title: "Data integrity",
        description: "On the basis of the risk assessment referred to in Article 10(2), and where appropriate, products with digital elements shall protect the integrity of stored, transmitted or otherwise processed personal or other data, commands, programs and settings against tampering or unauthorized modification by the user, and shall report cases of data corruption.",
        totalValue: 0,
        results: [
            {
                result: ComplianceResult.NonCompliant,
                text: "Based on the risk assessment in Article 10(2), products with digital elements do not need to protect the integrity of personal data or of commands, programs and configurations against tampering or modification, or report data corruption.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.RequiredImprovements,
                text: "According to the Article 10(2) risk assessment, products with digital elements must protect some personal data, commands and settings against unauthorized modifications, and report data corruption.",
                comment: "",
                value: 2
            },
            {
                result: ComplianceResult.PartiallyCompliant,
                text: "Based on the risk assessment referred to in Article 10(2), and where necessary, products with digital elements should protect the integrity of personal and other data, as well as commands, programs and configurations, whether stored, transmitted or processed, against unauthorized modifications by the user, and report cases of data corruption.",
                comment: "",
                value: 5
            },
            {
                result: ComplianceResult.FullyCompliant,
                text: "Based on the risk assessment referred to in Article 10(2), and where appropriate, products with digital elements shall protect the integrity of personal data or other stored, transmitted or otherwise processed data, commands, programs and settings against tampering or unauthorized modification by the user, and shall report cases of data corruption.",
                comment: "",
                value: 9
            }
        ]
    },
    {
        id: "q7",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        title: "Data processing",
        description: "On the basis of the risk assessment referred to in Article 10(2), and where appropriate, products with digital elements shall process only personal or other data that are adequate, relevant and limited to what is necessary for the intended use of the product (data minimization).",
        totalValue: 0,
        results: [
            {
                result: ComplianceResult.NonCompliant,
                text: "Based on the risk assessment in Article 10(2), products with digital elements may process any amount of personal or other data, regardless of whether it is relevant or necessary for the use of the product.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.RequiredImprovements,
                text: "According to the risk assessment of Article 10(2), products with digital elements should process only some personal data and other data necessary for the use of the product.",
                comment: "",
                value: 2
            },
            {
                result: ComplianceResult.PartiallyCompliant,
                text: "Based on the risk assessment referred to in Article 10(2), and where appropriate, products with digital elements should process only personal data and other data that are adequate and relevant, and that are limited to what is necessary for the use of the product (data minimization).",
                comment: "",
                value: 5
            },
            {
                result: ComplianceResult.FullyCompliant,
                text: "Based on the risk assessment referred to in Article 10(2), and where appropriate, products with digital elements shall only process personal data or other data that are adequate, relevant and limited to what is necessary for the intended use of the product (data minimization).",
                comment: "",
                value: 9
            }
        ]
    },
    {
        id: "q8",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        title: "Availability of essential functions",
        description: "On the basis of the risk assessment referred to in Article 10(2), and where appropriate, products with digital elements shall protect the availability of essential functions, including resilience against denial-of-service attacks and mitigation of their effects.",
        totalValue: 0,
        results: [
            {
                result: ComplianceResult.NonCompliant,
                text: "Based on the Article 10(2) risk assessment, products with digital elements do not need to protect the availability of essential functions or be resilient to denial of service attacks.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.RequiredImprovements,
                text: "According to the Article 10(2) risk assessment, products with digital elements must protect some essential functions and have some form of resilience against denial of service attacks.",
                comment: "",
                value: 2
            },
            {
                result: ComplianceResult.PartiallyCompliant,
                text: "Based on the risk assessment referred to in Article 10(2), and where appropriate, products with digital elements should protect the availability of essential functions and be resilient to denial of service attacks, as well as mitigate their effects.",
                comment: "",
                value: 5
            },
            {
                result: ComplianceResult.FullyCompliant,
                text: "Based on the risk assessment referred to in Article 10(2), and where appropriate, products with digital elements shall protect the availability of essential functions, including resilience against denial of service attacks and mitigation of their effects.",
                comment: "",
                value: 9
            }
        ]
    },
    {
        id: "q9",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        title: "Minimization of negative repercussions on availability",
        description: "On the basis of the risk assessment referred to in Article 10(2), and where appropriate, products with digital elements shall minimize their own negative impact on the availability of services provided by other devices or networks.",
        totalValue: 0,
        results: [
            {
                result: ComplianceResult.NonCompliant,
                text: "On the basis of the risk assessment in Article 10(2), products with digital elements should maximize their negative impact on the availability of services provided by other devices or networks.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.RequiredImprovements,
                text: "After the risk assessment in Article 10(2), products with digital elements may minimize their negative impact on the availability of services provided by other devices or networks.",
                comment: "",
                value: 2
            },
            {
                result: ComplianceResult.PartiallyCompliant,
                text: "Based on the risk assessment in Article 10(2), products with digital elements should minimize their negative impact on the availability of services provided by other devices or networks, where deemed appropriate.",
                comment: "",
                value: 5
            },
            {
                result: ComplianceResult.FullyCompliant,
                text: "On the basis of the risk assessment referred to in Article 10(2), and where appropriate, products with digital elements shall minimize their own negative impact on the availability of services provided by other devices or networks.",
                comment: "",
                value: 9
            }
        ]
    },
    {
        id: "q10",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        title: "Limitation of attack surfaces",
        description: "On the basis of the risk assessment referred to in Article 10(2), and where appropriate, devices with digital elements shall be designed, developed and produced to limit attack surfaces, including external interfaces.",
        totalValue: 0,
        results: [
            {
                result: ComplianceResult.NonCompliant,
                text: "On the basis of the risk assessment in Article 10(2), devices with digital elements should be designed, developed, and produced to maximize attack surfaces, including external interfaces.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.RequiredImprovements,
                text: "After the risk assessment in Article 10(2), devices with digital elements may be designed, developed, and produced to limit some attack surfaces, such as external interfaces.",
                comment: "",
                value: 2
            },
            {
                result: ComplianceResult.PartiallyCompliant,
                text: "Based on the risk assessment in Article 10(2), devices with digital elements should be designed, developed, and produced to reduce attack surfaces, including external interfaces, where applicable.",
                comment: "",
                value: 5
            },
            {
                result: ComplianceResult.FullyCompliant,
                text: "On the basis of the risk assessment referred to in Article 10(2), and where appropriate, devices with digital elements shall be designed, developed and produced to limit attack surfaces, including external interfaces.",
                comment: "",
                value: 9
            }
        ]
    },
    {
        id: "q11",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        title: "Incident impact reduction",
        description: "On the basis of the risk assessment referred to in Article 10(2), and where appropriate, products with digital elements shall be designed, developed and produced to reduce the impact of an incident, by means of appropriate mechanisms and techniques to mitigate the exploitation of vulnerabilities.",
        totalValue: 0,
        results: [
            {
                result: ComplianceResult.NonCompliant,
                text: "On the basis of the risk assessment in Article 10(2), products with digital elements should be designed, developed, and produced to maximize the impact of incidents, without employing mechanisms or techniques to mitigate vulnerabilities.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.RequiredImprovements,
                text: "After the risk assessment in Article 10(2), products with digital elements may be designed, developed, and produced to somewhat reduce the impact of incidents, employing some mechanisms and techniques to mitigate vulnerabilities.",
                comment: "",
                value: 2
            },
            {
                result: ComplianceResult.PartiallyCompliant,
                text: "Based on the risk assessment in Article 10(2), products with digital elements should be designed, developed, and produced to minimize the impact of incidents, using appropriate mechanisms and techniques to mitigate the exploitation of vulnerabilities.",
                comment: "",
                value: 5
            },
            {
                result: ComplianceResult.FullyCompliant,
                text: "On the basis of the risk assessment referred to in Article 10(2), and where appropriate, products with digital elements shall be designed, developed and produced to reduce the impact of an incident, by means of appropriate mechanisms and techniques to mitigate the exploitation of vulnerabilities.",
                comment: "",
                value: 9
            }
        ]
    },
    {
        id: "q12",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        title: "Provision of information on safety",
        description: "On the basis of the risk assessment referred to in Article 10(2), and where appropriate, products with digital elements shall provide security-related information by logging or monitoring relevant internal activity, including access to and modification of data, services or functions.",
        totalValue: 0,
        results: [
            {
                result: ComplianceResult.NonCompliant,
                text: "On the basis of the risk assessment in Article 10(2), products with digital elements should not provide any security-related information through logging or monitoring internal activities.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.RequiredImprovements,
                text: "After the risk assessment in Article 10(2), products with digital elements may provide some security-related information through logging or monitoring internal activities like data access or service modification.",
                comment: "",
                value: 2
            },
            {
                result: ComplianceResult.PartiallyCompliant,
                text: "Based on the risk assessment in Article 10(2), products with digital elements should provide security-related information through logging or monitoring relevant internal activity, such as access to and modification of data, services, or functions.",
                comment: "",
                value: 5
            },
            {
                result: ComplianceResult.FullyCompliant,
                text: "On the basis of the risk assessment referred to in Article 10(2), and where appropriate, products with digital elements shall provide security-related information by logging or monitoring relevant internal activity, including access to and modification of data, services or functions.",
                comment: "",
                value: 9
            }
        ]
    },
    {
        id: "q13",
        category: FunctionalRequirementCategory.Security,
        type: QuestionType.SingleSelect,
        title: "Vulnerability remediation guarantee",
        description: "On the basis of the risk assessment referred to in Article 10(2), and where appropriate, products with digital elements shall ensure that vulnerabilities can be addressed by security updates, including, where appropriate, automatic updates and notification of available updates to users.",
        totalValue: 0,
        results: [
            {
                result: ComplianceResult.NonCompliant,
                text: "On the basis of the risk assessment in Article 10(2), products with digital elements should not be concerned with addressing vulnerabilities through security updates or informing users about available updates.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.RequiredImprovements,
                text: "After the risk assessment in Article 10(2), products with digital elements may consider addressing vulnerabilities with security updates, potentially including automatic updates and informing users about available updates.",
                comment: "",
                value: 2
            },
            {
                result: ComplianceResult.PartiallyCompliant,
                text: "Based on the risk assessment in Article 10(2), products with digital elements should ensure vulnerabilities are addressed through security updates, including possibly automatic updates and notifying users of available updates.",
                comment: "",
                value: 5
            },
            {
                result: ComplianceResult.FullyCompliant,
                text: "On the basis of the risk assessment referred to in Article 10(2), and where appropriate, products with digital elements shall ensure that vulnerabilities can be addressed by security updates, including, where appropriate, automatic updates and notification of available updates to users.",
                comment: "",
                value: 9
            }
        ]
    },
    {
        id: "q14",
        category: FunctionalRequirementCategory.Vulnerability,
        type: QuestionType.SingleSelect,
        title: "Identification and documentation of vulnerabilities and components",
        description: "Manufacturers of products with digital elements shall identify and document vulnerabilities and components present in the product, in particular by developing a nomenclature of software materials in a commonly used, machine-readable format, including, at a minimum, the top-level dependencies of the product.",
        totalValue: 0,
        results: [
            {
                result: ComplianceResult.NonCompliant,
                text: "Manufacturers of products with digital elements are not required to identify or document vulnerabilities and components present in the product, nor to develop a nomenclature of software materials in a machine-readable format.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.RequiredImprovements,
                text: "Manufacturers of products with digital elements may consider identifying and documenting some vulnerabilities and components present in the product, potentially using a nomenclature of software materials in a commonly used, machine-readable format, including some dependencies of the product.",
                comment: "",
                value: 2
            },
            {
                result: ComplianceResult.PartiallyCompliant,
                text: "Manufacturers of products with digital elements should identify and document vulnerabilities and components present in the product, possibly developing a nomenclature of software materials in a commonly used, machine-readable format, including, at least, the primary dependencies of the product.",
                comment: "",
                value: 5
            },
            {
                result: ComplianceResult.FullyCompliant,
                text: "Manufacturers of products with digital elements shall identify and document vulnerabilities and components present in the product, in particular by developing a nomenclature of software materials in a commonly used, machine-readable format, including, at a minimum, the top-level dependencies of the product.",
                comment: "",
                value: 9
            }
        ]
    },
    {
        id: "q15",
        category: FunctionalRequirementCategory.Vulnerability,
        type: QuestionType.SingleSelect,
        title: "Vulnerability remediation",
        description: "Manufacturers of products with digital elements with regard to risks to products with digital elements shall address and remedy vulnerabilities without delay, in particular through the provision of security updates.",
        totalValue: 0,
        results: [
            {
                result: ComplianceResult.NonCompliant,
                text: "Manufacturers of products with digital elements are not responsible for addressing or remedying vulnerabilities or providing security updates.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.RequiredImprovements,
                text: "Manufacturers of products with digital elements may address and remedy some vulnerabilities if deemed necessary, including potentially providing security updates.",
                comment: "",
                value: 2
            },
            {
                result: ComplianceResult.PartiallyCompliant,
                text: "Manufacturers of products with digital elements should address and remedy vulnerabilities promptly, especially by providing security updates.",
                comment: "",
                value: 5
            },
            {
                result: ComplianceResult.FullyCompliant,
                text: "Manufacturers of products with digital elements with regard to risks to products with digital elements shall address and remedy vulnerabilities without delay, in particular through the provision of security updates.",
                comment: "",
                value: 9
            }
        ]
    },
    {
        id: "q16",
        category: FunctionalRequirementCategory.Vulnerability,
        type: QuestionType.SingleSelect,
        title: "Product safety examinations and tests",
        description: "Manufacturers of products with digital elements shall carry out effective and periodic examinations and tests of the safety of the product with digital elements.",
        totalValue: 0,
        results: [
            {
                result: ComplianceResult.NonCompliant,
                text: "Manufacturers of products with digital elements are not required to conduct examinations or tests to assess the safety of the product with digital elements.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.RequiredImprovements,
                text: "Manufacturers of products with digital elements may conduct occasional examinations and tests to check the safety of the product with digital elements.",
                comment: "",
                value: 2
            },
            {
                result: ComplianceResult.PartiallyCompliant,
                text: "mManufacturers of products with digital elements should conduct regular and effective examinations and tests to ensure the safety of the product with digital elements.",
                comment: "",
                value: 5
            },
            {
                result: ComplianceResult.FullyCompliant,
                text: "Manufacturers of products with digital elements shall carry out effective and periodic examinations and tests of the safety of the product with digital elements.",
                comment: "",
                value: 9
            }
        ]
    },
    {
        id: "q17",
        category: FunctionalRequirementCategory.Vulnerability,
        type: QuestionType.SingleSelect,
        title: "Disclosure of information on remediated vulnerabilities",
        description: "Manufacturers of products with digital elements shall, once a security update is available, disclose information about the remediated vulnerabilities, including a description of the vulnerabilities, information that allows users to identify the affected product with digital elements, the impact and severity of the vulnerabilities, and information to assist users in correcting the vulnerabilities.",
        totalValue: 0,
        results: [
            {
                result: ComplianceResult.NonCompliant,
                text: "Manufacturers of products with digital elements are not obligated to disclose information about remediated vulnerabilities when security updates become available.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.RequiredImprovements,
                text: "Manufacturers of products with digital elements may consider disclosing some information about remediated vulnerabilities if a security update is released, potentially including descriptions and impact details to assist users.",
                comment: "",
                value: 2
            },
            {
                result: ComplianceResult.PartiallyCompliant,
                text: "Manufacturers of products with digital elements should disclose information about remediated vulnerabilities when a security update becomes available, including descriptions of the vulnerabilities, details enabling users to identify the affected product with digital elements, the impact and severity of the vulnerabilities, and instructions to help users mitigate the vulnerabilities.",
                comment: "",
                value: 5
            },
            {
                result: ComplianceResult.FullyCompliant,
                text: "Manufacturers of products with digital elements shall, once a security update is available, disclose information about the remediated vulnerabilities, including a description of the vulnerabilities, information that allows users to identify the affected product with digital elements, the impact and severity of the vulnerabilities, and information to assist users in correcting the vulnerabilities.",
                comment: "",
                value: 9
            }
        ]
    },
    {
        id: "q18",
        category: FunctionalRequirementCategory.Vulnerability,
        type: QuestionType.SingleSelect,
        title: "Vulnerability disclosure policy",
        description: "Manufacturers of products with digital elements shall implement and enforce a coordinated vulnerability disclosure policy.",
        totalValue: 0,
        results: [
            {
                result: ComplianceResult.NonCompliant,
                text: "Manufacturers of products with digital elements are not required to implement or enforce a coordinated vulnerability disclosure policy.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.RequiredImprovements,
                text: "Manufacturers of products with digital elements may consider implementing a coordinated vulnerability disclosure policy.",
                comment: "",
                value: 2
            },
            {
                result: ComplianceResult.PartiallyCompliant,
                text: "Manufacturers of products with digital elements should implement and enforce a coordinated vulnerability disclosure policy.",
                comment: "",
                value: 5
            },
            {
                result: ComplianceResult.FullyCompliant,
                text: "Manufacturers of products with digital elements shall implement and enforce a coordinated vulnerability disclosure policy.",
                comment: "",
                value: 9
            }
        ]
    },
    {
        id: "q19",
        category: FunctionalRequirementCategory.Vulnerability,
        type: QuestionType.SingleSelect,
        title: "Provision of information on potential vulnerabilities",
        description: "Manufacturers of products with digital elements shall take measures to facilitate the exchange of information on possible vulnerabilities of their product with digital elements, as well as of third-party components present in the product, in particular by providing a contact address for the notification of vulnerabilities discovered in the product with digital elements.",
        totalValue: 0,
        results: [
            {
                result: ComplianceResult.NonCompliant,
                text: "Manufacturers of products with digital elements are not responsible for facilitating the exchange of information about vulnerabilities in their product or third-party components, and are not required to provide a contact address for reporting vulnerabilities.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.RequiredImprovements,
                text: "Manufacturers of products with digital elements may consider taking some measures to facilitate the exchange of information about potential vulnerabilities, including providing a contact address for reporting vulnerabilities.",
                comment: "",
                value: 2
            },
            {
                result: ComplianceResult.PartiallyCompliant,
                text: "Manufacturers of products with digital elements should take measures to facilitate the exchange of information regarding potential vulnerabilities in their product and third-party components, particularly by providing a contact address for reporting discovered vulnerabilities in the product with digital elements.",
                comment: "",
                value: 5
            },
            {
                result: ComplianceResult.FullyCompliant,
                text: "Manufacturers of products with digital elements shall take measures to facilitate the exchange of information on possible vulnerabilities of their product with digital elements, as well as of third-party components present in the product, in particular by providing a contact address for the notification of vulnerabilities discovered in the product with digital elements.",
                comment: "",
                value: 9
            }
        ]
    },
    {
        id: "q20",
        category: FunctionalRequirementCategory.Vulnerability,
        type: QuestionType.SingleSelect,
        title: "Distribution of updates",
        description: "Manufacturers of products with digital elements shall provide mechanisms for securely distributing updates to products with digital elements to ensure that exploitable vulnerabilities are addressed or mitigated in a timely manner.",
        totalValue: 0,
        results: [
            {
                result: ComplianceResult.NonCompliant,
                text: "Manufacturers of products with digital elements are not required to provide mechanisms for securely distributing updates to address or mitigate vulnerabilities.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.RequiredImprovements,
                text: "Manufacturers of products with digital elements may provide some mechanisms for distributing updates to products with digital elements, potentially addressing vulnerabilities.",
                comment: "",
                value: 2
            },
            {
                result: ComplianceResult.PartiallyCompliant,
                text: "Manufacturers of products with digital elements should provide mechanisms for securely distributing updates to products with digital elements to address or mitigate exploitable vulnerabilities promptly.",
                comment: "",
                value: 5
            },
            {
                result: ComplianceResult.FullyCompliant,
                text: "Manufacturers of products with digital elements shall provide mechanisms for securely distributing updates to products with digital elements to ensure that exploitable vulnerabilities are addressed or mitigated in a timely manner.",
                comment: "",
                value: 9
            }
        ]
    },
    {
        id: "q21",
        category: FunctionalRequirementCategory.Vulnerability,
        type: QuestionType.SingleSelect,
        title: "Dissemination of security updates",
        description: "Manufacturers of products with digital elements shall ensure that, when security patches or updates are available to address identified security issues, they are disseminated without delay and free of charge, accompanied by warning messages providing users with relevant information, in particular in relation to possible actions to be taken.",
        totalValue: 0,
        results: [
            {
                result: ComplianceResult.NonCompliant,
                text: "Manufacturers of products with digital elements are not required to disseminate security patches or updates to address identified security issues, nor provide warning messages or relevant information to users.",
                comment: "",
                value: 0
            },
            {
                result: ComplianceResult.RequiredImprovements,
                text: "Manufacturers of products with digital elements may consider disseminating security patches or updates when available to address identified security issues, potentially accompanied by warning messages with some relevant information for users.",
                comment: "",
                value: 2
            },
            {
                result: ComplianceResult.PartiallyCompliant,
                text: "Manufacturers of products with digital elements should ensure that security patches or updates addressing identified security issues are disseminated promptly and at no cost, along with warning messages that provide users with pertinent information, especially regarding recommended actions.",
                comment: "",
                value: 5
            },
            {
                result: ComplianceResult.FullyCompliant,
                text: "Manufacturers of products with digital elements shall ensure that, when security patches or updates are available to address identified security issues, they are disseminated without delay and free of charge, accompanied by warning messages providing users with relevant information, in particular in relation to possible actions to be taken.",
                comment: "",
                value: 9
            }
        ]
    }
]
