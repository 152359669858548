import React, { useContext } from "react"
import { Route, Routes } from "react-router-dom"
import { AbilityContext } from "@components/permissions/Can"
import { AssessmentDetail, AssessmentForm, AssessmentList } from "@views/assessment/index"
import Component from "@views/component/Component"

const entity = "Assessment"
const Assessment: React.FC = () => {
    const ability = useContext(AbilityContext)
    const routes = []

    if (ability.can("read", entity)) {
        routes.push(...[
            {
                route: "",
                component: <AssessmentList/>
            }
        ])
    }

    if (ability.can("update", entity)) {
        routes.push(...[
            {
                route: ":id",
                component: <AssessmentDetail/>
            },
            {
                route: ":id/component/*",
                component: <Component/>
            }
        ])
    }

    if (ability.can("create", entity)) {
        routes.push(...[
            {
                route: "/add",
                component: <AssessmentForm/>
            }

        ])
    }

    return (
        <Routes>
            {
                routes.map((item, idx) => (
                    <Route key={idx} path={item.route} element={item.component} />
                ))
            }
        </Routes>
    )
}

export { Assessment }
export default Assessment
