import { ComplianceResult } from "@models/Compliance"
import { Grid, Paper, Typography, useTheme } from "@mui/material"
import React from "react"

interface Props {
    result :ComplianceResult
}
interface Indicator {
    color: string;
    label: string;
}

type ColorMapType = {
    [key in ComplianceResult]: string;
  };
const colorMap : ColorMapType = {
    [ComplianceResult.NonCompliant]: "#D9534F",
    [ComplianceResult.RequiredImprovements]: "#EC971F",
    [ComplianceResult.PartiallyCompliant]: "#F2CC0C",
    [ComplianceResult.FullyCompliant]: "#4db04a"
}
const ComplianceIndicator: React.FC<Props> = ({
    result
}) => {
    const indicators: Array<Indicator> = []
    const theme = useTheme()
    indicators.push({ color: colorMap[result], label: result })

    if (indicators.length > 0) {
        return (
            <Grid
                component={Paper}
                elevation={4}
                container
                columns={indicators.length}
                sx={{ width: "200px", height: "12px" }}
            >
                {indicators.map((indicator, index) => {
                    return (
                        <Grid
                            key={index}
                            item
                            xs={1}
                            sx={{
                                background: indicator.color,
                                textAlign: "center",
                                fontSize: "12px",
                                fontWeight: "bold",
                                borderRadius: `${index === 0 ? "10px" : "0px"} ${index === indicators.length - 1 ? "10px" : "0px"
                                } ${index === indicators.length - 1 ? "10px" : "0px"} ${index === 0 ? "10px" : "0px"
                                }`
                            }}
                        >
                            <Typography sx={{ color: indicator.label === ComplianceResult.PartiallyCompliant ? theme.palette.secondary.contrastText : theme.palette.primary.contrastText }}>{indicator.label}</Typography>
                        </Grid>
                    )
                })}
            </Grid>
        )
    }
    return (
        <Typography variant="caption" fontStyle="italic" color={theme.palette.secondary.main}>
               No data
        </Typography>
    )
}
export { ComplianceIndicator }
