import { Alert, Box, Button, Grid, MenuItem, TextField, Typography, useTheme } from "@mui/material"
import React, { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ServicesContext } from "@context/index"
import { Client } from "@models/index"
import { ClientSectorKeysValueOps } from "@components/client/ClientTableDefinition"

const initValue: Client = {
    id: "",
    name: "",
    description: "",
    sector: undefined
}

const ClientForm: React.FC = () => {
    const theme = useTheme()
    const clientService = useContext(ServicesContext).clientService

    const [error, setError] = useState<Error|null>(null)

    const [formData, setFormData] = useState<Client>(initValue)
    const isFormValid = formData.name.trim() !== "" && formData.description.trim() !== ""
    const handleInputChange = (e: any) => {
        let events: {target :{name: string, value: string}}[] = e
        if (!Array.isArray(e)) {
            events = [e]
        }
        setFormData(events.reduce((result, { target: { name, value } }) => {
            return { ...result, [name]: value }
        }, formData))
    }

    const navigate = useNavigate()
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        try {
            await clientService.create(formData)
            setError(null)
            navigate(-1)
        } catch (e) {
            setError(e as Error)
        }
    }

    return (
        <Box sx={{ width: "100%" }}>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: "50%", alignSelf: "center", margin: "50px auto 50px auto" }}>
                {error && <Alert severity="error">{error.message}</Alert>}
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField margin="normal" required fullWidth variant="filled" label="Client Name" name="name"
                            value={formData.name} onChange={handleInputChange}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField margin="normal" select required fullWidth variant="filled" label="Sector" name="sector"
                            value={formData.sector} onChange={handleInputChange}>
                            {ClientSectorKeysValueOps.map((opt, idx) =>
                                (<MenuItem key={idx} value={opt.value}><Typography fontFamily="Griff" fontWeight="bolder">{opt.label}</Typography></MenuItem>)
                            )}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField margin="normal" required fullWidth variant="filled" label="Description" name="description"
                            value={formData.description} onChange={handleInputChange}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, color: theme.palette.secondary.contrastText }} disabled={!isFormValid}>
                            Add new Client
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export { ClientForm }
export default ClientForm
