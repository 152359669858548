import React from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import highchartsAccessibility from "highcharts/modules/accessibility"
import { Typography, useTheme } from "@mui/material"

highchartsAccessibility(Highcharts)
interface Donut5Values {
    title: string;
    values: {
        mitigated: number;
        unmitigated: number;

    }
}

const DonutChart5: React.FC<Donut5Values> = (props: Donut5Values) => {
    const theme = useTheme()
    const data = props.values.mitigated + props.values.unmitigated
    const options: Highcharts.Options = {
        title: {
            text: undefined
        },
        chart: {
            type: "pie",
            backgroundColor: "transparent",
            height: 150,
            width: 290,
            spacingTop: 0,
            spacingRight: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            plotBorderWidth: 0, // esto barras
            margin: [0, -10, 5, -25]
        },
        credits: { enabled: false },
        legend: {
            enabled: true,
            title: { text: props.title, style: { fontSize: "20px", fontFamily: "Griff", color: theme.palette.primary.contrastText, fontWeight: "normal" } },
            align: "right",
            layout: "vertical",
            verticalAlign: "top",
            itemStyle: { color: theme.palette.primary.contrastText },
            padding: 3,
            itemMarginTop: 5,
            itemMarginBottom: 5,
            width: 140,
            y: 13,
            x: -7
        },
        plotOptions: {
            pie: {
                innerSize: "48%",
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    enabled: false
                },
                center: [75, 60]
            }
        },
        exporting: {
            enabled: false
        },
        series: [{
            // colorByPoint: true,
            showInLegend: true,
            data: [{
                name: "Mitigated",
                y: props.values.mitigated,
                color: "#8dd3c7",
                borderColor: theme.palette.secondary.contrastText

            }, {
                name: "Unmitigated",
                y: props.values.unmitigated,
                color: "#A9A9A9",
                borderColor: theme.palette.secondary.contrastText
            }],
            type: "pie"
        }],
        tooltip: {
            pointFormatter: function () {
                const yValue = this.y !== undefined ? this.y : "N/A"
                const total = props.values.mitigated + props.values.unmitigated
                const percentage = this.y !== undefined ? (this.y / total * 100) : "N/A"
                return `<b>${yValue}</b></br><b>${percentage}%</b>`
            }
        }
    }
    const leftPositionNumber = data < 10 ? "65px" : data < 100 ? "60px" : "53px"
    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
            <Typography
                component="span"
                fontSize="24px"
                lineHeight="55px"
                fontFamily="Griff"
                sx ={{ position: "relative", top: "-100px", left: leftPositionNumber }}
            >{data}</Typography>
        </div>
    )
}

export { DonutChart5 }
export default DonutChart5
