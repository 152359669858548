import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import React, { ChangeEvent, useContext, useEffect, useState } from "react"
import { pdf } from "@react-pdf/renderer"
import { IconButton, Tooltip } from "@mui/material"
import ServicesContext from "@context/ServicesContext"
import { FileTypeByAssessmentType } from "@models/File"
import Assessment, { AssessmentStatus } from "@models/Assessment"
import { MediaTypes } from "@services/HttpService"
import saveAs from "file-saver"
import { FaFileDownload } from "react-icons/fa"
import VulnReportDocumentGeneric from "@components/report/VulnReportDocumentGeneric"
import { VulnReportDocument } from "./VulnReportDocument"
import VulnReportDocumentAquilles from "./VulnReportDocumentAquilles"

interface VulnReportUpDownloaderProps {
    assessment: Assessment
    simplified: boolean
    setError(arg0: any): any
}

const VulnReportUploader:React.FC<VulnReportUpDownloaderProps> = ({ assessment, setError }) => {
    const fileService = useContext(ServicesContext).fileService
    const [fileId, _] = useState(`file-input-${Math.random().toString(36).substring(7)}`)
    const [filetype, setFiletype] = useState(FileTypeByAssessmentType[assessment.type])
    useEffect(() => {
        setFiletype(FileTypeByAssessmentType[assessment.type])
    }, [assessment])
    const handleFileInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
        const input = e.target as HTMLInputElement
        input.value = "" // Clear the input value to trigger onChange even if the same file is selected
    }
    const handleFileInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files && e.target.files[0]

        if (selectedFile) {
            try {
                await fileService.uploadFileV2(assessment.id, filetype.type, Object.values(MediaTypes).find(v => v === filetype.mimetype) as MediaTypes, selectedFile)
            } catch (e: any) {
                setError({ message: "Wrong file type uploaded" } as Error)
                console.error(e)
            }
        }
    }
    return (
        <>
            <input
                type="file"
                accept={filetype.extension}
                style={{ display: "none" }}
                onClick={handleFileInputClick}
                onChange={handleFileInputChange}
                id={fileId}
            />
            {(assessment.status === AssessmentStatus.Started) &&
            <label htmlFor={fileId}>
                <Tooltip title="Upload SBOM">
                    <IconButton size="small" component="span" onClick={(e) => e.stopPropagation()}>
                        <CloudUploadIcon/>
                    </IconButton>
                </Tooltip>
            </label>
            }
        </>
    )
}

const VulnReportDownloader:React.FC<VulnReportUpDownloaderProps> = ({ assessment, simplified }) => {
    const vulnerabilityService = useContext(ServicesContext).vulnerabilityService
    const templateService = useContext(ServicesContext).templateService
    const fileService = useContext(ServicesContext).fileService
    const achillesService = useContext(ServicesContext).achillesService
    const [filetype, setFiletype] = useState(FileTypeByAssessmentType[assessment.type])
    useEffect(() => {
        setFiletype(FileTypeByAssessmentType[assessment.type])
    }, [assessment])

    const handleGenerateClick = async () => {
        try {
            let blob
            if (filetype.type === "cyclonedx") {
                const vulns = await vulnerabilityService.getAllFormatted(assessment.id, "cyclonedx")
                if (assessment.template.id !== "00000000-0000-0000-0000-000000000000") {
                    const template = await templateService.get(assessment.template.id)
                    const report = pdf(<VulnReportDocument simplified={simplified} assessment={assessment} template={template} data={vulns}/>)
                    blob = await report.toBlob()
                } else {
                    const report = pdf(<VulnReportDocument simplified={simplified} assessment={assessment} data={vulns}/>)
                    blob = await report.toBlob()
                }
            } else if (filetype.type === "achilles") {
                const achillesReport = await achillesService.getAllFormatted(assessment.id, "achilles")
                const report = pdf(<VulnReportDocumentAquilles assessment={assessment} data={achillesReport}/>)
                blob = await report.toBlob()
            } else if (filetype.type === "nessus") {
                const data = await vulnerabilityService.getAllFormatted(assessment.id, "nessus")
                const report = pdf(<VulnReportDocumentGeneric simplified={false} data={data.list}/>)
                blob = await report.toBlob()
            } else if (filetype.type === "acunetix") {
                const data = await vulnerabilityService.getAllFormatted(assessment.id, "acunetix")
                const report = pdf(<VulnReportDocumentGeneric simplified={false} data={data.list}/>)
                blob = await report.toBlob()
            } else if (filetype.type === "burp") {
                const data = await vulnerabilityService.getAllFormatted(assessment.id, "burp")
                const report = pdf(<VulnReportDocumentGeneric simplified={false} data={data.list}/>)
                blob = await report.toBlob()
            } else {
                const data = await fileService.downloadFile(assessment.id, filetype.type)
                blob = new Blob([data], { type: filetype.mimetype })
            }

            const url = window.URL.createObjectURL(blob)
            window.open(url)
        } catch (e) {
            console.error(e)
        }
    }

    const handleDownloadGenericFileClick = async () => {
        // download the file
        const data = await fileService.downloadFile(assessment.id, filetype.type)
        const blob = new Blob([data], { type: filetype.mimetype })
        saveAs(blob, `${assessment.product.name}_${assessment.product.version}_${assessment.type}`)
    }

    return (
        <>
            {(assessment.status === AssessmentStatus.Ended) &&
            <>
                <Tooltip title="Download Assessment">
                    <IconButton size="small" component="label" onClick={handleGenerateClick}>
                        {simplified ? <CloudDownloadIcon color="disabled"/> : <CloudDownloadIcon/>}
                    </IconButton>
                </Tooltip>
                <Tooltip title="Download original file">
                    <IconButton size="small" component="label" onClick={handleDownloadGenericFileClick}>
                        {simplified ? <FaFileDownload color="disabled"/> : <FaFileDownload />}
                    </IconButton>
                </Tooltip>
            </>
            }
        </>
    )
}

export { VulnReportUploader, VulnReportDownloader }
