import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import React, { useContext, useEffect } from "react"
import { Alert, Button, Checkbox, Divider, FormControl, FormControlLabel, MenuItem, TextField, useTheme } from "@mui/material"
import { StyledTextarea } from "@components/common/textareas"
import ClientSelector from "@components/client/ClientSelector"
import Can, { AbilityContext } from "@components/permissions/Can"
import { ServicesContext } from "@context/index"
import Email, { EmailFrequency, EmailFrequencyKeys } from "@models/Email"
import { FilterOperation } from "@utils/queryParams"
import { I18nContext } from "I18nProvider"

let initValue: Email = {
    id: "",
    emails: [],
    email_enabled: false,
    email_frequency: EmailFrequency.Day,
    notification_new_cve: false,
    notification_updated_cve: false,
    notification_score_flag: 0,
    client_id: ""
}

const EmailDetail: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const emailService = useContext(ServicesContext).emailService
    const [error, setError] = React.useState<Error | null>(null)
    const [email, setEmail] = React.useState(initValue)

    const formValid = (): boolean => {
        const isNotSameOldValues = [
            email.email_enabled === initValue.email_enabled,
            email.email_frequency === initValue.email_frequency,
            email.notification_new_cve === initValue.notification_new_cve,
            email.notification_updated_cve === initValue.notification_updated_cve,
            email.notification_score_flag === initValue.notification_score_flag,
            email.emails?.join(";") === initValue.emails?.join(";")
        ]
        if (isNotSameOldValues.every(rule => rule)) {
            return false
        }
        return true
    }

    const handleSaveChanges = async () => {
        try {
            const response = await emailService.update(email.id, email)
        } catch (e: any) {
            if (e.error) {
                setError({ message: e.error } as Error)
            }
        }
    }

    const getEmailDetail = async (id: string) => {
        if (id === "") {
            setEmail(initValue)
            return
        }
        try {
            const response = await emailService.getAll({ filters: [{ field: "client_id", operation: FilterOperation.EnumEqual, value: id }] })
            if (response.count < 1) {
                setEmail(initValue)
                return
            }
            setEmail(response.list[0])
            initValue = response.list[0]
        } catch (e: any) {
            if (e.error) {
                setError({ message: e.error } as Error)
            }
        }
    }

    const handleChange = (event: any) => {
        const { name, value, type, checked } = event.target
        let newValue = type === "checkbox" ? checked : value
        if (name === "emails") {
            newValue = newValue.split(/;/).map((email:any) => email.trim())
        } else if (name === "notification_score_flag") {
            newValue = (value.match(/\d+/) ? parseInt(value.match(/\d+/)[0], 10) : 0)
        }
        setEmail({ ...email, [name]: newValue })
    }

    let formattedEmail = ""
    if (Array.isArray(email.emails)) {
        formattedEmail = email.emails.join(";\n")
    }

    // Not Admin User
    const ability = useContext(AbilityContext)
    if (!ability.can("read", "Client")) {
        useEffect(() => {
            const fetchData = async () => {
                const email = await emailService.getAll({ sortField: "name" })
                if (email.count > 0) {
                    setEmail(email.list[0] as Email)
                    initValue = email.list[0] as Email
                } else {
                    setEmail(initValue)
                }
            }
            fetchData()
        }, [])
    }

    return (
        <Grid item xs container flexDirection="column" spacing="20px">
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>
                    {error && <Alert severity="error">{error.message}</Alert>}
                    <Box sx={{ borderRadius: "10px", border: "1px solid #6D6C6F", width: "100%", padding: "25px" }}>

                        <Grid container flexDirection="column" spacing="20px">
                            <Grid item container justifyContent="space-between" alignItems="center" spacing={2} sx={{ marginTop: "10px" }}>
                                <Grid item>
                                </Grid>
                                <Grid item xs={2} textAlign="end">
                                    <Button fullWidth variant="contained" sx={{ color: theme.palette.secondary.contrastText }} disabled={!formValid()} onClick={handleSaveChanges}>{context.t.translate("product_save")}</Button>
                                </Grid>
                            </Grid>

                            <Can key="ownership" I="ownership" an="Email" >
                                <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                                    <Grid item xs={6}>
                                        <ClientSelector value={email.client_id} onSelect={(id) => getEmailDetail(id || "")}></ClientSelector>
                                    </Grid>
                                </Grid>
                            </Can>

                            <Grid item>
                                <Divider sx={{ height: "1px", width: "100%", background: "#6D6C6F" }} />
                            </Grid>
                            <Grid item container justifyContent="space-around" alignItems="flex-start" mt={2}>
                                <Grid item container xs={12} md={4} textAlign="center">
                                    <Grid item>
                                        <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">{context.t.translate("alert_report")}</Typography>
                                    </Grid>
                                    <Grid item container flexDirection="row" textAlign="left" spacing="10px" py={2}>
                                        <Grid item xs={12}>
                                            <Typography fontSize="20px" fontFamily="Griff" color="secondary" fontWeight="bolder" >{context.t.translate("alert_when")}</Typography>
                                        </Grid>
                                        <Grid item xs={12} container direction="column">
                                            <Grid item>
                                                <FormControlLabel name="notification_new_cve" checked={email.notification_new_cve} onChange={handleChange} control={<Checkbox />} label={<Typography color="secondary" >{context.t.translate("alert_option1")}</Typography>} labelPlacement="end"/>
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel name="notification_updated_cve" checked={email.notification_updated_cve} onChange={handleChange} control={<Checkbox />} label={<Typography color="secondary" >{context.t.translate("alert_option2")}</Typography>} labelPlacement="end"/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item container flexDirection="row" textAlign="left" spacing="10px">
                                        <Grid item xs={12} container justifyContent="flex-start" alignItems="center">
                                            <Typography fontSize="20px" fontFamily="Griff" color="secondary" fontWeight="bolder">{context.t.translate("alert_cvss")}
                                                <TextField name="notification_score_flag" size="small" variant="outlined" value={email.notification_score_flag} onChange={handleChange}/>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} md={4}>
                                    <Grid item>
                                        <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">{context.t.translate("alert_alerts")}</Typography>
                                    </Grid>
                                    <Grid item container textAlign="left" spacing="10px" py={2}>
                                        <Grid item xs={12} container alignItems="center">
                                            <FormControlLabel name="email_enabled" checked={email.email_enabled} onChange={handleChange} control={<Checkbox />} label={<Typography fontSize="20px" fontFamily="Griff" color="secondary" fontWeight="bolder">{context.t.translate("alert_enable")}</Typography>} labelPlacement="end"/>
                                        </Grid>
                                    </Grid>
                                    <Grid item container alignItems="center" spacing="40px">
                                        <Grid item xs={12} md={12} container>
                                            <Grid item xs={12}>
                                                <Typography fontSize="20px" fontFamily="Griff" color="secondary" fontWeight="bolder">{context.t.translate("alert_recipient")}</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <StyledTextarea minRows={3} name="emails" value={formattedEmail} onChange={handleChange} placeholder="example@orbik-cybersecurity.com ; test@orbik-cybersecurity.com" style={{ width: "100%", boxSizing: "border-box", backgroundColor: "transparent" }}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item container textAlign="left" spacing="10px" py={2}>
                                        <Grid item xs={12} container alignItems="center">
                                            <FormControl fullWidth>
                                                <Grid item xs={12}>
                                                    <Typography fontSize="20px" fontFamily="Griff" color="secondary" fontWeight="bolder">{context.t.translate("alert_frequency")}</Typography>
                                                </Grid>
                                                <TextField margin="normal" select name="email_frequency" value={email.email_frequency} onChange={handleChange}>
                                                    {EmailFrequencyKeys.map((key, idx) =>
                                                        (<MenuItem key={idx} value={EmailFrequency[key]}><Typography fontFamily="Griff" fontWeight="bolder">{key}</Typography></MenuItem>)
                                                    )}
                                                </TextField>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid >
        </Grid >
    )
}

export { EmailDetail }
export default EmailDetail
