import * as oidc from "oidc-client-ts"

const HOST = process.env.REACT_APP_HOST || "http://localhost:3000"
const SSO = process.env.REACT_APP_SSO || "http://localhost:8080/"
const SSO_REALM = process.env.REACT_APP_SSO_REALM || "myorbik"
const SSO_CLIENT_ID = process.env.REACT_APP_SSO_CLIENT_ID || "orbikOIDC"

const oidcSettings = {
    authority: SSO + "realms/" + SSO_REALM,
    client_id: SSO_CLIENT_ID,
    redirect_uri: HOST,
    post_logout_redirect_uri: HOST,
    userStore: new oidc.WebStorageStateStore({ store: window.localStorage })
}

export { oidcSettings }
export default oidcSettings
