import React, { useContext } from "react"
import { Route, Routes } from "react-router-dom"
import { AbilityContext } from "@components/permissions/Can"
import EmailDetail from "./EmailDetail"

const entity = "Email"
const Email: React.FC = () => {
    const ability = useContext(AbilityContext)
    const routes = []

    if (ability.can("read", entity)) {
        routes.push(...[
            {
                route: "",
                component: <EmailDetail/>
            }
        ])
    }

    // if (ability.can("update", entity)) {
    //     routes.push(...[
    //         {
    //             route: ":id",
    //             component: <AssessmentDetail/>
    //         }
    //     ])
    // }

    // if (ability.can("create", entity)) {
    //     routes.push(...[
    //         {
    //             route: "/add",
    //             component: <AssessmentForm/>
    //         }

    //     ])
    // }

    return (
        <Routes>
            {
                routes.map((item, idx) => (
                    <Route key={idx} path={item.route} element={item.component} />
                ))
            }
        </Routes>
    )
}

export { Email }
export default Email
