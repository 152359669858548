
type Quiz = {
    id: string;
    name: string;
    description: string;
    questions: Question[];
}

type Question = {
    id: string;
    category: FunctionalRequirementCategory,
    type: QuestionType,
    title: string;
    description: string;
    results: Result[],
    totalValue: number
}

type Result = {
    result: ComplianceResult,
    text: string,
    comment: string,
    value: number
}
enum FunctionalRequirementCategory {
    Security = "Security Requirement",
    Vulnerability = "Vulnerability Management Requirement"
}

enum ComplianceResult {
    NonCompliant = "Non-Compliant",
    RequiredImprovements = "Required Improvements",
    PartiallyCompliant = "Partially Compliant",
    FullyCompliant = "Fully Compliant"
}

enum QuestionType {
    SingleSelect = "Single Select",
    MultipleSelect = "Multiple Select",
    Boolean = "Boolean",
    Text = "Text"
}

type Compliance = {
    id: string;
    registry: RegistryData[];
    conclusion: string;
    clientID: string;
}

type RegistryData = {
    time: string;
    q1: ComplianceResult;
    q2: ComplianceResult;
    q3: ComplianceResult;
    q4: ComplianceResult;
    q5: ComplianceResult;
    q6: ComplianceResult;
    q7: ComplianceResult;
    q8: ComplianceResult;
    q9: ComplianceResult;
    q10: ComplianceResult;
    q11: ComplianceResult;
    q12: ComplianceResult;
    q13: ComplianceResult;
    q14: ComplianceResult;
    q15: ComplianceResult;
    q16: ComplianceResult;
    q17: ComplianceResult;
    q18: ComplianceResult;
    q19: ComplianceResult;
    q20: ComplianceResult;
    q21: ComplianceResult;
}

export { FunctionalRequirementCategory, ComplianceResult, QuestionType }
export type { Quiz, Question, Result, Compliance, RegistryData }
export default Compliance
