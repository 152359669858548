
const CVSS3TemporalMetric = {
    E: {
        label: "Exploit Code Maturity (E)",
        metric: "E",
        values: {
            X: {
                label: "Not Defined",
                metric: "X",
                value: 1
            },
            U: {
                label: "Unproven that Exploit Exist",
                metric: "U",
                value: 0.91
            },
            P: {
                label: "Proof-of-Concept Code",
                metric: "P",
                value: 0.94
            },
            F: {
                label: "Functional Exploit Exist",
                metric: "F",
                value: 0.97
            },
            H: {
                label: "High",
                metric: "H",
                value: 1
            }
        }
    },
    RL: {
        label: "Remediation Level (RL)",
        metric: "RL",
        values: {
            X: {
                label: "Not Defined",
                metric: "X",
                value: 1
            },
            O: {
                label: "Official Fix",
                metric: "O",
                value: 0.95
            },
            T: {
                label: "Temporary Fix",
                metric: "T",
                value: 0.96
            },
            W: {
                label: "Workaround",
                metric: "W",
                value: 0.97
            },
            U: {
                label: "Unavailable",
                metric: "U",
                value: 1
            }
        }
    },
    RC: {
        label: "Report Confidence (RC)",
        metric: "RC",
        values: {
            X: {
                label: "Not Defined",
                metric: "X",
                value: 1
            },
            U: {
                label: "Unknown",
                metric: "U",
                value: 0.92
            },
            R: {
                label: "Reasonable",
                metric: "R",
                value: 0.96
            },
            C: {
                label: "Confirmed",
                metric: "C",
                value: 1
            }
        }
    }
}

export default CVSS3TemporalMetric
