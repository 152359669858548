import React, { useCallback, useContext, useEffect, useState } from "react"
import { NavigateFunction, useNavigate } from "react-router-dom"
import Grid from "@mui/material/Grid"
import { GridColDef, GridFilterItem, GridState, useGridApiRef } from "@mui/x-data-grid"
import { Button, useTheme } from "@mui/material"
import { format } from "date-fns"
import { convertModelDefToGridColDef, GenericTable } from "@components/common/tables/index"
import { AbilityContext } from "@components/permissions/index"
import { VulnerabilityTableDefinition } from "@components/vulnerability/index"
import { ServicesContext } from "@context/index"
import { Vulnerability } from "@models/index"
import { FilterOperation, FilterOption, QueryParameters } from "@utils/queryParams"
import { useTrack } from "@components/track/TrackContext"
import { FaFileExport } from "react-icons/fa"
import { pdf } from "@react-pdf/renderer"
import VulnReportDocumentGeneric from "@components/report/VulnReportDocumentGeneric"
import { I18nContext } from "I18nProvider"

const VulnerabilityList: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const vulnerabilityService = useContext(ServicesContext).vulnerabilityService

    const navigate: NavigateFunction = useNavigate()
    const [cols, setCols] = useState<GridColDef[]>([])

    const ability = useContext(AbilityContext)
    useEffect(() => {
        setCols(convertModelDefToGridColDef(VulnerabilityTableDefinition, ability))
    }, [])

    const RequestAnalyze = async () => {
        try {
            const response = await vulnerabilityService.analyze()
        } catch (error) {
            console.error(error)
        }
    }

    const apiRef = useGridApiRef()
    const [params, setParams] = useState<QueryParameters>({})

    const handleStateChange = useCallback(
        (state: GridState) => {
            if (state.filter.filterModel.items.every((filterItem: GridFilterItem) => filterItem.field !== undefined && filterItem.operator !== undefined && filterItem.value !== undefined)) {
                const newFilters: FilterOption[] = state.filter.filterModel.items.map((filterItem: GridFilterItem) => ({
                    field: filterItem.field.toString(),
                    operation: filterItem.operator as FilterOperation,
                    value: filterItem.value.toString()
                }))
                setParams((prevParams) => {
                    const filtersChanged = JSON.stringify(prevParams.filters) !== JSON.stringify(newFilters)
                    if (filtersChanged) {
                        return {
                            ...prevParams,
                            filters: newFilters
                        }
                    }
                    return prevParams
                })
            }
        },
        []
    )

    useEffect(() => {
        const unsubscribe = apiRef.current.subscribeEvent("stateChange", handleStateChange)
        return () => unsubscribe()
    }, [apiRef, handleStateChange])

    const HandleExport = async () => {
        try {
            const vulnerabilityList = await vulnerabilityService.getAllType(params, "csv")
            const report = pdf(<VulnReportDocumentGeneric simplified={false} data={vulnerabilityList.list}/>)
            const blob = await report.toBlob()
            const url = window.URL.createObjectURL(blob)
            window.open(url)
        } catch (error) {
            console.error(error)
        }
    }

    const getAllVulns = async () => {
        try {
            const filters: QueryParameters = { sortField: "name", sortMode: "desc" }
            const response = await vulnerabilityService.getAllType(filters, "csv")

            if (response.list.length === 0) {
                console.error("No data to export 1.")
                return
            }
            // Generar encabezados CSV
            const headers = ["identifier", "state", "score", "revised_score", "priority", "epss", "kev", "vector", "client", "product", "description"].join(";")

            const safeValue = (value: any) => (value === null || value === undefined ? "" : value)

            // Generar contenido CSV
            const rows = response.list.map((vuln: { identifier: any; state: any; score: any; revised_score: any; priority: any; epss: any; kev: any; vector: any; client: { name: any }; product: { name: any }; description: string }) =>
                [safeValue(vuln.identifier), safeValue(vuln.state), safeValue(vuln.score), safeValue(vuln.revised_score), safeValue(vuln.priority), safeValue(vuln.epss), safeValue(vuln.kev), safeValue(vuln.vector), safeValue(vuln.client.name), safeValue(vuln.product.name), safeValue(vuln.description.replace(/;/g, "."))].join(";")
            ).join("\n")

            const csvContent = `data:text/csv;charset=utf-8,${headers}\n${rows}`
            const encodedUri = encodeURI(csvContent)
            const link = document.createElement("a")
            link.setAttribute("href", encodedUri)
            const date = Date.now()
            const time = format(date, "MMMM do, yyyy H:mma")
            link.setAttribute("download", "cves_" + time + ".csv")
            document.body.appendChild(link) // Requerido para Firefox
            link.click()
            document.body.removeChild(link)
        } catch (error) {
            console.error(error)
            // return undefined; // Retorna undefined en caso de error
        }
    }
    const exportToCSV = () => {
        getAllVulns()
    }

    // Tracking
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "VulnerabilityList" })
    }, [])

    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }}>
            {/* <Button variant="contained" sx={{ position: "absolute", top: "-25px", right: "0px", color: theme.palette.secondary.contrastText }} onClick={RequestAnalyze} startIcon={<TbAnalyze/>}>Reanalyze</Button> "170px" */}
            <Button variant="contained" sx={{ position: "absolute", top: "-25px", right: "0px", color: theme.palette.secondary.contrastText }} onClick={HandleExport} startIcon={<FaFileExport />}>{context.t.translate("dash_export")}</Button> {/* "170px" */}
            {/* <Button variant="contained" sx={{ position: "absolute", top: "-25px", right: "0px" }} onClick={exportToCSV} startIcon={<FaFileExport/>}>Export Data</Button> */}
            <Grid item container flexDirection="column" rowGap="35px">
                <GenericTable<Vulnerability> entity="Vulnerability" columns={cols}
                    dataProvider={(filter) => {
                        filter.sortField = filter.sortField === "" || filter.sortField === undefined ? "epss" : filter.sortField
                        filter.sortMode = filter.sortMode === undefined ? "desc" : filter.sortMode
                        return vulnerabilityService.getAll(filter)
                    }}
                    onEdit={(elem: Vulnerability) => navigate("./" + elem.id)}
                    onDelete={(elem: Vulnerability) => vulnerabilityService.delete(elem.id)}
                    dataGridProps={{
                        onStateChange: handleStateChange,
                        apiRef
                    }} />
            </Grid >
        </Grid>
    )
}

export { VulnerabilityList }
export default VulnerabilityList
