import React, { useEffect, useState } from "react"
import Chip from "@mui/material/Chip"
import { VulnerabilityState, VulnerabilityStateKeysValueOps } from "@models/index"
import { VulnerabilityStateColors } from "@components/common/colors/VulnerabilityStateColors"

interface ColorAndBg{
    color: string;
    "background-color": string;
}

type StateColorMap = {
  [key in VulnerabilityState]: ColorAndBg;
};

const getStateColor = (state: VulnerabilityState): ColorAndBg => {
    const stateColorMap: StateColorMap = {
        [VulnerabilityState.Detected]: { color: "black", "background-color": VulnerabilityStateColors.detected },
        [VulnerabilityState.Resolved]: { color: "black", "background-color": VulnerabilityStateColors.resolved },
        [VulnerabilityState.Exploitable]: { color: "black", "background-color": VulnerabilityStateColors.exploitable },
        [VulnerabilityState.InTriage]: { color: "black", "background-color": VulnerabilityStateColors.in_triage },
        [VulnerabilityState.FalsePositive]: { color: "black", "background-color": VulnerabilityStateColors.false_positive },
        [VulnerabilityState.NotAffected]: { color: "black", "background-color": VulnerabilityStateColors.not_affected }
    }

    return stateColorMap[state]
}

interface VulnerabilityStateColoredProps {
  value: VulnerabilityState;
}

const VulnerabilityStateColored: React.FC<VulnerabilityStateColoredProps> = ({ value }) => {
    const [color, setColor] = useState<ColorAndBg| null>(null)
    const [label, setLabel] = useState<string|undefined>("")

    useEffect(() => {
        setLabel(VulnerabilityStateKeysValueOps.find(v => v.value === value)?.label)
        setColor(getStateColor(value))
    }, [value])

    return (
        <Chip size="small" style={{
            fontWeight: "bolder",
            color: color?.color,
            backgroundColor: color?.["background-color"]
        }} label={label}
        />
    )
}

export { VulnerabilityStateColored }
export default VulnerabilityStateColored
