import Compliance from "@models/Compliance"
import Service from "@services/abstract/BaseService"
import { MediaTypes } from "./HttpService"

class ComplianceService extends Service<Compliance> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/compliance", MediaTypes.JSON)
    }
}

export { ComplianceService }
export default ComplianceService
