import React, { useEffect, useState } from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import { VulnerabilityCycloneDX } from "@models/VulnerabilityReport"
import styles from "../../Styles"
import VexDocumentStyles from "../../VexStyleSheet"
import { Footer, Header } from "./HeaderFooter"
import ProgressCircle from "./ProgressCircle"

const severityMap: { [key: string]: keyof SeverityCount } = {
    critical: "critical",
    high: "high",
    medium: "medium",
    low: "low"
}

interface SeverityCount {
    critical: number;
    high: number;
    medium: number;
    low: number;
    undefined: number;
  }

const suggestion = {
    critical: { color: "black", label: "CRITICAL", subtext: ", which, if not addressed as soon as possible, these vulnerabilities could trigger a cybersecurity breach." },
    high: { color: "#D9534F", label: "HIGH", subtext: ", which, if not addressed in time, these vulnerabilities could trigger a cybersecurity breach." },
    medium: { color: "#FF8537", label: "MEDIUM", subtext: ", which, if not corrected, these vulnerabilities could trigger a cybersecurity breach." },
    low: { color: "#A8D1E7", label: "LOW", subtext: ", which, if not evaluated more thoroughly, these vulnerabilities could trigger a cybersecurity breach." },
    undefined: { color: "grey", label: "UNKNOWN", subtext: ", and a more thorough analysis is recommended." }
}
const ExecutiveSection: React.FC<{ vulnerabilities:VulnerabilityCycloneDX[]}> = ({ vulnerabilities }) => {
    const [severityCount, setSeverityCount] = useState<SeverityCount>({ critical: 0, high: 0, medium: 0, low: 0, undefined: 0 })
    const [severityCountGraph, setSeverityCountGraph] = useState<SeverityCount>({ critical: 0, high: 0, medium: 0, low: 0, undefined: 0 })
    const [severityLevel, setSeverityLevel] = useState<keyof SeverityCount>(severityMap.critical)
    const [empty, setEmpty] = useState<boolean>(false)

    useEffect(() => {
        const newSeverityCount: SeverityCount = { ...severityCount }

        let worstScore = -1
        let worstCriticity = severityMap.undefined
        vulnerabilities.forEach((vuln) => {
            const rating = vuln.ratings.find(r => r.method === "CVSSv3")
            if (rating && rating.severity && rating.severity in severityMap) {
                newSeverityCount[severityMap[rating.severity]]++
                if (worstScore < (rating?.score || 0)) {
                    worstScore = rating?.score || 0
                    worstCriticity = severityMap[rating.severity]
                }
            }
        })
        setSeverityCount(newSeverityCount)

        setSeverityLevel(worstCriticity)

        const scaleFactor = (newSeverityCount.critical + newSeverityCount.high + newSeverityCount.medium + newSeverityCount.low === 0 ? 0 : (10 / (newSeverityCount.critical + newSeverityCount.high + newSeverityCount.medium + newSeverityCount.low)))
        setSeverityCountGraph({
            critical: newSeverityCount.critical * scaleFactor,
            high: newSeverityCount.high * scaleFactor,
            medium: newSeverityCount.medium * scaleFactor,
            low: newSeverityCount.low * scaleFactor,
            undefined: 0
        })
    }, [vulnerabilities])

    useEffect(() => {
        if (Object.values(severityCountGraph).map(severity => severity / 10).filter(p => p !== 0).length === 1) {
            setEmpty(true)
        }
    }, [severityCountGraph])

    return (<Page size="A4">
        <Header></Header>
        <View style={{ ...VexDocumentStyles.section, flexDirection: "row" }}>
            <View style={styles.column}>
                <Text style={{ ...styles.titulo, fontFamily: "Bold" }}>3. Resumen ejecutivo</Text>
                <Text style={{ ...styles.textua, textAlign: "justify", marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>El objetivo del test es hacer uso de la ejecución del Fuzzing Test para identificar posibles anomalías en el funcionamiento del DUT y su capacidad para sobrellevar dichas pruebas. El resumen que figura a continuación es un enfoque condensado de los principales hallazgos.</Text>
                <Text style={{ ...styles.textua, textAlign: "justify", marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>Durante el transcurso del ejercicio <Text style={{ fontFamily: "Bold" }}>0 no conformidades graves</Text>, <Text style={{ fontFamily: "Bold", color: "#7940A5" }}> 1 no conformidad leve</Text>, <Text style={{ fontFamily: "Bold", color: "#FF8537" }}> 1 anomalía asumible </Text> y <Text style={{ fontFamily: "Bold", color: "#A8D1E7" }}> 32 sin anomalías</Text> fueron descubiertas.</Text>
                <Text style={{ ...styles.textua, textAlign: "justify", marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>El estado actual de las anomalías identificadas establece el riesgo en un nivel <Text style={{ fontFamily: "Bold" }}>ASUMBILE</Text>. , que no comprometen con el correcto funcionamiento del DUT. Por lo tanto, se considera que tiene una resistencia adecuada a este tipo de ataques.</Text>
                <Text style={{ ...styles.textua, textAlign: "justify", marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>Una descripción más detallada de las anomalías se encuentra en el apartado de  <Text style={{ fontFamily: "Bold" }}>Identificación de anomalías</Text> donde se describen cada una de ellas.</Text>
            </View>
            <View style={styles.column}>
                <View style={{ ...styles.rightImage, marginTop: -40, height: 700, marginLeft: 30, marginRight: -20, paddingTop: -200, marginBottom: -100, backgroundColor: "#fffa37", flexDirection: "row" }}>
                    <View style={{ ...styles.column, top: 50 }}>
                        <Image style={{ ...styles.rightImage, marginLeft: 20, paddingTop: 0, marginTop: 62, height: 90, width: 48 }} src="./assets/img/report/ColourArrows3.jpg" />
                        <Text style={{ ...styles.titulo, paddingLeft: 70, marginTop: 6, fontFamily: "Bold", fontSize: 16, textAlign: "left" }}>Servicios con anomalías detectadas: </Text>
                        <Text style={{ ...styles.titulo, paddingLeft: 70, marginTop: 20, fontFamily: "Griff", fontSize: 12 }}><Text style={{ fontFamily: "Bold", fontSize: 22 }}>0</Text> NO CONFORMIDAD GRAVE</Text>
                        <Text style={{ ...styles.titulo, paddingLeft: 70, marginTop: 6, fontFamily: "Griff", fontSize: 12 }}><Text style={{ fontFamily: "Bold", fontSize: 22 }}>0</Text> NO CONFORMIDAD LEVE</Text>
                        <Text style={{ ...styles.titulo, paddingLeft: 70, marginTop: 6, fontFamily: "Griff", fontSize: 12 }}><Text style={{ fontFamily: "Bold", fontSize: 22 }}>1</Text> ANOMALÍA ASUMIBLE</Text>
                        <Text style={{ ...styles.titulo, paddingLeft: 70, marginTop: 6, fontFamily: "Griff", fontSize: 12 }}><Text style={{ fontFamily: "Bold", fontSize: 22 }}>31</Text> SIN ANOMALÍAS</Text>
                        <Text style={{ marginLeft: 70, marginRight: 0, paddingTop: 20, marginBottom: 30, paddingRight: 30, textAlign: "center", fontFamily: "Griff", fontSize: 15 }}>__________________________</Text>
                        <Text style={{ marginLeft: 70, marginRight: 0, paddingTop: 0, marginBottom: 200, paddingRight: 30, textAlign: "center", fontFamily: "Griff", fontSize: 15 }}>El estado actual de las anomalías identificadas establece el riesgo en un nivel <Text style={{ fontFamily: "Bold" }}>ASUMIBLE</Text></Text>
                        <ProgressCircle Low = {severityCountGraph.low} Medium = {severityCountGraph.medium} High = {severityCountGraph.high} Critical = {severityCountGraph.critical} Unknown = {0} None={0} Empty={empty} ></ProgressCircle>
                    </View>
                </View>
            </View>
        </View>
        <Footer></Footer>
    </Page>)
}

export { ExecutiveSection }
