import React, { useContext, useEffect, useState } from "react"
import { Button, Grid, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { GridColDef } from "@mui/x-data-grid"
import { convertModelDefToGridColDef, GenericTable } from "@components/common/tables/index"
import { AbilityContext, Can } from "@components/permissions/index"
import { ServicesContext } from "@context/index"
import { Client } from "@models/index"
import ClientTableDefinition from "@components/client/ClientTableDefinition"

const ClientList: React.FC = () => {
    const navigate = useNavigate()
    const theme = useTheme()
    const [cols, setCols] = useState<GridColDef[]>([])

    const clientService = useContext(ServicesContext).clientService
    const ability = useContext(AbilityContext)
    useEffect(() => {
        setCols(convertModelDefToGridColDef(ClientTableDefinition, ability))
    }, [])

    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }}>
            <Can I="create" a="Client">
                <Button variant="contained" sx={{ position: "absolute", top: "-25px", right: "0px", color: theme.palette.secondary.contrastText }} onClick={() => navigate("./add")}>Add New Client</Button>
            </Can>
            <Grid item container flexDirection="column" rowGap="35px">
                <GenericTable<Client> entity="Client" columns={cols}
                    dataProvider={(filter) => clientService.getAll(filter) }
                    onEdit={(elem: Client) => navigate("./" + elem.id)}
                    onDelete={(elem: Client) => clientService.delete(elem.id)}
                />
            </Grid >
        </Grid>
    )
}

export { ClientList }
export default ClientList
