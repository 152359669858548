import { getEnumKeys } from "./utils"

type Email = {
    id: string;
    emails: string[];
    email_enabled: boolean;
    email_frequency: EmailFrequency;
    notification_new_cve: boolean;
    notification_updated_cve: boolean;
    notification_score_flag: number;
    client_id: string;
}

enum EmailFrequency {
    Day = "Once a day",
    Week = "Once a week",
}

const EmailFrequencyKeys = getEnumKeys(EmailFrequency)

export { EmailFrequency, EmailFrequencyKeys }
export type { Email }
export default Email
