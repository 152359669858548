import React from "react"
import { Document, Font } from "@react-pdf/renderer"
import { StatsAssessments, StatsProducts } from "@models/Stats"
import { BackPage } from "./sections/99_BackPage"
import { FrontPageDashboard } from "./Dashboard/0_FrontPage"
import { Stats1 } from "./Dashboard/1_1_Stats"
import { Stats2 } from "./Dashboard/1_2_Stats"

Font.register({ family: "Griff", src: "./assets/fonts/Griff-Regular.otf" })
Font.register({ family: "Bold", src: "./assets/fonts/Griff-Bold.otf" })

const hyphenationCallback = (word: string) => {
    // Devuelve las sílabas de la palabra en un array
    // Este es solo un ejemplo simple y puede necesitar mejoras
    return word.split("-")
}

// Registra la función de separación de sílabas
Font.registerHyphenationCallback(hyphenationCallback)

interface VEXDocumentProps {
    prodStats:StatsProducts | null,
    stats:StatsAssessments | null
}

const VulnReportDocumentDashboard: React.FC<VEXDocumentProps > = ({ prodStats, stats }) => {
    return (
        <>
            <Document pageLayout="twoColumnRight">
                <FrontPageDashboard></FrontPageDashboard>
                <Stats1 prodStats={prodStats} stats={stats}></Stats1>
                <Stats2></Stats2>
                <BackPage></BackPage>
            </Document>
        </>
    )
}

export { VulnReportDocumentDashboard }
export default VulnReportDocumentDashboard
