import Tag from "@models/Tag"
import { MediaTypes } from "@services/HttpService"
import Service from "@services/abstract/BaseService"

class TagService extends Service<Tag> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/tag", MediaTypes.JSON)
    }

    // public getTag = () => {
    //     return this.http.get(`/${this.path}/tag`) as Promise<Tag>
    // }

    // public createTag = (data: Tag) => {
    //     return this.http.post(`/${this.path}/tag`, data)
    // }
}

export { TagService }
export default TagService
