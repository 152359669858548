import React from "react"
import { styled } from "@mui/material/styles"
import { Switch, SwitchProps } from "@mui/material"

const OrbikSwitch = styled((props: SwitchProps) => (
    <Switch disableRipple {...props} />
))(({ theme }) => ({
    width: 64,
    height: 32,
    padding: 0,
    paddingRight: 8,
    borderRadius: 1000,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 6,
        transitionDuration: "300ms",
        transform: "translateX(3px)",
        color: theme.palette.secondary.dark,

        "& + .MuiSwitch-track": {
            borderRadius: 1000,
            backgroundColor: theme.palette.secondary.contrastText,
            opacity: 1,
            boxSizing: "border-box",
            border: `2px solid ${theme.palette.secondary.dark}`
        },

        "&.Mui-checked": {
            transform: "translateX(22px)",
            color: theme.palette.secondary.contrastText,
            "& + .MuiSwitch-track": {
                backgroundColor: "#FAE733",
                opacity: 1,
                border: 0
            }
        }
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 20,
        height: 20
    }
}))

export { OrbikSwitch }
export default OrbikSwitch
