import { Group } from "@models/index"
import { MediaTypes } from "@services/index"
import Service from "@services/abstract/BaseService"

class GroupService extends Service<Group> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/group", MediaTypes.JSON)
    }
}

export { GroupService }
export default GroupService
