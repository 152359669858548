import React from "react"
import { useAuth } from "react-oidc-context"
import { Navigate } from "react-router-dom"

const Logout: React.FC = () => {
    const auth = useAuth()

    React.useEffect(() => {
        const doAction = async () => {
            // Borrar tracking de local storage
            localStorage.removeItem("trackData")
            localStorage.removeItem("lastExecutionTime")
            await auth.removeUser()
            await auth.signoutRedirect()
        }
        doAction()
    }, [])

    return (<Navigate to="/" />)
}

export { Logout }
export default Logout
