import type * as React from "react"
import { TextareaAutosize } from "@mui/base/TextareaAutosize"
import { styled } from "@mui/system"
import { type TextareaAutosizeProps } from "@mui/material"

const blue = {
    100: "#DAECFF",
    200: "#b6daff",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75"
}

const grey = {
    50: "#f6f8fa",
    100: "#eaeef2",
    200: "#d0d7de",
    300: "#afb8c1",
    400: "#8c959f",
    500: "#6e7781",
    600: "#57606a",
    700: "#424a53",
    800: "#32383f",
    900: "#24292f"
}

const StyledTextarea: React.FC<TextareaAutosizeProps> = styled(TextareaAutosize)(
    ({ theme }) => `
    font-family: Griff, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 10px 10px 0 10px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? "000" : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[300] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === "dark" ? grey[900] : grey[50]};
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
)

export { StyledTextarea }
export default StyledTextarea
