import { Alert, Box, Button, Divider, Grid, IconButton, TextField, Tooltip, Typography, useTheme } from "@mui/material"
import React, { ChangeEvent, useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { HexColorPicker } from "react-colorful"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import { ClientSelector } from "@components/client/index"
import { ServicesContext } from "@context/index"
import { Template } from "@models/index"

const initValue = {
    id: "",
    name: "",
    color: "#FAE733",
    logo: null,
    client: { id: "" }
}

const TemplateDetail: React.FC = () => {
    const theme = useTheme()
    const templateService = useContext(ServicesContext).templateService
    const [fileId, _] = useState(`file-input-${Math.random().toString(36).substring(7)}`)
    const [error, setError] = useState<Error|null>(null)
    const [file, setFile] = useState<File>(new File([], "logo"))
    const [previewUrl, setPreviewUrl] = useState<string | ArrayBuffer | null>(null)
    const { id } = useParams<{ id: string }>()

    const [data, setData] = useState<Template>(initValue)
    const [formData, setFormData] = useState<Template>(initValue)
    const formValid = (): boolean => {
        const isNotNullrules = [
            formData.name === "",
            formData.color === "",
            formData.logo === null,
            formData.client.id === undefined
        ]
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        const isNotSameOldValues = [
            formData.name === data.name,
            formData.name === "",
            formData.color === "",
            formData.logo === null,
            formData.client.id === data.client.id
        ]
        if (isNotSameOldValues.every(rule => rule)) {
            return false
        }
        return true
    }

    // Generic change handler for all form fields
    const handleChange = (e:any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    // Fetch product data from API
    useEffect(() => {
        const fecthData = async () => {
            try {
                const val = await templateService.get(id as string)
                setData(val)
                setFormData(val)
                setPreviewUrl("data:image/png;base64," + val.logo)
            } catch (e: any) {
                setError({ message: e.error } as Error)
                navigate(-1)
            }
        }
        fecthData()
    }, [])
    // Fetch product data from API - END

    const navigate = useNavigate()
    // API Call save product
    const saveHandler = async () => {
        try {
            await templateService.update(formData.id, formData)
            setData(formData)
            setError(null)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
        }
    }
    // API Call save product - END

    if (data.id === "") return (<div>{error && <Alert severity="error">{error.message}</Alert>}<br></br>Loading...</div>)

    const handleFileInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
        const input = e.target as HTMLInputElement
        input.value = "" // Clear the input value to trigger onChange even if the same file is selected
    }
    const handleFileInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files && e.target.files[0]
        if (selectedFile) {
            try {
                const reader = new FileReader()
                reader.onloadend = () => {
                    setPreviewUrl(reader.result)
                }
                reader.readAsDataURL(selectedFile)
                formData.logo = previewUrl
                setFile(selectedFile)
            } catch (e: any) {
                console.error(e)
            }
        }
    }

    return (<Grid item>
        <Grid item xs container flexDirection="column">
            <Typography color={theme.palette.secondary.main} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                Template
            </Typography>
        </Grid>
        <Grid item xs container flexDirection="column" spacing="20px">
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>
                    <Box sx={{ borderRadius: "10px", border: "1px solid #6D6C6F", width: "100%", padding: "25px" }}>
                        {error && <Alert severity="error">{error.message}</Alert>}
                        <Grid container flexDirection="column" spacing="20px">
                            <Grid item flexDirection="row">
                                <Grid container flexDirection="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ marginTop: "10px" }}>
                                    <Grid item>
                                        <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
                                        </Typography>
                                    </Grid>
                                    <Grid item><Button variant="contained" sx={{ color: theme.palette.secondary.contrastText }} onClick={saveHandler} disabled={!formValid()}>Save Changes</Button></Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Divider sx={{ height: "1px", width: "100%", background: theme.palette.secondary.dark }} />
                            </Grid>
                            <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                                <Grid item xs={6}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Client</Typography>
                                    <ClientSelector value={formData.client.id} onSelect={(id) => handleChange({ target: { name: "client", value: { id } } })}></ClientSelector>
                                </Grid>
                            </Grid>
                            <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                                <Grid item xs={6}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Name</Typography>
                                    <TextField name="name" value={formData.name} onChange={handleChange} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <input
                                        type="file"
                                        accept=".jpg"
                                        style={{ display: "none" }}
                                        onClick={handleFileInputClick}
                                        onChange={handleFileInputChange}
                                        id={fileId}
                                    />
                                    <label htmlFor={fileId}>
                                        <Typography variant="h5" color="primary" fontWeight="bolder">Custom logo</Typography>
                                        <Tooltip title="Upload SBOM">
                                            <IconButton size="small" component="span" onClick={(e) => e.stopPropagation()}>
                                                <CloudUploadIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </label>
                                </Grid>
                            </Grid>
                            <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                                <Grid item xs={6}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Color</Typography>
                                    <HexColorPicker color={formData.color} onChange={handleChange} />
                                </Grid>
                                <Grid item xs={6} >
                                    {previewUrl !== null && <img style={{ width: 596, height: 250 }} src={previewUrl.toString()} alt="Preview" />}
                                </Grid>
                            </Grid>

                        </Grid>
                    </Box>
                </Grid>
            </Grid >
        </Grid >
    </Grid>
    )
}

export { TemplateDetail }
export default TemplateDetail
