import Hierarchy from "@models/Hierarchy"
import { ModelRelation } from "@models/utils"

export const MAX_DEEP_LEVEL = 4
export const PATH_SEPARATOR = ";"

export interface TreeNode {
    id: string;
    name: string;
    description: string;
    client: ModelRelation;
    children?: TreeNode[];
  }

export const convertToTree = (entries: Hierarchy[], sortField: "name" | "description", sortOrder: "asc" | "desc"): TreeNode[] => {
    const idToNodeMap: { [key: string]: TreeNode } = {}
    const rootNodes: TreeNode[] = []

    // Create a map of all nodes
    entries.forEach(entry => {
        idToNodeMap[entry.id] = {
            id: entry.id,
            name: entry.name,
            description: entry.description,
            client: entry.client,
            children: []
        }
    })

    // Build the tree structure
    entries.forEach(entry => {
        const pathSegments = entry.path.split(";")
        const node = idToNodeMap[entry.id]
        if (pathSegments.length === 1) {
            // This is a root node
            rootNodes.push(node)
        } else {
            // This is a child node
            const parentId = pathSegments[pathSegments.length - 2]
            const parentNode = idToNodeMap[parentId]
            parentNode.children!.push(node)
        }
    })

    // Sorting function
    const sortNodes = (nodes: TreeNode[]) => {
        nodes.sort((a, b) => {
            if (sortOrder === "asc") {
                return a[sortField].localeCompare(b[sortField])
            }
            return b[sortField].localeCompare(a[sortField])
        })

        nodes.forEach(node => {
            if (node.children && node.children.length > 0) {
                sortNodes(node.children)
            }
        })
    }

    // Sort the root nodes and their children
    sortNodes(rootNodes)
    return rootNodes
}

export const convertTreeToHierarchy = (nodes: TreeNode[]): Hierarchy[] => {
    const hierarchies: Hierarchy[] = []

    const traverseNode = (node: TreeNode, parentPath: string = "") => {
        const hierarchy: Hierarchy = {
            id: node.id,
            name: node.name,
            description: node.description,
            path: parentPath === "" ? `${node.id}` : `${parentPath};${node.id}`,
            client: node.client
        }
        hierarchies.push(hierarchy)

        if (node.children) {
            node.children.forEach(child => traverseNode(child, hierarchy.path))
        }
    }

    nodes.forEach(node => traverseNode(node))

    return hierarchies
}
