import { Email } from "@models/index"
import { MediaTypes } from "@services/HttpService"
import Service from "@services/abstract/BaseService"

class EmailService extends Service<Email> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/email", MediaTypes.JSON)
    }
}

export { EmailService }
export default EmailService
