import React, { useCallback, useContext, useEffect, useState } from "react"
import { Alert, Box, Button, Card, CardContent, Chip, CircularProgress, ClickAwayListener, Divider, Grid, IconButton, Modal, Tooltip, Typography, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { GridColDef, GridFilterModel, GridRowParams, GridSortModel } from "@mui/x-data-grid"
import CalculateIcon from "@mui/icons-material/Calculate"
import { DatePicker } from "@mui/x-date-pickers"
import moment from "moment"
import { TbAnalyze } from "react-icons/tb"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import { GenericTable } from "@components/common/tables/index"
import { AbilityContext, Can } from "@components/permissions/index"
import { convertGridFilterModelToQueryParameters, convertModelDefToGridColDef } from "@components/common/tables/utils"
import { VulnReportDownloader, VulnReportUploader } from "@components/report/VulnReportUpDownloader"
import { AssessmentTableDefinition } from "@components/assessment/index"
import CircularWithValueLabel from "@components/common/forms/CircularProgressWithLabel"
import QualityIndicator from "@components/common/indicator/QualityIndicator"
import { ServicesContext } from "@context/index"
import BomQuality from "@models/BomQuality"
import { Assessment, AssessmentExt, AssessmentStatus, AssessmentType } from "@models/index"
import { QueryParametersToURL } from "@services/HttpService"
import { capitalizeFirstLowercaseRest } from "@utils/capitalizeFirstLowercaseRest"
import { QueryParameters } from "@utils/queryParams"
import { useTrack } from "@components/track/TrackContext"
import { BiSolidComponent } from "react-icons/bi"
import { I18nContext } from "I18nProvider"

const AssessmentList: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const assessmentService = useContext(ServicesContext).assessmentService
    const fileService = useContext(ServicesContext).fileService
    const vulnerabilityService = useContext(ServicesContext).vulnerabilityService
    const [error, setError] = React.useState<Error | null>(null)
    const [requestError, setRequestError] = React.useState<Error | null>(null)
    const [analysisSuccess, setAnalysisSuccess] = React.useState<boolean>(false)
    const navigate = useNavigate()
    const [open, setOpen] = React.useState(false)
    const [openComponents, setOpenComponents] = React.useState(false)
    const [openStructural, setOpenStructural] = React.useState(false)
    const [openNTIA, setOpenNTIA] = React.useState(false)
    const [openSemantic, setOpenSemantic] = React.useState(false)
    const [openQuality, setOpenQuality] = React.useState(false)
    const [openSharing, setOpenSharing] = React.useState(false)
    const [showAlert, setShowAlert] = React.useState(true)

    const [qualityMetrics, setQualityMetrics] = useState<BomQuality>({ avg_score: 0, scores: [], timestamp: "" })
    // const [sbomComponents, setSbomComponents] = useState<Component[]>([])

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    })
    const [queryOptions, setQueryOptions] = useState<GridFilterModel|null>(null)
    const [sortOptions, setSortOptions] = useState<GridSortModel>([])
    const [loading, setLoading] = useState(false)
    const [rowCountState, setRowCountState] = React.useState(0)
    const [queryString, setQueryString] = useState<string>(QueryParametersToURL(convertGridFilterModelToQueryParameters(queryOptions, sortOptions, paginationModel)).join("&"))

    // Tracking
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "AssessmentList" })
    }, [])

    const onFilterChange = useCallback((filterModel: GridFilterModel) => {
        setQueryOptions({ ...filterModel })
    }, [])

    const onSortChange = useCallback((sortModel: GridSortModel) => {
        setSortOptions([...sortModel])
    }, [])

    const handleClose = () => setOpen(false)
    const handleCloseComponents = () => setOpenComponents(false)
    const handleOpen = async (id: string) => {
        const response = await fileService.scoreBom(id, new File([""], "filename"))
        setQualityMetrics(await response.json())
        setOpen(true)
    }
    const handleOpenComponents = async (id: string) => {
        const filters: QueryParameters = { sortField: "name", sortMode: "desc" }

        navigate(`./${id}/component`)
        // try {
        // setLoading(true)
        // const response = await vulnerabilityService.getAllType(filters, "cyclonedx")
        // setSbomComponents(await response.components)
        // // setSbomComponents(await response.vulnerabilities)
        // setRowCountState(response.components.length)
        // setLoading(false)

        // if (response.components.length < (paginationModel.page * paginationModel.pageSize)) {
        //     setPaginationModel({ page: paginationModel.page - 1, pageSize: paginationModel.pageSize })
        // }
        // } catch (e) {
        //     setError(e as Error)
        // }
        // setOpenComponents(true)
    }

    const scoresByCategory = qualityMetrics.scores.reduce((acc: {[key: string]: any[]}, score) => {
        if (!acc[score.category]) {
            acc[score.category] = []
        }
        acc[score.category].push(score)
        return acc
    }, {})
    const averageScoresByCategory: {[key: string]: number} = {}
    React.useEffect(() => {
        for (const category in scoresByCategory) {
            const scores = scoresByCategory[category]
            const totalScores = scores.length
            const sumScores = scores.reduce((total: number, score: any) => total + score.score, 0)
            const averageScore = sumScores / totalScores
            averageScoresByCategory[category] = averageScore
        }
    }, [qualityMetrics])

    const RequestAnalyze = async (id: string) => {
        try {
            const response = await vulnerabilityService.analyzeByAssessment(id)
            setRequestError({ message: response.message } as Error)
            setAnalysisSuccess(true)
        } catch (e: any) {
            console.error(e)
            setError({ message: e.error } as Error)
            setAnalysisSuccess(false)
        }
        setShowAlert(true)
    }

    const [cols, setCols] = useState<GridColDef[]>([])
    const customActions = (params: GridRowParams<AssessmentExt>) => {
        const actions: React.JSX.Element[] = [
            <Box key="analyze" sx={{ position: "relative", display: "inline-flex", height: "100%", width: "100%", mr: "15px", top: "0px" }}>
                <CircularProgress variant="determinate" value={params.row.analysis_status === undefined ? 0 : params.row.analysis_status! * 16.6666} size={32} style={{
                    position: "absolute" as "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)"
                }}/>
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <Tooltip title="Reanalyze"><IconButton key="analyze" onClick={() => { RequestAnalyze(params.row.id) }} sx={{ width: 33, height: 33 }} ><TbAnalyze size={20}/></IconButton ></Tooltip>
                </Box>
            </Box>,
            <Can key="create" I="create" an="File" ><VulnReportUploader setError={setError} simplified={false} key="vexUploader" assessment={params.row} /></Can>,
            // <Can key="read" I="read" an="Vulnerability" ><VulnReportDownloader simplified={true} key="vexDownloader" assessment={params.row} /></Can>,
            <Can key="read" I="read" an="Vulnerability" ><VulnReportDownloader setError={setError} simplified={false} key="vexDownloader" assessment={params.row}/></Can>
        ]
        if (params.row && params.row.status === AssessmentStatus.Ended && params.row.type === AssessmentType.SCA) {
            actions.push(
                <Can key="score" I="create" an="File" >
                    <Tooltip title="SBOM Metrics">
                        <IconButton size="small" component="label" onClick={() => navigate(`./${params.row.id}/sbomQuality`)}>
                            {/* <IconButton size="small" component="label" onClick={() => handleOpen(params.id as string)}> */}
                            <CalculateIcon/>
                        </IconButton>
                    </Tooltip>
                </Can>,
                <Can key="components" I="read" an="Vulnerability">
                    <Tooltip title="Components">
                        <IconButton size="small" component="label" onClick={() => handleOpenComponents(params.id as string)}>
                            <BiSolidComponent/>
                        </IconButton>
                    </Tooltip>
                </Can>
            )
        }
        return actions
    }

    const ability = useContext(AbilityContext)
    useEffect(() => {
        setCols(convertModelDefToGridColDef(AssessmentTableDefinition, ability))
    }, [])
    const style = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "80%",
        bgcolor: "background.paper",
        color: theme.palette.primary.contrastText,
        border: `2px solid ${theme.palette.secondary.contrastText}`,
        boxShadow: 24,
        p: 4,
        height: "85%"
    }
    const styleBox = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "90%",
        color: theme.palette.primary.contrastText,
        p: 4,
        height: "90%"
    }
    const styleScroll = {
        overflowY: "auto",
        height: "60%",
        "&::-webkit-scrollbar": {
            width: "12px"
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary.main,
            borderRadius: "6px"
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent"
        }
    }

    interface ScoreChipProp {
        value: number
    }

    const ScoreChip: React.FC<ScoreChipProp> = ({ value }) => {
        return (
            <Typography variant="h6" color={theme.palette.secondary.contrastText} fontWeight="bolder" sx={{ textAlign: "end", alignItems: "center" }}>{value}</Typography>
        )
    }

    const handleClickAway = () => {
        setOpen(false)
    }

    const categories = ["Structural", "NTIA-minimum-elements", "Semantic", "Quality", "Sharing"] as const
    type Category = typeof categories[number]
    const [expandedCategories, setExpandedCategories] = useState({
        Structural: false,
        "NTIA-minimum-elements": false,
        Semantic: false,
        Quality: false,
        Sharing: false
    })

    const handleExpandClick = (category: Category) => {
        setExpandedCategories((prev) => ({
            ...prev,
            [category]: !prev[category]
        }))
    }
    const MyModal = React.forwardRef(() => {
        const theme = useTheme()
        // Tracking
        const { track, trackData } = useTrack()
        useEffect(() => {
            track({ view: "SBOMQuality" })
        }, [])
        return (
            <ClickAwayListener onClickAway={handleClickAway}>
                <Box sx={styleBox}>
                    <Grid container item flexDirection="column" spacing="20px" padding="25px">
                        <Grid item xs={12} mx="0px" mb="25px">
                            <Typography variant="h4" color="primary" fontWeight="bolder">SBOM Quality Metrics</Typography>
                        </Grid>
                        <Divider sx={{ height: "1px", width: "100%", background: theme.palette.secondary.dark, mb: "25px" }} />
                        <Grid container item xs={12} spacing={2} direction="row" justifyContent="space-between">
                            <Grid item xs={6} mx="0px" mb="25px">
                                <Card sx={{ minWidth: 275, minHeight: 100 }}>
                                    <CardContent>
                                        <Grid container item xs={12} spacing={2} direction="row" justifyContent="space-between">
                                            <Grid item xs={6} style={{ alignContent: "center" }}>
                                                <Typography variant="h5" component="div" >Quality Score</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <CircularWithValueLabel progress={qualityMetrics.avg_score} />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={6} mx="0px" mb="25px">
                                <Card sx={{ minWidth: 275, minHeight: 100 }}>
                                    <CardContent>
                                        <Grid container item xs={12} spacing={2} direction="row" justifyContent="space-between">
                                            <Grid item xs={6} style={{ alignContent: "center" }}>
                                                <Typography variant="h5" component="div">Scanned at</Typography>
                                            </Grid>
                                            <Grid item xs={6} style={{ alignContent: "center" }}>
                                                <DatePicker
                                                    label=""
                                                    disabled
                                                    value={qualityMetrics.timestamp != null ? moment(qualityMetrics.timestamp, "YYYY-MM-DDTHH:mm:ssZ") : null}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <Divider sx={{ height: "1px", width: "100%", background: theme.palette.secondary.dark }} />
                    </Grid>
                    <Grid container item className="scroll-container" sx={{ ...styleScroll, paggingLeft: "50px" }}>
                        {Object.entries(scoresByCategory).map(([category, scores]) => (
                            <Grid key={"category_" + category} container item flexDirection="column" spacing="10px" mr={2}>
                                <Grid container item xs={12} spacing={2} direction="row" justifyContent="space-between">
                                    <Grid item xs={12} mx="0px" mb="25px">
                                        <Card sx={{
                                            minWidth: 275,
                                            marginLeft: "22px",
                                            marginRight: "7px"
                                        }}>
                                            <CardContent sx={{
                                                padding: "3px",
                                                maxHeight: expandedCategories[category as Category] ? "auto" : "22px",
                                                overflow: expandedCategories[category as Category] ? "auto" : "hidden",
                                                transition: "max-height 0.3s ease-in-out"
                                            }}>
                                                <Grid container item xs={12} spacing={2} direction="row" justifyContent="space-between" padding="20px" sx={{ marginTop: "-30px" }}>
                                                    <Grid item xs={10} >
                                                        <Typography variant="h5" component="div" color="primary" fontWeight="bolder">{category}
                                                            <IconButton size="small" component="span" onClick={() => handleExpandClick(category as Category)}>
                                                                {expandedCategories[category as Category] ? <ExpandLess /> : <ExpandMore />}
                                                            </IconButton>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Chip style={{ width: "80px" }} color="primary" label={<ScoreChip value={parseFloat(
                                                            (qualityMetrics.scores.filter(score => score.category === category).reduce((total, score) => total + score.score, 0) /
                                                            qualityMetrics.scores.filter(score => score.category === category).length).toFixed(2)
                                                        )} />} />
                                                    </Grid>
                                                </Grid>
                                                {expandedCategories[category as Category] &&
                                                    scores.map((score, idx) => (
                                                        <Grid key={"score_" + idx} container item xs={12} spacing={2} direction="row" justifyContent="space-between" paddingLeft="20px">
                                                            <Grid item xs={9}>
                                                                <Typography component="div" color="secondary">
                                                                    {capitalizeFirstLowercaseRest(score.description)}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography
                                                                    variant="h5"
                                                                    color="primary"
                                                                    fontWeight="bolder"
                                                                    sx={{ textAlign: "end", paddingRight: "20px", alignItems: "center" }}
                                                                >
                                                                    <QualityIndicator value={score.score} />
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                    <Button size="large" fullWidth variant="contained" style={{ marginTop: "20px", color: theme.palette.secondary.contrastText }} onClick={() => handleClose()}>Close</Button>
                </Box>
            </ClickAwayListener>
        )
    })
    MyModal.displayName = "MyModal"
    const MyModalComponents = React.forwardRef(() => {
        const theme = useTheme()
        // Tracking
        const { track, trackData } = useTrack()
        useEffect(() => {
            track({ view: "Components" })
        }, [])
        return (
            <ClickAwayListener onClickAway={handleClickAway}>
                <Box sx={styleBox}>
                    <Grid container item flexDirection="column" spacing="20px" padding="25px">
                        <Grid item xs={12} mx="0px" mb="25px">
                            <Typography variant="h4" color="primary" fontWeight="bolder">Components</Typography>
                        </Grid>
                        <Divider sx={{ height: "1px", width: "100%", background: theme.palette.secondary.dark, mb: "25px" }} />
                    </Grid>
                    <Grid container item className="scroll-container" sx={styleScroll}>
                        {/* <Box sx={{ height: (paginationModel.pageSize + 1) * 52 + 59, width: "100%" }}>
                            {error && <Alert severity="error">{error.message}</Alert>}
                            <DataGrid
                                rows={[]}
                                columns={columns}
                                filterMode="server"
                                sortingMode="server"
                                paginationMode="server"
                                onFilterModelChange={onFilterChange}
                                onSortModelChange={onSortChange}
                                onPaginationModelChange={setPaginationModel}
                                rowCount={rowCountState}
                                loading={loading && !error}
                                pageSizeOptions={[5, 10, 25]}
                                paginationModel={paginationModel}
                                sx={ { "& .MuiDataGrid-row": { marginTop: 1, marginBottom: 1 }, "& .coloured": { textAlign: "center", color: "#7181AD" }, "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": { display: "none" } }}
                                checkboxSelection
                                disableRowSelectionOnClick
                                getRowId={(row) => row.id}
                            />
                        </Box> */}
                    </Grid>
                    <Button size="large" fullWidth variant="contained" style={{ marginTop: "20px", color: theme.palette.secondary.contrastText }} onClick={() => handleCloseComponents()}>Close</Button>
                </Box>
            </ClickAwayListener>
        )
    })
    MyModalComponents.displayName = "MyModalComponents"

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            setShowAlert(false)
        }, 8000)

        return () => clearTimeout(timeout)
    }, [analysisSuccess])

    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }}>
            {requestError && showAlert && <Alert severity={analysisSuccess ? "success" : "error" }>{requestError.message}</Alert>}
            {error && <Alert severity="error">{error.message}</Alert>}
            {/* {error && (
                <Alert severity="error">{error.message}</Alert>
            )}
            {analysisSuccess && showAlert && (
                <Alert severity="success">fino</Alert>
            )} */}

            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>
                    <GenericTable<Assessment> entity="Assessment" columns={cols}
                        dataProvider={(filter) => assessmentService.getAllExt(filter) }
                        onEdit={(elem: Assessment) => navigate("./" + elem.id)}
                        onDelete={(elem: Assessment) => assessmentService.delete(elem.id)}
                        customActions={customActions}
                    />
                </Grid>
                <Can I="create" a="Assessment">
                    <Button variant="contained" sx={{ position: "absolute", top: "-25px", right: "0px", color: theme.palette.secondary.contrastText }} onClick={() => navigate("./add")}>{context.t.translate("assess_new")}</Button>
                </Can>
            </Grid>
            <Modal sx={style} open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <MyModal/>
            </Modal>
            <Modal sx={style} open={openComponents} onClose={handleCloseComponents} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <MyModalComponents/>
            </Modal>
        </Grid >
    )
}

export { AssessmentList }
export default AssessmentList
