import React, { useContext, useEffect, useState } from "react"
import { Alert, Autocomplete, Box, Button, Chip, Divider, Grid, IconButton, MenuItem, Modal, Select, TextField, Tooltip, Typography, useTheme } from "@mui/material"
import { GridColDef, GridRowParams } from "@mui/x-data-grid"
import { useNavigate } from "react-router-dom"
import { useAuth } from "react-oidc-context"
import { FaTags } from "react-icons/fa"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import { GenericTable } from "@components/common/tables/index"
import { convertModelDefToGridColDef } from "@components/common/tables/utils"
import { AbilityContext, Can } from "@components/permissions/index"
import { ServicesContext } from "@context/index"
import { Product, ProductDefinition } from "@models/index"
import Tag from "@models/Tag"
import { IterableList } from "@models/iterable"
import { QueryParameters } from "@utils/index"
import { useTrack } from "@components/track/TrackContext"
import { I18nContext } from "I18nProvider"

const initValue: Tag = {
    id: "",
    name: "",
    description: "",
    color: "",
    products: []
}

const ProductList: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const productService = useContext(ServicesContext).productService
    const tagService = useContext(ServicesContext).tagService
    const [tagNameList, setTagNameList] = useState<{ id: string; name: string; color: string; description: string; client_id: string; products: string[] }[]>([])
    const [createTagOpen, setCreateTagOpen] = React.useState(false)
    const [addTagOpen, setAddTagOpen] = React.useState(false)
    const [visualizeTagOpen, setvisualizeTagOpen] = React.useState(false)
    const [updateTags, setUpdateTags] = useState(false)
    const [tagName, setTagName] = useState<string[]>([])
    const [tagNameFiltered, setTagNameFiltered] = useState<string[]>([])
    const [tagData, setTagData] = useState<Tag[]>([])
    const [selectedTag, setSelectedTag] = useState<string>("")
    const [formTagData, setFormTagData] = useState<Tag>(initValue)
    const [productId, setProductID] = useState<string>("")
    const [productName, setProductName] = useState<string>("")

    const [selectedRow, setSelectedRow] = useState<string[]>([])
    const [isFormValid, setIsFormValid] = useState(false)

    const [cols, setCols] = useState<GridColDef[]>([])
    const [error, setError] = useState<Error|null>(null)

    // Tracking
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "ProductList" })
    }, [])

    const navigate = useNavigate()
    const auth = useAuth()
    const ability = useContext(AbilityContext)
    const colors = [
        { label: "yellow", value: "#fdffb5" },
        { label: "red", value: "#D9534F" },
        { label: "green", value: "#b4de6c" },
        { label: "blue", value: "#8dd3c7" }
    ]
    const style = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "40%",
        bgcolor: "background.paper",
        color: theme.palette.primary.contrastText,
        border: `2px solid ${theme.palette.secondary.contrastText}`,
        boxShadow: 24,
        p: 4
    }

    const handleCreateTagOpen = () => setCreateTagOpen(true)
    const handleCreateTagClose = () => setCreateTagOpen(false)

    const handleAddTagOpen = async (params: any) => {
        setTagName([])
        setAddTagOpen(true)
        try {
            const filters: QueryParameters = { sortField: "name" }
            const data = await tagService.getAll(filters)
            if (data.list) {
                // miramos si el id del producto está asignado ya en un tag
                const productExists = data.list && data.list.some(item => item.products && item.products.includes(params.id))
                if (productExists === false) {
                    const nombres = data.list.map(tag => tag.name && tag.name)
                    setTagName(nombres)
                } else {
                    const namesWithoutId = data.list.filter(item => !item.products || !item.products.includes(params.id)).map(item => item.name)
                    setTagNameFiltered(namesWithoutId)
                }
            }
            setTagData(data.list)
            setProductID(params.id)
        } catch (e) {
            setError(e as Error)
        }
    }
    const handleAddTagClose = () => setAddTagOpen(false)

    const handleVisualizeTagOpen = async (params: any) => {
        setvisualizeTagOpen(true)
        try {
            const filters: QueryParameters = { sortField: "name" }
            const data = await tagService.getAll(filters)
            const filteredList = data.list.filter(item => item.products && item.products.includes(params.id))
            const filteredTagNames = filteredList.map(item => ({ name: item.name, color: item.color, products: item.products, client_id: params.client.id, description: item.description, id: item.id }))
            setTagNameList(filteredTagNames)
            setSelectedRow(params)
            setProductName(params.name)
        } catch (e) {
            setError(e as Error)
        }
    }
    const handleVisualizeTagClose = () => setvisualizeTagOpen(false)

    const fetchData = async (): Promise<IterableList<Tag> | undefined> => {
        try {
            const filters: QueryParameters = { sortField: "name" }
            return await tagService.getAll(filters)
        } catch (e) {
            setError(e as Error)
            return undefined
        }
    }
    useEffect(() => {
        const { name, description, color } = formTagData
        if (name && description && color) {
            setIsFormValid(true)
        } else {
            setIsFormValid(false)
        }
    }, [formTagData])
    useEffect(() => {
        const init = async () => {
            // setLoadingPromises(true)
            try {
                const result = await fetchData()
                let tagList: Tag[]

                if (result !== undefined) {
                    tagList = result?.list
                }

                const newCol = convertModelDefToGridColDef(ProductDefinition, ability)
                newCol.push({
                    field: "Tags",
                    type: "tags",
                    headerName: "Tags",
                    flex: 0.1,
                    minWidth: 230,
                    renderCell: (params) => {
                        if (ability.can("read", "Product")) {
                            const tagList2 = tagList && tagList.filter(tag => tag.products && tag.products.some(item => item === params.id))
                            return (
                                <Grid style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    {tagList2 && tagList2.length >= 1
                                        ? (
                                            <>
                                                {tagList2.slice(0, 2).map((chip, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={chip.name}
                                                        variant="outlined"
                                                        style={{ color: theme.palette.secondary.contrastText, backgroundColor: chip.color, marginRight: "5px" }}
                                                        deleteIcon={<HighlightOffIcon style={{ color: theme.palette.secondary.contrastText }}/>}
                                                        onDelete={() => handleDelete(chip, params.row)}
                                                    />
                                                ))}
                                                <Button startIcon={<MoreVertIcon />} onClick={() => handleVisualizeTagOpen && handleVisualizeTagOpen(params.row)} style={{ padding: "0px", minWidth: "px", paddingRight: "-5px", paddingLeft: "12px" }}></Button>
                                            </>
                                        )
                                        : (
                                            tagList2 && tagList2.map((chip, index) => (
                                                <Chip
                                                    key={index}
                                                    label={chip.name}
                                                    variant="outlined"
                                                    style={{ color: theme.palette.secondary.contrastText, backgroundColor: chip.color }}
                                                    deleteIcon={<HighlightOffIcon style={{ color: theme.palette.secondary.contrastText }}/>}
                                                    onDelete={() => handleDelete(chip, params)}
                                                />
                                            ))
                                        )}

                                </Grid>

                            )
                        }
                        return (
                            <div>
                                {params.value}
                            </div>

                        )
                    }

                })
                setCols(newCol)
            } catch (e: any) {
                setError(e as Error)
            }
            // setLoadingPromises(false)
        }
        init()
    }, [updateTags])
    const handleDelete = async (chip: any, params: any) => {
        const { id: productId } = params
        const updatedProducts = chip.products.filter((productID: string) => productID !== productId)
        const updatedChip = { ...chip, products: updatedProducts }
        try {
            await tagService.update(updatedChip.id, updatedChip)
            setTagNameList((prevList) => prevList.filter(tag => tag.id !== chip.id))
            setError(null)
        } catch (e: any) {
            setError({ message: e.error } as Error)
        }
        setUpdateTags(prev => !prev)
    }

    const handleInputChange = (e: any) => {
        if (auth.isAuthenticated) {
            const { name, value } = e.target
            setFormTagData({ ...formTagData, [name]: value })
        }
    }
    const customActions = (params: GridRowParams<Product>) => {
        const actions: React.JSX.Element[] = []

        actions.push(
            <Can key="update" I="update" an="Product" >
                <Tooltip title="Add tags">
                    <IconButton size="small" component="label" onClick={() => handleAddTagOpen(params.row)}>
                        <FaTags/>
                    </IconButton>
                </Tooltip>
            </Can>
        )

        return actions
    }

    const handleCreateTag = async (e: React.FormEvent) => {
        e.preventDefault()
        try {
            await tagService.create(formTagData)
            setCreateTagOpen(false)
            setError(null)
        } catch (e: any) {
            setError({ message: e.error } as Error)
        }
    }
    const handleAddTag = async () => {
        const foundObject = tagData.some(item => item.name === selectedTag)
        const index = tagData.findIndex(item => item.name === selectedTag)
        let itemFound
        if (index !== -1) {
            itemFound = tagData[index]
        }
        if (itemFound) {
            if (foundObject !== false) {
                try {
                    if (itemFound.products !== null) {
                        const aa = itemFound.products.push(productId)
                    } else {
                        itemFound.products = []
                        const bb = itemFound.products.push(productId)
                    }
                    await tagService.update(itemFound.id, itemFound)
                    setError(null)
                } catch (e: any) {
                    setError({ message: e.error } as Error)
                }
            }
        }
        setUpdateTags(prev => !prev)
        setAddTagOpen(false)
    }

    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }}>
            <Button variant="contained" sx={{ position: "absolute", top: "-25px", right: "0px", color: theme.palette.secondary.contrastText }} onClick={handleCreateTagOpen}>{context.t.translate("product_newtag")}</Button>
            <Can I="create" a="Product">
                <Button variant="contained" sx={{ position: "absolute", top: "-25px", right: "130px", color: theme.palette.secondary.contrastText }} onClick={() => navigate("./add")}>{context.t.translate("product_newproduct")}</Button>
            </Can>

            <Grid item container flexDirection="column" rowGap="35px">

                <GenericTable<Product> entity="Product" columns={cols}
                    dataProvider={(filter) => productService.getAllExt(filter)}
                    onEdit={(elem: Product) => navigate("./" + elem.id)}
                    onDelete={(elem: Product) => productService.delete(elem.id)}
                    handleAddTagOpen={handleAddTagOpen}
                    handleVisualizeTagOpen={handleVisualizeTagOpen}
                    withTags={true}
                    customActions={customActions}
                />
            </Grid >
            <Grid>
                <Modal open={createTagOpen} onClose={handleCreateTagClose}>
                    <Box sx={style} component="form" noValidate onSubmit={handleCreateTag}>
                        <Grid item xs container flexDirection="column">
                            <Typography color={theme.palette.secondary.main} fontSize="37px" fontWeight="bolder" fontFamily="Griff">
                                {context.t.translate("product_newtag")}
                            </Typography>
                        </Grid>
                        {error && <Alert severity="error">{error.message}</Alert>}
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <TextField margin="normal" required fullWidth variant="filled" label={context.t.translate("product_tag_name")} name="name"
                                    value={formTagData.name} onChange={handleInputChange}/>
                            </Grid>
                            <Grid item>
                                <TextField margin="normal" required fullWidth variant="filled" label={context.t.translate("product_description")} name="description"
                                    value={formTagData.description} onChange={handleInputChange}/></Grid>
                            <Grid item>
                                <Typography color={theme.palette.primary.contrastText} fontSize="18px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("product_color")}</Typography>
                            </Grid>
                            <Grid item container>
                                <Grid item xs>
                                    <Select name="color" style={{ fontFamily: "Griff", color: theme.palette.primary.contrastText }} value={formTagData.color} onChange={handleInputChange} fullWidth>
                                        {colors.map((color, index) => (
                                            <MenuItem key={index} value={color.value}><Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: color.value }}>{color.label}</Typography></MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid item container justifyContent="center">
                                <Grid item>
                                    <Button size="large" variant="contained" disabled={!isFormValid} sx={{ color: theme.palette.secondary.contrastText }} type="submit" >{context.t.translate("product_createtag")}</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            </Grid>
            <Grid>
                <Modal open={addTagOpen} onClose={handleAddTagClose}>
                    <Box sx={style}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Typography id="simple-modal-title" variant="h6" component="h2">{context.t.translate("product_tag")}</Typography>
                                <Autocomplete disablePortal id="combo-box-demo" options={tagName.length > 0 ? tagName : tagNameFiltered} value={selectedTag} onChange={(event, newValue) => {
                                    if (newValue !== null) {
                                        setSelectedTag(newValue)
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                />
                            </Grid>

                            <Grid item container justifyContent="center">
                                <Grid item>
                                    <Button size="large" variant="contained" sx={{ color: theme.palette.secondary.contrastText }} onClick={handleAddTag}>{context.t.translate("product_addtag")}</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            </Grid>
            <Grid>
                <Modal open={visualizeTagOpen} onClose={handleVisualizeTagClose}>
                    <Box sx={style}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Typography id="simple-modal-title" variant="h6" component="h2" style={{ marginBottom: 15 }}>Tags of {productName}</Typography>
                                <Divider sx={{ height: "1px", width: "100%", background: theme.palette.secondary.dark, marginBottom: "15px" }} />
                                {tagNameList.map((chip, index) => (
                                    <Chip key={index} label={chip.name} variant="outlined" style={{ color: theme.palette.secondary.contrastText, backgroundColor: chip.color }} deleteIcon={<HighlightOffIcon style={{ color: "black" }}/>} onDelete={() => handleDelete(chip, selectedRow)} />
                                ))}
                            </Grid>

                        </Grid>
                    </Box>
                </Modal>
            </Grid>
        </Grid>
    )
}

export { ProductList }
export default ProductList
