import React, { useContext, useEffect, useState } from "react"
import { AssessmentsStats } from "@components/assessment/index"
import { VulnerabilitiesStats } from "@components/vulnerability/index"
import { Box, Button, ClickAwayListener, Divider, Grid, Modal, Typography, useTheme } from "@mui/material"
import { useAuth } from "react-oidc-context"
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef"
import { AbilityContext } from "@components/permissions"
import { convertModelDefToGridColDef } from "@components/common/tables/utils"
import { GenericTable } from "@components/common/tables"
import ServicesContext from "@context/ServicesContext"
import { KeycloakSessions, SessionDefinition } from "@models/Keycloak"
import Event from "@models/Event"
import { useTrack } from "@components/track/TrackContext"
import VulnReportDocumentDashboard from "@components/report/VulnReportDocumentDashboard"
import { pdf } from "@react-pdf/renderer"
import { StatsAssessments, StatsProducts, StatsVulnerabilities } from "@models/Stats"
import { ProductType } from "@models/Product"
import { AssessmentStatus, AssessmentType } from "@models/Assessment"
import { VulnerabilityState } from "@models/Vulnerability"
import ProductSunburst from "@components/product/ProductSunburst"

// declare module "jwt-decode" {
//     export interface JwtPayload {
//         resource_access: Role;
//     }
// }

// type Role = {
//     "realm-management": Realms
// }

// interface Realms {
//    roles: Array<string>
// }

// interface Donuts
interface DonutValuesI {
    name: string;
    y: number;
    color: string;
   }

const Products: React.FC = () => {
    const auth = useAuth()
    const theme = useTheme()
    const [loginevents, setLoginEvents] = useState<Event[]>([])
    const [userDif, setUserDif] = useState<number>(0)
    const [visible, setVisible] = useState<boolean>(false)
    const [open, setOpen] = useState<boolean>(false)

    const statsService = useContext(ServicesContext).statsService
    const [stats, setStats] = useState<StatsAssessments | null>(null)
    const [vulnStats, setVulnStats] = useState<StatsVulnerabilities | undefined>(undefined)
    const [prodStats, setProdStats] = useState<StatsProducts | null>(null)
    const [byProduct, setByProduct] = useState< DonutValuesI[] >([])
    const [byCategory, setByCategory] = useState< DonutValuesI[] >([])
    const [byStatus, setByStatus] = useState< DonutValuesI[] >([])
    const [byType, setByType] = useState< DonutValuesI[] >([])
    const [byVulnState, setbyVulnState] = useState< DonutValuesI[] >([])

    const keycloakService = useContext(ServicesContext).keycloakService

    const [cols, setCols] = useState<GridColDef[]>([])
    const ability = useContext(AbilityContext)
    useEffect(() => {
        setCols(convertModelDefToGridColDef(SessionDefinition, ability))
    }, [])

    const handleClickAway = () => {
        setOpen(false)
    }
    const style = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "80%",
        bgcolor: "background.paper",
        color: theme.palette.primary.contrastText,
        border: `2px solid ${theme.palette.secondary.contrastText}`,
        boxShadow: 24,
        p: 4,
        height: "85%"
    }

    const styleBox = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "90%",
        color: theme.palette.primary.contrastText,
        p: 4,
        height: "90%"
    }
    const styleScroll = {
        overflowY: "auto",
        height: "60%",
        "&::-webkit-scrollbar": {
            width: "12px"
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(255, 255, 0)",
            borderRadius: "6px"
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent"
        }
    }
    const handleClose = () => setOpen(false)
    const MyModal = React.forwardRef(() => {
        const theme = useTheme()
        return (
            <ClickAwayListener onClickAway={handleClickAway}>
                <Box sx={styleBox}>
                    <Grid container item flexDirection="row" spacing="20px" padding="25px">
                        <Grid item xs={12} mx="0px" mb="25px">
                            <Typography variant="h4" color="primary" fontWeight="bolder">User Sessions</Typography>
                        </Grid>
                        <Divider sx={{ height: "1px", width: "100%", background: theme.palette.secondary.dark }} />
                        <Grid item xs container flexDirection="row">
                            <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
                    Login in last 2 days: {loginevents.length}
                            </Typography>
                            <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
                    Users in last 2 days: {userDif}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item className="scroll-container" sx={styleScroll} flexDirection="row" >
                        {visible && loginevents !== undefined && (
                            <><Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }}>
                                <Grid item container flexDirection="column" rowGap="35px">
                                    <GenericTable<KeycloakSessions> entity="KeycloakSession" columns={cols}
                                        dataProvider={(filter) => keycloakService.getAll(filter)}
                                        onEdit={undefined}
                                        onDelete={undefined} />
                                </Grid>
                            </Grid>
                            </>
                        )}
                    </Grid>
                    <Button size="large" fullWidth variant="contained" style={{ marginTop: "20px", color: theme.palette.secondary.contrastText }} onClick={() => handleClose()}>Close</Button>
                </Box>
            </ClickAwayListener>
        )
    })
    MyModal.displayName = "MyModal"

    // React.useEffect(() => {
    //     (async () => {
    //         try {
    //             // const filters: QueryParameters = { sortField: "name" }
    //             // const keycloak = await keycloakService.getAll(filters)
    //             const roles = jwtDecode<JwtPayload>(auth.user?.access_token!)
    //             if (roles.resource_access!["realm-management"].roles.includes("view-events")) {
    //                 const token = auth.user?.access_token
    //                 const response = await fetch(process.env.REACT_APP_SSO + "admin/realms/myorbik/events?type=LOGIN&first=0&max=500", {
    //                     headers: {
    //                         Authorization: `Bearer ${token}`
    //                     }
    //                 })
    //                 setLoginEvents(await response.json())
    //                 const userDif = loginevents
    //                     .map(dataItem => dataItem.userId) // get all media types
    //                     .filter((mediaType, index, array) => array.indexOf(mediaType) === index) // filter out duplicates
    //                 const counts = userDif
    //                     .map(mediaType => ({
    //                         type: mediaType,
    //                         count: loginevents.filter(item => item.userId === mediaType).length
    //                     }))
    //                 setUserDif(counts.length)
    //                 setVisible(true)
    //             }
    //         } catch (e) {
    //             console.error(e)
    //         }
    //     })()
    // }, [auth])

    // return (
    //     <Grid item container>
    //         {visible && loginevents !== undefined && <Box sx={{ width: "100%", padding: "35px" }}>
    //             <Grid container spacing={2}>
    //                 <Grid item xs={6} sx={{ padding: "10px" }}>
    //                     <Box sx={{ border: "1px solid #6D6C6F", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "10px", borderRadius: "10px", height: "auto" }}>
    //                         <Typography fontSize="35px" fontFamily="Griff" color="primary" fontWeight="bolder">
    //                             Login in last 2 days
    //                         </Typography>
    //                         <Box sx={{ marginTop: "10px", height: "180px" }}>
    //                             <DonutChart4 values={loginevents.length} />
    //                         </Box>
    //                     </Box>
    //                 </Grid>
    //                 <Grid item xs={6} sx={{ padding: "20px" }}>
    //                     <Box sx={{ border: "1px solid #6D6C6F", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "10px", borderRadius: "10px", height: "auto" }}>
    //                         <Typography fontSize="35px" fontFamily="Griff" color="primary" fontWeight="bolder">
    //                             Users in last 2 days
    //                         </Typography>
    //                         <Box sx={{ marginTop: "10px", height: "180px" }}>
    //                             <DonutChart4 values={userDif} />
    //                         </Box>
    //                     </Box>
    //                 </Grid>
    //             </Grid><Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }}>
    //                 <Grid item container flexDirection="column" rowGap="35px">
    //                     <GenericTable<KeycloakSessions> entity="KeycloakSession" columns={cols}
    //                         dataProvider={(filter) => keycloakService.getAll(filter)}
    //                         onEdit={undefined}
    //                         onDelete={undefined} />
    //                 </Grid>
    //             </Grid>
    //         </Box>}
    //         <AssessmentsStats/>
    //         <VulnerabilitiesStats/>
    //     </Grid>

    // Tracking
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "Home" })
    }, [])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const stats = await statsService.getStatsAssessment()

                setStats(stats)

                const vulnsStats = await statsService.getStatsVulnerabilities()
                setVulnStats(vulnsStats)

                const productStats = await statsService.getStatsProduct()

                setProdStats(productStats)

                const ByVulnState: DonutValuesI[] = (Object.keys(vulnsStats.VulnerabilityStates) as VulnerabilityState[]).map((key) => ({
                    name: key,
                    y: vulnsStats.VulnerabilityStates[key],
                    color: "white"
                }))
                setbyVulnState(ByVulnState)

                const ByProduct: DonutValuesI[] = Object.keys(stats.ProductName).map((key) => ({
                    name: key,
                    y: stats.ProductName[key],
                    color: "white"
                }))
                setByProduct(ByProduct)

                const ByCategory: DonutValuesI[] = (Object.keys(stats.TestCategory) as AssessmentType[]).map((key) => ({
                    name: key,
                    y: stats.TestCategory[key],
                    color: "white"
                }))
                setByCategory(ByCategory)

                const ByStatus: DonutValuesI[] = (Object.keys(stats.TestStatus) as AssessmentStatus[]).map((key) => ({
                    name: key,
                    y: stats.TestStatus[key],
                    color: "white"
                }))
                setByStatus(ByStatus)

                const ByType: DonutValuesI[] = (Object.keys(productStats.ProductTypes) as ProductType[]).map((key) => ({
                    name: key,
                    y: productStats.ProductTypes[key],
                    color: "white"
                }))
                setByType(ByType)

                // setError(null)
            } catch (e) {
                if (stats !== null) {
                    // setError(e as Error)
                }
            }
        }
        fetchData()
    }, [])
    const HandleExport = async () => {
        try {
            const report = pdf(<VulnReportDocumentDashboard prodStats={prodStats} stats={stats} />)
            const blob = await report.toBlob()
            const url = window.URL.createObjectURL(blob)
            window.open(url)
        } catch (error) {
            console.error(error)
        }
    }

    return (<>
        {/* {visible && loginevents !== undefined && (
            <Button variant="contained" sx={{ position: "absolute", top: "125px", right: "120px" }} onClick={() => setOpen(true)}>Sessions</Button>
        )} */}
        {/* <Grid item>
            <Grid item xs container flexDirection="column"sx={{ position: "relative" }}>
                <Can key="read" I="read" an="Compliance" ><Button variant="contained" sx={{ position: "absolute", top: "-40px", right: "20px", color: theme.palette.secondary.contrastText }} onClick={HandleExport} startIcon={<FaFileExport />} >EXPORT</Button></Can>
            </Grid>
        </Grid> */}
        <AssessmentsStats/>
        <ProductSunburst/>
        <VulnerabilitiesStats/>
        <Modal sx={style} open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <MyModal/>
        </Modal>
    </>
    )
}

export { Products }
export default Products
