import React, { useContext, useEffect, useState } from "react"
import { Avatar, Box, Button, Divider, Grid, Typography, useTheme } from "@mui/material"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import { GrTree } from "react-icons/gr"
import { FaRankingStar, FaRegShareFromSquare } from "react-icons/fa6"
import { FaPencilRuler } from "react-icons/fa"
import { SiInstructure } from "react-icons/si"
import QualityIndicator, { ValorChip } from "@components/common/indicator/QualityIndicator"
import GradiantGauge from "@components/charts/gauge/GradientGauge"
import ServicesContext from "@context/ServicesContext"
import { useParams } from "react-router-dom"
import BomQuality from "@models/BomQuality"
import { IconType } from "react-icons/lib"

type Category = {
    name: string;
    score: number;
    checks: number[];
    icon: IconType;
    iconProps: {};
    description: string[];
};

const categories1: Category[] = [
    {
        name: "Structural",
        score: 8.2,
        checks: [8.2, 9, 10, 10],
        icon: SiInstructure,
        iconProps: {},
        description: ["provided sbom is in a supported sbom format of spdx,cyclonedx",
            "provided sbom should be in supported spec version for spec:1.5 and versions: 1.0,1.1,1.2,1.3,1.4,1.5",
            "provided sbom should be in supported file format for spec: json and version: json,xml",
            "provided sbom is parsable"
        ]
    },
    {
        name: "NTIA min. elements",
        score: 7.1,
        checks: [8.2, 10, 0, 10, 2.4, 10, 10],
        icon: GrTree,
        iconProps: {},
        description: [
            "0/6 have supplier names",
            "6/6 have names",
            "6/6 have versions",
            "6/6 have unique ID's",
            "doc has 5 relationships ",
            "doc has 0 authors",
            "doc has creation timestamp "

        ]
    }
]
const newcategory = [
    {
        name: "Structural",
        icon: SiInstructure
    },
    {
        name: "NTIA-minimum-elements",
        icon: GrTree
    },
    {
        name: "Semantic",
        icon: FaPencilRuler

    },
    {
        name: "Quality",
        icon: FaRankingStar

    },
    {
        name: "Sharing",
        icon: FaRegShareFromSquare

    }
]

const categories2: Category[] = [
    {
        name: "Semantic",
        score: 3.7,
        checks: [8.2, 1.2, 0],
        icon: FaPencilRuler,
        iconProps: {},
        description: [
            "Doc Fields:true Pkg Fields:true",
            "0/6 have licenses",
            "0/6 have checksums"
        ]
    },
    {
        name: "Quality",
        score: 9.1,
        checks: [10, 10, 10, 1.2, 10],
        icon: FaRankingStar,
        iconProps: {},
        description: [

            "0/6 components with valid license ",
            "6/6 components have primary purpose specified",
            "no licenses found",
            "no licenses found",
            "5/6 components have any lookup id",
            "0/6 components have multiple lookup id",
            "0/0 tools have creator and version",
            "primary component found"

        ]
    },
    {
        name: "Sharing",
        score: 0,
        checks: [0],
        icon: FaRegShareFromSquare,
        iconProps: {},
        description: [

            "doc has a sharable license free 0 :: of 0"
        ]
    }
]

const groupByCategory = (scores: any) => {
    return scores.reduce((acc: any, item: any) => {
        const { category } = item
        if (!acc[category]) {
            acc[category] = []
        }
        acc[category].push(item)
        return acc
    }, {})
}

const SBOMQuality: React.FC = () => {
    const theme = useTheme()
    const [error, setError] = useState<Error|null>(null)
    const fileService = useContext(ServicesContext).fileService
    const { id } = useParams<{ id: string }>()
    const [qualityMetrics, setQualityMetrics] = useState<BomQuality>({ avg_score: 0, scores: [], timestamp: "" })
    const [dataArray, setDataArray] = useState<[]>([])

    const scoresByCategory = qualityMetrics.scores.reduce((acc: {[key: string]: any[]}, score) => {
        if (!acc[score.category]) {
            acc[score.category] = []
        }
        acc[score.category].push(score)
        return acc
    }, {})
    const formatKey = (key: any) => {
        const formattedKey = key.replace(/-/g, " ")
        return formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1)
    }
    const averageScoresByCategory: {[key: string]: number} = {}
    React.useEffect(() => {
        for (const category in scoresByCategory) {
            const scores = scoresByCategory[category]
            const totalScores = scores.length
            const sumScores = scores.reduce((total: number, score: any) => total + score.score, 0)
            const averageScore = sumScores / totalScores
            averageScoresByCategory[category] = averageScore
        }
    }, [qualityMetrics])
    useEffect(() => {
        const fecthData = async () => {
            try {
                const response = await fileService.scoreBom(id as string, new File([""], "filename"))
                const prueba = await response.json()
                setQualityMetrics(prueba)
            } catch (e: any) {
                setError({ message: e.error } as Error)
            }
        }
        fecthData()
    }, [])

    useEffect(() => {
        const prueba = groupByCategory(qualityMetrics.scores)
        setDataArray(prueba)
    }, [qualityMetrics])

    return (
        <Box sx={{ backgroundColor: theme.palette.secondary.contrastText, color: "white", padding: "20px" }}>

            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
                <Typography variant="h6"></Typography>
                <Button variant="contained" sx={{ backgroundColor: "yellow", color: theme.palette.secondary.contrastText }} startIcon={<CloudDownloadIcon/>}>Download SBOM</Button>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Box sx={{
                        padding: "10px",
                        backgroundColor: "#1e1e1f",
                        borderRadius: "10px",
                        border: "1px solid #6D6C6F",
                        marginBottom: "20px"
                    }}>
                        <Typography fontSize="28px" fontFamily="Griff" fontWeight="bolder" color={theme.palette.primary.main}>Score Summary</Typography>
                        <Divider sx={{ height: "1px", width: "100%", background: theme.palette.primary.contrastText, mb: "25px" }} />

                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", marginLeft: "-5px", marginRight: "-25px" }}>
                            <GradiantGauge value={parseFloat(qualityMetrics.avg_score.toFixed(1))} />
                        </Grid>

                        {Object.entries(scoresByCategory).map(([category, scores]) => (
                            <React.Fragment key={category}>
                                {newcategory.map((catIcon, index) => {
                                    if (catIcon.name === category) {
                                        return (
                                            <React.Fragment key={`${category}_${index}`}>
                                                <Box sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                                    <Grid container xs={12} flexDirection="row" display="flex" justifyContent="space-between" alignItems="center">
                                                        <Grid container item xs={6} alignItems="center" flexDirection="row" display="flex">
                                                            <Grid item xs={3} sx={{ marginRight: "15px" }}>
                                                                <Avatar sx={{ bgcolor: "transparent" }} variant="rounded">
                                                                    <catIcon.icon style={{ color: theme.palette.primary.contrastText }} />
                                                                </Avatar>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Typography fontSize="16px" fontFamily="Griff" fontWeight="bolder">
                                                                    {formatKey(category)}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <ValorChip valor={parseFloat(
                                                                (qualityMetrics.scores.filter(score => score.category === category).reduce((total, score) => total + score.score, 0) /
                                                            qualityMetrics.scores.filter(score => score.category === category).length).toFixed(2)
                                                            )} />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                <Divider sx={{ height: "1px", width: "100%", background: theme.palette.secondary.dark, mb: "25px" }} />
                                            </React.Fragment>
                                        )
                                    }
                                    return null
                                })}
                            </React.Fragment>
                        ))}

                    </Box>

                </Grid>
                <Grid item xs={9} >
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            {Object.entries(scoresByCategory).map(([category, scores]) => {
                                if (category === "Structural" || category === "NTIA-minimum-elements") {
                                    const catIcon = newcategory.find(item => item.name === category)

                                    return (
                                        <Box key={"category_" + category} sx={{
                                            padding: "10px",
                                            backgroundColor: theme.palette.secondary.contrastText,
                                            borderRadius: "10px",
                                            borderWidth: "1px",
                                            borderStyle: "solid",
                                            borderColor: "#CECECE", // borde fino blanco
                                            marginBottom: "20px"
                                        }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={10} >
                                                    <Grid container xs={12} justifyContent="space-between" flexDirection="row">
                                                        <Grid item xs={12} container alignItems="center">
                                                            <Avatar sx={{ bgcolor: "transparent" }} variant="rounded">
                                                                {catIcon && catIcon.icon && (
                                                                    <catIcon.icon style={{ color: theme.palette.primary.contrastText }} />
                                                                )}
                                                            </Avatar>
                                                            <Typography fontSize="28px" fontFamily="Griff" fontWeight="bolder" color={theme.palette.primary.main}>
                                                                {formatKey(category)}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={2} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                                                    <Box sx={{ display: "flex", marginRight: "10px", flexDirection: "column", height: "100%", justifyContent: "center", alignItems: "center" }}>
                                                        <ValorChip
                                                            valor={parseFloat(
                                                                (
                                                                    qualityMetrics.scores
                                                                        .filter((score) => score.category === category)
                                                                        .reduce((total, score) => total + score.score, 0) /
                                            qualityMetrics.scores.filter((score) => score.category === category).length
                                                                ).toFixed(2)
                                                            )}
                                                        />
                                                    </Box>

                                                </Grid>

                                            </Grid>

                                            <Divider sx={{ height: "1px", width: "100%", background: theme.palette.primary.contrastText, mb: "25px" }} />

                                            {scores.map((score, idx) => (
                                                <Grid key={"score_" + idx} container sx={{ marginBottom: "10px" }}>
                                                    <Grid item xs={1} sx={{ height: "100%" }}>
                                                        <Typography sx={{ fontSize: "30px" }} fontSize="34px" fontFamily="Griff" color="#6D6C6F">
                                                            {idx >= 0 && idx < 9 ? `0${idx + 1}.` : idx + 1}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={9} sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                                        <Typography sx={{ marginBottom: "5px" }} fontSize="16px" fontFamily="Griff" color={theme.palette.primary.contrastText}>
                                                            {formatKey(score.description)}
                                                        </Typography>
                                                        <QualityIndicator value={score.score} />
                                                    </Grid>
                                                    <Grid item xs={2} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        <Box sx={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "center", alignItems: "center" }}>
                                                            <ValorChip valor={score.score} />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Box>
                                    )
                                }
                                return null
                            })}
                        </Grid>
                        <Grid item xs={6}>
                            {Object.entries(scoresByCategory).map(([category, scores]) => {
                                if (category !== "Structural" && category !== "NTIA-minimum-elements") {
                                    const catIcon = newcategory.find(item => item.name === category)
                                    return (
                                        <Box key={"category_" + category} sx={{
                                            padding: "10px",
                                            backgroundColor: theme.palette.secondary.contrastText,
                                            borderRadius: "10px",
                                            borderWidth: "1px",
                                            borderStyle: "solid",
                                            borderColor: "#CECECE", // borde fino blanco
                                            marginBottom: "20px"
                                        }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6} >
                                                    <Grid container xs={12} justifyContent="space-between" flexDirection="row">
                                                        <Grid item xs={12} container alignItems="center">
                                                            <Avatar sx={{ bgcolor: "transparent" }} variant="rounded">
                                                                {catIcon && catIcon.icon && (
                                                                    <catIcon.icon style={{ color: theme.palette.primary.contrastText }} />
                                                                )}
                                                            </Avatar>
                                                            <Typography fontSize="28px" fontFamily="Griff" fontWeight="bolder" color={theme.palette.primary.main}>
                                                                {formatKey(category)}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                                    <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
                                                        <ValorChip
                                                            valor={parseFloat(
                                                                (
                                                                    qualityMetrics.scores
                                                                        .filter((score) => score.category === category)
                                                                        .reduce((total, score) => total + score.score, 0) /
                                                                        qualityMetrics.scores.filter((score) => score.category === category).length
                                                                ).toFixed(2)
                                                            )}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Divider sx={{ height: "1px", width: "100%", background: theme.palette.primary.contrastText, mb: "25px" }} />
                                            {scores.map((score, idx) => (
                                                <Grid key={"score_" + idx} container sx={{ marginBottom: "10px" }}>
                                                    <Grid item xs={1} sx={{ display: "flex", alignItems: "center", height: "100%" }}>
                                                        <Typography sx={{ fontSize: "30px", textAlign: "center" }} fontSize="34px" fontFamily="Griff" color="#6D6C6F">
                                                            {idx >= 0 && idx < 9 ? `0${idx + 1}.` : idx + 1}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={9} sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                                        <Typography sx={{ marginBottom: "5px" }} fontSize="16px" fontFamily="Griff" color={theme.palette.primary.contrastText}>
                                                            {score.description}
                                                        </Typography>
                                                        <QualityIndicator value={score.score} />
                                                    </Grid>
                                                    <Grid item xs={2} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        <Box sx={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "center", alignItems: "center" }}>
                                                            <ValorChip valor={score.score} />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Box>
                                    )
                                }
                                return null
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default SBOMQuality
