import React, { useContext } from "react"
import { SearchInput } from "@components/common/forms/index"
import { ServicesContext } from "@context/index"
import { Client } from "@models/index"
import { FilterOperation } from "@utils/index"
import { I18nContext } from "I18nProvider"

interface ClientSelectorProps{
    value: string
    disabled?: boolean
    onSelect: (id: string|undefined)=>void
}
const ClientSelector: React.FC<ClientSelectorProps> = ({ value, disabled, onSelect }) => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const clientService = useContext(ServicesContext).clientService

    return (
        <SearchInput<Client>
            margin="normal" required label={context.t.translate("product_client")}
            variant="filled"
            value={value}
            disabled={disabled}
            requesterById={async v => {
                if (v !== undefined) {
                    const client = await clientService.get(v)
                    return [client]
                }
                const cli: Client = { id: "", name: "", description: "" }
                return [cli]
            } }
            requester={async v => {
                const client = await clientService.getAll({ filters: [{ field: "name", operation: FilterOperation.StringContains, value: v }] })

                return client.list
            }} getterID={elem => elem?.id} getterTitle={elem => `${elem.name}`} onClickElem={(v) => onSelect(v?.id)}></SearchInput>
    )
}

export { ClientSelector }
export default ClientSelector
