import Highcharts from "highcharts"

const splitNParts = (number: number, parts: number, min: number) => {
    const randombit = number - min * parts
    const out = []

    for (let i = 0; i < parts; i++) {
        out.push(Math.random())
    }

    const mult = randombit / out.reduce(function (a, b) { return a + b })

    return out.map(function (el) { return Math.floor(el * mult + min) })
}

const random = (min: number, max: number) => Math.floor(Math.random() * (1 + max - min)) + min

const dataGenerator = () => {
    const data: Highcharts.PointOptionsObject[] = [
    ]

    const stages = [
        { name: "Application", min: 250, max: 350, color: "#4DB04A" },
        { name: "Firmware", min: 20, max: 100, color: "#377EB9" },
        { name: "Other", min: 20, max: 100, color: "#377EB9" }
    ]

    const deviceCatalog = [
        {
            id: "1",
            name: "Product 1",
            color: "#8DD3C7",
            products: [
                {
                    id: "satechsc",
                    name: "saTECH SCU",
                    versions: [
                        { version: "2.0", totalValue: random(10, 100) },
                        { version: "3.0", totalValue: random(10, 100) }
                    ]
                },
                {
                    id: "rockwell",
                    name: "Rockwell Dynamo EEV",
                    versions: [
                        { version: "2.0", totalValue: random(10, 100) },
                        { version: "3.0", totalValue: random(10, 100) }
                    ]
                }
            ]
        },
        {
            id: "Firmware",
            name: "Firmware",
            color: "#FFFFB3",
            products: [
                {
                    id: "satechsc",
                    name: "saTECH SCU",
                    versions: [
                        { version: "2.0", totalValue: random(10, 100) },
                        { version: "3.0", totalValue: random(10, 100) }
                    ]
                },
                {
                    id: "rockwell",
                    name: "Rockwell Dynamo EEV",
                    versions: [
                        { version: "2.0", totalValue: random(10, 100) },
                        { version: "3.0", totalValue: random(10, 100) }
                    ]
                }
            ]
        },
        {
            id: "Others",
            name: "Others",
            color: "#B3DE68",
            products: [
                {
                    id: "gamesamv",
                    name: "Gamesa MV",
                    versions: [
                        { version: "2.0", totalValue: random(10, 100) },
                        { version: "3.0", totalValue: random(10, 100) }
                    ]
                }
            ]
        }
    ]

    stages.forEach(stage => {
        const devicesInStage = random(stage.min, stage.max)
        data.push({
            id: stage.name,
            name: stage.name,
            color: stage.color
        })

        const deviceCatalogDiv = splitNParts(devicesInStage, deviceCatalog.length, 1)
        deviceCatalog.forEach((catalogFamily, i) => {
            const catalogFamilyDiv = splitNParts(deviceCatalogDiv[i], catalogFamily.products.length, 1)
            data.push({
                parent: stage.name,
                color: catalogFamily.color,
                id: stage.name + "." + catalogFamily.id,
                name: catalogFamily.name
            })

            catalogFamily.products.forEach((product, j) => {
                const productDiv = splitNParts(catalogFamilyDiv[j], product.versions.length, 1)

                data.push({
                    parent: stage.name + "." + catalogFamily.id,
                    color: catalogFamily.color,
                    id: stage.name + "." + catalogFamily.id + "." + product.id,
                    name: product.name
                })

                product.versions.forEach((versionObj: { version: string; totalValue: number }, k) => {
                    data.push({
                        parent: stage.name + "." + catalogFamily.id + "." + product.id,
                        color: catalogFamily.color,
                        id: stage.name + "." + catalogFamily.id + "." + product.id + "." + versionObj.version,
                        name: versionObj.version,
                        value: productDiv[k]
                    })
                })
            })
        })
    })

    return data
}

export default dataGenerator
