import React from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import HighchartsMore from "highcharts/highcharts-more"
import HighchartsExporting from "highcharts/modules/exporting"
import HighchartsBoost from "highcharts/modules/boost"
import HighchartsTimeline from "highcharts/modules/timeline"
import highchartsAccessibility from "highcharts/modules/accessibility"
import HighchartsNavigator from "highcharts/modules/navigator"
import { useNavigate } from "react-router-dom"

HighchartsNavigator(Highcharts)
HighchartsMore(Highcharts)
HighchartsExporting(Highcharts)
HighchartsBoost(Highcharts)
HighchartsTimeline(Highcharts)
highchartsAccessibility(Highcharts)

interface TimeLineValues {

    x: number;
    name: string;
    description: string;
    version: string;
    id: string;

}
interface Event {
    newenventsdata: TimeLineValues[],
    selected_id: string | undefined;
}

const TimelineChart : React.FC<Event> = (props: Event) => {
    const navigate = useNavigate()
    const { newenventsdata } = props
    // const totalData = newenventsdata
    const totalData = newenventsdata.map((data: any) => ({ ...data, clicked: false }))

    const options = {
        chart: {
            inverted: true,
            zooming: {
                type: "x"
            },
            type: "timeline",
            height: 400,
            width: 250,
            backgroundColor: "transparent",
            margin: [50, 0, 30, 0]
        },
        xAxis: {
            type: "datetime",
            visible: false
        },
        yAxis: {
            lineColor: "yellow",
            visible: false,
            labels: {
                enabled: false
            }
        },
        legend: {
            enabled: false
        },
        title: {
            text: undefined
        },
        credits: { enabled: false },
        exporting: { enabled: false },
        tooltip: {
            style: {
                width: 300,
                color: "black"

            }
        },

        plotOptions: {
            series: {
                showInNavigator: true,
                events: {

                    click: function (event: any) {
                        navigate("/product/" + event.point.id)

                        event.point.update({
                            dataLabels: {
                                backgroundColor: "yellow",
                                color: "black"
                            }
                        })
                        // Set a timer to revert the data label background color back to black after 1 second
                        setTimeout(() => {
                            // Revert the data label background color to black
                            event.point.update({
                                dataLabels: {
                                    backgroundColor: "black",
                                    color: "white" // Optionally change text color to white for better contrast
                                }
                            }) // Use `false` to avoid immediate redraw
                        }, 500)
                    }
                }
            },
            area: {
                lineColor: "yellow"
            }
        },
        series: [{
            type: "timeline",
            lineColor: "yellow",
            dataLabels: {
                allowOverlap: false,
                color: "white",
                backgroundColor: "black",
                format: "<span style=\"color:{point.color}\">● </span><span " +
                    "style=\"font-weight: bold;\" > " +
                    "{point.x:%d %b %Y}</span><br/>{point.label}"
            },
            marker: {
                symbol: "circle",
                lineColor: "grey"
            },
            data: totalData
        }]
    }
    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    )
}
export { TimelineChart }
export default TimelineChart
