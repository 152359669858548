import React from "react"
import { Divider, Grid, Theme, Tooltip, useTheme } from "@mui/material"
import { IconBaseProps, IconType } from "react-icons/lib"
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom"
import { Can } from "@components/permissions/index"
import { Subjects } from "@permissions/index"

interface Item {
    icon: IconType;
    title: string;
    iconProps: IconBaseProps;
    entity?: Subjects;
    link: string;
}
interface Props {
    primaryItems: Array<Item>[]
    secondaryItems: Array<Item>[]
}

interface ItemProps{
    theme: Theme,
    item: Item,
    navigate: NavigateFunction;
    isLocationActive: (link: string) => boolean;
}

const SidebarItem: React.FC<ItemProps> = ({ theme, item, navigate, isLocationActive }) => (
    <Grid item container alignItems="center" justifyContent="center" onClick={() => navigate(item.link)} sx={{
        height: "60px",
        cursor: "pointer",
        ...isLocationActive(item.link) && ({ background: theme.palette.secondary.contrastText })
    }}>
        <Tooltip title={item.title}>
            <Grid item>
                {
                    React.createElement(item.icon, { size: "28px", color: isLocationActive(item.link) ? theme.palette.primary.main : theme.palette.secondary.contrastText, ...item.iconProps })
                }
            </Grid>
        </Tooltip>
    </Grid>
)

const Sidebar: React.FC<Props> = ({ primaryItems, secondaryItems }) => {
    const theme = useTheme()
    const location = useLocation().pathname
    const navigate = useNavigate()
    const isLocationActive = (link: string) => {
        return link === location || (link !== "/" && location.indexOf(link) === 0)
    }

    return (
        <Grid item container flexDirection="column" sx={{ background: theme.palette.primary.main, height: "100%", width: "60px" }}>
            <Grid item xs container flexDirection="column">
                {
                    primaryItems.map((groupItem, groupIdx) => (
                        <div key={groupItem + "-" + groupIdx}>
                            {
                                groupItem.map((item, idx) => {
                                    const itCmp = <SidebarItem key={groupIdx + "-" + idx} item={item} isLocationActive={isLocationActive} theme={theme} navigate={navigate}></SidebarItem>
                                    return item.entity != null
                                        ? <Can key={groupIdx + "-" + idx} I="read" a={item.entity}>{itCmp}</Can>
                                        : itCmp
                                }
                                )
                            }
                            {
                                groupIdx < (primaryItems.length - 1) && (
                                    <Grid item container alignItems="center" justifyContent="center" sx={{ margin: "12px 0" }}>
                                        <Divider sx={{ border: `1px solid ${theme.palette.secondary.dark}`, width: "50%" }} />
                                    </Grid>
                                )
                            }
                        </div>
                    ))
                }
            </Grid>
            <Grid item xs="auto">
                {
                    secondaryItems.map((groupItem, groupIdx) => (
                        <div key={groupItem + "-" + groupIdx}>
                            {
                                groupItem.map((item, idx) => {
                                    const itCmp = <SidebarItem key={groupIdx + "-" + idx} item={item} isLocationActive={isLocationActive} theme={theme} navigate={navigate}></SidebarItem>
                                    return item.entity != null
                                        ? <Can key={groupIdx + "-" + idx} I="read" a={item.entity}>{itCmp}</Can>
                                        : itCmp
                                }
                                )
                            }
                            {
                                groupIdx < (primaryItems.length - 1) && (
                                    <Grid item container alignItems="center" justifyContent="center" sx={{ margin: "12px 0" }}>
                                        <Divider sx={{ border: `1px solid ${theme.palette.secondary.dark}`, width: "50%" }} />
                                    </Grid>
                                )
                            }
                        </div>
                    ))
                }
            </Grid>
        </Grid>
    )
}

export { Sidebar }
export default Sidebar
