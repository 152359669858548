import { AssessmentTypeKeysValueOps } from "@components/assessment/index"
import { Vulnerability, VulnerabilityJustificationKeysValueOps, VulnerabilityResponseKeysValueOps, VulnerabilityStateKeysValueOps } from "@models/index"
import { ModelDefinition } from "@models/utils"
import { NumberColoredCVSS, VulnerabilityStateColored } from "."
import TagColored from "./TagColored"
import KevCheckbox from "./KevCheckbox"
import AssessmentStateColored from "./AssessmentStateColored"
import VulnerabilityNameNavigate from "./VulnerabilityNameNavigate"
// import TagColored from "./TagColored"

const VulnerabilityTableDefinition: ModelDefinition<Vulnerability> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 0.3, isId: true },
        { name: "identifier", label: "Identifier", flex: 0.2, type: "string", render: VulnerabilityNameNavigate },
        { name: "score", label: "Score", type: "number", flex: 0.18, render: NumberColoredCVSS },
        { name: "revised_score", label: "Revised Score", type: "number", flex: 0.18, render: NumberColoredCVSS },
        { name: "priority", label: "Priority", type: "number", flex: 0.12 },
        { name: "epss", label: "EPSS", type: "number", flex: 0.12 },
        // { name: "percentile", label: "EPSS %", type: "number", flex: 0.15 },
        { name: "kev", label: "KEV", type: "string", flex: 0.1, render: KevCheckbox },
        { name: "vector", label: "Vector", type: "string", flex: 0.3, hide: true },
        { name: "state", label: "Status", type: "singleSelect", flex: 0.16, allowedValueOptions: VulnerabilityStateKeysValueOps, render: VulnerabilityStateColored },
        { name: "description", label: "Description", type: "string", flex: 0.5, hide: true },
        { name: "justification", label: "Justification", type: "singleSelect", flex: 0.15, allowedValueOptions: VulnerabilityJustificationKeysValueOps, hide: true },
        { name: "response", label: "Response", type: "singleSelect", flex: 0.15, allowedValueOptions: VulnerabilityResponseKeysValueOps, hide: true },
        { name: "time_spent", label: "Time Spent", type: "number", flex: 0.1, hide: true },
        { name: "cmp_name", label: "Cmp Name", type: "string", flex: 0.2, hide: true },
        { name: "cmp_version", label: "Cmp Version", type: "string", flex: 0.2, hide: true },
        { name: "assesment.name", label: "Assessment", type: "singleSelect", flex: 0.15, allowedValueOptions: AssessmentTypeKeysValueOps, valueGetter: vuln => vuln.assessment.name, render: AssessmentStateColored },
        { name: "product.name", label: "Product", type: "string", flex: 0.2, valueGetter: vuln => vuln.product.name },
        { name: "tags", label: "Tags", type: "string", flex: 0.25, render: TagColored, valueGetter: vuln => vuln.product.id },
        { name: "product.version", label: "Version", type: "string", flex: 0.1, valueGetter: vulnerability => vulnerability.product.version },
        { name: "client.name", label: "Client", type: "string", flex: 0.1, valueGetter: vuln => vuln.client.name, permission: { subject: "Multitenant", action: "read" } }
    ]
}

export { VulnerabilityTableDefinition, VulnerabilityStateKeysValueOps, VulnerabilityJustificationKeysValueOps, VulnerabilityResponseKeysValueOps }
export default VulnerabilityTableDefinition
