import React from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import VexDocumentStyles from "@components/report/VexStyleSheet"
import styles from "@components/report/Styles"
import { Footer, Header } from "./HeaderFooter"

export const introduccion = [
    { id: "Executive Summary: ", description: "A general summary of the results obtained during the software composition analysis is presented." },
    { id: "Vulnerability Identification: ", description: "The system's vulnerabilities and weaknesses are identified, and a series of countermeasures are proposed to mitigate the identified and/or exploited vulnerabilities and weaknesses." },
    { id: "Next Steps: ", description: "The different services offered by Orbik to support compliance with cybersecurity regulations are explained." }
]

const IntroCompliance: React.FC<{}> = () => (
    <Page size="A4">
        <Header></Header>
        <View style={{ ...VexDocumentStyles.section, flexDirection: "row", textAlign: "justify" }}>
            <View style={styles.column}>
                <View>
                    <Text style={styles.titulo}>1. Introduction</Text>
                    <Text style={{ ...styles.textua, marginRight: 50, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }}>This document presents the results of the latest Cyber Resilience Act (CRA) Compliance Self-Assessment conducted on myorbik.com..</Text>
                    <Text style={{ ...styles.textua, marginRight: 50, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }} wrap={true}>This assessment is designed to identify and characterize compliance weaknesses, design flaws, and vulnerabilities within the entire system. Conducted routinely during the product verification and validation phase, this activity is a specific requirement of the CRA normative</Text>
                    <Text style={{ ...styles.textua, marginRight: 50, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }}>The report is organized this way:</Text>
                    {introduccion.map((point, index) => (
                        <Text key={index} style={{ ...styles.puntuak, marginRight: 50, fontFamily: "Griff", fontSize: 10, paddingTop: 5 }}>
                            <Text style={styles.puntuak}>• &nbsp;</Text>
                            <Text style={{ ...styles.puntuak, fontFamily: "Bold" }}>{point.id}</Text>
                            <Text style={styles.puntuak}>{point.description}</Text>
                        </Text>
                    ))}
                </View>
            </View>
            <View style={styles.column}>
                <Image style={{ ...styles.rightImage, marginLeft: 30, paddingTop: 0, marginTop: -40, height: 305 }} src="./assets/img/report/computer.jpg" />
                <View style={{ ...styles.rightImage, marginTop: 265, height: 270, marginLeft: 30, marginRight: -20, paddingTop: 100, marginBottom: 50, backgroundColor: "#fffa37", flexDirection: "row" }}>
                    <Text style={{ marginLeft: 55, marginRight: 50, paddingTop: -80, marginBottom: 200, paddingRight: 30, textAlign: "right", fontFamily: "Bold", fontSize: 18 }}>The analysis aims to identify and characterize the system&apos;s level of compliance with CRA requirements.</Text>
                </View>
            </View>
        </View>
        <Footer></Footer>
    </Page>
)

export { IntroCompliance }
