import { Grid, Paper, Typography, useTheme } from "@mui/material"
import React from "react"

interface Props {
    low :number,
    medium :number,
    high :number,
    critical :number,
    none :number
}
interface Indicator {
    backgroundColor: string,
    color: string;
    count: number;
}
const VulnerabilityIndicator: React.FC<Props> = ({
    low,
    medium,
    high,
    critical,
    none
}) => {
    const indicators: Array<Indicator> = []
    const theme = useTheme()
    if (none > 0) { indicators.push({ backgroundColor: "black", color: "#A9A9A9", count: none }) }
    if (low > 0) { indicators.push({ backgroundColor: "black", color: "#F2CC0C", count: low }) }
    if (medium > 0) { indicators.push({ backgroundColor: "black", color: "#EC971F", count: medium }) }
    if (high > 0) { indicators.push({ backgroundColor: "black", color: "#D9534F", count: high }) }
    if (critical > 0) { indicators.push({ backgroundColor: "black", color: "#7940A5", count: critical }) }
    if (indicators.length === 0) {
        indicators.push({ backgroundColor: "black", color: "gray", count: 0 })
    }

    if (indicators.length > 0) {
        return (
            <Grid
                component={Paper}
                elevation={4}
                container
                columns={indicators.length}
                sx={{ width: "200px", height: "12px" }}
            >
                {indicators.map((indicator, index) => {
                    return (
                        <Grid
                            key={index}
                            item
                            xs={1}
                            sx={{
                                background: indicator.color,
                                textAlign: "center",
                                fontSize: "12px",
                                fontWeight: "bold",
                                borderRadius: `${index === 0 ? "10px" : "0px"} ${index === indicators.length - 1 ? "10px" : "0px"
                                } ${index === indicators.length - 1 ? "10px" : "0px"} ${index === 0 ? "10px" : "0px"
                                }`
                            }}
                        >
                            {indicator.count}
                        </Grid>
                    )
                })}
            </Grid>
        )
    }
    return (
        <Typography variant="caption" fontStyle="italic" color={theme.palette.secondary.main}>
               No vulnerabilities
        </Typography>
    )
}
export { VulnerabilityIndicator }
