import React from "react"
import { styled } from "@mui/material/styles"
import { Checkbox, CheckboxProps } from "@mui/material"

const OrbikCheckbox = styled((props: CheckboxProps) => (
    <Checkbox {...props} />
))(({ theme }) => ({
    padding: 0,
    paddingRight: 8,
    transform: "scale(1.5)"
}))

export { OrbikCheckbox }
export default OrbikCheckbox
