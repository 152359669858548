import React, { useContext, useEffect, useState } from "react"
import { Button, Grid, useTheme } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { useNavigate } from "react-router-dom"
import { ServicesContext } from "@context/index"
import Ticketing, { TicketingTableDefinition } from "@models/Ticketing"
import { convertModelDefToGridColDef, GenericTable } from "@components/common/tables"
import Can, { AbilityContext } from "@components/permissions/Can"
import { I18nContext } from "I18nProvider"

const TicketingList: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const ticketingService = useContext(ServicesContext).ticketingService

    const [cols, setCols] = useState<GridColDef[]>([])

    const navigate = useNavigate()
    const theme = useTheme()
    const ability = useContext(AbilityContext)
    useEffect(() => {
        setCols(convertModelDefToGridColDef(TicketingTableDefinition, ability))
    }, [])
    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }}>
            <Can I="create" a="Ticketing">
                <Button variant="contained" sx={{ position: "absolute", top: "-25px", right: "0px", color: theme.palette.secondary.contrastText }} onClick={() => navigate("/settings/ticket/add")}>{context.t.translate("setting_add_ticket")}</Button>
            </Can>
            <Grid item container flexDirection="column" rowGap="35px">

                <GenericTable<Ticketing> entity="Ticketing" columns={cols}
                    dataProvider={(filter) => ticketingService.getAll(filter)}
                    onEdit={(elem: Ticketing) => navigate(`/settings/ticket/${elem.id}`)}
                    onDelete={(elem: Ticketing) => ticketingService.delete(elem.id)}
                />
            </Grid >
        </Grid>
    )
}

export { TicketingList }
export default TicketingList
