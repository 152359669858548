import React from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import styles from "../../Styles"
import VexDocumentStyles from "../../VexStyleSheet"

const indice = [
    "Introducción..............................................................3",
    "Alcance......................................................................4",
    "Resumen ejecutivo....................................................5",
    "Descubrimiento e identificación del sistema............6",
    "Servicios publicados..................................................6",
    "Puesta en marcha.....................................................6",
    "Identificación de anomalías......................................7",
    "Introducción.............................................................7",
    "Resultados................................................................8",
    "Ejecución de las pruebas.........................................9",
    "TCP/IP.......................................................................9",
    "HTTP.......................................................................12",
    "Conclusiones...........................................................12"
]

const normativas = [
    { id: "Normativas" },
    { id: "- Open Web Application Security Project (OWASP)" },
    { id: "- IEC 62443-4-1" },
    { id: "- The Penetration Testing Execution Standard Documentation" },
    { id: "- PCI Data Security Standard (PCI DSS)" }
]
export const indextable = [
    ["Versión", "Fecha", "Secciones afectadas", "Modificaciones"],
    ["1.0", "29/01/24", "Todas", "Versión inicial completa"],
    ["1.1", "30/01/24", "Todas", "Versión general"],
    ["1.2", "31/01/24", "Todas", "Segunda revisión"],
    ["1.3", "05/02/24", "Todas", "Revisión final"]
]

const Index: React.FC<{}> = () => (
    <Page size="A4">
        <View style={styles.page}>

            <Image style={{ ...styles.image3, paddingRight: 0, position: "absolute", top: 0, left: 0, width: 595, height: 842, zIndex: -1 }} src="./assets/img/report/indice_def.jpg" />
            <View style={{ ...VexDocumentStyles.section, flexDirection: "row" }}>
                <View style={styles.column}>
                    <View style={{ ...styles.column, paddingLeft: 10, paddingTop: 250, paddingRight: 10 }}>
                        <Text style={{ ...styles.titulo, fontFamily: "Bold" }}>Índice</Text>
                        <View>
                            {indice.map((point, index) => (
                                <Text key={index} style={{ ...styles.puntuak2, paddingLeft: 40, marginRight: -30, paddingBottom: 5 }}>
                                    <Text style={{ ...styles.puntuak, textAlign: "left", fontFamily: "Bold", fontSize: 10.5, marginRight: -20 }}>{index + 1}.  {point}</Text>
                                </Text>
                            ))}
                        </View>
                        <Text>&nbsp;</Text>
                        <View style={{ ...styles.tableScope, width: "90%" }}>
                            <Text style={{ ...styles.tableColScope, textAlign: "left", width: "58.25%", height: 140, fontSize: 10.5 }}>
                                {normativas.map((fila, index) => (
                                    index === 0 ? fila.id : `\n\n${fila.id}`
                                ))}
                            </Text>
                        </View>
                    </View>
                </View>

            </View>
            <FooterIndex></FooterIndex>
            {/* <View style={{ ...styles.column, paddingTop: 300 }}>
  <Text style={{ ...styles.textua, paddingRight: 0, marginRight: -20, paddingLeft: 19 }}>HISTÓRICO DE CAMBIOS:</Text>
  <View style={{ ...styles.tableScope, width: "95%", paddingLeft: 19, paddingRight: 10 }}>
        {version.map((fila, indexFila) => (
                  <View key={indexFila} style={{ ...styles.tableRowScope, width: "100%", textAlign: "center" }}>
                    {fila.map((dato, indexDato) => (
                      <Text key={indexDato} style={{ ...styles.tableColScope, width: "100%", textAlign: "center" }}>
                      {dato}
                    </Text>
                    ))}
                  </View>
                ))}
      </View>
</View> */}
        </View>
    </Page>
)

const FooterIndex: React.FC<{}> = () => (
    <Text style={{ paddingLeft: 32, fontFamily: "Griff", paddingRight: -340, paddingBottom: 32, paddingTop: 787, fontSize: 5.5, position: "absolute", textAlign: "justify" }}>
            Los derechos de propiedad intelectual e industrial sobre el presente documento son titularidad exclusiva de ORBIK Cybersecurity, S.L. Asimismo, el presente informe y, en su caso, cualquier documento anexo al
mismo, contiene información de carácter confidencial exclusivamente dirigida a su destinatario.
        <Text style={{ fontFamily: "Bold", color: "#FFC000", backgroundColor: "black" }}>TLP-AMBER</Text>  Queda prohibida su divulgación, copia o distribución a terceros sin la previa autorización escrita de
ORBIK Cybersecurity. En el caso de haber recibido este informe por error, proceda a su borrado y notifíquelo inmediatamente a ORBIK Cybersecurity, S.L
    </Text>
)

export { Index }
