import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { DataGrid, getGridStringOperators, GridColDef, GridFilterModel, GridFilterOperator, GridRowsProp, GridSortModel } from "@mui/x-data-grid"
import { Box, Button, Grid, IconButton, Paper, TableContainer, useTheme } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { AbilityContext, Can } from "@components/permissions/index"
import { ServicesContext } from "@context/index"
import { FilterOperation, QueryParameters } from "@utils/index"
import { I18nContext } from "I18nProvider"
import { convertToTree } from "./hierarchyUtils"

interface TreeNode {
  id: string;
  name: string;
  description: string,
  children?: TreeNode[];
}

interface FlattenedTreeNode extends Omit<TreeNode, "children"> {
    parentId?: string;
    level: number;
    isLeaf: boolean;
  }

const flattenTreeData = (
    nodes: TreeNode[],
    parentId: string | undefined = undefined,
    level: number = 0
): FlattenedTreeNode[] => {
    return nodes.flatMap(node => {
        const { children, ...rest } = node
        const isLeaf = !children || children.length === 0
        const flatNode: FlattenedTreeNode = { ...rest, parentId, level, isLeaf }
        if (isLeaf) {
            return [flatNode]
        }
        return [flatNode, ...flattenTreeData(children, node.id, level + 1)]
    })
}

const allowedStringOperators: GridFilterOperator[] = getGridStringOperators().filter(operator =>
    ["contains", "equals"].includes(operator.value)
)

const HierarchyList: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const hierarchyService = useContext(ServicesContext).hierarchyService
    const [treeData, setTreeData] = useState<FlattenedTreeNode[]>([])
    const [visibleRows, setVisibleRows] = useState<FlattenedTreeNode[]>([])
    const [expanded, setExpanded] = useState<Set<string>>(new Set())
    const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] })
    const [sortModel, setSortModel] = useState<GridSortModel>([])
    const [rows, setRows] = useState<GridRowsProp>([])
    const navigate = useNavigate()
    const ability = useContext(AbilityContext)

    const toggleExpand = (id: string) => {
        setExpanded(prev => {
            const newExpanded = new Set(prev)
            if (newExpanded.has(id)) {
                newExpanded.delete(id)
            } else {
                newExpanded.add(id)
            }
            return newExpanded
        })
    }

    const init = async () => {
        const sortField = sortModel.length > 0 ? sortModel[0].field : "name"
        const sortMode = sortModel.length > 0 ? (sortModel[0].sort as "asc" | "desc") : "asc"

        const filters: QueryParameters = {
            filters: filterModel.items
                .filter(item => !!item.value)
                .map(item => ({
                    field: item.field,
                    operation: (() => {
                        switch (item.operator) {
                        case "equals":
                            return FilterOperation.StringEqual
                        default:
                            return FilterOperation.StringContains
                        }
                    })(),
                    value: item.value as string
                }))
        }

        const data = await hierarchyService.getAllExt(filters)
        const flattenedData = flattenTreeData(convertToTree(data.list, sortField as "name" | "description", sortMode))
        setTreeData(flattenedData)

        const initialExpanded = new Set<string>()
        flattenedData.forEach(node => {
            initialExpanded.add(node.id)
        })
        setExpanded(initialExpanded)
    }

    useEffect(() => {
        init()
    }, [filterModel, sortModel])

    useEffect(() => {
        const isVisible = (node: FlattenedTreeNode): boolean => {
            if (!node.parentId) {
                return true
            }
            if (!expanded.has(node.parentId)) {
                return false
            }
            const parent = treeData.find(row => row.id === node.parentId)
            return parent ? isVisible(parent) : false
        }

        const v = treeData.filter(isVisible)
        setVisibleRows(v)
    }, [expanded, treeData])

    useEffect(() => {
        const v = visibleRows.map(node => ({
            id: node.id,
            name: node.name,
            description: node.description,
            level: node.level,
            isLeaf: node.isLeaf
        }))
        setRows(v)
    }, [visibleRows])

    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: "Name",
            width: 300,
            renderCell: params => {
                const node = treeData.find(row => row.id === params.row.id)
                return node
                    ? (
                        <Box sx={{ pl: node.level * 2 }}>
                            {!node.isLeaf && (
                                <IconButton
                                    size="small"
                                    onClick={() => toggleExpand(node.id)}
                                >
                                    {expanded.has(node.id)
                                        ? (
                                            <ExpandMoreIcon />
                                        )
                                        : (
                                            <ChevronRightIcon />
                                        )}
                                </IconButton>
                            )}
                            {params.value}
                        </Box>
                    )
                    : null
            },
            filterOperators: allowedStringOperators
        },
        {
            field: "description",
            headerName: "Description",
            width: 400,
            filterOperators: allowedStringOperators
        }
    ]

    const handleFilterModelChange = (model: GridFilterModel) => {
        setFilterModel(model)
    }

    const handleSortModelChange = (model: GridSortModel) => {
        setSortModel(model)
    }

    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }}>
            <Can I="create" a="Hierarchy">
                <Button variant="contained" sx={{ position: "absolute", top: "-25px", right: "0px", color: theme.palette.secondary.contrastText }} onClick={() => navigate("./manage")}>{context.t.translate("hierar_manage")}</Button>
            </Can>

            <Grid item container flexDirection="column" rowGap="35px">
                <TableContainer component={Paper}>
                    <DataGrid sx={{ backgroundColor: theme.palette.secondary.contrastText }} rows={rows} columns={columns} autoHeight filterMode="server" filterModel={filterModel} onFilterModelChange={handleFilterModelChange} sortingMode="server" sortModel={sortModel} onSortModelChange={handleSortModelChange} />
                </TableContainer>
            </Grid>
        </Grid>

    )
}

export { HierarchyList }
export default HierarchyList
