import React from "react"
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid"
import { Grid, Link, Typography, useTheme } from "@mui/material"
import { Link as RouterLink } from "react-router-dom" // Importamos Link para react-router-dom
import Vulnerability from "@models/Vulnerability"
import { CWES } from "@models/CWE"

interface CWEDataGridProps {
  formData: Vulnerability;
}

const CWEDataGrid: React.FC<CWEDataGridProps> = ({ formData }) => {
    const theme = useTheme()
    const renderIdCell = (params: GridRenderCellParams<any, any>) => (
        <Link component={RouterLink} to={`https://cwe.mitre.org/data/definitions/${params.value}.html`} style={{ textDecoration: "none", color: "inherit" }}>
            <Typography style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}>{params.value}</Typography>
        </Link>
    )

    const columns: GridColDef[] = [
        { field: "id", headerName: "CWE ID", flex: 0.1, renderCell: renderIdCell },
        {
            field: "description",
            headerName: "Description",
            flex: 1,
            renderCell: ({ id }) => {
                const selectedCheck = rows.filter(row => row.id === id)[0]
                return [
                    // eslint-disable-next-line react/jsx-key
                    <Typography style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}>{selectedCheck.description}</Typography>
                ]
            }
        }
    ]

    const rows = formData.cwe?.map((opt, idx) => ({
        id: opt,
        description: CWES[opt] || "Insufficient Information"
    })) || []

    return (
        <Grid item xs container flexDirection="column" spacing={2} marginTop="20px">
            <DataGrid
                rows={rows}
                columns={columns}
                filterMode="client"
                sortingMode="client"
                paginationMode="client"
                disableRowSelectionOnClick
                getRowId={(row) => row.id}
                sx={{
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
                        outline: "none "
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": { outline: "none" }
                }}
            />
        </Grid>
    )
}

export default CWEDataGrid
