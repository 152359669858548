import { Client } from "@models/index"
import { MediaTypes } from "@services/index"
import Service from "@services/abstract/BaseService"

class ClientService extends Service<Client> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/client", MediaTypes.JSON)
    }
}

export { ClientService }
export default ClientService
