import React, { useContext, useState } from "react"
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Paper,
    Typography,
    useTheme
} from "@mui/material"
import { styled } from "@mui/material/styles"
import ExitToAppIcon from "@mui/icons-material/ExitToApp"
import { I18nContext } from "I18nProvider"
import { FaSave } from "react-icons/fa"

interface Props {
    open: boolean;
    text: string;
    title: string;
    handleCloseDialog: () => void;
    handleConfirmDialog: () => void;
}

const ConfirmationDialog: React.FC<Props> = ({
    open,
    text,
    title,
    handleCloseDialog,
    handleConfirmDialog
}) => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const [isOpen, setIsOpen] = useState(true)
    const StyledPaper = styled(Paper)`
  `
    return (
        <Dialog
            onClose={handleCloseDialog}
            open={open}
            disableEscapeKeyDown={true}
            PaperComponent={StyledPaper}
        >
            <DialogTitle>
                <Typography variant="h5">{title}</Typography>
            </DialogTitle>
            <Divider></Divider>
            <DialogContent>
                <Typography variant="h6">
                    {text}
                </Typography>
                <Typography variant="subtitle2">
                    {context.t.translate("CRA_text2")}
                </Typography>
            </DialogContent>
            <DialogActions>

                <Button
                    variant="contained"
                    startIcon={<ExitToAppIcon />}
                    color="error"
                    sx={{ ml: "10px", color: theme.palette.secondary.contrastText }}
                    onClick={handleCloseDialog}
                >
                    {context.t.translate("CRA_cancel")}
                </Button>
                <Button
                    variant="contained"
                    startIcon={<FaSave />}
                    color="primary"
                    sx={{ ml: "10px", color: theme.palette.secondary.contrastText }}
                    onClick={handleConfirmDialog}
                >
                    {context.t.translate("CRA_save")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmationDialog
