import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import { AuthProvider } from "react-oidc-context"
import oidcSettings from "@config/oidcSettings"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import { I18nProvider } from "I18nProvider"

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
)
root.render(
    // <React.StrictMode>
    <AuthProvider {...oidcSettings}>
        <I18nProvider>
            <App />
        </I18nProvider>
    </AuthProvider>
    // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
