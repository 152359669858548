
import { TicketingStatus } from "@models/Ticketing"

export const TicketingStatusColors: Record<TicketingStatus, string> = {
    accepted: "#FFA500", // aquamarine
    completed: "#A9A9A9", // jungle green
    pending: "#00FBFF", // blue iris
    rejected: "#D9534F", // lightpink
    requested: "#4db04a"
}
