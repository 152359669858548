import * as React from "react"
import Box from "@mui/material/Box"
import { DataGrid, type GridColDef } from "@mui/x-data-grid"

const columns: GridColDef[] = [
    // { field: 'id', headerName: 'ID', width: 90 },
    {
        field: "date",
        headerName: "Date",
        type: "date",
        width: 100
    },
    {
        field: "user",
        headerName: "user",
        width: 100
    },
    {
        field: "action",
        headerName: "action",
        width: 500
    }

]

const rows = [
    { id: 1, date: new Date("05/09/2021"), user: "PEN001", action: "Created" },
    { id: 3, date: new Date("05/09/2021"), user: "PEN001", action: "Severity '10.0' -> '8.0'" },
    { id: 4, date: new Date("05/09/2021"), user: "PEN001", action: "Status 'Review Pending' -> 'Planned'" }
]

const HistoryTable: React.FC = () => {
    return (
        <Box sx={{ height: 400, width: "100%" }}>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 5
                        }
                    }
                }}
                pageSizeOptions={[5]}
                checkboxSelection
                disableRowSelectionOnClick
            />
        </Box>
    )
}

export { HistoryTable }
export default HistoryTable
