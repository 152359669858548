import { MediaTypes } from "@services/HttpService"
import Service from "@services/abstract/BaseService"

class KeycloakService extends Service<any> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/keycloak/sessions", MediaTypes.JSON)
    }
}

export { KeycloakService }

export default KeycloakService
