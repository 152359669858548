import { Alert, Box, Button, Grid, MenuItem, TextField, Typography, useTheme } from "@mui/material"
import React, { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ClientSelector } from "@components/client/index"
import { ServicesContext } from "@context/index"
import { AssessmentType } from "@models/Assessment"
import { DatePicker } from "@mui/x-date-pickers"
import Ticketing, { TicketingStatus, TicketingStatusKeys, TicketingTypeKeys } from "@models/Ticketing"
import moment from "moment"
import Can from "@components/permissions/Can"
import { AssessmentTypeColors } from "@components/common/colors/AssessmentTypeColors"
import { TicketingStatusColors } from "@components/common/colors/TicketingTypeColors"
import { I18nContext } from "I18nProvider"

const initValue: Ticketing = {
    id: "",
    type: AssessmentType.SCA,
    status: TicketingStatus.Requested,
    target_date: null,
    license_validity: null,
    notes: "",
    client_id: ""
}

const TicketingForm: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const ticketingService = useContext(ServicesContext).ticketingService

    const [error, setError] = useState<Error|null>(null)
    const [data, setData] = useState<Ticketing>(initValue)
    // API Call form submission
    const [formData, setFormData] = useState<Ticketing>(initValue)
    const handleInputChange = (e: any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }
    // Api call form validation
    const navigate = useNavigate()
    const formValid = (): boolean => {
        const isNotNullrules = [
            formData.target_date === "Invalid date",
            formData.target_date === "",
            formData.target_date === undefined,
            formData.target_date === null,
            formData.license_validity === "Invalid date",
            formData.license_validity === "",
            formData.license_validity === undefined,
            formData.license_validity === null,
            formData.notes === ""
        ]
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        return true
    }
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        try {
            await ticketingService.create(formData)
            setError(null)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
        }
    }

    return (
        <Box sx={{ width: "100%" }}>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: "50%", alignSelf: "center", margin: "50px auto 50px auto" }}>
                {error && <Alert severity="error">{error.message}</Alert>}
                <Can key="read" I="read" an="Client">
                    <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                        <Grid item xs={6}>
                            <ClientSelector value={formData.client_id} onSelect={(id) => handleInputChange({ target: { name: "client_id", value: id } })}></ClientSelector>
                        </Grid>
                    </Grid>
                </Can>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField margin="normal" select required fullWidth variant="filled" label={context.t.translate("product_type")} name="type"
                            value={formData.type} onChange={handleInputChange}>
                            {TicketingTypeKeys.map((key, index) =>
                                (<MenuItem key={index} value={AssessmentType[key]}><Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: AssessmentTypeColors[AssessmentType[key]] }}>{key}</Typography></MenuItem>)
                            )}
                        </TextField>
                    </Grid>
                    <Can key="update" I="update" an="Ticketing">
                        <Grid item xs={12} sm={6}>
                            <TextField margin="normal" select required fullWidth variant="filled" label={context.t.translate("assess_status")} name="status"
                                value={formData.status} onChange={handleInputChange}>
                                {TicketingStatusKeys.map((key, index) =>
                                    (<MenuItem key={index} value={TicketingStatus[key]}><Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: TicketingStatusColors[TicketingStatus[key]] }}>{key}</Typography></MenuItem>)
                                )}
                            </TextField>
                        </Grid>
                    </Can>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            label={context.t.translate("setting_due")}
                            value={formData.target_date != null ? moment(formData.target_date, "YYYY-MM-DDTHH:mm:ssZ") : null}
                            onChange={m => handleInputChange({ target: { name: "target_date", value: m?.format("YYYY-MM-DDTHH:mm:ssZ") } })}
                            slots={{
                                textField: (params) => <TextField margin="normal"
                                    variant='filled' {...params} />
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            label={context.t.translate("setting_license")}
                            value={formData.license_validity != null ? moment(formData.license_validity, "YYYY-MM-DDTHH:mm:ssZ") : null}
                            onChange={m => handleInputChange({ target: { name: "license_validity", value: m?.isValid ? m?.format("YYYY-MM-DDTHH:mm:ssZ") : null } })}
                            slots={{
                                textField: (params) => <TextField margin="normal"
                                    variant='filled' {...params} />
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid>
                    <TextField margin="normal" required fullWidth variant="filled" label={context.t.translate("setting_notes")} name="notes"
                        value={formData.notes} onChange={handleInputChange}/>
                </Grid>
                <Button disabled={!formValid()} type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, color: theme.palette.secondary.contrastText }}>
                    {context.t.translate("setting_add_ticket")}
                </Button>
            </Box>
        </Box>
    )
}

export { TicketingForm }
export default TicketingForm
