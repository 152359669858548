import { MediaTypes } from "@services/index"
import Service from "@services/abstract/BaseService"
import SectorStats from "@models/SectorStats"

class SectorStatsService extends Service<SectorStats> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/sectorStats", MediaTypes.JSON)
    }
}

export { SectorStatsService }
export default SectorStatsService
