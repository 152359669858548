import React, { useEffect, useState } from "react"
import Chip from "@mui/material/Chip"
import { ClientSector } from "@models/Client"
import { ClientSectorKeysValueOps } from "./ClientTableDefinition"

interface ColorAndBg{
    color: string;
    "background-color": string;
}

type SectorColorMap = {
  [key in ClientSector]: ColorAndBg;
};

const getStateColor = (state: ClientSector): ColorAndBg => {
    const stateColorMap: SectorColorMap = {
        [ClientSector.Automotive]: { color: "black", "background-color": "#42A5F5" },
        [ClientSector.Elevator]: { color: "black", "background-color": "#4370a4" },
        [ClientSector.EnergyAndElectricNetworks]: { color: "black", "background-color": "#FF5722" },
        [ClientSector.HealthCare]: { color: "black", "background-color": "#ffffff" },
        [ClientSector.IOTDevice]: { color: "black", "background-color": "#E1BEE7" },
        [ClientSector.Industry]: { color: "black", "background-color": "#288d43" },
        [ClientSector.Transport]: { color: "black", "background-color": "#ec971f" }
    }
    return stateColorMap[state]
}

interface ClientSectorColoredProps {
  value: ClientSector;
}

const ClientSectorColored: React.FC<ClientSectorColoredProps> = ({ value }) => {
    const [color, setColor] = useState<ColorAndBg| null>(null)
    const [label, setLabel] = useState<string|undefined>("")

    useEffect(() => {
        if (value !== undefined) {
            setLabel(ClientSectorKeysValueOps.find(v => v.value === value)?.label)
            setColor(getStateColor(value))
        }
    }, [value])

    return (
        <>
            {value && (
                <Chip size="small" style={{
                    fontWeight: "bolder",
                    color: color?.color,
                    backgroundColor: color?.["background-color"]
                }} label={label}

                />
            )}
        </>
    )
}

export { ClientSectorColored }
export default ClientSectorColored
