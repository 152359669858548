import React, { useEffect, useState } from "react"
import Chip from "@mui/material/Chip"
import { TicketingStatus, TicketingStatusValueOps } from "@models/Ticketing"
import { TicketingStatusColors } from "@components/common/colors/TicketingTypeColors"

interface ColorAndBg{
    color: string;
    "background-color": string;
}

type StateColorMap = {
  [key in TicketingStatus]: ColorAndBg;
};

const getStateColor = (state: TicketingStatus): ColorAndBg => {
    const stateColorMap: StateColorMap = {
        [TicketingStatus.Requested]: { color: "black", "background-color": TicketingStatusColors.requested },
        [TicketingStatus.Pending]: { color: "black", "background-color": TicketingStatusColors.pending },
        [TicketingStatus.Accepted]: { color: "black", "background-color": TicketingStatusColors.accepted },
        [TicketingStatus.Rejected]: { color: "black", "background-color": TicketingStatusColors.rejected },
        [TicketingStatus.Completed]: { color: "black", "background-color": TicketingStatusColors.completed }
    }
    return stateColorMap[state]
}

interface TicketStatusColoredProps {
  value: TicketingStatus;
}

const TicketStatusColored: React.FC<TicketStatusColoredProps> = ({ value }) => {
    const [color, setColor] = useState<ColorAndBg| null>(null)
    const [label, setLabel] = useState<string|undefined>("")

    useEffect(() => {
        if (value !== undefined) {
            setLabel(TicketingStatusValueOps.find(v => v.value === value)?.label)
            setColor(getStateColor(value))
        }
    }, [value])

    return (
        <>
            {value && (
                <Chip size="small" style={{
                    fontWeight: "bolder",
                    color: color?.color,
                    backgroundColor: color?.["background-color"]
                }} label={label}

                />
            )}
        </>
    )
}
export { TicketStatusColored }
export default TicketStatusColored
