
const CVSS3Metric = {
    AV: {
        label: "Attack Vector (AV)",
        metric: "AV",
        values: {
            N: {
                label: "Network",
                metric: "N",
                value: 0.85
            },
            A: {
                label: "Adjacent Network",
                metric: "A",
                value: 0.62
            },
            L: {
                label: "Local ",
                metric: "L",
                value: 0.55
            },
            P: {
                label: "Physical",
                metric: "P",
                value: 0.2
            }
        }
    },
    AC: {
        label: "Attack Complexity (AC)",
        metric: "AC",
        values: {
            L: {
                label: "Low",
                metric: "L",
                value: 0.77
            },
            H: {
                label: "High",
                metric: "H",
                value: 0.44
            }
        }
    },
    PR: {
        label: "Privileges Required (PR)",
        metric: "PR",
        values: {
            N: {
                label: "None",
                metric: "N",
                value: 0.85
            },
            L: {
                label: "Low",
                metric: "L",
                value: {
                    U: 0.62,
                    C: 0.68
                }
            },
            H: {
                label: "High",
                metric: "H",
                value: {
                    U: 0.27,
                    C: 0.5
                }
            }
        }
    },
    UI: {
        label: "User Interaction (UI)",
        metric: "UI",
        values: {
            N: {
                label: "None",
                metric: "N",
                value: 0.85
            },
            R: {
                label: "Required",
                metric: "R",
                value: 0.62
            }
        }
    },
    S: {
        label: "Scope (S)",
        metric: "S",
        values: {
            U: {
                label: "Unchanged",
                metric: "U",
                value: 6.42
            },
            C: {
                label: "Changed",
                metric: "C",
                value: 7.52
            }
        }
    },
    C: {
        label: "Confidentiality",
        metric: "C",
        values: {
            N: {
                label: "None",
                metric: "N",
                value: 0
            },
            L: {
                label: "Low",
                metric: "L",
                value: 0.22
            },
            H: {
                label: "High",
                metric: "H",
                value: 0.56
            }
        }
    },
    I: {
        label: "Integrity (I)",
        metric: "I",
        values: {
            N: {
                label: "None",
                metric: "N",
                value: 0
            },
            L: {
                label: "Low",
                metric: "L",
                value: 0.22
            },
            H: {
                label: "High",
                metric: "H",
                value: 0.56
            }
        }
    },
    A: {
        label: "Availability (A)",
        metric: "A",
        values: {
            N: {
                label: "None",
                metric: "N",
                value: 0
            },
            L: {
                label: "Low",
                metric: "L",
                value: 0.22
            },
            H: {
                label: "High",
                metric: "H",
                value: 0.56
            }
        }
    }
}

export default CVSS3Metric
