import React, { useContext, useEffect, useState } from "react"
import { Alert, Box, Divider, Grid, Typography } from "@mui/material"
import { useParams } from "react-router-dom"
import { ColumnChart } from "@components/vulnerability/index"
import ServicesContext from "@context/ServicesContext"
import { StatsVulnerabilities } from "@models/index"
import VulnerabilityEvolutionChart from "@views/product/VersionChart/VulnerabilityChart"
// import Test from "@views/product/VersionChart.tsx/Test"

// Create the component
const VulnerabilitiesStats: React.FC = () => {
    const [error, setError] = useState<Error|null>(null)
    const statsService = useContext(ServicesContext).statsService
    const [stats, setStats] = useState<StatsVulnerabilities | undefined>(undefined)
    const { id } = useParams<{ id: string }>()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const stats = await statsService.getStatsVulnerabilities()
                setStats(stats)

                setError(null)
            } catch (e) {
                setError(e as Error)
            }
        }
        const fetchDataID = async (id: string) => {
            try {
                const stats = await statsService.getStatsVulnerabilitiesFilter(id)
                setStats(stats)

                setError(null)
            } catch (e) {
                setError(e as Error)
            }
        }

        if (id !== undefined) {
            fetchDataID(id)
        } else {
            fetchData()
        }
    }, [])

    return (
        <Grid item container>
            <Box sx={{ borderRadius: "10px", border: "1px solid #6D6C6F", width: "100%", padding: "25px" }}>
                {error && <Alert severity="error">{error.message}</Alert>}

                {stats !== undefined
                    ? <Grid container flexDirection="column" spacing="20px">
                        <Grid paddingTop="15px !important" item>
                            <Typography fontSize="34px" fontFamily="Griff" color="primary" fontWeight="bolder">Vulnerabilities</Typography>
                        </Grid>
                        {/* <Grid item container flexDirection="column">
                             <Grid item container spacing="60px">
                                <Grid item xs="auto" container flexDirection="column">
                                    <DonutChart3 values={stats.MitigatedVulnerabilities}></DonutChart3>
                                    <Grid item xs="auto" container flexDirection="column">
                                        <Grid item><Typography fontSize="17px" lineHeight="25px" fontWeight="100" sx={{ fontVariant: "all-small-caps" }}>Mitigated Vulnerabilities</Typography></Grid>
                                        <Grid item><Typography component="span" fontSize="55px" lineHeight="55px" fontFamily="Griff">{stats.MitigatedVulnerability}</Typography> <Typography component="span" color="grey" fontSize="55px" lineHeight="55px" fontFamily="Griff">({stats.MitigatedVulnerability === 0 ? 0 : (stats.MitigatedVulnerability / (stats.MitigatedVulnerability + stats.UnmitigatedVulnerability) * 100).toFixed(2)}%)</Typography></Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs="auto" container flexDirection="column">
                                    <DonutChart3 values={stats.UnmitigatedVulnerabilities}></DonutChart3>
                                    <Grid item xs="auto" container flexDirection="column">
                                        <Grid item><Typography fontSize="17px" lineHeight="25px" fontWeight="100" sx={{ fontVariant: "all-small-caps" }}>Unmitigated Vulnerabilities</Typography></Grid>
                                        <Grid item><Typography component="span" fontSize="55px" lineHeight="55px" fontFamily="Griff">{stats.UnmitigatedVulnerability}</Typography> <Typography component="span" color="grey" fontSize="55px" lineHeight="55px" fontFamily="Griff">({stats.UnmitigatedVulnerability === 0 ? 0 : (stats.UnmitigatedVulnerability / (stats.MitigatedVulnerability + stats.UnmitigatedVulnerability) * 100).toFixed(2)}%)</Typography></Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs="auto" container flexDirection="column">
                                    <DonutChart3 values={ExploitableAssetsList}></DonutChart3>
                                    <Grid item xs="auto" container flexDirection="column" alignItems="center">
                                        <Grid item><Typography fontSize="17px" lineHeight="25px" fontWeight="100" sx={{ fontVariant: "all-small-caps" }}>Exploitable Assets</Typography></Grid>
                                        <Grid item><Typography fontSize="55px" lineHeight="65px" fontFamily="Griff">{apiData.ExploitableAssets}</Typography></Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container flexDirection="column" spacing="5px">
                                <Grid item><Typography component="span" fontSize="16px">Criticity</Typography></Grid>
                                <Grid item container spacing="20px" flexDirection="row">
                                    <Grid item><Grid container alignItems="center" spacing="4px" flexDirection="row"><Grid item><span style={{ height: "15px", width: "15px", backgroundColor: "#7940A5", borderRadius: "50%", display: "inline-block" }}></span></Grid><Grid item><Typography component="span" fontSize="15px">Critical</Typography></Grid></Grid></Grid>
                                    <Grid item><Grid container alignItems="center" spacing="4px" flexDirection="row"><Grid item><span style={{ height: "15px", width: "15px", backgroundColor: "#D9534F", borderRadius: "50%", display: "inline-block" }}></span></Grid><Grid item><Typography component="span" fontSize="15px">High</Typography></Grid></Grid></Grid>
                                    <Grid item><Grid container alignItems="center" spacing="4px" flexDirection="row"><Grid item><span style={{ height: "15px", width: "15px", backgroundColor: "#EC971F", borderRadius: "50%", display: "inline-block" }}></span></Grid><Grid item><Typography component="span" fontSize="15px">Medium</Typography></Grid></Grid></Grid>
                                    <Grid item><Grid container alignItems="center" spacing="4px" flexDirection="row"><Grid item><span style={{ height: "15px", width: "15px", backgroundColor: "#F2CC0C", borderRadius: "50%", display: "inline-block" }}></span></Grid><Grid item><Typography component="span" fontSize="15px">Low</Typography></Grid></Grid></Grid>
                                    <Grid item><Grid container alignItems="center" spacing="4px" flexDirection="row"><Grid item><span style={{ height: "15px", width: "15px", backgroundColor: "#A9A9A9", borderRadius: "50%", display: "inline-block" }}></span></Grid><Grid item><Typography component="span" fontSize="15px">None</Typography></Grid></Grid></Grid>
                                </Grid>
                            </Grid>
                        </Grid> */}
                        {/* <Grid item>
                            <Divider sx={{ height: "1px", width: "100%", background: "#6D6C6F" }} />
                        </Grid> */}
                        <Grid item container justifyContent="center" flexDirection="column" paddingTop="15px !important" spacing="10px" sx={{ width: "100%", maxWidth: "2000px", margin: "0 auto" }}>
                            <Grid item><Typography fontSize="30px" fontWeight="bolder" fontFamily="Griff">Mitigated vs Unmitigated vulnerabilities</Typography></Grid>
                            {(stats.MitigatedVulnerability !== 0 || stats.UnmitigatedVulnerability !== 0) && (
                                <Grid item alignItems="center" sx={{ height: "570px", width: "100%" }}>
                                    <ColumnChart values={{
                                        critical: { mitigated: stats.MitigatedVulnerabilities.critical, unmitigated: stats.UnmitigatedVulnerabilities.critical },
                                        high: { mitigated: stats.MitigatedVulnerabilities.high, unmitigated: stats.UnmitigatedVulnerabilities.high },
                                        medium: { mitigated: stats.MitigatedVulnerabilities.medium, unmitigated: stats.UnmitigatedVulnerabilities.medium },
                                        low: { mitigated: stats.MitigatedVulnerabilities.low, unmitigated: stats.UnmitigatedVulnerabilities.low },
                                        none: { mitigated: stats.MitigatedVulnerabilities.none, unmitigated: stats.UnmitigatedVulnerabilities.none }
                                    }}></ColumnChart>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item sx={{ marginTop: "80px", marginBottom: "40px" }}>
                            <Divider sx={{ height: "1px", width: "100%", background: "#6D6C6F" }} />
                        </Grid>
                        <Grid item container flexDirection="column" paddingTop="15px !important" spacing={2}>
                            <Grid item>
                                <Typography fontSize="30px" fontWeight="bolder" fontFamily="Griff">
          Evolution of vulnerabilities
                                </Typography>
                            </Grid>
                            <Grid container item alignItems="center" alignContent="center">
                                {/* <VerticalTypography>Number of vulnerabilities</VerticalTypography> */}
                                <Grid item sx={{ flex: "1 1 auto", marginLeft: "15px" }}>
                                    <VulnerabilityEvolutionChart />
                                </Grid>
                            </Grid>
                            {/* <Grid item sx={{ width: "100%", textAlign: "center", color: "#E8E7ED", fontSize: "16.33px", fontFamily: "Roboto", fontWeight: "700", wordWrap: "break-word" }}>
        Versions
                            </Grid> */}
                        </Grid>
                        {/* <Grid item sx={{ marginTop: "80px", marginBottom: "40px" }}>
                            <Divider sx={{ height: "1px", width: "100%", background: "#6D6C6F" }} />
                        </Grid> */}

                        {/* <Grid item container flexDirection="column" paddingTop="15px !important" spacing="10px">
                            <BenchmarkStats/>
                        </Grid> */}
                    </Grid>
                    : <div></div>}
            </Box>
        </Grid>
    )
}

export { VulnerabilitiesStats }
export default VulnerabilitiesStats
