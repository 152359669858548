import React, { useEffect, useState } from "react"
import Assessment from "@models/Assessment"
import Template from "@models/Template"
import Vulnerability from "@models/Vulnerability"
import { Document, Font } from "@react-pdf/renderer"
import { FrontPage } from "./GenericVulnerability/sections/0_FrontPage"
import { Index } from "./GenericVulnerability/sections/0_Index"
import { Intro } from "./GenericVulnerability/sections/1_Intro"
import { ExecutiveSection } from "./GenericVulnerability/sections/3_ExecutiveSection"
import { VulnsSection, VulnsSection2 } from "./GenericVulnerability/sections/4_1_VulnsSection"
import { VulnsList, VulnsListDetail } from "./GenericVulnerability/sections/4_2_VulnsList"
import { BackPage } from "./sections"

Font.register({ family: "Griff", src: "./assets/fonts/Griff-Regular.otf" })
Font.register({ family: "Bold", src: "./assets/fonts/Griff-Bold.otf" })

const hyphenationCallback = (word: string) => {
    // Devuelve las sílabas de la palabra en un array
    // Este es solo un ejemplo simple y puede necesitar mejoras
    return word.split("-")
}

// Registra la función de separación de sílabas
Font.registerHyphenationCallback(hyphenationCallback)

interface VEXDocumentProps {
    assessment?: Assessment,
    template?: Template,
    data: Vulnerability[]
    simplified?: boolean
}

const VulnReportDocumentGeneric: React.FC<VEXDocumentProps> = ({ assessment, template, data, simplified }) => {
    const [orderedVulns, setOrderedVulns] = useState<Vulnerability[]>([])
    const [orderedLimitedVulns, setOrderedLimitedVulns] = useState<Vulnerability[]>([])

    useEffect(() => {
        const ordered = [...data].sort((a, b) => {
            let rank = 0
            const rankB = (a.score)
            const rankA = (a.score)
            if (rankA != null && rankB != null) {
                rank = rankA - rankB
            }
            if (rank === 0) {
                rank = (b.score || 0) - (a.score || 0)
            }
            return rank
        })
        setOrderedVulns(ordered)
        setOrderedLimitedVulns(ordered.slice(0, 10))
    }, [data])

    return (
        <>
            {template === undefined
                ? (
                    <Document pageLayout="twoColumnRight">
                        <FrontPage></FrontPage>
                        <Index></Index>
                        <Intro></Intro>
                        <ExecutiveSection index="3" vulnerabilities={data}></ExecutiveSection>
                        <VulnsSection></VulnsSection>
                        <VulnsSection2></VulnsSection2>
                        <VulnsListDetail vulnerabilities={orderedLimitedVulns}></VulnsListDetail>
                        <VulnsList vulnerabilities={orderedVulns}></VulnsList>
                        <BackPage></BackPage>
                    </Document>
                )
                : (
                    <Document pageLayout="twoColumnRight">
                        <FrontPage></FrontPage>
                        <Index></Index>
                        <Intro></Intro>
                    </Document>
                )}
        </>
    )
}

export { VulnReportDocumentGeneric }
export default VulnReportDocumentGeneric
