import React, { useEffect, useState } from "react"
import { Document, Font } from "@react-pdf/renderer"
import { CustomBackPage } from "@components/customReport/sections/99_BackPage"
import { CustomFrontPage } from "@components/customReport/sections/0_CustomFrontPage"
import { CustomIndex } from "@components/customReport/sections/0_CustomIndex"
import { CustomIntro } from "@components/customReport/sections/1_CustomIntro"
import { CustomScopeSection } from "@components/customReport/sections/2_ScopeSection"
import { CustomExecutiveSection } from "@components/customReport/sections/3_ExecutiveSection"
import { CustomVulnsSection, CustomVulnsSection2 } from "@components/customReport/sections/4_1_VulnsSection"
import { CustomVulnsList, CustomVulnsListDetail } from "@components/customReport/sections/4_2_VulnsList"
import Assessment from "@models/Assessment"
import Template from "@models/Template"
import VulnerabilityReportCycloneDX, { VulnerabilityCycloneDX } from "@models/VulnerabilityReport"
import { BackPage } from "./sections/99_BackPage"
import { ScopeSection } from "./sections/2_ScopeSection"
import { ExecutiveSection } from "./sections/3_ExecutiveSection"
import { Intro } from "./sections/1_Intro"
import { FrontPage } from "./sections/0_FrontPage"
import { Index } from "./sections/0_Index"
import { VulnsSection, VulnsSection2 } from "./sections/4_1_VulnsSection"
import { VulnsList, VulnsListDetail } from "./sections"
import { ServicesImg, ServicesSection } from "./sections/5_ServicesSection"
import { SimplifiedIndex } from "./sections/0_Simplified_index"
import { SimplifiedIntro } from "./sections/1_Simplified_intro"
import { SimplifiedFrontPage } from "./sections/0_Simplified_FrontPage"

Font.register({ family: "Griff", src: "./assets/fonts/Griff-Regular.otf" })
Font.register({ family: "Bold", src: "./assets/fonts/Griff-Bold.otf" })

const hyphenationCallback = (word: string) => {
    // Devuelve las sílabas de la palabra en un array
    // Este es solo un ejemplo simple y puede necesitar mejoras
    return word.split("-")
}

// Registra la función de separación de sílabas
Font.registerHyphenationCallback(hyphenationCallback)

interface VEXDocumentProps {
    assessment: Assessment,
    template?: Template,
    data: VulnerabilityReportCycloneDX
    simplified: boolean
}

const VulnReportDocument: React.FC<VEXDocumentProps> = ({ assessment, template, data, simplified }) => {
    const [orderedVulns, setOrderedVulns] = useState<VulnerabilityCycloneDX[]>([])
    const [orderedLimitedVulns, setOrderedLimitedVulns] = useState<VulnerabilityCycloneDX[]>([])

    useEffect(() => {
        const ordered = [...data.vulnerabilities].sort((a, b) => {
            let rank = 0
            const rankB = (b.ratings.find(r => r.source?.name === "ORBIK")?.score)
            const rankA = (a.ratings.find(r => r.source?.name === "ORBIK")?.score)
            if (rankA != null && rankB != null) {
                rank = rankA - rankB
            }
            if (rank === 0) {
                rank = (b.ratings.find(r => r.method === "CVSSv3")?.score || 0) - (a.ratings.find(r => r.method === "CVSSv3")?.score || 0)
            }
            return rank
        }
        )
        setOrderedVulns(ordered)
        setOrderedLimitedVulns(ordered.slice(0, 10))
    }, [data])

    return (
        <>
            {simplified
                ? (
                    <Document pageLayout="twoColumnRight">
                        <SimplifiedFrontPage assessment={assessment}></SimplifiedFrontPage>
                        <SimplifiedIndex></SimplifiedIndex>
                        <SimplifiedIntro></SimplifiedIntro>
                        <ExecutiveSection index="2" vulnerabilities={data.vulnerabilities}></ExecutiveSection>
                        <ServicesSection></ServicesSection>
                        <ServicesImg></ServicesImg>
                        <VulnsSection></VulnsSection>
                        <VulnsSection2></VulnsSection2>
                        <VulnsList vulnerabilities={orderedVulns} components={data.components}></VulnsList>
                        <BackPage></BackPage>
                    </Document>
                )
                : template !== undefined
                    ? (
                        <Document pageLayout="twoColumnRight">
                            <CustomFrontPage assessment={assessment} template={template}></CustomFrontPage>
                            <CustomIndex template={template!}></CustomIndex>
                            <CustomIntro template={template!}></CustomIntro>
                            <CustomScopeSection template={template!} metadata={data.metadata}></CustomScopeSection>
                            <CustomExecutiveSection index="3" vulnerabilities={data.vulnerabilities} template={template!}></CustomExecutiveSection>
                            <CustomVulnsSection template={template!}></CustomVulnsSection>
                            <CustomVulnsSection2 template={template!}></CustomVulnsSection2>
                            <CustomVulnsListDetail vulnerabilities={orderedLimitedVulns} components={data.components} template={template!}></CustomVulnsListDetail>
                            <CustomVulnsList template={template!} vulnerabilities={orderedVulns} components={data.components}></CustomVulnsList>
                            <CustomBackPage template={template!}></CustomBackPage>
                        </Document>
                    )
                    : (
                        <Document pageLayout="twoColumnRight">
                            <FrontPage assessment={assessment}></FrontPage>
                            <Index></Index>
                            <Intro></Intro>
                            <ScopeSection metadata={data.metadata}></ScopeSection>
                            <ExecutiveSection index="3" vulnerabilities={data.vulnerabilities}></ExecutiveSection>
                            <VulnsSection></VulnsSection>
                            <VulnsSection2></VulnsSection2>
                            <VulnsListDetail vulnerabilities={orderedLimitedVulns} components={data.components}></VulnsListDetail>
                            <VulnsList vulnerabilities={orderedVulns} components={data.components}></VulnsList>
                            <BackPage></BackPage>
                        </Document>
                    )}
        </>
    )
}

export { VulnReportDocument }
export default VulnReportDocument
