import * as React from "react"
import CircularProgress, {
    CircularProgressProps
} from "@mui/material/CircularProgress"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { Grid } from "@mui/material"

function CircularProgressWithLabel (
    props: CircularProgressProps & { value: number }
) {
    return (
        <Box sx={{ position: "relative", display: "inline-flex", height: "100%", width: "100%" }}>
            <CircularProgress variant="determinate" {...props } style={{
                position: "absolute" as "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                minWidth: "65px",
                minHeight: "65px",
                boxShadow: "inset 0 0 0 5.5px #d1d1d1",
                color: "grey",
                borderRadius: "50%"
            }}/>
            <CircularProgress variant="determinate" {...props } style={{
                position: "absolute" as "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                minWidth: "65px",
                minHeight: "65px"
            }}/>
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                    fontWeight= "bolder"
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    )
}
interface CircularWithValueLabelProps {
    progress: number
  }

const CircularWithValueLabel: React.FC<CircularWithValueLabelProps> = ({ progress }) => {
    return <Grid container item xs={12} style={{ minWidth: "65px", minHeight: "65px", justifyContent: "center", alignContent: "center" }}>
        <CircularProgressWithLabel value={progress * 10} />
    </Grid>
}

export { CircularWithValueLabel }
export default CircularWithValueLabel
