import React, { SetStateAction, useContext, useEffect } from "react"
import { Box, Divider, Grid, MenuItem, Select, Typography } from "@mui/material"
import BenchmarkProductChart from "@components/benchmark/BenchmarkProductChart"
import ServicesContext from "@context/ServicesContext"
import { BenchmarkProduct } from "@models/Benchmark"
import { QueryParameters } from "@utils/queryParams"
import { useTrack } from "@components/track/TrackContext"
import SectorHistoricGraph from "@components/benchmark/sectorHistoricGraph"
import { I18nContext } from "I18nProvider"

const BenchmarkCharts:React.FC<any> = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const benchmarkService = React.useContext(ServicesContext).benchmarkService
    const productService = React.useContext(ServicesContext).productService

    const [error, setError] = React.useState<Error|null>(null)
    const [selectedProduct1, setSelectedProduct1] = React.useState("")
    const [selectedProduct2, setSelectedProduct2] = React.useState("")

    const [productNames, setProductNames] = React.useState<{ name: string; version: string }[]>([])
    const [vulnerabilitiesByProduct, setVulnerabilitiesByProduct] = React.useState<{ [productName: string]: number[] }>({})

    const [productData, setProductData] = React.useState<{id: string, name: string}[]>([])

    // Tracking
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "BenchmarkCharts" })
    }, [])

    const handleProduct1Change = (event: { target: { value: SetStateAction<string> } }) => {
        setSelectedProduct1(event.target.value)
    }
    interface EmptyProducts {[key: string]: number[]}
    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const filters: QueryParameters = { sortField: "name" }
                const productMainData = await productService.getAll(filters)
                const productBase = productMainData.list.map(item => ({ id: item.id, name: item.name }))
                setProductData(productBase)
                const productNameData = productMainData.list.map(item => ({ name: item.name, version: item.version }))
                setProductNames(productNameData)
                const foundProduct = productData.find(product => product.name === selectedProduct1)
                const selectedProducts = [selectedProduct1, selectedProduct2]
                const foundIds: string[] = []

                selectedProducts.forEach(selectedProduct => {
                    const foundProduct = productData.find(product => product.name === selectedProduct)
                    if (foundProduct) {
                        foundIds.push(foundProduct.id)
                    }
                })

                if (foundIds.length > 1) {
                    const data1: BenchmarkProduct[] = await benchmarkService.getBenchmarkProduct(foundIds[0])
                    const data2: BenchmarkProduct[] = await benchmarkService.getBenchmarkProduct(foundIds[1])

                    const combinedData = [...data1, ...data2]
                    const vulnerabilitiesByProductData: { [productName: string]: number[] } = {}
                    combinedData.forEach(item => {
                        const productName = item.product_name
                        const vulnerabilities = [item.critical_vulnerability, item.high_vulnerability, item.medium_vulnerability, item.low_vulnerability].filter(vuln => vuln !== undefined) as number[]
                        vulnerabilitiesByProductData[productName] = vulnerabilities
                    })
                    setVulnerabilitiesByProduct(vulnerabilitiesByProductData)
                }

                setError(null)
            } catch (e) {
                setError(e as Error)
            }
        }

        fetchData()
    }, [selectedProduct1, selectedProduct2])
    const handleProduct2Change = (event: { target: { value: SetStateAction<string> } }) => {
        setSelectedProduct2(event.target.value)
    }
    return (
        <Grid pl="20px">
            <Grid container spacing={2} style={{ marginTop: "20px" }}>
                <Grid item container xs={12}>
                    <Grid>
                        {(selectedProduct1 === "" || selectedProduct2 === "") &&
                            <Typography variant="h6" align="left">
                                {context.t.translate("bench_text")}
                            </Typography>
                        }
                    </Grid>
                    <Box sx={{ borderRadius: "10px", border: "1px solid #6D6C6F", width: "100%", padding: "25px" }}>
                        <Grid item container justifyContent="space-around" rowSpacing={2}>
                            <Grid item xs={12} md={4}>
                                <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("bench_select")}</Typography>
                                <Select name="select" size="small" value={selectedProduct1} onChange={handleProduct1Change} sx={{ width: "100%" }} >
                                    {productNames.filter(product => product.name !== selectedProduct2).map((product, index) => (
                                        <MenuItem key={index} value={product.name}>{`${product.name} - ${product.version}`}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("bench_select")}</Typography>
                                <Select name="select2" size="small" value={selectedProduct2} onChange={handleProduct2Change} sx={{ width: "100%" }} >
                                    {productNames.filter(product => product.name !== selectedProduct1).map((product, index) => (
                                        <MenuItem key={index} value={product.name}>{`${product.name} - ${product.version}`}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <figure className="highcharts-figure">
                                {selectedProduct1 !== "" && selectedProduct2 !== "" &&
                        <BenchmarkProductChart firstProduct={selectedProduct1} secondProduct={selectedProduct2}
                            firstItem={vulnerabilitiesByProduct[selectedProduct1]} secondItem={vulnerabilitiesByProduct[selectedProduct2]}/>
                                }
                            </figure>
                        </Grid>
                    </Box>
                </Grid>

                <Divider></Divider>

                <Grid item container xs={12}>
                    <Box sx={{ borderRadius: "10px", border: "1px solid #6D6C6F", width: "100%", padding: "25px" }}>
                        <SectorHistoricGraph></SectorHistoricGraph>
                    </Box>
                </Grid>

            </Grid>
        </Grid>
    )
}

export { BenchmarkCharts }
export default BenchmarkCharts
