import React from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import { MetadataCycloneDX } from "@models/VulnerabilityReport"
import styles from "../Styles"
import VexDocumentStyles from "../VexStyleSheet"
import { Footer, Header } from "./HeaderFooter"

export const scope = [
    ["Identification, Documentation and Management of Software Components", "SBOM analysis involves identifying all software components used in an application or system, including libraries, frameworks, modules, plugins, and any other elements that contribute to the operation of the software."],
    ["Vulnerability scan", "Once a complete SBOM has been generated, a vulnerability scan can be performed to identify potential security gaps in software components."],
    ["Vulnerability Prioritization", "This analysis involves evaluating the severity of each identified vulnerability, followed by the generation of a list ordered by its level of criticality."]
]

const ScopeSection: React.FC<{metadata: MetadataCycloneDX}> = ({ metadata }) => (
    <Page size="A4">

        <Header></Header>
        <View style={{ ...VexDocumentStyles.section, flexDirection: "row" }}>
            <View style={styles.column}>
                <Text style={{ ...styles.titulo, fontFamily: "Bold" }}>2. Scope</Text>
                <Text style={{ ...styles.textua, marginRight: 30, fontFamily: "Griff", fontSize: 10 }}>Carrying out a vulnerability scan generally involves defining a series of aspects:</Text>
                {scope.map((point, index) => (
                    <Text key={index} style={{ ...styles.puntuak, marginRight: 50, fontFamily: "Griff", fontSize: 10, paddingTop: 5 }}>
                        <Text style={styles.puntuak}>• &nbsp;</Text>
                        <Text style={{ ...styles.puntuak, fontFamily: "Bold" }}>{point[0]}:</Text><Text style={{ ...styles.puntuak }}> {point[1]}</Text>
                    </Text>
                ))}
                <Text style={{ ...styles.textua, marginRight: 30, fontFamily: "Griff", fontSize: 10 }}>The assets within the scope are listed in the following table:</Text>
                <Text>&nbsp;</Text>
                <View style={{ ...styles.tableScope }}>
                    <View style={{ ...styles.tableRowScope, backgroundColor: "yellow", fontFamily: "Bold" }}>
                        <Text style={{ ...styles.tableColScope, borderBottomWidth: 0, borderRightWidth: 0 }}>Asset name</Text>
                        <Text style={{ ...styles.tableColScope, borderBottomWidth: 0, borderRightWidth: 1 }}>Version</Text>
                    </View>
                    <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                        <Text style={{ ...styles.tableColScope, borderBottomWidth: 1, borderRightWidth: 0 }}>{metadata.component.name}</Text>
                        <Text style={{ ...styles.tableColScope, borderBottomWidth: 1, borderRightWidth: 1 }}>{metadata.component.version}</Text>
                    </View>

                </View>
                <Text style={{ ...styles.textua, marginRight: 50, fontFamily: "Griff", fontSize: 8, marginTop: 3, color: "gray" }} >Table 1. Scope</Text>
            </View>
            <View style={styles.column}>
                <Image style={styles.rightImageFull} src="./assets/img/report/octogono.jpg" />
            </View>
        </View>
        <Footer></Footer>
    </Page>
)

export { ScopeSection }
