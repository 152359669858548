import { ModelDefinition, ModelRelation } from "@models/utils"

type Template = {
 id: string;
 name: string;
 color: string;
 logo: string | ArrayBuffer | null;
 client: ModelRelation;
}

const TemplateDefinition: ModelDefinition<Template> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 0.1, isId: true },
        { name: "name", label: "Name", type: "string", flex: 0.2 },
        { name: "color", label: "Color", type: "string", flex: 0.15 },
        { name: "client.name", label: "Client", type: "struct", flex: 0.15, valueGetter: product => product.client.name, permission: { subject: "Multitenant", action: "read" } }
    ]
}

export { TemplateDefinition }
export type { Template }
export default Template
