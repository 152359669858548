import React from "react"
import { Grid } from "@mui/material"
import Products from "@views/home/Products"

const Home: React.FC = () => {
    return (
        <Grid item xs container flexDirection="column" spacing="20px">
            <Grid item container flexDirection="column" paddingTop="0 !important" rowGap="35px">
                <Products></Products>
            </Grid >
        </Grid >
    )
}

export { Home }
export default Home
