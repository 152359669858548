import React from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import Assessment from "@models/Assessment"
import styles from "../../Styles"
import VexDocumentStyles from "../../VexStyleSheet"
import { Footer, Header } from "./HeaderFooter"

export const introduccion = [
    { id: "Alcance: ", description: "Se describe el alcance inicial desde el cual se parte a la hora de realizar este ejercicio." },
    { id: "Resumen ejecutivo: ", description: "Se muestra un resumen generalde los resultados obtenidos durante el test Network Stress." },
    { id: "Descubrimiento e identificación del sistema: ", description: "Se describen los protocolos objetivos del test." },
    { id: "Identificación de anomalías: ", description: "Se analizan los resultados del test de Network Stress obtenidos y se identifican las anomalías ocurridas durante su ejecución." }
]

const Intro: React.FC<{assessment: Assessment}> = ({ assessment }) => (
    <Page size="A4">
        <Header></Header>
        <View style={{ ...VexDocumentStyles.section, flexDirection: "row" }}>
            <View style={styles.column}>
                <View>
                    <Text style={styles.titulo}>1. Introducción</Text>
                    <Text style={{ ...styles.textua, marginRight: 50, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }}>En este documento se describen los resultados del test que se ha realizado sobre la plataforma {assessment.product.name}. El test de Fuzzing tiene como objetivo identificar posibles anomalías en su funcionamiento y su capacidad para sobrellevar dichas pruebas.</Text>
                    <Text style={{ ...styles.textua, marginRight: 50, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }}>El informe está organizado de esta forma:</Text>
                    {introduccion.map((point, index) => (
                        <Text key={index} style={{ ...styles.puntuak, marginRight: 50, fontFamily: "Griff", fontSize: 10, paddingTop: 5 }}>
                            <Text style={styles.puntuak}>• &nbsp;</Text>
                            <Text style={{ ...styles.puntuak, fontFamily: "Bold" }}>{point.id}</Text>
                            <Text style={styles.puntuak}>{point.description}</Text>
                        </Text>
                    ))}
                    <Text style={{ ...styles.textua, marginRight: 50, marginTop: 5, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }}>Las pruebas se ejecutan con Achilles, una plataforma de pruebas para los fabricantes de infraestructuras críticas que necesitan garantizar que sus productos no son vulnerables a ciberataques. La plataforma de pruebas permite revisar la robustez de las comunicaciones mientras supervisa tanto la red como los parámetros operativos, lo que permite descubrir vulnerabilidades, reproducir fallos, aislarlos, identificarlos y resolverlos antes de introducir los productos en el mercado.</Text>
                </View>
            </View>
            <View style={styles.column}>
                <Image style={{ ...styles.rightImage, marginLeft: 30, paddingTop: 0, marginTop: -40, height: 305 }} src="./assets/img/report/computer.jpg" />
                <View style={{ ...styles.rightImage, marginTop: 265, height: 270, marginLeft: 30, marginRight: -20, paddingTop: 100, marginBottom: 50, backgroundColor: "#fffa37", flexDirection: "row" }}>
                    <Text style={{ marginLeft: 55, marginRight: 50, paddingTop: -80, marginBottom: 200, paddingRight: 30, textAlign: "center", fontFamily: "Bold", fontSize: 18 }}>El Diagnóstico de robustez de comunicaciones en {assessment.product.name} tiene como objetivo identificar anomalías en el rendimiento del dispositivo</Text>
                </View>
            </View>
        </View>
        <Footer></Footer>
    </Page>
)

export { Intro }
