import React, { useCallback, useContext, useEffect, useState } from "react"
import { Button, Grid, IconButton, List, ListItem, ListItemIcon, Paper, Typography, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"
import DragIndicatorIcon from "@mui/icons-material/DragIndicator"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import { DndProvider, useDrag, useDrop } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { ClientSelector } from "@components/client/index"
import { HierarchySelector } from "@components/hierarchy/HierarchySelector"
import { Can } from "@components/permissions/index"
import ConfirmationDialog from "@components/dialogs/ConfirmationDialog"
import { ServicesContext } from "@context/index"
import { ModelRelation } from "@models/utils"
import { FilterOperation, FilterOption } from "@utils/queryParams"
import { QueryParameters } from "@utils/index"
import Hierarchy from "@models/Hierarchy"
import { I18nContext } from "I18nProvider"
import { convertToTree, convertTreeToHierarchy, MAX_DEEP_LEVEL, PATH_SEPARATOR, TreeNode } from "./hierarchyUtils"

interface DragItem {
  id: string;
  type: string;
}

const HierarchyItem: React.FC<{ node: TreeNode; moveNode: (dragId: string, hoverId: string) => void ; onDelete: (id: string) => void ; onEdit: (id: string) => void }> = ({ node, moveNode, onDelete, onEdit }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: "TREE_NODE",
        item: { id: node.id },
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    }))

    const [, drop] = useDrop(() => ({
        accept: "TREE_NODE",
        hover: (item: DragItem) => {
            if (item.id !== node.id) {
                moveNode(item.id, node.id)
            }
        }
    }))

    const handleEdit = (node: TreeNode) => {
        onEdit(node.id)
    }

    const handleDelete = (node: TreeNode) => {
        onDelete(node.id)
    }

    return (
        <Paper ref={node.children ? drop : drag} sx={{ opacity: isDragging ? 0.5 : 1, marginBottom: 1 }}>
            <ListItem ref={drag} sx={{ cursor: "move" }}>
                <ListItemIcon>
                    <DragIndicatorIcon />
                </ListItemIcon>
                <div style={{ flexGrow: 1 }}>
                    <Typography variant="body1">{node.name}</Typography>
                    <Typography variant="body2" color="textSecondary">{node.description}</Typography>
                </div>
                <IconButton edge="end" aria-label="edit" onClick={() => handleEdit(node)}>
                    <EditIcon />
                </IconButton>
                <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(node)}>
                    <DeleteIcon />
                </IconButton>
            </ListItem>
            {node.children && (
                <List sx={{ paddingLeft: 4 }}>
                    {node.children.map(child => (
                        <HierarchyItem key={child.id} node={child} moveNode={moveNode} onDelete={onDelete} onEdit={onEdit} />
                    ))}
                </List>
            )}
        </Paper>
    )
}

const HierarchyTree: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const navigate = useNavigate()
    const hierarchyService = useContext(ServicesContext).hierarchyService

    const [data, setData] = useState<TreeNode[]>([])
    const [isEditing, setIsEditing] = useState(false)
    const [originalData, setOriginalData] = useState<Hierarchy[]>([])
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
    const [childrenToDelete, setChildrenToDelete] = useState<number>(0)
    const [deleteItemId, setDeleteItemId] = useState<string>()
    const [selectedClient, setSelectedClient] = useState<ModelRelation>({ id: "" })
    const [selectedHierarchies, setSelectedHierarchies] = useState<(Hierarchy | null)[]>(Array.from({ length: MAX_DEEP_LEVEL - 1 }, () => null))

    const init = async () => {
        const { filters, orFilters } = getFilterOptions()
        const params: QueryParameters = {}

        if (filters.length > 0) {
            params.filters = filters
        }
        if (orFilters.length > 0) {
            params.orFilters = orFilters
        }

        if (filters.length > 0 || orFilters.length > 0) {
            const data = await hierarchyService.getAll(params)
            const convertedData = convertToTree(data.list, "name", "asc")
            setOriginalData(data.list)
            setData(convertedData)
        }
    }

    const getFilterOptions = (): { filters: FilterOption[], orFilters: FilterOption[] } => {
        const filters : FilterOption[] = []
        const orFilters : FilterOption[] = []
        if (selectedClient.id !== "") {
            filters.push({ field: "client_id", operation: FilterOperation.UUIDEqual, value: selectedClient.id })
            if (selectedHierarchies[0]) {
                orFilters.push({ field: "path", operation: FilterOperation.StringEqual, value: selectedHierarchies[0].path })
            }
            if (selectedHierarchies[1]) {
                orFilters.push({ field: "path", operation: FilterOperation.StringEqual, value: selectedHierarchies[1].path })
            }
            if (selectedHierarchies[2]) {
                orFilters.push({ field: "path", operation: FilterOperation.StringEqual, value: selectedHierarchies[2].path })
            }
            if (selectedHierarchies[0] && !selectedHierarchies[1] && !selectedHierarchies[2]) {
                orFilters.push({ field: "path", operation: FilterOperation.StringContains, value: selectedHierarchies[0].path })
            } else if (selectedHierarchies[0] && selectedHierarchies[1] && !selectedHierarchies[2]) {
                orFilters.push({ field: "path", operation: FilterOperation.StringContains, value: selectedHierarchies[1].path })
            } else if (selectedHierarchies[2]) {
                orFilters.push({ field: "path", operation: FilterOperation.StringContains, value: selectedHierarchies[2].path })
            }
        }
        return { filters, orFilters }
    }

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        init()
    }, [selectedClient, selectedHierarchies])

    const findNode = useCallback((id: string, nodes: TreeNode[]): [TreeNode | undefined, TreeNode[]] => {
        for (let i = 0; i < nodes.length; i++) {
            if (nodes[i].id === id) return [nodes[i], nodes]
            if (nodes[i].children) {
                const [found, children] = findNode(id, nodes[i].children!)
                if (found) return [found, children]
            }
        }
        return [undefined, []]
    }, [])

    const moveNode = useCallback((dragId: string, hoverId: string) => {
        setData(prevData => {
            const clonedData = JSON.parse(JSON.stringify(prevData)) as TreeNode[]
            const [dragNode, dragParentChildren] = findNode(dragId, clonedData)
            const [hoverNode, hoverParentChildren] = findNode(hoverId, clonedData)
            if (dragNode && hoverNode) {
                dragParentChildren.splice(dragParentChildren.indexOf(dragNode), 1)
                hoverNode.children = hoverNode.children || []
                hoverNode.children.push(dragNode)
            }
            return clonedData
        })
        setIsEditing(true)
    }, [findNode])

    const handleSave = async () => {
        try {
            // Compare data and movedNodes to find nodes with changed paths
            const nodesToUpdate: Hierarchy[] = []
            const dataHierarchy = convertTreeToHierarchy(data)
            dataHierarchy.forEach(movedNode => {
                const originalNode = originalData.find(node => node.id === movedNode.id)
                if (originalNode && originalNode.path !== movedNode.path) {
                    nodesToUpdate.push(movedNode)
                }
            })

            // Update nodes with changed paths
            for (const node of nodesToUpdate) {
                await hierarchyService.update(node.id, node)
            }

            setIsEditing(false)
            init()
        } catch (error) {
            console.error("Failed to save hierarchy", error)
        }
    }

    const countDescendants = (node: TreeNode | undefined): number => {
        if (!node || !node.children) {
            return 0
        }
        let count = node.children.length
        for (const child of node.children) {
            count += countDescendants(child)
        }
        return count
    }

    const handleDelete = (id: string) => {
        const [selectedNode] = findNode(id, data)
        if (selectedNode) {
            setDeleteItemId(id)
            setChildrenToDelete(countDescendants(selectedNode))
            setOpenDeleteDialog(true)
        }
    }

    const handleConfirmDelete = async () => {
        if (deleteItemId) {
            try {
                await hierarchyService.delete(deleteItemId)
                setOpenDeleteDialog(false)
                setDeleteItemId(undefined)
                init()
            } catch (error) {
                console.error("Failed to delete hierarchy", error)
            }
        }
    }

    const handleEdit = (id: string) => {
        navigate(`../edit/${id}`)
    }

    const handleSelectorChange = (index: number, id: string | undefined, hierarchy: Hierarchy | null) => {
        const newSelectedHierarchies = [...selectedHierarchies]
        newSelectedHierarchies[index] = hierarchy
        newSelectedHierarchies.fill(null, index + 1, MAX_DEEP_LEVEL - 1)
        setSelectedHierarchies(newSelectedHierarchies)
    }

    const getCustomFilters = (index: number): FilterOption[] => {
        if (index === 0) {
            if (selectedClient) {
                return [
                    { field: "client_id", operation: FilterOperation.UUIDEqual, value: selectedClient.id },
                    { field: "path", operation: FilterOperation.StringNotContains, value: `%${PATH_SEPARATOR}%` }
                    // { field: "path", operation: FilterOperation.StringEqual, value: "" }
                ]
            }
            return []
        }
        const parentHierarchy = selectedHierarchies[index - 1]
        if (!parentHierarchy) return []
        return [
            // { field: "path", operation: FilterOperation.StringEqual, value: parentHierarchy.path }
            { field: "path", operation: FilterOperation.StringContains, value: `${parentHierarchy.path}${PATH_SEPARATOR}%` },
            { field: "path", operation: FilterOperation.StringNotContains, value: `${parentHierarchy.path}${PATH_SEPARATOR}%${PATH_SEPARATOR}%` }
        ]
    }

    const handleClientChange = (e: any) => {
        setSelectedHierarchies(Array.from({ length: MAX_DEEP_LEVEL - 1 }, () => null))
        setSelectedClient(e.target.value)
    }

    return (
        <>
            <Grid item xs container flexDirection="column" spacing={2} sx={{ position: "relative" }}>
                <Grid container item alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h6">{context.t.translate("hierar_manage")}</Typography>
                    </Grid>
                    <Grid item>
                        { !isEditing &&
                        <Grid container spacing={1}>
                            <Grid item>
                                <Can I="create" a="Hierarchy">
                                    <Button variant="contained" sx={{ color: theme.palette.secondary.contrastText }} onClick={() => navigate("../add")}>{context.t.translate("hierar_node")}</Button>
                                </Can>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" sx={{ color: theme.palette.secondary.contrastText }} onClick={() => navigate("/hierarchy")}>{context.t.translate("hierar_back")}</Button>
                            </Grid>
                        </Grid>}
                        { isEditing &&
                        <Grid container spacing={1}>
                            <Grid item>
                                <Button variant="contained" sx={{ color: theme.palette.secondary.contrastText }} onClick={handleSave}>{context.t.translate("CRA_save")}</Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" sx={{ color: theme.palette.secondary.contrastText }} onClick={() => window.location.reload()}>{context.t.translate("hierar_reset")}</Button>
                            </Grid>
                        </Grid>}
                    </Grid>
                </Grid>

                <Grid item container>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <ClientSelector value={selectedClient.id} onSelect={(id) => handleClientChange({ target: { name: "client", value: { id } } })}></ClientSelector>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <HierarchySelector
                            value={selectedHierarchies[0]?.id || ""}
                            customFilters={getCustomFilters(0)}
                            onSelect={(id, hierarchy) => handleSelectorChange(0, id, hierarchy)}
                            index={0}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        {selectedHierarchies[0] && (
                            <HierarchySelector
                                value={selectedHierarchies[1]?.id || ""}
                                customFilters={getCustomFilters(1)}
                                onSelect={(id, hierarchy) => handleSelectorChange(1, id, hierarchy)}
                                index={1}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        {selectedHierarchies[1] && (
                            <HierarchySelector
                                value={selectedHierarchies[2]?.id || ""}
                                customFilters={getCustomFilters(2)}
                                onSelect={(id, hierarchy) => handleSelectorChange(2, id, hierarchy)}
                                index={2}
                            />
                        )}
                    </Grid>
                </Grid>

                <Grid item container flexDirection="column" rowGap="35px">
                    <DndProvider backend={HTML5Backend}>
                        <List>
                            {data.map(node => (
                                <HierarchyItem key={node.id} node={node} moveNode={moveNode} onDelete={handleDelete} onEdit={handleEdit} />
                            ))}
                        </List>
                    </DndProvider>
                </Grid>
            </Grid>
            <ConfirmationDialog open={openDeleteDialog} text={childrenToDelete > 0 ? `Are you sure you want to delete selected item and its ${childrenToDelete} children?` : "Are you sure you want to delete selected node?"} title="Delete node" handleConfirmDialog={handleConfirmDelete} handleCloseDialog={() => { setOpenDeleteDialog(false); setDeleteItemId(undefined) }} />

        </>

    )
}

export { HierarchyTree }
export default HierarchyTree
