import { useEffect, useReducer, useRef } from "react"

type State<E> = {
    status: "idle"|"fetching"|"fetched"|"error"|"debounced"
    data: E[]
    error?: Error
}

type Action<E> = {
    type: "FETCHING"|"FETCHED"|"FETCH_ERROR"|"DEBOUNCED"
    payload: E[]
    error?: Error
}

function useFetch<E> (val: string, func: (val: string)=>Promise<E[]>, allowEmpty:boolean = true, debounce: number = 0) : [E[], Error|undefined, "idle"|"fetching"|"fetched"|"error"|"debounced"] {
    const cache = useRef<{[index: string]: E[];}>({})

    const initialState: State<E> = {
        status: "idle",
        error: undefined,
        data: []
    }

    const [state, dispatch] = useReducer((state: State<E>, action: Action<E>): State<E> => {
        switch (action.type) {
        case "DEBOUNCED":
            return { ...initialState, status: "debounced", data: action.payload }
        case "FETCHING":
            return { ...initialState, status: "fetching" }
        case "FETCHED":
            return { ...initialState, status: "fetched", data: action.payload }
        case "FETCH_ERROR":
            return { ...initialState, status: "error", error: action.error }
        default:
            return state
        }
    }, initialState)

    useEffect(() => {
        let cancelRequest = false
        let timer: any = null

        const fetchData = async () => {
            if (cache.current[val]) {
                dispatch({ type: "FETCHING", payload: [] })
                const data = cache.current[val]
                dispatch({ type: "FETCHED", payload: data })
            } else {
                dispatch({ type: "DEBOUNCED", payload: [] })
                timer = setTimeout(async () => {
                    try {
                        if (!cancelRequest) {
                            dispatch({ type: "FETCHING", payload: [] })
                        }
                        const data = await func(val)
                        cache.current[val] = data
                        if (cancelRequest) return
                        dispatch({ type: "FETCHED", payload: data })
                    } catch (error) {
                        if (cancelRequest) return
                        dispatch({ type: "FETCH_ERROR", payload: [], error: error as Error })
                    }
                }, debounce || 500)
            }
        }

        if (allowEmpty || val !== "") {
            fetchData()
        }

        return () => { cancelRequest = true; if (timer !== null) { clearTimeout(timer) } }
    }, [val])

    return [state.data, state.error, state.status]
}

export { useFetch }
