import React, { useEffect, useState } from "react"
import { Link, Page, Text, View } from "@react-pdf/renderer"
import { CVSS3CriticalityKeysValueOps } from "@models/Vulnerability"
import { ComponentCycloneDX, VulnerabilityCycloneDX } from "@models/VulnerabilityReport"
import { CWES } from "@models/CWE"
import styles from "../../Styles"
import { Footer, Header } from "./HeaderFooter"
import VexDocumentStyles from "../../VexStyleSheet"

const severityColors: { [key: string]: string } = {
    critical: "black",
    high: "#D9534F",
    medium: "#FF8537",
    low: "#A8D1E7",
    undefined: "grey"
}
export const introduccion = [
    { id: "Alcance: ", description: "Se describe el alcance inicial desde el cual se parte a la hora de realizar este ejercicio." },
    { id: "Resumen ejecutivo: ", description: "Se muestra un resumen generalde los resultados obtenidos durante el test Network Stress." },
    { id: "Descubrimiento e identificación del sistema: ", description: "Se describen los protocolos objetivos del test." },
    { id: "Identificación de anomalías: ", description: "Se analizan los resultados del test de Network Stress obtenidos y se identifican las anomalías ocurridas durante su ejecución." }
]

const Resultados: React.FC<{ vulnerabilities:VulnerabilityCycloneDX[], components: ComponentCycloneDX[] }> = ({ vulnerabilities, components }) => {
    const [componentsByMap, setComponentsByMap] = useState<{[key: string]: ComponentCycloneDX}>({})

    useEffect(() => {
        setComponentsByMap(components.reduce((acc, current) => {
            acc[current["bom-ref"]] = current
            return acc
        }, {} as {[key: string]: ComponentCycloneDX}))
    }, [components])

    return (
        <Page size="A4">
            <Header></Header>

            <View style={{ ...styles.tableScope, marginTop: 13, width: "90%" }}>
                <View style={{ ...styles.tableRowScope, fontFamily: "Bold", textAlign: "center" }}>
                    <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 0, width: "26%" }}>IGMP</Text>
                    <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 1 }}>Monitor</Text>
                </View>
                <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#e7e7e7", width: "130%", justifyContent: "center", fontWeight: "bold" }}>Test</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#e7e7e7", justifyContent: "center", fontWeight: "bold" }}>T</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#e7e7e7", justifyContent: "center", fontWeight: "bold" }}>L1</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#e7e7e7", justifyContent: "center", fontWeight: "bold" }}>A1</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#e7e7e7", justifyContent: "center", fontWeight: "bold" }}>I1</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 1, backgroundColor: "#e7e7e7", justifyContent: "center", fontWeight: "bold" }}>TP1</Text>
                </View>
                <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", width: "130%", justifyContent: "center", fontWeight: "bold", textAlign: "left" }}>IGMPv3 Query Grammar</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", justifyContent: "center", fontWeight: "light" }}>N/A</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 1, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                </View>
                <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", width: "130%", justifyContent: "center", fontWeight: "bold", textAlign: "left" }}>IGMPv1 Grammar</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", justifyContent: "center", fontWeight: "light" }}>N/A</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 1, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                </View>
                <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", width: "130%", justifyContent: "center", fontWeight: "bold", textAlign: "left" }}>IGMPv2 Grammar</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", justifyContent: "center", fontWeight: "light" }}>N/A</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 1, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                </View>
                <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 1, borderRightWidth: 0, backgroundColor: "transparent", width: "130%", justifyContent: "center", fontWeight: "bold", textAlign: "left" }}>IGMPv3 Report Grammar</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 1, borderRightWidth: 0, backgroundColor: "transparent", justifyContent: "center", fontWeight: "light" }}>N/A</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 1, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 1, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 1, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 1, borderRightWidth: 1, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                </View>

            </View>
            <Text style={{ ...styles.textua, marginBottom: 25, marginLeft: "28%", marginRight: "-50%", fontFamily: "Griff", fontSize: 8, marginTop: 3, color: "gray" }} >Tabla 9: Tests del protocolo IGMP sin anomalías.</Text>
            <View style={{ ...styles.tableScope, marginTop: 13, width: "90%" }}>
                <View style={{ ...styles.tableRowScope, fontFamily: "Bold", textAlign: "center" }}>
                    <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 0, width: "26%" }}>TCP</Text>
                    <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 1 }}>Monitor</Text>
                </View>
                <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#e7e7e7", width: "130%", justifyContent: "center", fontWeight: "bold" }}>Test</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#e7e7e7", justifyContent: "center", fontWeight: "bold" }}>T</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#e7e7e7", justifyContent: "center", fontWeight: "bold" }}>L1</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#e7e7e7", justifyContent: "center", fontWeight: "bold" }}>A1</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#e7e7e7", justifyContent: "center", fontWeight: "bold" }}>I1</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 1, backgroundColor: "#e7e7e7", justifyContent: "center", fontWeight: "bold" }}>TP1</Text>
                </View>
                <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", width: "130%", justifyContent: "center", fontWeight: "bold", textAlign: "left" }}>TCP Fuzzer (L1/L2)</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", justifyContent: "center", fontWeight: "light" }}>N/A</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 1, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                </View>
                <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", width: "130%", justifyContent: "center", fontWeight: "bold", textAlign: "left" }}>TCP/IP Grammar (L2)</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", justifyContent: "center", fontWeight: "light" }}>N/A</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 1, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                </View>
                <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", width: "130%", justifyContent: "center", fontWeight: "bold", textAlign: "left" }}>TCP Data Grammar (L2)</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", justifyContent: "center", fontWeight: "light" }}>N/A</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 1, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                </View>
                <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", width: "130%", justifyContent: "center", fontWeight: "bold", textAlign: "left" }}>TCP Grammar – Header Fields (L2) </Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "light" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 1, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                </View>
                <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", width: "130%", justifyContent: "center", fontWeight: "bold", textAlign: "left" }}>TCP Grammar – Contextuality Invalid Packets (L2)</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "light" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 1, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                </View>
                <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", width: "130%", justifyContent: "center", fontWeight: "bold", textAlign: "left" }}>TCP Priority Traffic Interleaving (L2)</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "light" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 1, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                </View>
                <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", width: "130%", justifyContent: "center", fontWeight: "bold", textAlign: "left" }}>TCP Timestamp Manipulation (L2)</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "light" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 1, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                </View>
                <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", width: "130%", justifyContent: "center", fontWeight: "bold", textAlign: "left" }}>TCP Initial Sequence Number Randomness Check (L2)</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "light" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 1, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                </View>
                <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", width: "130%", justifyContent: "center", fontWeight: "bold", textAlign: "left" }}>TCP Selective Acknowledgement (L2)</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#FF9A9A", justifyContent: "center", fontWeight: "light" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#A0A0A0", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#A0A0A0", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#A0A0A0", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 1, backgroundColor: "#A0A0A0", justifyContent: "center", fontWeight: "bold" }}></Text>
                </View>
                <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 1, borderRightWidth: 0, backgroundColor: "transparent", width: "130%", justifyContent: "center", fontWeight: "bold", textAlign: "left" }}>TCP Receive Window (L2)</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 1, borderRightWidth: 0, backgroundColor: "#FF9A9A", justifyContent: "center", fontWeight: "light" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 1, borderRightWidth: 0, backgroundColor: "#A0A0A0", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 1, borderRightWidth: 0, backgroundColor: "#A0A0A0", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 1, borderRightWidth: 0, backgroundColor: "#A0A0A0", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 1, borderRightWidth: 1, backgroundColor: "#A0A0A0", justifyContent: "center", fontWeight: "bold" }}></Text>
                </View>

            </View>
            <Text style={{ ...styles.textua, marginBottom: 30, marginLeft: "27%", marginRight: "-50%", fontFamily: "Griff", fontSize: 8, marginTop: 3, color: "gray" }} >Tabla 10: Tests del protocolo TCP sin anomalías.</Text>

            <Footer></Footer>
        </Page>
    )
}

const VulnerabilityRow: React.FC<{ vulnerability: VulnerabilityCycloneDX, index: number, component: ComponentCycloneDX }> = ({ vulnerability, index, component }) => (
    <View style={VexDocumentStyles.row} wrap={false}>
        <View style={{ ...VexDocumentStyles.col, width: "10%", borderRightWidth: 0, borderTopWidth: 0 }}>
            <Link src={`#SCA-${String(index + 1).padStart(3, "0")}`} style={{ textDecoration: "none", color: "black" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", borderBottomWidth: 0 }}>{`SCA-${String(index + 1).padStart(3, "0")} `}</Text></Link>
        </View>
        <View style={{ ...VexDocumentStyles.col, width: "10%", borderRightWidth: 0, borderTopWidth: 0 }}>
            <Link href={`https://nvd.nist.gov/vuln/detail/${vulnerability.id}`} style={{ textDecoration: "none", color: "black" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Helvetica", width: "100%" }}> {vulnerability.id } </Text></Link>
        </View>
        <View style={{ ...VexDocumentStyles.col, width: "60%", borderRightWidth: 0, borderTopWidth: 0 }}>
            <Link href={vulnerability.cwes?.length > 0 ? `https://cwe.mitre.org/data/definitions/${vulnerability.cwes[0]}.html` : ""} style={{ textDecoration: "none", color: "black" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff" }}>{vulnerability.cwes?.length > 0 ? CWES[vulnerability.cwes[0]] : "Insufficient Information"}</Text></Link>
        </View>
        <View style={{ ...VexDocumentStyles.col, width: "10%", borderRightWidth: 0, borderTopWidth: 0 }}>
            <Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", color: severityColors[vulnerability.ratings.find(r => r.method === "CVSSv3")?.severity || "undefined"] }}>{CVSS3CriticalityKeysValueOps.find(v => v.value === vulnerability.ratings.find(r => r.method === "CVSSv3")?.severity)?.label}</Text>
        </View>
        <View style={{ ...VexDocumentStyles.col, width: "10%", borderTopWidth: 0 }}>
            <Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold" }}>{component != null
                ? `${component.name} - ${component.version} `
                : null}</Text>
        </View>
    </View>
)

const VulnsListDetail: React.FC<{ vulnerabilities:VulnerabilityCycloneDX[], components: ComponentCycloneDX[] }> = ({ vulnerabilities, components }) => {
    const [componentsByMap, setComponentsByMap] = useState<{[key: string]: ComponentCycloneDX}>({})

    useEffect(() => {
        setComponentsByMap(components.reduce((acc, current) => {
            acc[current["bom-ref"]] = current
            return acc
        }, {} as {[key: string]: ComponentCycloneDX}))
    }, [components])

    return (
        <Page size="A4">
            <Header></Header>
            <Text style={{ ...styles.textua, fontFamily: "Bold", marginLeft: 12, fontSize: 12, marginRight: 0 }}>4.1. Servicios publicados</Text>
            <Text style={{ ...styles.textua, marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>En esta fase se descubren y se identifican todos los protocolos en los que se van a realizar los test para el diagnóstico de robustez de comunicaciones en el DUT.</Text>

            <Footer></Footer>
        </Page>
    )
}
const VulnerabilityHeaderDetail: React.FC<{ vulnerability: VulnerabilityCycloneDX; index: number, component: ComponentCycloneDX }> = ({ vulnerability, index, component }) => (
    <View key={index + 1} style={{ ...VexDocumentStyles.section }} wrap={false}>
        <Text id={`SCA-${String(index + 1).padStart(3, "0")}`} style={{ ...styles.textua, fontFamily: "Bold", fontSize: 12, marginRight: 0, paddingLeft: 5, paddingBottom: 5 }}>4.2.{index} {`SCA-${String(index + 1).padStart(3, "0")} `}</Text>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 10, paddingBottom: -3, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>CVE</Text></View>
            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 12, paddingBottom: 0, paddingLeft: 5 }}><Link href={`https://nvd.nist.gov/vuln/detail/${vulnerability.id}`} style={{ textDecoration: "none", color: "black" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9 }}> {vulnerability.id} </Text></Link></View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>Name</Text></View>
            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", fontSize: 9, padding: 0, paddingTop: 5, paddingBottom: 0, paddingLeft: 5 }}><Link href={vulnerability.cwes?.length > 0 ? `https://cwe.mitre.org/data/definitions/${vulnerability.cwes[0]}.html` : ""} style={{ textDecoration: "none", color: "black" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9 }}>{vulnerability.cwes?.length > 0 ? CWES[vulnerability.cwes[0]] : "Insufficient Information"}</Text></Link></View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>Criticality</Text></View>
            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 9, marginBottom: 0 }}>{CVSS3CriticalityKeysValueOps.find(v => v.value === vulnerability.ratings.find(r => r.method === "CVSSv3")?.severity)?.label}</Text></View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>CVSS</Text></View>
            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 6, paddingBottom: 0, paddingLeft: 5 }}>
                <View>
                    <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 0 }}>{(vulnerability.ratings as any)[0].score} {(vulnerability.ratings as any)[0].vector}</Text>
                </View>
            </View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>Affected assets</Text></View>
            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 10, paddingBottom: 0, paddingLeft: 5 }}>
                <Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 9, marginBottom: 0 }}>
                    {component != null
                        ? `${component.name} - ${component.version} `
                        : null}
                </Text>
            </View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>Description</Text></View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5, paddingRight: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 2 }}>{vulnerability.description}</Text></View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>Solution</Text></View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5, paddingRight: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 0 }}>N/A</Text></View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>Reference</Text></View>
            <View style={{ ...VexDocumentStyles.col, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 0, paddingLeft: 5 }}><Link href={`https://nvd.nist.gov/vuln/detail/${vulnerability.id}`} style={{ textDecoration: "none", color: "black" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 2 }}> {`https://nvd.nist.gov/vuln/detail/${vulnerability.id}`} </Text></Link></View>
        </View>
    </View>
)

export { Resultados, VulnsListDetail }
