import { Alert, Box, Button, Grid, TextField, Typography, useTheme } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { HierarchySelector } from "@components/hierarchy/HierarchySelector"
import { ClientSelector } from "@components/client/index"
import { ServicesContext } from "@context/index"
import { Hierarchy } from "@models/Hierarchy"
import { FilterOperation, FilterOption } from "@utils/queryParams"
import { I18nContext } from "I18nProvider"
import { MAX_DEEP_LEVEL, PATH_SEPARATOR } from "./hierarchyUtils"

const HierarchyForm: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const { id } = useParams<{ id: string }>()
    const hierarchyService = useContext(ServicesContext).hierarchyService

    const [error, setError] = useState<Error|null>(null)
    const [selectedHierarchies, setSelectedHierarchies] = useState<(Hierarchy | null)[]>(Array.from({ length: MAX_DEEP_LEVEL - 1 }, () => null))

    const initValue: Hierarchy = {
        id: "",
        name: "",
        description: "",
        path: "",
        client: { id: "" }
    }
    const [formData, setFormData] = useState<Hierarchy>(initValue)

    useEffect(() => {
        const fetchHierarchy = async () => {
            if (id) {
                try {
                    const hierarchy = await hierarchyService.get(id)
                    setFormData(hierarchy)
                    const pathIds = hierarchy.path.split(PATH_SEPARATOR).filter(pathId => pathId !== id)
                    const fetchedHierarchies = await Promise.all(pathIds.map(id => hierarchyService.get(id)))
                    setSelectedHierarchies(fetchedHierarchies.map(h => h || null))
                } catch (error) {
                    setError({ message: "Failed to fetch hierarchy" } as Error)
                }
            }
        }

        fetchHierarchy()
    }, [id, hierarchyService])

    const handleInputChange = (e: any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const handleSelectorChange = (index: number, id: string | undefined, hierarchy: Hierarchy | null) => {
        const newSelectedHierarchies = [...selectedHierarchies]
        newSelectedHierarchies[index] = hierarchy
        newSelectedHierarchies.fill(null, index + 1, MAX_DEEP_LEVEL - 1)
        setSelectedHierarchies(newSelectedHierarchies)

        const newPath = newSelectedHierarchies.filter(h => h !== null).map(h => h!.id).join(";")
        setFormData({ ...formData, path: newPath })
    }

    const formValid = (): boolean => {
        const isNotNullrules = [
            formData.name === "",
            formData.description === "",
            formData.client.id === "",
            formData.client.id === undefined
        ]
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        return true
    }
    // Api call form validation
    const navigate = useNavigate()
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        try {
            if (formData.id) {
                let updatedPath = formData.path
                if (!updatedPath || !updatedPath.endsWith(formData.id)) {
                    // Add the id to the end of the path
                    updatedPath = updatedPath ? `${updatedPath}${PATH_SEPARATOR}${formData.id}` : formData.id
                }
                const updatedFormData = { ...formData, path: updatedPath }
                // Update existing hierarchy
                await hierarchyService.update(formData.id, updatedFormData)
            } else {
                // Create new hierarchy
                await hierarchyService.create(formData)
            }
            setError(null)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
        }
    }

    const getCustomFilters = (index: number): FilterOption[] => {
        if (index === 0) {
            return [
                { field: "client_id", operation: FilterOperation.UUIDEqual, value: formData.client.id },
                { field: "path", operation: FilterOperation.StringNotContains, value: `%${PATH_SEPARATOR}%` }
            ]
        }
        const parentHierarchy = selectedHierarchies[index - 1]
        if (!parentHierarchy) return []
        return [
            { field: "path", operation: FilterOperation.StringContains, value: `${parentHierarchy.path}${PATH_SEPARATOR}%` },
            { field: "path", operation: FilterOperation.StringNotContains, value: `${parentHierarchy.path}${PATH_SEPARATOR}%${PATH_SEPARATOR}%` }
        ]
    }

    return (
        <Box sx={{ width: "100%" }}>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: "50%", alignSelf: "center", margin: "50px auto 50px auto" }}>
                {error && <Alert severity="error">{error.message}</Alert>}
                <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                    <Grid item xs={6}>
                        <ClientSelector value={formData.client.id} onSelect={(id) => handleInputChange({ target: { name: "client", value: { id } } })}></ClientSelector>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField margin="normal" required fullWidth variant="filled" label={context.t.translate("product_name")} name="name"
                        value={formData.name} onChange={handleInputChange}/>
                </Grid>
                <Grid>
                    <TextField margin="normal" required fullWidth variant="filled" label={context.t.translate("product_description")} name="description"
                        value={formData.description} onChange={handleInputChange}/>
                </Grid>
                {formData.id
                    ? (
                        <Grid>
                            <Typography variant="body1" sx={{ mt: 2 }}>
                            Hierarchy: {selectedHierarchies.filter((h) => h !== null).map((h) => h!.name).join(" > ")}
                            </Typography>
                        </Grid>
                    )
                    : (
                        <Grid>
                            <HierarchySelector
                                value={selectedHierarchies[0]?.id || ""}
                                customFilters={getCustomFilters(0)}
                                onSelect={(id, hierarchy) => handleSelectorChange(0, id, hierarchy)}
                                index={0}
                            />
                            {selectedHierarchies[0] && (
                                <HierarchySelector
                                    value={selectedHierarchies[1]?.id || ""}
                                    customFilters={getCustomFilters(1)}
                                    onSelect={(id, hierarchy) => handleSelectorChange(1, id, hierarchy)}
                                    index={1}
                                />
                            )}
                            {selectedHierarchies[1] && (
                                <HierarchySelector
                                    value={selectedHierarchies[2]?.id || ""}
                                    customFilters={getCustomFilters(2)}
                                    onSelect={(id, hierarchy) => handleSelectorChange(2, id, hierarchy)}
                                    index={2}
                                />
                            )}
                        </Grid>
                    )}
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, color: theme.palette.secondary.contrastText }} disabled={!formValid()}>
                            {context.t.translate("CRA_save")}
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2, color: theme.palette.secondary.contrastText }} onClick={() => navigate("../manage")}>
                            {context.t.translate("CRA_cancel")}
                        </Button>
                    </Grid>
                </Grid>

            </Box>
        </Box>
    )
}

export { HierarchyForm }
export default HierarchyForm
