import React from "react"
import { useTheme } from "@mui/material/styles"
import { Divider, Grid, Paper, Typography } from "@mui/material"
import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import { TiHomeOutline } from "react-icons/ti"
import { BiBug } from "react-icons/bi"
import { FiLogOut } from "react-icons/fi"
import { CgFileDocument, CgTemplate } from "react-icons/cg"
import { VscGraph } from "react-icons/vsc"
import { TbDeviceHeartMonitor } from "react-icons/tb"
import { FaUsers } from "react-icons/fa"
import { MdOutlineNotificationsNone } from "react-icons/md"
import { Sidebar } from "@components/index"
import { Subjects } from "@permissions/ability"
import { Assessment, Client, Home, Logout, Product, Vulnerability } from "@views/index"
import BenchmarkCharts from "@views/benchmark/BenchmarkCharts"
import { Email } from "@views/email"
import { Template } from "@views/templates"
import { IoIosSettings } from "react-icons/io"
import { GrCompliance, GrTree } from "react-icons/gr"
import Compliance from "@views/compliance/Compliance"
import SettingsPage from "@views/settings"
import { ComponentDetail, ComponentList } from "@views/component"
import SBOMQuality from "@views/assessment/SBOMQuality"
import { TicketingDetail, TicketingForm, TicketingList } from "@views/ticketing"
import { Hierarchy } from "@views/hierarchy"
const sidebarPrimaryItems = [
    [
        {
            icon: TiHomeOutline,
            iconProps: {},
            route: "/",
            link: "/",
            component: <Home />,
            title: "Dashboard"
        }
    ],
    [{
        icon: GrTree,
        iconProps: {},
        route: "/hierarchy/*",
        link: "/hierarchy",
        entity: "Hierarchy" as Subjects,
        component: <Hierarchy />,
        title: "Hierarchy"
    },
    {
        icon: TbDeviceHeartMonitor,
        iconProps: { size: "30px" },
        route: "/product/*",
        link: "/product",
        entity: "Product" as Subjects,
        component: <Product />,
        title: "Products"
    },
    {
        icon: CgFileDocument,
        iconProps: {},
        route: "/assessment/*",
        link: "/assessment",
        entity: "Assessment" as Subjects,
        component: <Assessment />,
        title: "Assessments"
    },
    {
        icon: BiBug,
        iconProps: {},
        route: "/vulnerability/*",
        link: "/vulnerability",
        entity: "Vulnerability" as Subjects,
        component: <Vulnerability />,
        title: "Vulnerabilities"
    },
    {
        icon: VscGraph,
        iconProps: {},
        route: "/benchmark/*",
        link: "/benchmark",
        entity: "Benchmark" as Subjects,
        component: <BenchmarkCharts />,
        title: "Benchmark"
    },
    {
        icon: GrCompliance,
        iconProps: {},
        route: "/compliance/*",
        link: "/compliance",
        entity: "Compliance" as Subjects,
        component: <Compliance />,
        title: "CRA Compliance"
    },
    {
        icon: MdOutlineNotificationsNone,
        iconProps: {},
        route: "/email/*",
        link: "/email",
        component: <Email />,
        entity: "Email" as Subjects,
        title: "Notifications & Alerts"
    }
    ]
]

const sidebarSecondaryItems = [
    [
        {
            icon: IoIosSettings,
            iconProps: {},
            route: "/settings/*",
            link: "/settings",
            component: <SettingsPage />,
            entity: "Settings" as Subjects,
            title: "Account & Settings"
        },
        {
            icon: FaUsers,
            iconProps: {},
            route: "/client/*",
            link: "/client",
            component: <Client />,
            entity: "Client" as Subjects,
            title: "Client"
        },
        {
            icon: CgTemplate,
            iconProps: {},
            route: "/template/*",
            link: "/template",
            entity: "Template" as Subjects,
            component: <Template />,
            title: "Templates"
        }
    ],
    [
        {
            icon: FiLogOut,
            iconProps: {},
            route: "/logout",
            link: "/logout",
            title: "Logout",
            component: <Logout />
        }
    ]
]

const hiddenItems = [
    {
        icon: BiBug,
        iconProps: {},
        route: "/assessment/:id/component/",
        link: "/assessment/:id/component/",
        entity: "Component" as Subjects,
        component: <ComponentList />,
        title: "Components"
    },
    {
        icon: BiBug,
        iconProps: {},
        route: "/assessment/:id/component/:componentId",
        link: "/assessment/:id/component/:componentId",
        entity: "Component" as Subjects,
        component: <ComponentDetail />,
        title: "Components"
    },
    {
        icon: BiBug,
        iconProps: {},
        route: "/assessment/:id/sbomQuality",
        link: "/assessment/:id/sbomQuality",
        entity: "SBOMQuality" as Subjects,
        component: <SBOMQuality />,
        title: "SBOMQuality"
    },
    {
        icon: BiBug,
        iconProps: {},
        route: "/settings/ticket/",
        link: "/settings/ticket/",
        entity: "Ticketing" as Subjects,
        component: <TicketingList />,
        title: "Ticketing"
    },
    {
        icon: BiBug,
        iconProps: {},
        route: "/settings/ticket/:id",
        link: "/settings/ticket/:id",
        entity: "Ticketing" as Subjects,
        component: <TicketingDetail />,
        title: "Ticketing"
    },
    {
        icon: BiBug,
        iconProps: {},
        route: "/settings/ticket/add",
        link: "/settings/ticket/add",
        entity: "Ticketing" as Subjects,
        component: <TicketingForm />,
        title: "Ticketing"
    }
]
const AppLayout: React.FC = () => {
    const theme = useTheme()
    const location = useLocation()

    return (
        <Paper sx={{ height: "100%", borderRadius: 0 }}>
            <Grid container sx={{ height: "100%" }}>
                <Grid item container flexDirection="column" paddingY="40px" sx={{ background: theme.palette.primary.main, height: "100%", width: "60px" }}>
                    <Sidebar primaryItems={sidebarPrimaryItems} secondaryItems={sidebarSecondaryItems}/>
                </Grid>
                <Grid item xs container sx={{ background: theme.palette.secondary.contrastText, height: "100%", overflowY: "scroll" }}>
                    <Grid item xs padding="40px" container flexDirection="column" spacing="20px">
                        <Grid item container>
                            <Typography color="primary" fontSize="32px" fontWeight="bolder" fontFamily="Griff">Orbik</Typography>
                            <Divider sx={{ height: "0,5px", width: "100%", background: theme.palette.primary.main, margin: "9px 0" }} />
                            <Typography color={theme.palette.secondary.main} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                                {
                                    [...sidebarPrimaryItems, ...sidebarSecondaryItems].flat(1).find(item => item.link === location.pathname)?.title || ""
                                }
                            </Typography>
                        </Grid>

                        <Routes>
                            {
                                [...sidebarPrimaryItems, ...sidebarSecondaryItems, ...hiddenItems].flat(1).map((item, idx) => (
                                    <Route key={idx} path={item.route} element={item.component} />
                                ))
                            }
                            <Route key="" path="*" element={<Navigate to="/" replace={true} />} />
                        </Routes>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}

export { AppLayout }
export default AppLayout
