
import { DeviceStats, StatsAssessments, StatsProducts, StatsVulnerabilities } from "@models/index"
import { MediaTypes } from "@services/HttpService"
import Service from "@services/abstract/BaseService"
import { FilterOperation, QueryParameters } from "@utils/index"

class StatsService extends Service<any> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/stats", MediaTypes.JSON)
    }

    public getStatsAssessment = () => {
        return this.http.get(`/${this.path}/assessments`) as Promise<StatsAssessments>
    }

    public getStatsAssessmentFilter = (id: string) => {
        return this.http.getMultiple(`/${this.path}/assessments`, { filters: [{ field: "client_id", operation: FilterOperation.UUIDEqual, value: id }] }) as any
    }

    public getStatsVulnerabilities = () => {
        return this.http.get(`/${this.path}/vulnerabilities`) as Promise<StatsVulnerabilities>
    }

    public getStatsVulnerabilitiesWithParams = (params: QueryParameters) => {
        return this.http.getMultiple(`/${this.path}/vulnerabilities`, params) as any
    }

    public getStatsVulnerabilitiesFilter = (id: string) => {
        return this.http.getMultiple(`/${this.path}/vulnerabilities`, { filters: [{ field: "client_id", operation: FilterOperation.UUIDEqual, value: id }] }) as any
    }

    public getStatsProduct = () => {
        return this.http.get(`/${this.path}/products`) as Promise<StatsProducts>
    }

    public getDeviceStats = () => {
        return this.http.get(`/${this.path}/devices`) as Promise<DeviceStats[]>
    }
}

export { StatsService }

export default StatsService
