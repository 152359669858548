
import React from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import HighchartsMore from "highcharts/highcharts-more"
import SolidGauge from "highcharts/modules/solid-gauge"
import { Grid } from "@mui/material"

type Metrics = {
    exploitability?: number;
    impact: number;
};

type GaugeChartProps = {
    environmentalMetrics: Metrics
};

HighchartsMore(Highcharts)
SolidGauge(Highcharts)

const EnvironmentalColorGauge: React.FC<GaugeChartProps> = ({ environmentalMetrics }) => {
    const getLabel = (value: number) => {
        if (value < 5) {
            return "Bad"
        } else if (value < 7) {
            return "Good"
        }
        return "Very Good"
    }
    const options = {
        chart: {
            type: "solidgauge",
            backgroundColor: "#1e1e1f",
            margin: [0, 0, 0, 0],
            spacingTop: 0, // Quitar el espacio superior
            spacingBottom: 0, // Quitar el espacio inferior
            events: {
                load: function (this: Highcharts.Chart) {
                    const chart = this
                    const exploitabilitySeries = chart.series[0]
                    const impactSeries = chart.series[1]

                    if (exploitabilitySeries && exploitabilitySeries.data && exploitabilitySeries.data[0] && typeof exploitabilitySeries.data[0].y === "number") {
                        const yValue = exploitabilitySeries.data[0].y
                        if (yValue > environmentalMetrics.exploitability!) {
                            exploitabilitySeries.addPoint(environmentalMetrics.exploitability!)
                        }
                    }

                    if (impactSeries && impactSeries.data && impactSeries.data[0] && typeof impactSeries.data[0].y === "number") {
                        const yValue = impactSeries.data[0].y
                        if (yValue > environmentalMetrics.impact) {
                            impactSeries.addPoint(environmentalMetrics.impact)
                        }
                    }
                }
            }

        },
        title: null,
        pane: {
            center: ["50%", "70%"],
            size: "100%",
            startAngle: -90,
            endAngle: 90,
            background: {
                backgroundColor: "#333",
                innerRadius: "60%",
                outerRadius: "100%",
                shape: "arc",
                borderWidth: 0
            }
        },
        yAxis: {
            min: 0,
            max: 10,
            stops: [
                [environmentalMetrics.exploitability! / 10, "#a6761d"], // exploitability
                [environmentalMetrics.impact / 10, "#1b9d76"] // impact
            ],
            tickInterval: 2.5, // Intervalo de ticks principales
            minorTickInterval: "auto", // Intervalo de ticks menores (opcional)
            minorTickWidth: 1,
            minorTickLength: 10,
            minorTickZIndex: 15,
            minorTickPosition: "inside",
            minorTickColor: "white",
            lineWidth: 0,
            tickWidth: 2,
            tickPosition: "inside",
            tickLength: 15,
            tickColor: "white",
            tickAmount: 5,
            tickZIndex: 15,
            zIndex: 100,
            title: {
                y: -70
            },
            labels: {
                y: 16,
                style: {
                    color: "white",
                    textOutline: "none"
                },
                zIndex: 12 // asegurar que las etiquetas estén encima del gráfico
            }
        },
        plotOptions: {
            solidgauge: {
                dataLabels: {
                    y: -25,
                    borderWidth: 0,
                    useHTML: true,
                    style: {
                        color: "white", // color de los data labels,
                        fontSize: "10px",
                        fontFamily: "Griff",
                        fontWeight: "bolder",
                        zIndex: 12
                    }
                }
            }
        },
        series: [
            // {
            //     name: "Score",
            //     data: [Math.max(environmentalMetrics.exploitability!, environmentalMetrics.impact)],
            //     dataLabels: {
            //         format: `<div style="text-align:center; margin-top: -80px">
            //                     <span style="font-size:35px;color:white">{y}</span><br/>
            //                     <span style="font-size:14px;color:white">${getLabel(Math.max(environmentalMetrics.exploitability!, environmentalMetrics.impact))}</span>
            //                  </div>`
            //     },
            //     tooltip: {
            //         valueSuffix: " "
            //     }
            // }
            {
                name: "Environmental",
                data: [environmentalMetrics.exploitability],
                dataLabels: {
                    format: `<div style="text-align:center; margin-top: -100px; margin-bottom: 20px">
                   <span style="font-size:55px;color:white">{y}</span><br/>
                                 <span style="font-size:14px;color:white">${getLabel(Math.max(environmentalMetrics.exploitability!, environmentalMetrics.impact))}</span><br/>
                                <div style="display: inline-block; width: 10px; height: 10px; border-radius: 50%; margin-top: 20px; background-color: #a6761d; margin-right: 5px;"></div>
                                <span style="font-size:12px;color:white">Environmental</span><br/>
                             </div>`
                },
                tooltip: {
                    valueSuffix: " "
                }
            },
            {
                name: "Impact",
                data: [environmentalMetrics.impact],
                dataLabels: {
                    format: `<div style="text-align:center; margin-top: -15px;">
                                <div style="display: inline-block; width: 10px; height: 10px; border-radius: 50%; background-color: #1b9d76; margin-right: 5px;"></div>
                                <span style="font-size:12px;color:white">Modified Impact</span>
                             </div>`
                },
                tooltip: {
                    valueSuffix: " "
                }
            }

        ],
        credits: {
            enabled: false
        },
        legend: {
            enabled: false // deshabilitar la leyenda
        },
        exporting: {
            enabled: false
        }
    }
    const containerStyle = {
        maxHeight: "400px",
        overflow: "hidden",
        maxWidth: "400px"

    }

    return (
        <Grid display="flex" justifyContent="center" style={containerStyle}>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </Grid>
    )
}
export type { GaugeChartProps }
export default EnvironmentalColorGauge
