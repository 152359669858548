import { Track } from "@models/index"
import { MediaTypes } from "@services/HttpService"
import Service from "@services/abstract/BaseService"

class TrackService extends Service<Track> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/track", MediaTypes.JSON)
    }
}

export { TrackService }
export default TrackService
