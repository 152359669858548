const CVSS3EnvironmentalMetric = {
    CR: {
        label: "Confidentiality Requirement (CR)",
        metric: "CR",
        values: {
            X: {
                label: "Not Defined",
                metric: "X",
                value: 1
            },
            L: {
                label: "Low",
                metric: "L",
                value: 0.5
            },
            M: {
                label: "Medium",
                metric: "M",
                value: 1
            },
            H: {
                label: "High",
                metric: "H",
                value: 1.5
            }
        }
    },
    IR: {
        label: "Integrity Requirement (IR)",
        metric: "IR",
        values: {
            X: {
                label: "Not Defined",
                metric: "X",
                value: 1
            },
            L: {
                label: "Low",
                metric: "L",
                value: 0.5
            },
            M: {
                label: "Medium",
                metric: "M",
                value: 1
            },
            H: {
                label: "High",
                metric: "H",
                value: 1.5
            }
        }
    },
    AR: {
        label: "Availability Requirement (AR)",
        metric: "AR",
        values: {
            X: {
                label: "Not Defined",
                metric: "X",
                value: 1
            },
            L: {
                label: "Low",
                metric: "L",
                value: 0.5
            },
            M: {
                label: "Medium",
                metric: "M",
                value: 1
            },
            H: {
                label: "High",
                metric: "H",
                value: 1.5
            }
        }
    },
    MAV: {
        label: "Modified Attack Vector (MAV)",
        metric: "MAV",
        values: {
            X: {
                label: "Not Defined",
                metric: "X",
                value: 1
            },
            N: {
                label: "Network",
                metric: "N",
                value: 0.85
            },
            A: {
                label: "Adjacent Network",
                metric: "A",
                value: 0.62
            },
            L: {
                label: "Local",
                metric: "L",
                value: 0.55
            },
            P: {
                label: "Physical",
                metric: "P",
                value: 0.2
            }
        }
    },
    MAC: {
        label: "Attack Complexity (MAC)",
        metric: "MAC",
        values: {
            X: {
                label: "Not Defined",
                metric: "X",
                value: 1
            },
            L: {
                label: "Low",
                metric: "L",
                value: 0.77
            },
            H: {
                label: "High",
                metric: "H",
                value: 0.44
            }
        }
    },
    MPR: {
        label: "Privileges Required (MPR)",
        metric: "MPR",
        values: {
            X: {
                label: "Not Defined",
                metric: "X",
                value: 1
            },
            N: {
                label: "None",
                metric: "N",
                value: 0.85
            },
            L: {
                label: "Low",
                metric: "L",
                value: {
                    U: 0.62,
                    C: 0.68
                }
            },
            H: {
                label: "High",
                metric: "H",
                value: {
                    U: 0.27,
                    C: 0.5
                }
            }
        }
    },
    MUI: {
        label: "User Interaction (MUI)",
        metric: "MUI",
        values: {
            X: {
                label: "Not Defined",
                metric: "X",
                value: 1
            },
            N: {
                label: "None",
                metric: "N",
                value: 0.85
            },
            R: {
                label: "Required",
                metric: "R",
                value: 0.62
            }
        }
    },
    MS: {
        label: "Scope (MS)",
        metric: "MS",
        values: {
            X: {
                label: "Not Defined",
                metric: "X",
                value: 1
            },
            U: {
                label: "Unchanged",
                metric: "U",
                value: 6.42
            },
            C: {
                label: "Changed",
                metric: "C",
                value: 7.52
            }
        }
    },
    MC: {
        label: "Confidentiality Impact (MC)",
        metric: "MC",
        values: {
            X: {
                label: "Not Defined",
                metric: "X",
                value: 1
            },
            N: {
                label: "None",
                metric: "N",
                value: 0
            },
            L: {
                label: "Low",
                metric: "L",
                value: 0.22
            },
            H: {
                label: "High",
                metric: "H",
                value: 0.56
            }
        }
    },
    MI: {
        label: "Integrity Impact (MI)",
        metric: "MI",
        values: {
            X: {
                label: "Not Defined",
                metric: "X",
                value: 1
            },
            N: {
                label: "None",
                metric: "N",
                value: 0
            },
            L: {
                label: "Low",
                metric: "L",
                value: 0.22
            },
            H: {
                label: "High",
                metric: "H",
                value: 0.56
            }
        }
    },
    MA: {
        label: "Availability Impact (MA)",
        metric: "MA",
        values: {
            X: {
                label: "Not Defined",
                metric: "X",
                value: 1
            },
            N: {
                label: "None",
                metric: "N",
                value: 0
            },
            L: {
                label: "Low",
                metric: "L",
                value: 0.22
            },
            H: {
                label: "High",
                metric: "H",
                value: 0.56
            }
        }
    }
}

export default CVSS3EnvironmentalMetric
