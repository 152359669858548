import React from "react"
import { Document, Font } from "@react-pdf/renderer"
import Assessment, { AssessmentStatus, AssessmentType } from "@models/Assessment"
import Achilles from "@models/Achilles"
import { BackPage } from "./sections/99_BackPage"
import { FrontPage } from "./Aquiles/sections/0_FrontPage"
import { Index } from "./Aquiles/sections/0_Index"
import { Intro } from "./Aquiles/sections/1_Intro"
import { ScopeSection } from "./Aquiles/sections/2_ScopeSection"
import { ExecutiveSection } from "./Aquiles/sections/3_ExecutiveSection"
import { VulnsSection } from "./Aquiles/sections/4_1_VulnsList"
import { Resultado } from "./Aquiles/sections/5_2_Results"
import { Pruebas } from "./Aquiles/sections/5_3_TestExecutions"
import { Resultados } from "./Aquiles/sections/4_2_VulnsList "
import { Resulta2 } from "./Aquiles/sections/4_3_VulnsList "
import { Conclusion } from "./Aquiles/sections/4_4_VulnsList "

Font.register({ family: "Griff", src: "./assets/fonts/Griff-Regular.otf" })
Font.register({ family: "Bold", src: "./assets/fonts/Griff-Bold.otf" })

const hyphenationCallback = (word: string) => {
    // Devuelve las sílabas de la palabra en un array
    // Este es solo un ejemplo simple y puede necesitar mejoras
    return word.split("-")
}

// Registra la función de separación de sílabas
Font.registerHyphenationCallback(hyphenationCallback)

interface VEXDocumentProps {
    assessment: Assessment,
    data: Achilles[]
}

const VulnReportDocumentAquilles: React.FC<VEXDocumentProps> = ({ assessment, data }) => {
    return (
        <>
            <Document pageLayout="twoColumnRight">
                <FrontPage assessment={assessment}></FrontPage>
                <Index></Index>
                <Intro assessment={{
                    id: "",
                    type: AssessmentType.CRT,
                    status: AssessmentStatus.Contracted,
                    contractDate: null,
                    startDate: undefined,
                    endDate: undefined,
                    product: {
                        id: "",
                        name: undefined,
                        version: undefined
                    },
                    client: {
                        id: "",
                        name: undefined
                    },
                    template: {
                        id: "",
                        name: undefined
                    },
                    analysis_status: undefined
                }}></Intro>
                <ScopeSection assessment={{
                    id: "",
                    type: AssessmentType.CRT,
                    status: AssessmentStatus.Contracted,
                    contractDate: null,
                    startDate: undefined,
                    endDate: undefined,
                    product: {
                        id: "",
                        name: undefined,
                        version: undefined
                    },
                    client: {
                        id: "",
                        name: undefined
                    },
                    template: {
                        id: "",
                        name: undefined
                    },
                    analysis_status: undefined
                }}></ScopeSection>
                <ExecutiveSection vulnerabilities={[]}></ExecutiveSection>
                <VulnsSection></VulnsSection>
                <Resultado vulnerabilities={[]} components={[]}></Resultado>
                <Pruebas vulnerabilities={[]} components={[]}></Pruebas>
                <Resulta2 vulnerabilities={[]} components={[]}></Resulta2>
                <Resultados vulnerabilities={[]} components={[]}></Resultados>
                <Conclusion vulnerabilities={[]} components={[]}></Conclusion>
                <BackPage></BackPage>
            </Document>
        </>
    )
}

export { VulnReportDocumentAquilles }
export default VulnReportDocumentAquilles
