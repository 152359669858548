import React, { useEffect, useState } from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import Vulnerability, { getCVSS3Criticality } from "@models/Vulnerability"
import styles from "../../Styles"
import VexDocumentStyles from "../../VexStyleSheet"
import { Footer, Header } from "./HeaderFooter"
import ProgressCircle from "./ProgressCircle"

const severityMap: { [key: string]: keyof SeverityCount } = {
    critical: "critical",
    high: "high",
    medium: "medium",
    low: "low"
}

interface SeverityCount {
    critical: number;
    high: number;
    medium: number;
    low: number;
    undefined: number;
  }

const suggestion = {
    critical: { color: "black", label: "CRITICAL", subtext: ", which, if not addressed as soon as possible, these vulnerabilities could trigger a cybersecurity breach." },
    high: { color: "#D9534F", label: "HIGH", subtext: ", which, if not addressed in time, these vulnerabilities could trigger a cybersecurity breach." },
    medium: { color: "#FF8537", label: "MEDIUM", subtext: ", which, if not corrected, these vulnerabilities could trigger a cybersecurity breach." },
    low: { color: "#A8D1E7", label: "LOW", subtext: ", which, if not evaluated more thoroughly, these vulnerabilities could trigger a cybersecurity breach." },
    undefined: { color: "grey", label: "UNKNOWN", subtext: ", and a more thorough analysis is recommended." }
}
const ExecutiveSection: React.FC<{ index: string, vulnerabilities:Vulnerability[]}> = ({ index, vulnerabilities }) => {
    const [severityCount, setSeverityCount] = useState<SeverityCount>({ critical: 0, high: 0, medium: 0, low: 0, undefined: 0 })
    const [severityCountGraph, setSeverityCountGraph] = useState<SeverityCount>({ critical: 0, high: 0, medium: 0, low: 0, undefined: 0 })
    const [severityLevel, setSeverityLevel] = useState<keyof SeverityCount>(severityMap.critical)
    const [empty, setEmpty] = useState<boolean>(false)

    useEffect(() => {
        const newSeverityCount: SeverityCount = { ...severityCount }

        let worstScore = -1
        let worstCriticity = severityMap.undefined
        vulnerabilities.forEach((vuln) => {
            const rating = { score: vuln.score, severity: getCVSS3Criticality(vuln.score) }
            if (rating && rating.severity && rating.severity in severityMap) {
                newSeverityCount[severityMap[rating.severity]]++
                if (worstScore < (rating?.score || 0)) {
                    worstScore = rating?.score || 0
                    worstCriticity = severityMap[rating.severity]
                }
            }
        })
        setSeverityCount(newSeverityCount)
        setSeverityLevel(worstCriticity)

        const scaleFactor = (newSeverityCount.critical + newSeverityCount.high + newSeverityCount.medium + newSeverityCount.low === 0 ? 0 : (10 / (newSeverityCount.critical + newSeverityCount.high + newSeverityCount.medium + newSeverityCount.low)))
        setSeverityCountGraph({
            critical: newSeverityCount.critical * scaleFactor,
            high: newSeverityCount.high * scaleFactor,
            medium: newSeverityCount.medium * scaleFactor,
            low: newSeverityCount.low * scaleFactor,
            undefined: 0
        })
    }, [vulnerabilities])

    useEffect(() => {
        if (Object.values(severityCountGraph).map(severity => severity / 10).filter(p => p !== 0).length === 1) {
            setEmpty(true)
        }
    }, [severityCountGraph])

    return (<Page size="A4">
        <Header></Header>
        <View style={{ ...VexDocumentStyles.section, flexDirection: "row" }}>
            <View style={styles.column}>
                <Text style={{ ...styles.titulo, fontFamily: "Bold" }}>{index}. Executive Summary</Text>
                <Text style={{ ...styles.textua, marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>The objective of the evaluation is to use our analysis methodology to identify system vulnerabilities and evaluate its level of cybersecurity.
                The assessment provides insight into the application`&apos;`s security mechanisms to resist attacks from unauthorized users and the potential for valid users to abuse their privileges and access. The summary below is a condensed approach to the main findings.
                During the course of the exercise, the number of vulnerabilities discovered was <Text style={{ fontFamily: "Bold" }}>{ severityCount.critical } critical</Text>, <Text style={{ fontFamily: "Bold", color: "#7940A5" }}>{ severityCount.high } high</Text>, <Text style={{ fontFamily: "Bold", color: "#FF8537" }}>{ severityCount.medium } medium</Text> and <Text style={{ fontFamily: "Bold", color: "#A8D1E7" }}>{ severityCount.low } low</Text>.</Text>
                <Text style={{ ...styles.textua, marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>The current state of the identified vulnerabilities establishes the risk at a level <Text style={{ fontFamily: "Bold", color: suggestion[severityLevel].color }}>{suggestion[severityLevel].label}</Text>{suggestion[severityLevel].subtext}</Text>
                <Text style={{ ...styles.textua, marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>A more detailed description of the vulnerabilities can be found in the section <Text style={{ fontFamily: "Bold" }}>Identification of vulnerabilities</Text> where each of them is described.</Text>
            </View>
            <View style={styles.column}>
                <View style={{ ...styles.rightImage, marginTop: -40, height: 700, marginLeft: 30, marginRight: -20, paddingTop: -200, marginBottom: -100, backgroundColor: "#fffa37", flexDirection: "row" }}>
                    <View style={{ ...styles.column, top: 50 }}>
                        <Image style={{ ...styles.rightImage, marginLeft: 20, paddingTop: 0, marginTop: 39.5, height: 90, width: 48 }} src="./assets/img/report/ColourArrows3.jpg" />
                        <Text style={{ ...styles.titulo, paddingLeft: 70, marginTop: 6, fontFamily: "Bold", fontSize: 16 }}>Vulnerabilities: </Text>
                        <Text style={{ ...styles.titulo, paddingLeft: 70, marginTop: 6, fontFamily: "Griff", fontSize: 12 }}><Text style={{ fontFamily: "Bold", fontSize: 22 }}>{ severityCount.critical }</Text> CRITICAL</Text>
                        <Text style={{ ...styles.titulo, paddingLeft: 70, marginTop: 6, fontFamily: "Griff", fontSize: 12 }}><Text style={{ fontFamily: "Bold", fontSize: 22 }}>{ severityCount.high }</Text> HIGH</Text>
                        <Text style={{ ...styles.titulo, paddingLeft: 70, marginTop: 6, fontFamily: "Griff", fontSize: 12 }}><Text style={{ fontFamily: "Bold", fontSize: 22 }}>{ severityCount.medium }</Text> MEDIUM</Text>
                        <Text style={{ ...styles.titulo, paddingLeft: 70, marginTop: 6, fontFamily: "Griff", fontSize: 12 }}><Text style={{ fontFamily: "Bold", fontSize: 22 }}>{ severityCount.low }</Text> LOW</Text>
                        <Text style={{ marginLeft: 70, marginRight: 0, paddingTop: 20, marginBottom: 200, paddingRight: 30, textAlign: "left", fontFamily: "Griff", fontSize: 15 }}>The current state of the identified vulnerabilities establishes the risk at a level <Text style={{ fontFamily: "Bold", color: suggestion[severityLevel].color }}>{suggestion[severityLevel].label}</Text></Text>
                        <ProgressCircle Low = {severityCountGraph.low} Medium = {severityCountGraph.medium} High = {severityCountGraph.high} Critical = {severityCountGraph.critical} Unknown = {0} None={0} Empty={empty} ></ProgressCircle>
                    </View>
                </View>
            </View>
        </View>
        <Footer></Footer>
    </Page>)
}

export { ExecutiveSection }
