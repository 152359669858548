import React, { useEffect, useState } from "react"
import { Link, Page, Text, View } from "@react-pdf/renderer"
import { CVSS3CriticalityKeysValueOps } from "@models/Vulnerability"
import { ComponentCycloneDX, VulnerabilityCycloneDX } from "@models/VulnerabilityReport"
import { CWES } from "@models/CWE"
import styles from "../../Styles"
import { Footer, Header } from "./HeaderFooter"
import VexDocumentStyles from "../../VexStyleSheet"

const severityColors: { [key: string]: string } = {
    critical: "black",
    high: "#D9534F",
    medium: "#FF8537",
    low: "#A8D1E7",
    undefined: "grey"
}
export const introduccion = [
    { id: "Verde: ", description: "En todas las ejecuciones todos los tests del protocolo han tenido resultado Normal." },
    { id: "Ámbar: ", description: "Se ha producido al menos un Warning en al menos una de las ejecuciones de al menos uno de los tests." },
    { id: "Rojo: ", description: "Se ha producido al menos un Failure en al menos una de las ejecuciones de al menos uno de los tests. Un Failure en el monitor T indica que el test no puede reconectarse al DUT para continuar su ejecución." }
]
export const anomalías = [
    { id: "No conformidades graves: ", description: "No se han detectado anomalías. Ver que el monitor L1 está en estado Normal." },
    { id: "No conformidades leves: ", description: "No se han detectado anomalías." },
    { id: "Anomalías asumibles: ", description: "Debidas a Warnings en la función de red TP1 en el test TCP Data Grammar." },
    { id: "Errores de ejecución: ", description: "Debidos a Failures en el monitor T en el test TCP Data Grammar." }
]

const Resultado: React.FC<{ vulnerabilities:VulnerabilityCycloneDX[], components: ComponentCycloneDX[] }> = ({ vulnerabilities, components }) => {
    const [componentsByMap, setComponentsByMap] = useState<{[key: string]: ComponentCycloneDX}>({})

    useEffect(() => {
        setComponentsByMap(components.reduce((acc, current) => {
            acc[current["bom-ref"]] = current
            return acc
        }, {} as {[key: string]: ComponentCycloneDX}))
    }, [components])

    return (
        <Page size="A4">
            <Header></Header>
            <Text style={{ ...styles.textua, fontFamily: "Bold", marginLeft: 12, fontSize: 12, marginRight: 0 }}>5.2. Resultados</Text>
            <Text style={{ ...styles.textua, marginRight: 50, fontFamily: "Griff", fontSize: 10 }}>La Tabla 3 muestra los resultados agregados por protocolo y monitor (T: Test monitor, A1: ARP Monitor, I1: ICMP Monitor, L1: LinkState monitor, TP1: TCP Ports Monitor) de la ejecución final, donde los colores significan:</Text>
            {introduccion.map((point, index) => {
                let color
                switch (index) {
                case 0:
                    color = "green"
                    break
                case 1:
                    color = "orange"
                    break
                case 2:
                    color = "red"
                    break
                default:
                    color = "black" // default color if there are more than three points
                }
                return (
                    <Text key={index} style={{ ...styles.puntuak, marginRight: 50, fontFamily: "Griff", fontSize: 10, paddingTop: 5 }}>
                        <Text style={styles.puntuak}>• &nbsp;</Text>
                        <Text style={{ ...styles.puntuak, fontFamily: "Bold", color }}>{point.id}</Text>
                        <Text style={styles.puntuak}>{point.description}</Text>
                    </Text>
                )
            })}
            <View style={{ ...styles.tableScope, marginTop: 13, width: "90%" }}>
                <View style={{ ...styles.tableRowScope, fontFamily: "Bold", textAlign: "center" }}>
                    <Text style={{ ...styles.tableColScope, backgroundColor: "transparent", borderWidth: 0, borderBottomWidth: 0, borderRightWidth: 0, width: "26%" }}></Text>
                    <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 1 }}>Monitor</Text>
                </View>
                <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#e7e7e7", width: "130%", justifyContent: "center", fontWeight: "bold" }}>Protocolo</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#e7e7e7", justifyContent: "center", fontWeight: "bold" }}>T</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#e7e7e7", justifyContent: "center", fontWeight: "bold" }}>A1</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#e7e7e7", justifyContent: "center", fontWeight: "bold" }}>I1</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#e7e7e7", justifyContent: "center", fontWeight: "bold" }}>L1</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 1, backgroundColor: "#e7e7e7", justifyContent: "center", fontWeight: "bold" }}>TP1</Text>
                </View>
                <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#e7e7e7", width: "130%", justifyContent: "center", fontWeight: "bold" }}>Ethernet</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", justifyContent: "center", fontWeight: "light" }}>N/A</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 1, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                </View>
                <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#e7e7e7", width: "130%", justifyContent: "center", fontWeight: "bold" }}>ARP</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", justifyContent: "center", fontWeight: "light" }}>N/A</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 1, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                </View>
                <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#e7e7e7", width: "130%", justifyContent: "center", fontWeight: "bold" }}>LLDP</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", justifyContent: "center", fontWeight: "light" }}>N/A</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 1, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                </View>
                <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#e7e7e7", width: "130%", justifyContent: "center", fontWeight: "bold" }}>IP</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", justifyContent: "center", fontWeight: "light" }}>N/A</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 1, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                </View>
                <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#e7e7e7", width: "130%", justifyContent: "center", fontWeight: "bold" }}>ICMP</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", justifyContent: "center", fontWeight: "light" }}>N/A</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 1, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                </View>
                <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#e7e7e7", width: "130%", justifyContent: "center", fontWeight: "bold" }}>IGMP</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "transparent", justifyContent: "center", fontWeight: "light" }}>N/A</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 1, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                </View>
                <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#e7e7e7", width: "130%", justifyContent: "center", fontWeight: "bold" }}>TCP</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 0, borderRightWidth: 1, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                </View>
                <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff" }}>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 1, borderRightWidth: 0, backgroundColor: "#e7e7e7", width: "130%", justifyContent: "center", fontWeight: "bold" }}>HTTP</Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 1, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 1, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 1, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 1, borderRightWidth: 0, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                    <Text style={{ ...styles.tableColScope, fontFamily: "Bold", borderBottomWidth: 1, borderRightWidth: 1, backgroundColor: "#c2f09d", justifyContent: "center", fontWeight: "bold" }}></Text>
                </View>

            </View>
            <Text style={{ ...styles.textua, marginBottom: 30, marginLeft: "25%", marginRight: "-50%", fontFamily: "Griff", fontSize: 8, marginTop: 3, color: "gray" }} >Tabla 3. Resultados agregados por protocolo y monitor</Text>
            <Text style={{ ...styles.textua, marginRight: 50, fontFamily: "Griff", fontSize: 10 }}>En resumen, las anomalías detectadas según categoría son:</Text>
            {anomalías.map((point, index) => (
                <Text key={index} style={{ ...styles.puntuak, marginRight: 50, fontFamily: "Griff", fontSize: 10, paddingTop: 5 }}>
                    <Text style={styles.puntuak}>• &nbsp;</Text>
                    <Text style={{ ...styles.puntuak, fontFamily: "Bold" }}>{point.id}</Text>
                    <Text style={styles.puntuak}>{point.description}</Text>
                </Text>
            ))}
            <Text style={{ ...styles.textua, marginRight: 50, fontFamily: "Griff", fontSize: 10 }}>Para mayor detalle en los resultados, consulte el apartado 5.2.</Text>
            <Footer></Footer>
        </Page>
    )
}

const VulnerabilityRow: React.FC<{ vulnerability: VulnerabilityCycloneDX, index: number, component: ComponentCycloneDX }> = ({ vulnerability, index, component }) => (
    <View style={VexDocumentStyles.row} wrap={false}>
        <View style={{ ...VexDocumentStyles.col, width: "10%", borderRightWidth: 0, borderTopWidth: 0 }}>
            <Link src={`#SCA-${String(index + 1).padStart(3, "0")}`} style={{ textDecoration: "none", color: "black" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", borderBottomWidth: 0 }}>{`SCA-${String(index + 1).padStart(3, "0")} `}</Text></Link>
        </View>
        <View style={{ ...VexDocumentStyles.col, width: "10%", borderRightWidth: 0, borderTopWidth: 0 }}>
            <Link href={`https://nvd.nist.gov/vuln/detail/${vulnerability.id}`} style={{ textDecoration: "none", color: "black" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Helvetica", width: "100%" }}> {vulnerability.id } </Text></Link>
        </View>
        <View style={{ ...VexDocumentStyles.col, width: "60%", borderRightWidth: 0, borderTopWidth: 0 }}>
            <Link href={vulnerability.cwes?.length > 0 ? `https://cwe.mitre.org/data/definitions/${vulnerability.cwes[0]}.html` : ""} style={{ textDecoration: "none", color: "black" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff" }}>{vulnerability.cwes?.length > 0 ? CWES[vulnerability.cwes[0]] : "Insufficient Information"}</Text></Link>
        </View>
        <View style={{ ...VexDocumentStyles.col, width: "10%", borderRightWidth: 0, borderTopWidth: 0 }}>
            <Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", color: severityColors[vulnerability.ratings.find(r => r.method === "CVSSv3")?.severity || "undefined"] }}>{CVSS3CriticalityKeysValueOps.find(v => v.value === vulnerability.ratings.find(r => r.method === "CVSSv3")?.severity)?.label}</Text>
        </View>
        <View style={{ ...VexDocumentStyles.col, width: "10%", borderTopWidth: 0 }}>
            <Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold" }}>{component != null
                ? `${component.name} - ${component.version} `
                : null}</Text>
        </View>
    </View>
)

const VulnsListDetail: React.FC<{ vulnerabilities:VulnerabilityCycloneDX[], components: ComponentCycloneDX[] }> = ({ vulnerabilities, components }) => {
    const [componentsByMap, setComponentsByMap] = useState<{[key: string]: ComponentCycloneDX}>({})

    useEffect(() => {
        setComponentsByMap(components.reduce((acc, current) => {
            acc[current["bom-ref"]] = current
            return acc
        }, {} as {[key: string]: ComponentCycloneDX}))
    }, [components])

    return (
        <Page size="A4">
            <Header></Header>
            <Text style={{ ...styles.textua, fontFamily: "Bold", marginLeft: 12, fontSize: 12, marginRight: 0 }}>4.1. Servicios publicados</Text>
            <Text style={{ ...styles.textua, marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>En esta fase se descubren y se identifican todos los protocolos en los que se van a realizar los test para el diagnóstico de robustez de comunicaciones en el DUT.</Text>

            <Footer></Footer>
        </Page>
    )
}
const VulnerabilityHeaderDetail: React.FC<{ vulnerability: VulnerabilityCycloneDX; index: number, component: ComponentCycloneDX }> = ({ vulnerability, index, component }) => (
    <View key={index + 1} style={{ ...VexDocumentStyles.section }} wrap={false}>
        <Text id={`SCA-${String(index + 1).padStart(3, "0")}`} style={{ ...styles.textua, fontFamily: "Bold", fontSize: 12, marginRight: 0, paddingLeft: 5, paddingBottom: 5 }}>4.2.{index} {`SCA-${String(index + 1).padStart(3, "0")} `}</Text>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 10, paddingBottom: -3, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>CVE</Text></View>
            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 12, paddingBottom: 0, paddingLeft: 5 }}><Link href={`https://nvd.nist.gov/vuln/detail/${vulnerability.id}`} style={{ textDecoration: "none", color: "black" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9 }}> {vulnerability.id} </Text></Link></View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>Name</Text></View>
            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", fontSize: 9, padding: 0, paddingTop: 5, paddingBottom: 0, paddingLeft: 5 }}><Link href={vulnerability.cwes?.length > 0 ? `https://cwe.mitre.org/data/definitions/${vulnerability.cwes[0]}.html` : ""} style={{ textDecoration: "none", color: "black" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9 }}>{vulnerability.cwes?.length > 0 ? CWES[vulnerability.cwes[0]] : "Insufficient Information"}</Text></Link></View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>Criticality</Text></View>
            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 9, marginBottom: 0 }}>{CVSS3CriticalityKeysValueOps.find(v => v.value === vulnerability.ratings.find(r => r.method === "CVSSv3")?.severity)?.label}</Text></View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>CVSS</Text></View>
            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 6, paddingBottom: 0, paddingLeft: 5 }}>
                <View>
                    <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 0 }}>{(vulnerability.ratings as any)[0].score} {(vulnerability.ratings as any)[0].vector}</Text>
                </View>
            </View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>Affected assets</Text></View>
            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 10, paddingBottom: 0, paddingLeft: 5 }}>
                <Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 9, marginBottom: 0 }}>
                    {component != null
                        ? `${component.name} - ${component.version} `
                        : null}
                </Text>
            </View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>Description</Text></View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5, paddingRight: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 2 }}>{vulnerability.description}</Text></View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>Solution</Text></View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5, paddingRight: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 0 }}>N/A</Text></View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>Reference</Text></View>
            <View style={{ ...VexDocumentStyles.col, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 0, paddingLeft: 5 }}><Link href={`https://nvd.nist.gov/vuln/detail/${vulnerability.id}`} style={{ textDecoration: "none", color: "black" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 2 }}> {`https://nvd.nist.gov/vuln/detail/${vulnerability.id}`} </Text></Link></View>
        </View>
    </View>
)

export { Resultado, VulnsListDetail }
