import React, { useContext, useState } from "react"
import {
    Box,
    Button,
    Checkbox,
    Chip,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    Pagination,
    PaginationItem,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography,
    useTheme
} from "@mui/material"
import { ComplianceResult, FunctionalRequirementCategory, QuestionType, Result } from "@models/Compliance"
import { Gauge } from "@mui/x-charts/Gauge"
import CloseIcon from "@mui/icons-material/Close"
import { useNavigate } from "react-router-dom"
import ConfirmNotification from "@components/dialogs/ConfirmationDialog"
import ServicesContext from "@context/ServicesContext"
import { I18nContext } from "I18nProvider"
import { questions } from "../../components/compliance/QuestionData"

function Quiz () {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const navigate = useNavigate()

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
    const [selectedAnswers, setSelectedAnswers] = useState<{ [key: string]: any }>({})
    const [score, setScore] = useState(0)
    const [results, setResults] = useState<Result[]>([])
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedAnswers({
            ...selectedAnswers,
            [currentQuestionIndex]: event.target.value
        })
        setScore(calculateTotalScore() / (questions.length * 10))
    }

    const handlePaginationChange = (_: React.ChangeEvent<unknown>, page: number) => {
        setCurrentQuestionIndex(page - 1)
        setScore(calculateTotalScore() / (questions.length * 10))
    }

    const currentQuestion = questions[currentQuestionIndex]
    const formValid = (): boolean => {
        return true
    }

    const complianceService = useContext(ServicesContext).complianceService
    const saveHandler = async () => {
        try {
            const registry: any = [{
                Time: new Date().toISOString(),
                ...Array.from({ length: 21 }, (_, i) => `Q${i + 1}`)
                    .reduce((acc, key, index) => ({ ...acc, [key]: selectedAnswers[index] }), {})
            }]
            await complianceService.create({
                id: "",
                conclusion: "",
                registry,
                clientID: ""
            })
        } catch (e: any) {
            // Handle error
        }
    }
    const setSelectedAnswer = (answerOption: Result) => {
        setSelectedAnswers({
            ...selectedAnswers,
            [currentQuestionIndex]: answerOption.value
        })
        questions[currentQuestionIndex].totalValue = answerOption.value
        setResults([...results, answerOption])
    }
    const getPaginationItemColor = (result: ComplianceResult |null): string => {
        if (result === null) {
            return "primary"
        }
        return colorMap[result]
    }
    type ColorMapType = {
        [key in ComplianceResult]: string;
      };
    const colorMap : ColorMapType = {
        [ComplianceResult.NonCompliant]: "#D9534F",
        [ComplianceResult.RequiredImprovements]: "orange",
        [ComplianceResult.PartiallyCompliant]: "yellow",
        [ComplianceResult.FullyCompliant]: "green"
    }
    type ColorMapCategoryType = {
        [key in FunctionalRequirementCategory]: string;
      };
    const colorCategoryMap : ColorMapCategoryType = {
        [FunctionalRequirementCategory.Security]: theme.palette.primary.main,
        [FunctionalRequirementCategory.Vulnerability]: theme.palette.primary.main
    }

    const calculateTotalScore = () => {
        const totalScore = questions.reduce((accumulator, currentQuestion) => {
            return accumulator + currentQuestion.totalValue
        }, 0)
        return totalScore
    }

    return (
        <Grid item>
            <Grid item xs container flexDirection="column" spacing="20px">
                <Grid item container flexDirection="column" rowGap="35px">
                    <Grid item container>
                        <Box sx={{ borderRadius: "10px", border: "1px solid #6D6C6F", width: "100%", padding: "25px" }}>
                            <Grid container xs={12} flexDirection="row"justifyContent="space-between">

                                <Grid item>
                                    <Chip
                                        label={currentQuestion.category}
                                        sx={{
                                            backgroundColor: colorCategoryMap[currentQuestion.category],
                                            color: theme.palette.secondary.contrastText,
                                            fontWeight: "bold",
                                            borderRadius: "4px",
                                            marginTop: "5px"
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        sx={{ color: theme.palette.secondary.contrastText }}
                                        onClick={saveHandler}
                                        disabled={!formValid()}
                                    >
                                        {context.t.translate("CRA_save")}                                    </Button>
                                    <Button
                                        variant="contained"
                                        startIcon={<CloseIcon />}
                                        sx={{ ml: "10px", color: theme.palette.secondary.contrastText }}
                                        onClick={() => setOpenDeleteDialog(true)}
                                        disabled={!formValid()}
                                    >
                                        {context.t.translate("CRA_cancel")}
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} flexDirection="row" >
                                <Grid item flexDirection="row">
                                    <Grid container flexDirection="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ marginTop: "10px" }}>
                                        <Grid item xs={12}>
                                            <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
                        Question {currentQuestionIndex + 1} : {currentQuestion.title}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography fontSize="20px" fontFamily="Griff">
                                                {currentQuestion.description}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container xs={12} flexDirection="column">
                                <Grid item>
                                    <Divider sx={{ height: "1px", width: "100%", mt: "10px", background: theme.palette.secondary.dark }} />
                                </Grid>
                                <Grid container flexDirection="row" alignItems="center" spacing="40px" marginLeft="40px">
                                    <Grid item xs={12}>
                                        {currentQuestion.type === QuestionType.SingleSelect && (
                                            <RadioGroup value={selectedAnswers[currentQuestionIndex] || ""} onChange={handleOptionChange}>
                                                {currentQuestion.results.map((answerOption, index) => (
                                                    <FormControlLabel
                                                        key={index}
                                                        value={answerOption.result}
                                                        control={<Radio onClick={() => setSelectedAnswer(answerOption)} sx={{
                                                            "&, &.Mui-checked": {
                                                                color: colorMap[answerOption.result]
                                                            }
                                                        }} />}
                                                        label={answerOption.result}
                                                    />
                                                ))}
                                            </RadioGroup>
                                        )}
                                        {currentQuestion.type === QuestionType.MultipleSelect && (
                                            <FormGroup>
                                                {currentQuestion.results.map((answerOption, index) => (
                                                    <FormControlLabel
                                                        key={index}
                                                        control={
                                                            <Checkbox
                                                                checked={selectedAnswers[currentQuestionIndex]?.includes(answerOption.text) || false}
                                                                onChange={(e) => {
                                                                    const value = e.target.checked
                                                                        ? [...(selectedAnswers[currentQuestionIndex] || []), answerOption.result]
                                                                        : (selectedAnswers[currentQuestionIndex] || []).filter((text: string) => text !== answerOption.text)
                                                                    setSelectedAnswers({
                                                                        ...selectedAnswers,
                                                                        [currentQuestionIndex]: value
                                                                    })
                                                                }}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={answerOption.result}
                                                    />
                                                ))}
                                            </FormGroup>
                                        )}
                                        {currentQuestion.type === QuestionType.Boolean && (
                                            <RadioGroup value={selectedAnswers[currentQuestionIndex] || ""} onChange={handleOptionChange}>
                                                <FormControlLabel value="true" control={<Radio color="primary" />} label="True" />
                                                <FormControlLabel value="false" control={<Radio color="primary" />} label="False" />
                                            </RadioGroup>
                                        )}
                                        {currentQuestion.type === QuestionType.Text && (
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                value={selectedAnswers[currentQuestionIndex] || ""}
                                                onChange={handleOptionChange}
                                                multiline
                                                rows={4}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container xs={12} flexDirection="column" alignItems="center">
                                <Stack spacing={2} marginTop="20px">

                                    <Pagination
                                        count={questions.length}
                                        color="primary"
                                        page={currentQuestionIndex + 1}
                                        onChange={handlePaginationChange}
                                        renderItem={(item) => (
                                            <PaginationItem
                                                {...item}
                                                sx={{
                                                    color: (item !== undefined && item.page !== null && results[item.page - 1] !== undefined
                                                        ? getPaginationItemColor(
                                                            results[item.page - 1].result
                                                        )
                                                        : "primary")
                                                }}
                                            />
                                        )}
                                    />
                                </Stack>
                            </Grid>
                            <Grid container xs={12} flexDirection="column" alignItems="center">
                                <Gauge width={100} height={100} value={score * 100} startAngle={-90} endAngle={90} />
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <ConfirmNotification open={openDeleteDialog} text= {context.t.translate("CRA_exit")} title={context.t.translate("CRA_closet")} handleConfirmDialog={() => { setOpenDeleteDialog(!openDeleteDialog) }} handleCloseDialog={() => { setOpenDeleteDialog(!openDeleteDialog); navigate("/compliance") }} />
            </Grid>
        </Grid>
    )
}

export default Quiz
