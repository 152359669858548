import { GridColDef, GridFilterInputDate, GridFilterInputSingleSelect, GridFilterInputValue, GridFilterModel, GridSortModel } from "@mui/x-data-grid"
import { ModelDefinition } from "@models/utils"
import { AppAbility } from "@permissions/index"
import { filterNameByTypeAndValue, FilterOperation, QueryParameters } from "@utils/index"

const convertModelDefToGridColDef = (model: ModelDefinition<any>, ability: AppAbility) => {
    const columns: GridColDef[] = model.properties
        .filter(elem => elem.isId === undefined)
        .filter(elem => elem.permission == null ? true : ability.can(elem.permission.action, elem.permission.subject))
        .map((prop) => ({
            valueGetter: ({ value, row }) => {
                if (prop.valueGetter != null) {
                    return prop.valueGetter(row)
                }
                return value
            },
            type: prop.type,
            field: prop.name,
            headerName: prop.label,
            headerAlign: "left",
            align: "left",
            valueOptions: prop.allowedValueOptions,
            minWidth: 50,
            flex: prop.flex,
            filterOperators: (Object.keys(filterNameByTypeAndValue[prop.type]) as FilterOperation[]).map((value: FilterOperation) => ({
                label: filterNameByTypeAndValue[prop.type][value],
                value: value.toString(),
                getApplyFilterFn: (_, __) => (___) => true,
                InputComponent: (prop.type === "singleSelect") ? GridFilterInputSingleSelect : ((prop.type === "date") ? GridFilterInputDate : GridFilterInputValue),
                InputComponentProps: (prop.type === "date") ? { type: "date" } : {}
            })),
            sortable: prop.type !== "struct",
            hide: prop.hide,
            renderCell: prop.render
        }
        ))
    return columns
}

function convertGridFilterModelToQueryParameters (filterModel: GridFilterModel | null, sortModel: GridSortModel, paginationModel: {page: number, pageSize: number}) {
    const queryParameters: QueryParameters = {
        page_number: paginationModel.page,
        page_size: paginationModel.pageSize
    }

    if (filterModel != null && filterModel.items.length > 0) {
        queryParameters.filters = filterModel.items
            .filter(({ value }) => value != null)
            .map(({ field, operator, value }) => ({ field, operation: operator as FilterOperation, value }))
    }

    if (sortModel.length > 0) {
        const { sort, field } = sortModel[0]
        if (sort === "asc" || sort === "desc") {
            queryParameters.sortField = field
            queryParameters.sortMode = sort
        }
    }
    return queryParameters
}

export { convertModelDefToGridColDef, convertGridFilterModelToQueryParameters }
