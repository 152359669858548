import React from "react"
import Highcharts from "highcharts/highstock"
import xrange from "highcharts/modules/xrange"
import HighchartsReact from "highcharts-react-official"
import highchartsAccessibility from "highcharts/modules/accessibility"
import ReactDomServer from "react-dom/server"

highchartsAccessibility(Highcharts)
xrange(Highcharts)
interface IconProps {
    color: string
}
// #D9534F
const Icon: React.FC<IconProps> = (props:IconProps) => (<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="multiple">
        <rect id="border" opacity="0.75" x="1.29297" y="1" width="22" height="22" rx="11" stroke={props.color} strokeWidth="2"/>
        <rect id="border_2" x="5.29297" y="5" width="14" height="14" rx="7" stroke={props.color} strokeWidth="2"/>
        <rect id="border_3" x="9.29297" y="9" width="6" height="6" rx="3" stroke={props.color} strokeWidth="2"/>
    </g>
</svg>)

const XRangeChart: React.FC = () => {
    const options: Highcharts.Options = {
        title: {
            text: undefined
        },
        chart: {
            type: "xrange",
            backgroundColor: "transparent",
            height: 270,
            width: 995,
            spacingTop: 25,

            plotBorderWidth: 0
        },
        credits: { enabled: false },
        scrollbar: { enabled: false },
        rangeSelector: {
            enabled: false
        },
        navigator: {
            enabled: true,

            series: {
                type: "column",
                color: "#403F41",
                borderRadius: 0
            },
            maskFill: "rgba(255, 255, 255, 0.15)",
            xAxis: {

                tickWidth: 1,
                lineWidth: 1,
                lineColor: "#FFFFFF",
                gridLineWidth: 0,
                labels: {
                    align: "bottom",
                    style: {
                        color: "#FFFFFF",
                        opacity: 1
                    },
                    x: 0,
                    y: 15
                },
                plotLines: [
                    { // mark the weekend
                        color: "#FFFFFF",
                        width: 2,
                        value: Date.UTC(2022, 6, 12)
                    }
                ],
                plotBands: [{ // mark the weekend
                    color: "#FAE73380",
                    from: Date.UTC(2022, 5, 1),
                    to: Date.UTC(2022, 6, 18)

                }]
            }

        },
        plotOptions: {
            series: {
                showInNavigator: true
            }
        },
        xAxis: {
            range: 24 * 60 * 60,
            type: "datetime",
            labels: {
                style: {
                    color: "white",
                    font: "Roboto"
                }
            },
            plotBands: [{ // mark the weekend
                color: "#FAE73326",
                from: Date.UTC(2022, 5, 1),
                to: Date.UTC(2022, 6, 18),
                label: {
                    text: "Eval 1",
                    style: {
                        color: "#FFFFFF"
                    },
                    x: -5,
                    y: -9
                }
            }],
            plotLines: [
                { // mark the weekend
                    color: "#FAE733",
                    width: 2,
                    value: Date.UTC(2022, 5, 1)
                },
                { // mark the weekend
                    color: "#FAE733",
                    width: 2,
                    value: Date.UTC(2022, 6, 18)
                },

                { // mark the weekend
                    color: "#FFFFFF",
                    width: 2,
                    value: Date.UTC(2022, 6, 12),
                    label: {
                        x: -20,
                        y: -15,
                        text: "Today",
                        rotation: 0,
                        style: {
                            color: "#FFFFFF",
                            fontWeight: "bold"
                        }
                    }
                }]
        },
        yAxis: {
            min: 0,
            max: 3,
            title: {
                text: ""
            },
            gridLineColor: "#333333",
            opposite: false,
            categories: ["VIT", "CRT", "Functional", "Vulnerabilities"],
            labels: {
                style: {
                    color: "white",
                    font: "Roboto",
                    fontWeight: "700"
                }
            }
        },
        series: [{
            // showInNavigator: true,
            // pointPadding: 0,
            // groupPadding: 0,
            // borderColor: "gray",
            // pointWidth: 20,
            data: [
                {
                    x: Date.UTC(2022, 0, 1),
                    y: 3
                },
                {
                    x: Date.UTC(2022, 11, 1),
                    y: 3
                },
                // Vulnerabilities
                {
                    x: Date.UTC(2022, 5, 6),
                    y: 3,
                    marker: {
                        radius: 8,
                        lineColor: "#7940A5",
                        fillColor: "#7940A5",
                        lineWidth: 1,
                        symbol: "circle",
                        fillOpacity: 0.5
                    }
                }, {
                    x: Date.UTC(2022, 5, 9),
                    y: 3,
                    marker: {
                        radius: 8,
                        lineColor: "#D9534F",
                        fillColor: "#D9534F",
                        lineWidth: 1,
                        symbol: "circle",
                        fillOpacity: 0.5
                    }
                }, {
                    x: Date.UTC(2022, 5, 10),
                    y: 3,
                    marker: {
                        radius: 8,
                        lineColor: "#EC971F",
                        fillColor: "#EC971F",
                        lineWidth: 1,
                        symbol: "circle",
                        fillOpacity: 0.5
                    }
                }, {
                    x: Date.UTC(2022, 5, 14),
                    y: 3,
                    marker: {
                        radius: 8,
                        lineColor: "#A9A9A9",
                        fillColor: "#A9A9A9",
                        lineWidth: 1,
                        symbol: "circle",
                        fillOpacity: 0.5
                    }
                }, {
                    x: Date.UTC(2022, 5, 17),
                    y: 3,
                    marker: {
                        radius: 7,
                        lineColor: "#EC971F",
                        fillColor: "#EC971F",
                        lineWidth: 1,
                        symbol: "circle",
                        fillOpacity: 0.5
                    }
                },
                {
                    x: Date.UTC(2022, 5, 22),
                    y: 3,
                    marker: {
                        symbol: `url(data:image/svg+xml;base64,${btoa(ReactDomServer.renderToStaticMarkup(<Icon color="#F2CC0C"></Icon>))})`
                    }
                },
                {
                    x: Date.UTC(2022, 5, 28),
                    y: 3,
                    marker: {
                        symbol: `url(data:image/svg+xml;base64,${btoa(ReactDomServer.renderToStaticMarkup(<Icon color="#D9534F"></Icon>))})`
                    }
                },
                // Functional
                {
                    x: Date.UTC(2022, 5, 6),
                    y: 2,
                    marker: {
                        radius: 8,
                        lineColor: "#4DB04A",
                        fillColor: "#4DB04A",
                        lineWidth: 1,
                        symbol: "circle",
                        fillOpacity: 0.5
                    }
                }, {
                    x: Date.UTC(2022, 5, 9),
                    y: 2,
                    marker: {
                        symbol: `url(data:image/svg+xml;base64,${btoa(ReactDomServer.renderToStaticMarkup(<Icon color="#4DB04A"></Icon>))})`
                    }
                }, {
                    x: Date.UTC(2022, 5, 10),
                    y: 2,
                    marker: {
                        radius: 8,
                        lineColor: "#4DB04A",
                        fillColor: "#4DB04A",
                        lineWidth: 1,
                        symbol: "circle",
                        fillOpacity: 0.5
                    }
                }, {
                    x: Date.UTC(2022, 5, 14),
                    y: 2,
                    marker: {
                        radius: 8,
                        lineColor: "#4DB04A",
                        fillColor: "#4DB04A",
                        lineWidth: 1,
                        symbol: "circle",
                        fillOpacity: 0.5
                    }
                },
                // CRT
                {
                    x: Date.UTC(2022, 5, 17),
                    y: 1,
                    marker: {
                        radius: 7,
                        lineColor: "#E51A1D",
                        fillColor: "#E51A1D",
                        lineWidth: 1,
                        symbol: "circle",
                        fillOpacity: 0.5
                    }
                },
                {
                    x: Date.UTC(2022, 5, 20),
                    y: 1,
                    marker: {
                        radius: 7,
                        lineColor: "#4DB04A",
                        fillColor: "#4DB04A",
                        lineWidth: 1,
                        symbol: "circle",
                        fillOpacity: 0.5
                    }
                },
                {
                    x: Date.UTC(2022, 5, 22),
                    y: 1,
                    marker: {
                        symbol: `url(data:image/svg+xml;base64,${btoa(ReactDomServer.renderToStaticMarkup(<Icon color="#FFFFFF"></Icon>))})`
                    }
                },
                {
                    x: Date.UTC(2022, 5, 23),
                    y: 1,
                    marker: {
                        radius: 7,
                        lineColor: "#E51A1D",
                        fillColor: "#E51A1D",
                        lineWidth: 1,
                        symbol: "circle",
                        fillOpacity: 0.5
                    }
                },
                {
                    x: Date.UTC(2022, 6, 15),
                    y: 1,
                    marker: {
                        radius: 7,
                        lineColor: "#FF8000",
                        fillColor: "#FF8000",
                        lineWidth: 1,
                        symbol: "circle",
                        fillOpacity: 0.5
                    }
                }, {
                    x: Date.UTC(2022, 6, 18),
                    y: 1,
                    marker: {
                        radius: 7,
                        lineColor: "#FF8000",
                        fillColor: "#FF8000",
                        lineWidth: 1,
                        symbol: "circle",
                        fillOpacity: 0.5
                    }
                },
                // VIT
                {
                    x: Date.UTC(2022, 5, 18),
                    y: 0,
                    marker: {
                        symbol: `url(data:image/svg+xml;base64,${btoa(ReactDomServer.renderToStaticMarkup(<Icon color="#FFFFFF"></Icon>))})`
                    }
                },
                {
                    x: Date.UTC(2022, 5, 19),
                    y: 0,
                    marker: {
                        radius: 7,
                        lineColor: "#4DB04A",
                        fillColor: "#4DB04A",
                        lineWidth: 1,
                        symbol: "circle",
                        fillOpacity: 0.5
                    }
                },
                {
                    x: Date.UTC(2022, 5, 20),
                    y: 0,
                    marker: {
                        radius: 7,
                        lineColor: "#E51A1D",
                        fillColor: "#E51A1D",
                        lineWidth: 1,
                        symbol: "circle",
                        fillOpacity: 0.5
                    }
                },
                {
                    x: Date.UTC(2022, 6, 13),
                    y: 0,
                    marker: {
                        radius: 7,
                        lineColor: "#FF8000",
                        fillColor: "#FF8000",
                        lineWidth: 1,
                        symbol: "circle",
                        fillOpacity: 0.5
                    }
                }
            ],
            dataLabels: {
                enabled: false
            },
            type: "scatter"
        }]
    }
    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                constructorType="stockChart"
                options={options}
            />
        </div>
    )
}

export { XRangeChart }
export default XRangeChart
