import React, { useContext, useEffect, useState } from "react"
import { Box, Divider, Grid, Link, Tab, Tabs, Typography, useTheme } from "@mui/material"
import CustomizedSwitches from "@components/common/switches/CustomSwitches"
import Client, { ClientSector } from "@models/Client"
import ServicesContext from "@context/ServicesContext"
import { AbilityContext } from "@components/permissions/Can"
import { TicketingList } from "@views/ticketing"
import EmailIcon from "@mui/icons-material/Email"
import PhoneIcon from "@mui/icons-material/Phone"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import { I18nContext } from "../../I18nProvider"

const ColorModeContext = React.createContext({ toggleColorMode: () => {} })

const initValue: Client = {
    id: "",
    name: "",
    description: "",
    license_validation_date: "",
    sector: ClientSector.Automotive
}

const SettingsPage = () => {
    const theme = useTheme()
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const [tabIndex, setTabIndex] = useState(0)
    const clientService = useContext(ServicesContext).clientService

    const handleTabChange = (event: any, newIndex: React.SetStateAction<number>) => {
        setTabIndex(newIndex)
    }

    const [data, setData] = useState<Client>(initValue)

    const ability = useContext(AbilityContext)
    useEffect(() => {
        const fetchData = async () => {
            if (!ability.can("read", "Client")) {
                try {
                    const clientData = await clientService.getAll({ sortField: "name" })
                    if (clientData.count > 0) {
                        setData(clientData.list[0])
                    } else {
                        setData(initValue)
                    }
                } catch (error) {
                    console.error("Error fetching client data:", error)
                }
            }
        }
        fetchData()
    }, [])

    return (
        <Grid container flexDirection="row">
            <Tabs value={tabIndex} onChange={handleTabChange} centered sx={{ justifyContent: "space-around", width: "100%" }}>
                <Tab label={context.t.translate("setting_info")} />
                <Tab label={context.t.translate("setting_billing")} />
            </Tabs>
            <Grid container flexDirection="column" >
                <Grid item xs={12} padding="30px">
                    {tabIndex === 0 && (
                        <Grid container flexDirection="column" justifyItems="space-between" spacing={2}>
                            <Grid item>
                                <Typography variant="h6">{context.t.translate("setting_info")}</Typography>
                                <CustomizedSwitches />

                                {/* <FormControl fullWidth margin="normal">
                                    <LanguageSelector></LanguageSelector>
                                </FormControl> */}
                            </Grid>
                            <Grid item>
                                <Typography variant="h6">{context.t.translate("setting_support")}</Typography>
                                <Typography>
                                    {context.t.translate("setting_check_subscription")}:{" "}
                                    <Link href="https://orbik-cybersecurity.com/my-orbik/" target="_blank" rel="noopener noreferrer">
                    https://orbik-cybersecurity.com/my-orbik/
                                    </Link>
                                </Typography>
                                <Typography>{context.t.translate("setting_send_email")}</Typography>
                                <Typography>
                                    <EmailIcon sx={{ verticalAlign: "middle", marginRight: "5px" }} />
                                    <Typography component="span" sx={{ fontWeight: "bold" }}>
                    info@orbik-cybersecurity.com
                                    </Typography>
                                </Typography>
                                <Typography>
                                    <PhoneIcon sx={{ verticalAlign: "middle", marginRight: "5px" }} />
                                    <Typography component="span" sx={{ fontWeight: "bold" }}>
                    634 753 615
                                    </Typography>
                                </Typography>
                                <Typography>
                                    <LinkedInIcon sx={{ verticalAlign: "middle", marginRight: "5px" }} />
                                    <Link href="https://www.linkedin.com/company/orbik-cybersecurity/" target="_blank" rel="noopener noreferrer">
                                    LinkdIn
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    {tabIndex === 1 && (
                        <Box>
                            <Typography variant="h6">{context.t.translate("setting_billing")}</Typography>
                            <Box mt={2} mb={2}>
                                <Typography variant="h6">{context.t.translate("setting_plan")}</Typography>
                                {data.license_validation_date
                                    ? (
                                        <Typography>
                                            {context.t.translate("setting_license_pro")}{data.license_validation_date}.
                                        </Typography>
                                    )
                                    : (
                                        <Typography>
                                            {context.t.translate("setting_license_free")}
                                        </Typography>

                                    )}
                            </Box>
                            <Divider />

                            <Divider />
                            <Box mt={2} mb={2}>
                                <Typography variant="h6">{context.t.translate("setting_ticket")}</Typography>
                                <TicketingList/>
                            </Box>
                        </Box>
                    )}
                </Grid>

            </Grid>
        </Grid>

    )
}
export default SettingsPage
