import { VulnerabilityIndicator } from "@components/common/indicator/VulnerabilityIndicator"
import KevCheckbox from "@components/vulnerability/KevCheckbox"
import Component from "@models/Component"
import { ModelDefinition } from "@models/utils"

const ComponentTableDefinition: ModelDefinition<Component> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 0.3, isId: true },
        { name: "bom_ref", label: "Bom Ref", type: "string", flex: 0.25 },
        { name: "name", label: "Name", type: "string", flex: 0.25 },
        { name: "version", label: "Version", type: "string", flex: 0.25 },
        {
            name: "vulns_numbers",
            label: "Total Vulns",
            type: "struct",
            flex: 0.5,
            render: (params) => <VulnerabilityIndicator
                critical={params.value ? params.value.critical : 0}
                high={params.value ? params.value.high : 0}
                medium={params.value ? params.value.medium : 0}
                low={params.value ? params.value.low : 0}
                none={params.value ? params.value.none : 0}
            />
        },
        { name: "outdated", label: "Outdated", type: "string", flex: 0.1, render: KevCheckbox }
    ]
}

export default ComponentTableDefinition
