import React, { useContext } from "react"
import { SearchInput } from "@components/common/forms/index"
import { ServicesContext } from "@context/index"
import { Template } from "@models/index"
import { FilterOperation } from "@utils/index"
import { I18nContext } from "I18nProvider"

interface TemplateSelectorProps{
    value: string
    onSelect: (id: string|undefined, p: Template|null)=>void
}
const TemplateSelector: React.FC<TemplateSelectorProps> = ({ value, onSelect }) => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const templateService = useContext(ServicesContext).templateService

    return (
        <SearchInput<Template>
            margin="normal" label={context.t.translate("asess_template_name")}
            variant="filled"
            value={value}
            requesterById={async v => {
                if (v !== undefined) {
                    const client = await templateService.get(v)
                    return [client]
                }
                const pro: Template = { id: "", name: "", color: "", logo: null, client: { id: "" } }
                return [pro]
            } }
            requester={async v => {
                const client = await templateService.getAll({ filters: [{ field: "name", operation: FilterOperation.StringContains, value: v }] })
                return client.list
            }} getterID={elem => elem?.id} getterTitle={elem => `${elem.name}`} onClickElem={(v) => onSelect(v?.id, v)}></SearchInput>
    )
}

export { TemplateSelector }
export default TemplateSelector
