import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Alert, Button, Divider, FormControl, MenuItem, Select, TextField, useTheme } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { AssessmentStatusKeysValueOps } from "@components/assessment"
import TemplateSelector from "@components/template/TemplateSelector"
import { ServicesContext } from "@context/index"
import { Assessment, AssessmentStatus, AssessmentType, StatsVulnerabilities, Vulnerability } from "@models/index"
import { FilterOperation, QueryParameters } from "@utils/index"
import { convertModelDefToGridColDef, GenericTable } from "@components/common/tables"
import { GridColDef } from "@mui/x-data-grid"
import { AbilityContext } from "@components/permissions"
import { VulnerabilityTableDefinition } from "@components/vulnerability"
import { AssessmentStatusColors } from "@components/common/colors/AssessmentStatusColors"
import { AssessmentTypeColors } from "@components/common/colors/AssessmentTypeColors"
import { I18nContext } from "I18nProvider"
import moment from "moment"

const initValue: Assessment = {
    id: "",
    type: AssessmentType.CRT,
    status: AssessmentStatus.Contracted,
    contractDate: null,
    startDate: null,
    endDate: null,
    product: { id: "" },
    client: { id: "" },
    template: { id: "00000000-0000-0000-0000-000000000000" },
    analysis_status: 0
}

const AssessmentDetail: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const assessmentService = useContext(ServicesContext).assessmentService
    const vulnerabilityService = useContext(ServicesContext).vulnerabilityService
    const statsService = useContext(ServicesContext).statsService
    const [error, setError] = useState<Error|null>(null)

    const { id } = useParams<{ id: string }>()
    const [data, setData] = useState<Assessment>(initValue)
    const [formData, setFormData] = useState<Assessment>(initValue)
    const [cols, setCols] = useState<GridColDef[]>([])
    const [vulnStats, setVulnStats] = useState<StatsVulnerabilities | undefined>(undefined)
    const ability = useContext(AbilityContext)
    useEffect(() => {
        setCols(convertModelDefToGridColDef(VulnerabilityTableDefinition, ability))
    }, [])

    const formValid = (): boolean => {
        const isNotNullrules = [
            formData.id === undefined,
            formData.client.id === undefined,
            formData.contractDate === undefined,
            formData.startDate === undefined,
            formData.endDate === undefined,
            formData.contractDate === "Invalid date",
            formData.startDate === "Invalid date",
            formData.endDate === "Invalid date",
            formData.template.id === undefined
        ]
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        const isNotSameOldValues = [
            formData.type === data.type,
            formData.status === data.status,
            formData.contractDate === data.contractDate,
            formData.startDate === data.startDate,
            formData.endDate === data.endDate,
            formData.client.id === data.client.id,
            formData.template.id === data.template.id
        ]
        if (isNotSameOldValues.every(rule => rule)) {
            return false
        }
        return true
    }

    // Generic change handler for all form fields
    const handleInputChange = (e: any) => {
        let events: {target :{name: string, value: string}}[] = e
        if (!Array.isArray(e)) {
            events = [e]
        }

        setFormData(events.reduce((result, { target: { name, value } }) => {
            return { ...result, [name]: value }
        }, formData))
    }
    // Generic change handler for all form fields - END

    // Fetch assessment data from API
    useEffect(() => {
        const fecthData = async () => {
            try {
                const val = await assessmentService.get(id as string)
                setData(val)
                setFormData(val)
                const queryParameters: QueryParameters = { filters: [{ field: "assessment_id", operation: FilterOperation.EnumEqual, value: id as string }] }
                const vulnsStats = await statsService.getStatsVulnerabilitiesWithParams(queryParameters)
                setVulnStats(vulnsStats)
            } catch (e: any) {
                setError({ message: e.error } as Error)
            }
        }
        fecthData()
    }, [])
    // Fetch assessment data from API - END

    const navigate = useNavigate()
    // API Call save assessment
    const saveHandler = async () => {
        try {
            await assessmentService.update(formData.id, formData)
            setData(formData)
            setError(null)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
        }
    }
    // API Call save assessment - END

    return (<Grid item>
        <Grid item xs container flexDirection="column">
            <Typography color={theme.palette.secondary.main} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                {context.t.translate("dash_assess")}
            </Typography>
        </Grid>
        <Grid item xs container flexDirection="column" spacing="20px">
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>
                    <Box sx={{ borderRadius: "10px", border: "1px solid #6D6C6F", width: "100%", padding: "25px" }}>
                        {error && <Alert severity="error">{error.message}</Alert>}
                        <Grid container flexDirection="column" spacing="20px">
                            <Grid item flexDirection="row">
                                <Grid container flexDirection="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ marginTop: "10px" }}>
                                    <Grid item>
                                        <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
                                        </Typography>
                                    </Grid>
                                    <Grid item><Button variant="contained" sx={{ color: theme.palette.secondary.contrastText }} onClick={saveHandler} disabled={!formValid()}>{context.t.translate("product_save")}</Button></Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Divider sx={{ height: "1px", width: "100%", background: theme.palette.secondary.dark }} />
                            </Grid>
                            <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                                <Grid item xs={6}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Product Name</Typography>
                                    <FormControl sx={{ width: "100%" }} disabled>
                                        <Select name="type" size="small" value={formData.product.id} fullWidth>
                                            <MenuItem value={formData.product.id}><Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: AssessmentTypeColors[formData.type] }} >{formData.product.name}</Typography></MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <TemplateSelector value={formData.template.id} onSelect={(id, p) => {
                                        handleInputChange([{ target: { name: "template", value: { id } } }])
                                    }}></TemplateSelector>
                                </Grid>
                            </Grid>
                            <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                                <Grid item xs={12} md={6}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("product_type")}</Typography>
                                    <FormControl sx={{ width: "100%" }} disabled>
                                        <Select name="type" size="small" value={formData.type} fullWidth>
                                            <MenuItem value={formData.type}><Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: AssessmentTypeColors[formData.type] }} >{formData.type}</Typography></MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("assess_status")}</Typography>
                                    <Select disabled={!ability.can("*", "Assessment")} name="status" size="small" value={formData.status} onChange={handleInputChange} fullWidth>
                                        {AssessmentStatusKeysValueOps.map((opt, idx) =>
                                            (<MenuItem key={idx} value={opt.value}><Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: AssessmentStatusColors[AssessmentStatus[opt.label]] }}>{opt.label}</Typography></MenuItem>)
                                        )}
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid item container flexDirection="row" alignItems='center' spacing="40px">

                                <Grid item xs={12} md={4}>
                                    <DatePicker
                                        label={context.t.translate("assess_date_contrac")}
                                        value={formData.contractDate != null ? moment(formData.contractDate, "YYYY-MM-DDTHH:mm:ssZ") : null}
                                        onChange={m => handleInputChange({ target: { name: "contractDate", value: m?.format("YYYY-MM-DDTHH:mm:ssZ") } })}
                                        slots={{
                                            textField: (params) => <TextField required margin="normal"
                                                variant='filled' {...params} />
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <DatePicker
                                        label={context.t.translate("assess_date_start")}
                                        value={formData.startDate != null ? moment(formData.startDate, "YYYY-MM-DDTHH:mm:ssZ") : null}
                                        onChange={m => handleInputChange({ target: { name: "startDate", value: m?.isValid ? m?.format("YYYY-MM-DDTHH:mm:ssZ") : null } })}
                                        slots={{
                                            textField: (params) => <TextField margin="normal"
                                                variant='filled' {...params} />
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <DatePicker
                                        label={context.t.translate("assess_date_end")}
                                        value={formData.endDate != null ? moment(formData.endDate, "YYYY-MM-DDTHH:mm:ssZ") : null}
                                        onChange={m => handleInputChange({ target: { name: "endDate", value: m?.isValid ? m?.format("YYYY-MM-DDTHH:mm:ssZ") : null } })}
                                        slots={{
                                            textField: (params) => <TextField margin="normal"
                                                variant='filled' {...params} />
                                        }}
                                    />
                                </Grid>
                            </Grid>

                        </Grid>
                    </Box>
                </Grid>
            </Grid >
        </Grid >
        {/* <Grid item xs container flexDirection="column" spacing="20px" sx={{ marginTop: "20px" }}>
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>
                    <Typography fontSize="45px" fontWeight="bolder" fontFamily="Griff" sx={{ marginTop: "-20px", marginBottom: "20px" }}>Vulnerability </Typography>
                    <Box sx={{ borderRadius: "10px", border: "1px solid #6D6C6F", width: "100%", padding: "25px" }}>
                        {vulnStats != null && <Grid item container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Typography fontSize="17px" fontWeight="100" sx={{ fontVariant: "all-small-caps", marginBottom: "20px" }}>
                                MITIGATED VULNERABILITIES
                                </Typography>
                                <DonutChart3 title="Criticity" values={vulnStats.MitigatedVulnerabilities} number={vulnStats.MitigatedVulnerability}/>
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                <Typography fontSize="17px" fontWeight="100" sx={{ fontVariant: "all-small-caps", marginBottom: "20px" }}>
                                UNMITIGATED VULNERABILITIES
                                </Typography>
                                <DonutChart3 title="Criticity" values={vulnStats.UnmitigatedVulnerabilities} number={vulnStats.UnmitigatedVulnerability}/>
                            </Grid>
                        </Grid> }
                    </Box>
                </Grid>
            </Grid>
        </Grid> */}
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ marginTop: "20px" }}>
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>
                    <Typography fontSize="45px" fontWeight="bolder" fontFamily="Griff" sx={{ marginTop: "-20px", marginBottom: "20px" }}>{context.t.translate("product_vulner_list")}</Typography>
                    <GenericTable<Vulnerability> entity="Vulnerability" columns={cols}
                        dataProvider={(filter) => {
                            // Define your filter criteria here

                            const updatedFilter: QueryParameters = { ...filter, filters: [{ field: "assessment_id", operation: FilterOperation.EnumEqual, value: id as string }] }
                            return vulnerabilityService.getAll(updatedFilter)
                        }}
                        onEdit={(elem: Vulnerability) => navigate("../../vulnerability/" + elem.id)}
                        onDelete={(elem: Vulnerability) => vulnerabilityService.delete(elem.id)}
                    />
                </Grid>
            </Grid>
        </Grid>

    </Grid>
    )
}

export { AssessmentDetail }
export default AssessmentDetail
