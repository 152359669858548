import React from "react"
import { Image, Text, View } from "@react-pdf/renderer"
import Template from "@models/Template"
import styles from "./Styles"
import VexDocumentStyles from "./VexStyleSheet"

const CustomHeader: React.FC<{template: Template}> = ({ template }) => {
    let imagen
    if (template.logo != null) {
        const byteArray = Uint8Array.from(atob(template.logo.toString()), c => c.charCodeAt(0))
        const blob = new Blob([byteArray], { type: "image/png" })
        imagen = URL.createObjectURL(blob)
    }
    return (
        <View fixed>
            {(imagen !== undefined) ? (<Image style={{ width: 596, height: 250, position: "relative", backgroundColor: template.color }} src={imagen}/>) : (<Image style={{ width: 596, height: 250, position: "relative", backgroundColor: template.color }} src="./assets/img/report/custom_goiburu.png" />)}
            <Text style={{ ...styles.textoEncima, color: "black" }}>SW Composition Analysis</Text>
            <Text>&nbsp;</Text>
        </View>
    )
}

const CustomFooter: React.FC<{template: Template}> = ({ template }) => (
    <View fixed>
        <Text>&nbsp;</Text>
        <View style={{ ...VexDocumentStyles.section, marginLeft: -20, marginRight: -20, paddingTop: 0, marginBottom: 0, backgroundColor: template.color, flexDirection: "row" }}>

            <Text style={{ paddingLeft: 32, paddingRight: 150, paddingBottom: 32, paddingTop: 32, fontSize: 5.5 }}>
            The intellectual and industrial property rights over this document are the exclusive property of ORBIK Cybersecurity, S.L.
            Likewise, this report and, where applicable, any document annexed to it, contains confidential information exclusively addressed to its recipient.
                <Text style={{ fontFamily: "Bold", color: "#FFC000", backgroundColor: "black" }}>TLP-AMBER</Text> Its disclosure, copy or distribution to third parties is prohibited without the prior written authorization of ORBIK Cybersecurity.
            If you have received this report in error, proceed to delete it and immediately notify ORBIK Cybersecurity, S.L.
            </Text>
            <Text style={styles.numero} render={({ pageNumber, totalPages }) => `${pageNumber} `} />
        </View>
    </View>
)

export { CustomHeader, CustomFooter }
