import { Alert, Box, Button, CircularProgress, Divider, Grid, IconButton, MenuItem, TextField, Tooltip, Typography, useTheme } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { StyledTextarea } from "@components/common/textareas/index"
import { ClientSelector } from "@components/client/index"
import { ServicesContext } from "@context/index"
import { Assessment, AssessmentStatus, AssessmentType, Product, ProductType, ProductTypeKeys, StatsVulnerabilities } from "@models/index"
import { VulnReportDownloader, VulnReportUploader } from "@components/report/VulnReportUpDownloader"
import { convertModelDefToGridColDef, GenericTable } from "@components/common/tables"
import { GridColDef, GridRowParams } from "@mui/x-data-grid"
import { AbilityContext, Can } from "@components/permissions/index"
import { BiSolidComponent } from "react-icons/bi"
import CalculateIcon from "@mui/icons-material/Calculate"
import { FilterOperation, FilterOption, QueryParameters } from "@utils/queryParams"
import { TbAnalyze } from "react-icons/tb"
import { AssessmentTableDefinition } from "@components/assessment"
import { ProductTypeColors } from "@components/common/colors/ProductTypeColors"
import { DonutChart3 } from "@components/vulnerability"
import Hierarchy from "@models/Hierarchy"
import { MAX_DEEP_LEVEL, PATH_SEPARATOR } from "@views/hierarchy/hierarchyUtils"
import HierarchySelector from "@components/hierarchy/HierarchySelector"
import { I18nContext } from "I18nProvider"
import ProductTimeLine from "./ProductTimeLine/ProductTimeLine"

const initValue = {
    id: "",
    name: "",
    type: ProductType.Application,
    description: "",
    version: "",
    hierarchy: "",
    client: { id: "" },
    created_at: ""
}

interface TimeLineValues {
        x: number;
        name: string;
        description: string;
        version: string;
        id:string;
}

const ProductDetail: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const productService = useContext(ServicesContext).productService
    const assessmentService = useContext(ServicesContext).assessmentService
    const vulnerabilityService = useContext(ServicesContext).vulnerabilityService
    const hierarchyService = useContext(ServicesContext).hierarchyService
    const fileService = useContext(ServicesContext).fileService
    const statsService = useContext(ServicesContext).statsService

    const [error, setError] = useState<Error|null>(null)

    const { id } = useParams<{ id: string }>()
    const [data, setData] = useState<Product>(initValue)
    const [formData, setFormData] = useState<Product>(initValue)
    const [dataTime, setDataTime] = useState<TimeLineValues[]>([])

    const [cols, setCols] = useState<GridColDef[]>([])
    const [showAlert, setShowAlert] = React.useState(true)
    const [requestError, setRequestError] = React.useState<Error | null>(null)
    const [analysisSuccess, setAnalysisSuccess] = React.useState<boolean>(false)
    const [vulnStats, setVulnStats] = useState<StatsVulnerabilities | undefined>(undefined)
    const [filter, setFilter] = useState({})
    const [selectedHierarchies, setSelectedHierarchies] = useState<(Hierarchy | null)[]>(Array.from({ length: MAX_DEEP_LEVEL }, () => null))
    const ability = useContext(AbilityContext)

    const formValid = (): boolean => {
        const isNotNullrules = [
            formData.name === "",
            formData.version === "",
            formData.description === "",
            formData.client.id === undefined
        ]
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        const isNotSameOldValues = [
            formData.name === data.name,
            formData.version === data.version,
            formData.description === data.description,
            formData.type === data.type,
            formData.client.id === data.client.id,
            formData.hierarchy === data.hierarchy
        ]
        if (isNotSameOldValues.every(rule => rule)) {
            return false
        }
        return true
    }

    // Generic change handler for all form fields
    const handleChange = (e:any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    // Fetch product data from API
    useEffect(() => {
        const fecthData = async () => {
            try {
                const newCol = convertModelDefToGridColDef(AssessmentTableDefinition, ability)
                setCols(newCol)
                const val = await productService.get(id as string)
                setData(val)
                setFormData(val)

                if (val.hierarchy && val.hierarchy !== "") {
                    const pathIds = val.hierarchy.split(PATH_SEPARATOR)
                    const fetchedHierarchies = await Promise.all(pathIds.map(id => hierarchyService.get(id)))
                    setSelectedHierarchies(fetchedHierarchies.map(h => h || null))
                }

                const filterssss: QueryParameters = { filters: [{ field: "product_id", operation: FilterOperation.EnumEqual, value: id as string }] }
                const vulnsStats = await statsService.getStatsVulnerabilitiesWithParams(filterssss)

                setVulnStats(vulnsStats)
                const initialFilter = {
                    sortField: "name",
                    sortOrder: "asc"
                }
                setFilter(initialFilter)
            } catch (e: any) {
                setError({ message: e.error } as Error)
                navigate(-1)
            }
        }
        fecthData()
    }, [])
    useEffect(() => {
        const fecthData = async () => {
            try {
                const newCol = convertModelDefToGridColDef(AssessmentTableDefinition, ability)
                setCols(newCol)
                const val = await productService.get(id as string)
                setData(val)
                setFormData(val)

                if (val.hierarchy && val.hierarchy !== "") {
                    const pathIds = val.hierarchy.split(PATH_SEPARATOR)
                    const fetchedHierarchies = await Promise.all(pathIds.map(id => hierarchyService.get(id)))
                    setSelectedHierarchies(fetchedHierarchies.map(h => h || null))
                }

                const filterssss: QueryParameters = { filters: [{ field: "product_id", operation: FilterOperation.EnumEqual, value: id as string }] }
                const vulnsStats = await statsService.getStatsVulnerabilitiesWithParams(filterssss)

                setVulnStats(vulnsStats)
                const initialFilter = {
                    sortField: "name",
                    sortOrder: "asc"
                }
                setFilter(initialFilter)
            } catch (e: any) {
                setError({ message: e.error } as Error)
                navigate(-1)
            }
        }
        fecthData()
    }, [id])
    // Fetch product data from API - END
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = formData.name
                const filter: QueryParameters = { filters: [{ field: "name", operation: FilterOperation.EnumEqual, value: data as string }] }
                const allVal = await productService.getAllExt(filter)
                const timeLineValues: TimeLineValues[] = allVal.list.map(item => {
                    const date = new Date(item.created_at)
                    const year = date.getUTCFullYear()
                    const month = date.getUTCMonth()
                    const day = date.getUTCDate()

                    return {
                        x: Date.UTC(year, month, day), // Convertir la fecha a un array [year, month, day]
                        name: item.name,
                        description: item.description,
                        version: item.version,
                        id: item.id
                    }
                })
                setDataTime(timeLineValues)
            } catch (e: any) {
                setError({ message: e.error } as Error)
                navigate(-1)
            }
        }
        fetchData()
    }, [formData])

    const navigate = useNavigate()
    const handleOpenComponents = async (id: string) => {
        const filters: QueryParameters = { sortField: "name", sortMode: "desc" }

        navigate(`../../assessment/${id}/component`)
    }
    // API Call save product
    const saveHandler = async () => {
        try {
            await productService.update(formData.id, formData)
            setData(formData)
            setError(null)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
        }
    }
    // API Call save product - END

    if (data.id === "") return (<div>{error && <Alert severity="error">{error.message}</Alert>}<br></br>Loading...</div>)

    const RequestAnalyze = async (id: string) => {
        try {
            const response = await vulnerabilityService.analyzeByAssessment(id)
            setRequestError({ message: response.message } as Error)
            setAnalysisSuccess(true)
        } catch (e: any) {
            console.error(e)
            setError({ message: e.error } as Error)
            setAnalysisSuccess(false)
        }
        setShowAlert(true)
    }

    const customActions = (params: GridRowParams<Assessment>) => {
        const actions: React.JSX.Element[] = [
            <Box key="analyze" sx={{ position: "relative", display: "inline-flex", height: "100%", width: "100%", mr: "15px", top: "0px" }}>
                <CircularProgress variant="determinate" value={params.row.analysis_status === undefined ? 0 : params.row.analysis_status! * 16.6666} size={32} style={{
                    position: "absolute" as "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)"
                }}/>
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <Tooltip title="Reanalyze"><IconButton key="analyze" onClick={() => { RequestAnalyze(params.row.id) }} sx={{ width: 33, height: 33 }} ><TbAnalyze size={20}/></IconButton ></Tooltip>
                </Box>
            </Box>,
            <Can key="create" I="create" an="File" ><VulnReportUploader setError={setError} simplified={false} key="vexUploader" assessment={params.row} /></Can>,
            // <Can key="read" I="read" an="Vulnerability" ><VulnReportDownloader simplified={true} key="vexDownloader" assessment={params.row} /></Can>,
            <Can key="read" I="read" an="Vulnerability" ><VulnReportDownloader setError={setError} simplified={false} key="vexDownloader" assessment={params.row} /></Can>,

            <Can key="components" I="read" an="Vulnerability">
                <Tooltip title="Components">
                    <IconButton size="small" component="label" onClick={() => handleOpenComponents(params.id as string)}>
                        <BiSolidComponent/>
                    </IconButton>
                </Tooltip>
            </Can>
        ]
        if (params.row && params.row.status === AssessmentStatus.Ended && params.row.type === AssessmentType.SCA) {
            actions.push(
                <Can key="score" I="create" an="File" >
                    <Tooltip title="SBOM Metrics">
                        <IconButton size="small" component="label" onClick={() => navigate(`../../assessment/${params.row.id}/sbomQuality`)}>
                            <CalculateIcon/>
                        </IconButton>
                    </Tooltip>
                </Can>
            )
        }
        return actions
    }

    // Hierarchies
    const handleSelectorChange = (index: number, id: string | undefined, hierarchy: Hierarchy | null) => {
        const newSelectedHierarchies = [...selectedHierarchies]
        newSelectedHierarchies[index] = hierarchy
        newSelectedHierarchies.fill(null, index + 1, MAX_DEEP_LEVEL)
        setSelectedHierarchies(newSelectedHierarchies)

        const newPath = newSelectedHierarchies.filter(h => h !== null).map(h => h!.id).join(";")
        setFormData({ ...formData, hierarchy: newPath })
    }
    const getCustomFilters = (index: number): FilterOption[] => {
        if (index === 0) {
            return [
                { field: "client_id", operation: FilterOperation.UUIDEqual, value: formData.client.id },
                { field: "path", operation: FilterOperation.StringNotContains, value: `%${PATH_SEPARATOR}%` }
            ]
        }
        const parentHierarchy = selectedHierarchies[index - 1]
        if (!parentHierarchy) return []
        return [
            { field: "path", operation: FilterOperation.StringContains, value: `${parentHierarchy.path}${PATH_SEPARATOR}%` },
            { field: "path", operation: FilterOperation.StringNotContains, value: `${parentHierarchy.path}${PATH_SEPARATOR}%${PATH_SEPARATOR}%` }
        ]
    }

    return (<Grid item>
        <Grid item xs container flexDirection="column">
            <Typography color={theme.palette.secondary.main} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                {context.t.translate("product_title")}
            </Typography>
        </Grid>
        <Grid item xs container flexDirection="column" spacing="20px">
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>
                    <Box sx={{ borderRadius: "10px", border: "1px solid #6D6C6F", width: "100%", padding: "25px" }}>
                        {error && <Alert severity="error">{error.message}</Alert>}
                        <Grid container flexDirection="column" spacing="20px">
                            <Grid item flexDirection="row">
                                <Grid container flexDirection="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ marginTop: "10px" }}>
                                    <Grid item>
                                        <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
                                        </Typography>
                                    </Grid>
                                    <Grid item><Button variant="contained" sx={{ color: theme.palette.secondary.contrastText }} onClick={saveHandler} disabled={!formValid()}>{context.t.translate("product_save")}</Button></Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Divider sx={{ height: "1px", width: "100%", background: theme.palette.secondary.dark }} />
                            </Grid>

                            <Grid item>
                                <Divider sx={{ height: "1px", width: "100%", background: theme.palette.secondary.dark }} />
                            </Grid>
                            <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                                <Grid item xs={6}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("product_client")}</Typography>
                                    <ClientSelector value={formData.client.id} disabled={!ability.can("*", "Product")} onSelect={(id) => handleChange({ target: { name: "client", value: { id } } })}></ClientSelector>
                                </Grid>
                            </Grid>
                            <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                                <Grid item xs={3}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("product_name")}</Typography>
                                    <TextField disabled={!ability.can("*", "Product")} name="name" value={formData.name} onChange={handleChange} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("product_version")}</Typography>
                                    <TextField disabled={!ability.can("*", "Product")} name="version" value={formData.version} onChange={handleChange} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("product_type")}</Typography>
                                    <TextField disabled={!ability.can("*", "Product")} margin="normal" select required variant="standard" name="type" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}
                                        value={formData.type} onChange={handleChange}>
                                        {ProductTypeKeys.map((key, index) =>
                                            (<MenuItem key={index} value={ProductType[key]}><Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: ProductTypeColors[ProductType[key]] }}>{key}</Typography></MenuItem>)
                                        )}
                                    </TextField>
                                </Grid>
                            </Grid>
                            <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                                <Grid item xs={3}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Hierarchy</Typography>
                                    <HierarchySelector
                                        value={selectedHierarchies[0]?.id || ""}
                                        customFilters={getCustomFilters(0)}
                                        onSelect={(id, hierarchy) => handleSelectorChange(0, id, hierarchy)}
                                        index={0}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    {selectedHierarchies[0] && (
                                        <Box sx={{ mt: "40px" }}>
                                            <HierarchySelector
                                                value={selectedHierarchies[1]?.id || ""}
                                                customFilters={getCustomFilters(1)}
                                                onSelect={(id, hierarchy) => handleSelectorChange(1, id, hierarchy)}
                                                index={1}
                                            />
                                        </Box>

                                    )}
                                </Grid>
                                <Grid item xs={3}>
                                    {selectedHierarchies[1] && (
                                        <Box sx={{ mt: "40px" }}>
                                            <HierarchySelector
                                                value={selectedHierarchies[2]?.id || ""}
                                                customFilters={getCustomFilters(2)}
                                                onSelect={(id, hierarchy) => handleSelectorChange(2, id, hierarchy)}
                                                index={2}
                                            />
                                        </Box>
                                    )}
                                </Grid>
                                <Grid item xs={3}>
                                    {selectedHierarchies[2] && (
                                        <Box sx={{ mt: "40px" }}>
                                            <HierarchySelector
                                                value={selectedHierarchies[3]?.id || ""}
                                                customFilters={getCustomFilters(3)}
                                                onSelect={(id, hierarchy) => handleSelectorChange(3, id, hierarchy)}
                                                index={3}
                                            />
                                        </Box>
                                    )}
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("product_description")}</Typography>
                                <StyledTextarea minRows={3} name="description" placeholder="Enter description here..." value={formData.description} onChange={handleChange} style={{ width: "100%", boxSizing: "border-box", backgroundColor: "transparent" }}/>
                            </Grid>
                            <Grid item>
                                <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("product_versions")}</Typography>
                            </Grid>

                        </Grid>
                    </Box>
                    {/* <ProductTree/> */}
                </Grid>
            </Grid >
        </Grid >
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ marginTop: "20px" }}>
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>
                    <Typography fontSize="45px" fontWeight="bolder" fontFamily="Griff" sx={{ marginTop: "-20px", marginBottom: "20px" }}>{context.t.translate("assess_status")}</Typography>
                    <Box sx={{ borderRadius: "10px", border: "1px solid #6D6C6F", width: "100%", padding: "25px" }}>
                        <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                            <Grid item xs={6} sx={{ borderRadius: "10px", display: "flex", justifyContent: "center", alignContent: "center" }}>

                                <ProductTimeLine newenventsdata={dataTime} selected_id={id} />
                            </Grid>
                            <Grid item xs={6} sx={{ borderRadius: "10px" }}>
                                {vulnStats != null && <Grid item container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography fontSize="17px" fontWeight="100" sx={{ fontVariant: "all-small-caps", marginBottom: "20px" }}>
                                            {context.t.translate("dash_vulner_mitigated")}
                                        </Typography>
                                        <DonutChart3 title="Criticity" values={vulnStats.MitigatedVulnerabilities} number={vulnStats.MitigatedVulnerability}/>
                                    </Grid>
                                    <Grid item xs={12} sm={6} >
                                        <Typography fontSize="17px" fontWeight="100" sx={{ fontVariant: "all-small-caps", marginBottom: "20px" }}>
                                            {context.t.translate("dash_vulner_nomitigated")}
                                        </Typography>
                                        <DonutChart3 title="Criticity" values={vulnStats.UnmitigatedVulnerabilities} number={vulnStats.UnmitigatedVulnerability}/>
                                    </Grid>
                                </Grid> }
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ marginTop: "20px" }}>
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>
                    <Typography fontSize="45px" fontWeight="bolder" fontFamily="Griff" sx={{ marginTop: "-20px", marginBottom: "20px" }}>{context.t.translate("dash_assess")}</Typography>

                    <GenericTable<Assessment> entity="Assessment" columns={cols}
                        dataProvider={(filter) => {
                            // Define your filter criteria here
                            const updatedFilter: QueryParameters = { ...filter, filters: [{ field: "product_id", operation: FilterOperation.EnumEqual, value: id as string }] }
                            return assessmentService.getAllExt(updatedFilter)
                        }}
                        onEdit={(elem: Assessment) => navigate("../../assessment/" + elem.id)}
                        onDelete={(elem: Assessment) => assessmentService.delete(elem.id)}
                        customActions={customActions}
                    />
                </Grid>
            </Grid>
        </Grid>

    </Grid>
    )
}

export { ProductDetail }
export default ProductDetail
