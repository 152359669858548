import { AssessmentType } from "@models/index"

interface FileType {
    type: string
    extension: string
    mimetype: string
}
const CycloneDX: FileType = { type: "cyclonedx", extension: ".json", mimetype: "application/json" }
const PDF: FileType = { type: "pdf", extension: ".pdf", mimetype: "application/pdf" }
const NessusXML: FileType = { type: "nessus", extension: ".nessus", mimetype: "text/xml" }
const Aquilles: FileType = { type: "achilles", extension: ".xml", mimetype: "text/xml" }
const BurpHTML: FileType = { type: "burp", extension: ".html", mimetype: "text/html" }
const Acunetix: FileType = { type: "acunetix", extension: ".html", mimetype: "text/html" }

const FileTypeByAssessmentType: { [id in AssessmentType] : FileType} = {
    [AssessmentType.CRT]: Aquilles,
    [AssessmentType.SCA]: CycloneDX,
    [AssessmentType.FunctionalTest]: CycloneDX,
    [AssessmentType.LogicalPentest]: CycloneDX,
    [AssessmentType.NetworkScan]: NessusXML,
    [AssessmentType.PortScan]: BurpHTML,
    [AssessmentType.WebScan]: Acunetix
}

export type { FileType }
export { FileTypeByAssessmentType }
