import Hierarchy from "@models/Hierarchy"
import { MediaTypes } from "@services/HttpService"
import Service from "@services/abstract/BaseService"

class HierarchyService extends Service<Hierarchy> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/hierarchy", MediaTypes.JSON)
    }
}

export { HierarchyService }
export default HierarchyService
