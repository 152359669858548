import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import RateReviewIcon from "@mui/icons-material/RateReview"
import AssessmentIcon from "@mui/icons-material/Assessment"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { Alert, Box, Button, Checkbox, Chip, Divider, Grid, MenuItem, Select, Tab, Tabs, TextField, Typography, useTheme } from "@mui/material"
import { StyledTextarea } from "@components/common/textareas/index"
import { VulnerabilityJustificationKeysValueOps, VulnerabilityResponseKeysValueOps, VulnerabilityStateKeysValueOps } from "@components/vulnerability"
import { ServicesContext } from "@context/index"
import { CVSS3CriticalityKeysValueOps, getCVSS3Criticality, Vulnerability, VulnerabilityState } from "@models/index"
import CVSS3Metric from "@models/CVSS"
import CVSS3TemporalMetric from "@models/CVSSTemporal"
import CVSS3EnvironmentalMetric from "@models/CVSSEnvironmental"
import "./styles.css"
import { useTrack } from "@components/track/TrackContext"
import { I18nContext } from "I18nProvider"
import CWEDataGrid from "./CWE/CWEDataGrid"

let initValue: Vulnerability = {
    id: "",
    identifier: "",
    score: 0,
    revised_score: 0,
    priority: 0,
    epss: 0,
    percentile: 0,
    kev: false,
    vector: "",
    description: "",
    state: VulnerabilityState.Detected,
    justification: null,
    response: [],
    revised_severity: -1,
    time_spent: -1,
    cmp_name: undefined,
    cmp_version: undefined,
    assessment: { id: "" },
    product: { id: "" },
    client: { id: "" },
    origin: "",
    metadata: undefined
}

interface TabPanelProps {
    children?: any
    index: number[]
    value: number
    }

const CustomTabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props
    if (index.includes(value)) {
        return (children)
    }
    return (<div></div>)
}

interface DepPathStruct {
    n: string
    v: string
}

interface TreeElem {
    name: string
    children: string[]
}
const getColorByScore = (score: any) => {
    if (score === "None" || score === "0.0") {
        return "white"
    } else if (score >= 0.1 && score <= 3.9) {
        return "#f2cc0c"
    } else if (score >= 4.0 && score <= 6.0) {
        return "#ec971f"
    } else if (score >= 7.0 && score <= 8.9) {
        return "#d9534f"
    } else if (score >= 9.0 && score <= 10.0) {
        return "black"
    }
    return "darkgrey" // Por defecto si no coincide con ningún rango
}

const VulnerabilityDetail: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const vulnerabilityService = useContext(ServicesContext).vulnerabilityService

    const [error, setError] = useState<Error|null>(null)
    const { id } = useParams<{ id: string }>()
    const [data, setData] = useState<Vulnerability>(initValue)
    const [formData, setFormData] = useState<Vulnerability>(initValue)
    const [label, setLabel] = useState<string|undefined>("")
    const [openBaseScore, setOpenBaseScore] = React.useState(false)
    const [openTemporal, setOpenTemporal] = React.useState(false)
    const [openEnvironmental, setOpenEnvironmental] = React.useState(false)
    const [colorone, setColor] = useState<string>("#ffffff")
    const [color1, setColor1] = useState<string>("#ffffff")
    const [color2, setColor2] = useState<string>("#ffffff")

    const formValidRequest = (): boolean => {
        const isNotNullrules = [
            formData.state as any === "",
            formData.justification as any === ""
        ]
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        const isNotSameOldValues = [
            formData.state === data.state,
            formData.justification === data.justification,
            formData.response === data.response
        ]
        if (isNotSameOldValues.every(rule => rule)) {
            return false
        }
        return true
    }
    const formValidResponse = (): boolean => {
        const isNotNullrules = [
            formData.response as any === "",
            formData.revised_score as any === ""
        ]
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        const isNotSameOldValues = [
            formData.state === data.state,
            formData.justification === data.justification,
            formData.response === data.response,
            formData.revised_score === data.revised_score,
            formData.vector === data.vector
        ]
        if (isNotSameOldValues.every(rule => rule)) {
            return false
        }
        return true
    }

    const handleStateChange = (value: any) => {
        if (value === VulnerabilityState.Detected) {
            setRegistry(0)
            setColor("yellow")
            setColor1("#ffffff")
            setColor2("#ffffff")
        } else if (value === VulnerabilityState.InTriage || value === VulnerabilityState.Exploitable) {
            setRegistry(1)
            setColor1("yellow")
        } else if (value === VulnerabilityState.FalsePositive || value === VulnerabilityState.NotAffected || value === VulnerabilityState.Resolved) {
            setRegistry(2)
            setColor2("yellow")
        }
    }

    // Generic change handler for all form fields
    const handleChange = (e:any) => {
        const { name, value } = e.target
        if (name === "state") {
            handleStateChange(value)
        }
        if (name === "revised_score") {
            if (value > 10) {
                return
            }
        }
        setFormData({ ...formData, [name]: value })
    }
    // Generic change handler for all form fields - END

    // Fetch vulnerability data from API
    useEffect(() => {
        const fecthData = async () => {
            try {
                const val = await vulnerabilityService.get(id as string)
                setData(val)
                setFormData(val)
                handleStateChange(val.state)
                initValue = val
                const criticality = getCVSS3Criticality(val.revised_score)
                setLabel(CVSS3CriticalityKeysValueOps.find(v => v.value === criticality)?.label)
            } catch (e: any) {
                setError({ message: e.Errors.Response } as Error)
            }
        }
        fecthData()
    }, [])

    const handleClickAway = () => {
        setOpen(false)
    }

    // Fetch vulnerability data from API - END

    const navigate = useNavigate()
    // API Call save assessment
    const saveHandler = async () => {
        try {
            formData.revised_score = formData.state === VulnerabilityState.Resolved || formData.state === VulnerabilityState.NotAffected || formData.state === VulnerabilityState.FalsePositive ? 0 : Number(formData.revised_score)
            await vulnerabilityService.update(formData.id, formData)
            setData(formData)
            setError(null)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
        }
    }
    // API Call save assessment - END

    // TABS
    const [registry, setRegistry] = React.useState(1)

    /* const handleRegistryChange = (event: React.SyntheticEvent, newRegistry: number) => {
        setRegistry(newRegistry)
    } */

    // MODAL
    const testDataTree = {
        children: [
            {
                name: "test",
                children: []
            },
            {
                name: "test2",
                children: []
            }
        ],
        name: "Parent"
    }
    const [open, setOpen] = React.useState(false)
    const [openComponentsPath, setOpenComponentsPath] = React.useState(false)
    const { track, trackData } = useTrack()
    const handleOpen = () => {
        track({ view: "CVSSCalculator" })
        navigate("./cvss")
    }
    const handleClose = () => setOpen(false)

    // const handleCloseModal: DialogProps["onClose"] = (event, reason) => {
    //     if (reason && reason === "backdropClick") {
    //         return;
    //     }
    //     setOpen(false);
    // }
    const handleOpenComponentsPath = () => setOpenComponentsPath(true)
    const handleCloseComponentsPath = () => setOpenComponentsPath(false)
    const [CVSS3Vector, setCVSS3Vector] = React.useState("")
    const [CVSS3Score, setCVSS3Score] = React.useState(0)
    const [CVSS3BaseScore, setCVSS3BaseScore] = React.useState(0)
    const [CVSS3ImpactScore, setCVSS3ImpactScore] = React.useState(0)
    const [CVSS3ExplotailabilityScore, setCVSS3ExplotailabilityScore] = React.useState(0)
    const [CVSS3ModifiedImpactScore, setCVSS3ModifiedImpactScore] = React.useState(0)
    const [CVSS3TemporalScore, setCVSS3TemporalScore] = React.useState(0)
    const [CVSS3EnvironmentalScore, setCVSS3EnvironmentalScore] = React.useState(0)
    const [CVSS3OverallScore, setCVSS3OverallScore] = React.useState(0)

    type CVSS3MetricValues = {
        label: string
        metric: string
        value: number
    }
    type CVSS3MetricI = {
        AV: CVSS3MetricValues | null
        AC: CVSS3MetricValues | null
        PR: {
            label: string
            metric: string
            value: number | { U: number, C: number }
        } | null
        UI: CVSS3MetricValues | null
        S: CVSS3MetricValues | null
        C: CVSS3MetricValues | null
        I: CVSS3MetricValues | null
        A: CVSS3MetricValues | null
    }

    type CVSS3TemporalMetricI = {
        E: CVSS3MetricValues | null
        RL: CVSS3MetricValues | null
        RC: CVSS3MetricValues | null
    }

    type CVSS3EnvironmentalMetricI = {
        MAV: CVSS3MetricValues | null
        MAC: CVSS3MetricValues | null
        MPR: {
            label: string
            metric: string
            value: number | { U: number, C: number }
        } | null
        MUI: CVSS3MetricValues | null
        MS: CVSS3MetricValues | null
        MC: CVSS3MetricValues | null
        MI: CVSS3MetricValues | null
        MA: CVSS3MetricValues | null
        CR: CVSS3MetricValues | null
        IR: CVSS3MetricValues | null
        AR: CVSS3MetricValues | null
    }
    // Almacena los value de cada metrica

    const [CVSS3Metrics, setCVSS3Metrics] = useState<CVSS3MetricI>({
        AV: CVSS3Metric.AV.values.N,
        AC: CVSS3Metric.AC.values.L,
        PR: CVSS3Metric.PR.values.N,
        UI: CVSS3Metric.UI.values.N,
        S: CVSS3Metric.S.values.U,
        C: CVSS3Metric.C.values.N,
        I: CVSS3Metric.I.values.N,
        A: CVSS3Metric.A.values.N
    })

    const [CVSS3TemporalMetrics, setCVSS3TemporalMetrics] = useState<CVSS3TemporalMetricI>({
        E: CVSS3TemporalMetric.E.values.X,
        RL: CVSS3TemporalMetric.RL.values.X,
        RC: CVSS3TemporalMetric.RC.values.X
    })

    const [CVSS3EnvironmentalMetrics, setCVSS3EnvironmentalMetrics] = useState<CVSS3EnvironmentalMetricI>({
        MAV: CVSS3EnvironmentalMetric.MAV.values.X,
        MAC: CVSS3EnvironmentalMetric.MAC.values.X,
        MPR: CVSS3EnvironmentalMetric.MPR.values.X,
        MUI: CVSS3EnvironmentalMetric.MUI.values.X,
        MS: CVSS3EnvironmentalMetric.MS.values.X,
        MC: CVSS3EnvironmentalMetric.MC.values.X,
        MI: CVSS3EnvironmentalMetric.MI.values.X,
        MA: CVSS3EnvironmentalMetric.MA.values.X,
        CR: CVSS3EnvironmentalMetric.CR.values.X,
        IR: CVSS3EnvironmentalMetric.IR.values.X,
        AR: CVSS3EnvironmentalMetric.AR.values.X
    })
    const enable = Object.values(CVSS3Metrics).some(metric => metric === null)

    useEffect(() => {
        let tmp = Object.entries(CVSS3Metrics)
            .filter(([key, value]) => value !== null && typeof value === "object")
            .map(([key, value]) => {
                if (typeof value === "object" && value !== null && "metric" in value) {
                    return `${key}:${value.metric}`
                }
                return ""
            })
            .join("/")
        if (!Object.values(CVSS3TemporalMetrics).every(metric => metric?.metric === "X")) {
            const temporal = Object.entries(CVSS3TemporalMetrics)
                .filter(([key, value]) => value !== null && typeof value === "object")
                .map(([key, value]) => {
                    if (typeof value === "object" && value !== null && "metric" in value) {
                        return `${key}:${value.metric}`
                    }
                    return ""
                })
                .join("/")
            tmp = tmp + "/" + temporal
        }
        if (!Object.values(CVSS3EnvironmentalMetrics).every(metric => metric?.metric === "X")) {
            const environmental = Object.entries(CVSS3EnvironmentalMetrics)
                .filter(([key, value]) => value !== null && typeof value === "object")
                .map(([key, value]) => {
                    if (typeof value === "object" && value !== null && "metric" in value) {
                        return `${key}:${value.metric}`
                    }
                    return ""
                })
                .join("/")
            tmp = tmp + "/" + environmental
        }
        setCVSS3Vector(tmp)
        if (enable) {
            setCVSS3Score(0)
            setCVSS3TemporalScore(0)
            setCVSS3EnvironmentalScore(0)
            setCVSS3BaseScore(0)
            setCVSS3ExplotailabilityScore(0)
            setCVSS3ImpactScore(0)
            setCVSS3ModifiedImpactScore(0)
            setCVSS3OverallScore(0)
        } else {
            setCVSS3BaseScore(getCVSS3BaseScore())
            setCVSS3TemporalScore(getCVSS3TemporalScore())
            setCVSS3EnvironmentalScore(getCVSS3EnvironmentalScore())
        }
    }
    , [CVSS3Metrics, CVSS3TemporalMetrics, CVSS3EnvironmentalMetrics])

    useEffect(() => {
        CVSS3EnvironmentalScore > 0 ? setCVSS3OverallScore(CVSS3EnvironmentalScore) : CVSS3TemporalScore > 0 ? setCVSS3OverallScore(CVSS3TemporalScore) : setCVSS3OverallScore(CVSS3BaseScore)
    }
    , [CVSS3BaseScore, CVSS3TemporalScore, CVSS3EnvironmentalScore])

    const setDefaultValuesTempEnv = () => {
        const enableTemporal = Object.values(CVSS3TemporalMetrics).some(metric => metric === null)
        const enableEnvironmental = Object.values(CVSS3EnvironmentalMetrics).some(metric => metric === null)

        if (enableTemporal) {
        // On load set"s default value to temporal and environmental metrics
            const defaultTemporal : CVSS3TemporalMetricI = {
                E: {
                    label: "Exploit Code Maturity (E)",
                    metric: "E",
                    value: 1
                },
                RL: {
                    label: "Remediation Level (RL)",
                    metric: "RL",
                    value: 1
                },
                RC: {
                    label: "Report Confidence (RC)",
                    metric: "RC",
                    value: 1
                }
            }
            setCVSS3TemporalMetrics(defaultTemporal)
        }
        if (enableEnvironmental) {
            const defaultTemporal : CVSS3EnvironmentalMetricI = {
                MAV: {
                    label: "Modified Attack Vector (MAV)",
                    metric: "MAV",
                    value: 1
                },
                MUI: {
                    label: "Remediation Level (RL)",
                    metric: "RL",
                    value: 1
                },
                MC: {
                    label: "Report Confidence (RC)",
                    metric: "RC",
                    value: 1
                },
                MI: {
                    label: "Report Confidence (RC)",
                    metric: "RC",
                    value: 1
                },
                MA: {
                    label: "Report Confidence (RC)",
                    metric: "RC",
                    value: 1
                },
                CR: {
                    label: "Confidentiality Requirement (CR)",
                    metric: "CR",
                    value: 1
                },
                IR: {
                    label: "Integrity Requirement (IR)",
                    metric: "IR",
                    value: 1
                },
                AR: {
                    label: "Availability Requirement (AR)",
                    metric: "AR",
                    value: 1
                },
                MAC: {
                    label: "Attack Complexity (MAC)",
                    metric: "MAC",
                    value: 1
                },
                MPR: {
                    label: "Privileges Required (MPR)",
                    metric: "MPR",
                    value: 1
                },
                MS: {
                    label: "Scope (MS)",
                    metric: "MS",
                    value: 1
                }
            }
            setCVSS3EnvironmentalMetrics(defaultTemporal)
        }
    }

    const getCVSS3BaseScore = () => {
        const ISS = 1 - ((1 - CVSS3Metrics.C!.value) * (1 - CVSS3Metrics.I!.value) * (1 - CVSS3Metrics.A!.value))
        const Impact = (CVSS3Metrics.S!.metric === "U" ? 6.42 * ISS : 7.52 * (ISS - 0.029) - 3.25 * Math.pow((ISS - 0.02), 15))
        const Exploitability = 8.22 * CVSS3Metrics.AV!.value * CVSS3Metrics.AC!.value * (typeof CVSS3Metrics.PR?.value === "object" ? 0.85 : CVSS3Metrics.PR!.value) * CVSS3Metrics.UI!.value
        const BaseScore = Impact <= 0 ? 0 : (CVSS3Metrics.S!.metric === "U" ? Math.min((Impact + Exploitability), 10) : Math.min(1.08 * (Impact + Exploitability), 10))
        setCVSS3BaseScore(BaseScore)
        setCVSS3ImpactScore(Impact)
        setCVSS3ExplotailabilityScore(Exploitability)
        return roundUp(BaseScore)
    }

    const getCVSS3TemporalScore = () => {
        if (Object.values(CVSS3TemporalMetrics).every(metric => metric?.metric === "X")) {
            return 0
        }
        const ISS = 1 - ((1 - CVSS3Metrics.C!.value) * (1 - CVSS3Metrics.I!.value) * (1 - CVSS3Metrics.A!.value))
        const Impact = (CVSS3Metrics.S!.metric === "U" ? 6.42 * ISS : 7.52 * (ISS - 0.029) - 3.25 * Math.pow((ISS - 0.02), 13))
        const Exploitability = 8.22 * CVSS3Metrics.AV!.value * CVSS3Metrics.AC!.value * (typeof CVSS3Metrics.PR?.value === "object" ? 0.85 : CVSS3Metrics.PR!.value) * CVSS3Metrics.UI!.value
        const BaseScore = Impact <= 0 ? 0 : (CVSS3Metrics.S!.metric === "U" ? Math.min((Impact + Exploitability), 10) : Math.min(1.08 * (Impact + Exploitability), 10))
        return roundUp(BaseScore * CVSS3TemporalMetrics.E!.value * CVSS3TemporalMetrics.RC!.value * CVSS3TemporalMetrics.RL!.value)
    }

    const getCVSS3EnvironmentalScore = () => {
        if (Object.values(CVSS3EnvironmentalMetrics).every(metric => metric?.metric === "X")) {
            return 0
        }
        const MISS = Math.min(1 - ((1 - CVSS3EnvironmentalMetrics.CR!.value * CVSS3EnvironmentalMetrics.MC!.value) * (1 - CVSS3EnvironmentalMetrics.IR!.value * CVSS3EnvironmentalMetrics.MI!.value) * (1 - CVSS3EnvironmentalMetrics.AR!.value * CVSS3EnvironmentalMetrics.MA!.value)), 0.915)
        const ModifiedImpact = (CVSS3EnvironmentalMetrics.MS!.metric === "U" ? 6.42 * MISS : 7.52 * (MISS - 0.029) - 3.25 * Math.pow(MISS * 0.9731 - 0.02, 13))
        const ModifiedExploitability = 8.22 * CVSS3EnvironmentalMetrics.MAV!.value * CVSS3EnvironmentalMetrics.MAC!.value * (typeof CVSS3EnvironmentalMetrics.MPR?.value === "object" ? 0.85 : CVSS3EnvironmentalMetrics.MPR!.value) * CVSS3EnvironmentalMetrics.MUI!.value
        const EnvironmentalScore = ModifiedImpact <= 0 ? 0 : (CVSS3EnvironmentalMetrics.MS!.metric === "U" ? roundUp(roundUp(Math.min((ModifiedImpact + ModifiedExploitability), 10)) * CVSS3TemporalMetrics.E!.value * CVSS3TemporalMetrics.RC!.value * CVSS3TemporalMetrics.RL!.value) : roundUp(roundUp(Math.min(1.08 * (ModifiedImpact + ModifiedExploitability), 10) * CVSS3TemporalMetrics.E!.value * CVSS3TemporalMetrics.RC!.value * CVSS3TemporalMetrics.RL!.value)))
        setCVSS3ModifiedImpactScore(ModifiedImpact)
        return EnvironmentalScore
    }

    const handleCVSSChange = (metric: keyof CVSS3MetricI, newValue: any) => {
        if (newValue === null) {
            return
        }
        if (metric === "PR" || metric === "S") {
            if (metric === "PR") {
                if (CVSS3Metrics.S !== null && newValue !== null && newValue.metric !== "N" && typeof newValue.value === "object") {
                    newValue.value = CVSS3Metrics.S?.metric === "C" ? newValue.value.C : newValue.value.U
                }
            } else {
                if (CVSS3Metrics.PR !== null && CVSS3Metrics.PR.metric !== "L" && typeof CVSS3Metrics.PR.value === "object") {
                    if (newValue === "U") {
                        CVSS3Metrics.PR.value = CVSS3Metrics.PR.value.U
                    } else {
                        CVSS3Metrics.PR.value = CVSS3Metrics.PR.value.C
                    }
                }
            }
        }

        setCVSS3Metrics((prevState) => ({
            ...prevState,
            [metric]: newValue
        }))
    }

    const handleTemporalCVSSChange = (metric: keyof CVSS3TemporalMetricI, newValue: any) => {
        if (newValue === null) {
            return
        }
        setCVSS3TemporalMetrics((prevState) => ({
            ...prevState,
            [metric]: newValue
        }))
    }

    const handleEnvironmentalCVSSChange = (metric: keyof CVSS3EnvironmentalMetricI, newValue: any) => {
        if (newValue === null) {
            return
        }
        if (metric === "MPR" || metric === "MS") {
            if (metric === "MPR") {
                if (CVSS3EnvironmentalMetrics.MS !== null && newValue !== null && newValue.metric !== "N" && typeof newValue.value === "object") {
                    newValue.value = CVSS3EnvironmentalMetrics.MS?.metric === "C" ? newValue.value.C : newValue.value.U
                }
            } else {
                if (CVSS3EnvironmentalMetrics.MPR !== null && CVSS3EnvironmentalMetrics.MPR.metric !== "L" && typeof CVSS3EnvironmentalMetrics.MPR.value === "object") {
                    if (newValue === "U") {
                        CVSS3EnvironmentalMetrics.MPR.value = CVSS3EnvironmentalMetrics.MPR.value.U
                    } else {
                        CVSS3EnvironmentalMetrics.MPR.value = CVSS3EnvironmentalMetrics.MPR.value.C
                    }
                }
            }
        }
        setCVSS3EnvironmentalMetrics((prevState) => ({
            ...prevState,
            [metric]: newValue
        }))
    }

    useEffect(() => {
        importCVSS3(data.vector)
        // setDefaultValuesTempEnv()
    }, [data])

    function roundUp (input: number): number {
        const intInput = Math.round(input * 100000)
        if ((intInput % 10000) === 0) {
            return intInput / 100000.0
        }
        return (Math.floor(intInput / 10000) + 1) / 10.0
    }

    const importCVSS3 = (cvss: string) => {
        cvss.split("/").forEach((metric) => {
            const [key, value] = metric.split(":")
            if (key in CVSS3Metric) {
                setCVSS3Metrics((prevState) => ({
                    ...prevState,
                    [key]: Object.values(CVSS3Metric[key as keyof typeof CVSS3Metric].values).find((v) => v.metric === value)
                }))
            }
        })
    }
    const style = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "80%",
        bgcolor: "background.paper",
        color: theme.palette.primary.contrastText,
        border: `2px solid ${theme.palette.secondary.contrastText}`,
        boxShadow: 24,
        p: 4,
        height: "85%"
    }

    const styleBox = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "95%",
        color: theme.palette.primary.contrastText,
        p: 4,
        height: "85%",
        overflow: "auto"
    }

    const updateScore = () => {
        setFormData({ ...formData, vector: "CVSS:3.0/" + CVSS3Vector, revised_score: CVSS3OverallScore })
        setOpen(false)
    }
    const chipColor = getColorByScore(formData.score)

    const formatKey = (key: any) => {
        return key
            .replace(/_/g, " ") // Reemplaza los guiones bajos con espacios
            .replace(/([a-z])([A-Z])/g, "$1 $2") // Inserta espacios antes de las mayúsculas
            .replace(/\b\w/g, (char: string) => char.toUpperCase()) // Capitaliza la primera letra de cada palabra
    }

    return (
        <Grid item xs container flexDirection="column" spacing="20px">
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>
                    <Box sx={{ borderRadius: "10px", border: "1px solid #6D6C6F", width: "100%", padding: "25px" }}>
                        {error && <Alert severity="error">{error.message}</Alert>}
                        <Grid container flexDirection="column" spacing="20px">
                            <Grid item flexDirection="row">
                                <Grid container flexDirection="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ marginTop: "10px" }}>
                                    <Grid item>
                                        <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
                                            {formData.identifier}
                                        </Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography fontSize="27px" fontFamily="Griff" color="primary" fontWeight="bolder">
                                            <Button onClick={handleOpen}>{context.t.translate("vulner_cvs")}</Button>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Divider sx={{ height: "1px", width: "100%", background: theme.palette.secondary.dark }} />
                            </Grid>

                            <Grid item container flexDirection="row" spacing="35px">
                                <Grid item xs={1.5}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("vulner_product")}</Typography>
                                    <TextField name="Product" disabled value={formData.product.name} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                </Grid>
                                <Grid item xs={1.5}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("vulner_vers")}</Typography>
                                    <TextField name="Version" disabled value={formData.product.version} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                </Grid>
                                <Grid item xs={2.5}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("vulner_comp")}</Typography>
                                    <TextField name="Component" disabled value={`${formData.cmp_name} (${formData.cmp_version})`} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                </Grid>
                            </Grid>

                            <Grid item container flexDirection="row" spacing="35px" xs={12}>
                                <Grid item xs={1}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("vulner_criti")}</Typography>
                                    <TextField name="Criticity" disabled value={label} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                </Grid>
                                {data.revised_score !== data.score && (
                                    <Grid item xs={2}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("vulner_revis")}</Typography>
                                        <TextField name="Score" disabled value={formData.revised_score} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                    </Grid>
                                )}
                                <Grid item xs={1}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("vulner_score")}</Typography>
                                    {/* <TextField name="Score" disabled value={formData.score} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/> */}
                                    <Chip size="small" style={{ fontWeight: "bolder", color: "black", borderWidth: "1px", borderStyle: "solid", borderColor: chipColor, backgroundColor: chipColor, marginTop: "5px" }} label={formData.score } />

                                </Grid>
                                <Grid item xs={3}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("vulner_vector")}</Typography>
                                    <TextField name="Vector" disabled value={formData.vector} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("vulner_prio")}</Typography>
                                    <TextField name="Priority" disabled value={formData.priority} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("vulner_epss")}</Typography>
                                    <TextField name="EPSS" disabled value={formData.epss} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("vulner_kev")}</Typography>
                                    <Grid container alignItems="center" direction="row">
                                        <Checkbox name="KEV" disabled checked={formData.kev} style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                        {formData.kev
                                            ? (
                                                <Typography color="#ffffff" fontSize="18px" fontFamily="Griff" fontWeight="bolder" style={{ marginLeft: 8 }}>
                                                    {context.t.translate("vulner_sikev")}
                                                </Typography>
                                            )
                                            : (
                                                <Typography color="#ffffff" fontSize="18px" fontFamily="Griff" style={{ marginLeft: 8 }}>
                                                    {context.t.translate("vulner_nokev")}
                                                </Typography>
                                            )}
                                    </Grid>
                                </Grid>

                            </Grid>

                            <Grid item>
                                <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("vulner_desc")}</Typography>
                                <TextField name="Description" maxRows={4} multiline disabled value={formData.description} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                            </Grid>

                            <Grid item>
                                <Grid item mt={1} mb={5}>
                                    <Tabs centered value={registry} sx={{ justifyContent: "space-around", width: "100%" }}>
                                        <Tab label={
                                            <Typography fontSize="20px" fontFamily="Griff" fontWeight="bolder">
                                                <RateReviewIcon style={{ marginRight: "5px", marginBottom: "-10px", fontSize: "20px", width: "32px", height: "32px" }}/>
                                                {context.t.translate("vulner_review")}</Typography>
                                        } sx={{ flex: 1, color: colorone }} value={0}/>
                                        <Tab label={
                                            <Typography fontSize="20px" fontFamily="Griff" fontWeight="bolder">
                                                <AssessmentIcon style={{ marginRight: "5px", marginBottom: "-8px", fontSize: "20px", width: "32px", height: "32px" }}/>
                                                {context.t.translate("vulner_assess")}</Typography>
                                        } sx={{ flex: 1, color: color1 }} value={1}/>
                                        <Tab label={
                                            <Typography fontSize="20px" fontFamily="Griff" fontWeight="bolder">
                                                <CheckCircleIcon style={{ marginRight: "5px", marginBottom: "-8px", fontSize: "20px", width: "32px", height: "32px" }}/>
                                                {context.t.translate("vulner_addre")}
                                            </Typography>
                                        } sx={{ flex: 1, color: color2 }} value={2}/>
                                    </Tabs>
                                </Grid>

                                <Grid item container flexDirection="row" alignItems="center" justifyContent="center" xs={12} spacing={4} paddingX={10}>

                                    <CustomTabPanel value={registry} index={[0, 1, 2]}>
                                        <Grid item xs={registry === 0 ? 12 : 9}>
                                            <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("vulner_stat")}</Typography>
                                            <Select name="state" size="small" value={formData.state} onChange={handleChange} fullWidth>
                                                {VulnerabilityStateKeysValueOps.map((opt, idx) =>
                                                    (<MenuItem key={idx} value={opt.value}><Typography fontFamily="Griff" fontWeight="bolder">{opt.label}</Typography></MenuItem>)
                                                )}
                                            </Select>
                                        </Grid>
                                    </CustomTabPanel>

                                    <CustomTabPanel value={registry} index={[1, 2]}>
                                        <Grid item xs={3}>
                                            <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("vulner_revis")}</Typography>
                                            <TextField type="number" name="revised_score" value={registry > 1 ? 0 : formData.revised_score} disabled={registry > 1} variant="outlined" size="small" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }} onChange={handleChange}/>
                                        </Grid>
                                    </CustomTabPanel>

                                    <CustomTabPanel value={registry} index={[1, 2]}>
                                        <Grid container justifyContent="center" item xs={12} spacing="35px">
                                            <Grid item xs={6}>
                                                <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("vulner_just")}</Typography>
                                                <Select name="justification" size="small" value={formData.justification} onChange={handleChange} fullWidth>
                                                    {VulnerabilityJustificationKeysValueOps.map((opt, idx) =>
                                                        (<MenuItem key={idx} value={opt.value}><Typography fontFamily="Griff" fontWeight="bolder">{opt.label}</Typography></MenuItem>)
                                                    )}
                                                </Select>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("vulner_resp")}</Typography>
                                                <Select name="response" size="small" value={formData.response} onChange={handleChange} fullWidth>
                                                    {VulnerabilityResponseKeysValueOps.map((opt, idx) =>
                                                        (<MenuItem key={idx} value={opt.value}><Typography fontFamily="Griff" fontWeight="bolder">{opt.label}</Typography></MenuItem>)
                                                    )}
                                                </Select>
                                            </Grid>
                                        </Grid>
                                    </CustomTabPanel>

                                    <CustomTabPanel value={registry} index={[1, 2]}>
                                        <Grid item xs={12}>
                                            <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("vulner_det")}</Typography>
                                            <StyledTextarea minRows={4} placeholder="Enter details here..." style={{ width: "100%", boxSizing: "border-box" }}/>
                                        </Grid>
                                    </CustomTabPanel>

                                    { data && <Grid item xs={12} my={2}><Button fullWidth variant="contained" onClick={saveHandler} disabled={!formValidResponse()} sx={{ fontWeight: "bold" }}>{context.t.translate("product_save")}</Button></Grid>}

                                </Grid>
                            </Grid>

                        </Grid>
                        {formData.cwe && formData.cwe.length > 0 &&
                        <><Grid item>
                            <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("vulner_weak")}</Typography>
                        </Grid>
                        <CWEDataGrid formData={formData} /></>
                        }
                    </Box>

                </Grid>

                {formData.metadata && (
                    <Grid item xs container flexDirection="column" spacing="20px" sx={{ marginTop: "20px" }}>
                        <Grid item container flexDirection="column" rowGap="35px">
                            <Grid item container>
                                <Typography fontSize="45px" fontWeight="bolder" fontFamily="Griff" sx={{ marginTop: "-20px", marginBottom: "20px" }}>{context.t.translate("vulner_meta")}</Typography>

                                <Box sx={{ borderRadius: "10px", border: "1px solid #6D6C6F", width: "100%", padding: "25px" }}>
                                    <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
                                        {formData.origin}
                                    </Typography>
                                    <Grid item>
                                        <Divider sx={{ height: "1px", width: "100%", background: theme.palette.secondary.dark, marginBottom: "10px" }} />
                                    </Grid>
                                    <Grid item container flexDirection="row" spacing="35px">

                                        {Object.entries(formData.metadata).map(([key, value]) => (
                                            <Grid item xs={3} key={key}>
                                                <Typography key={key} color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{formatKey(key)}</Typography>
                                                <TextField name="Product" disabled value={value} variant="standard" multiline style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                            </Grid>
                                        ))}

                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid >
        </Grid >
    )
}

export { VulnerabilityDetail }
export default VulnerabilityDetail
