const en = {
    languages: {
        en: "English",
        es: "Spanish"
    },
    settings: {
        languageChange: "Change language",
        languageCurrent: "Chosen language"
    },
    addMessage: "Receive a message",
    messages: {
        one: "You have one message",
        other: "You have {count} messages"
    },
    // dashboard
    dash_assess_finished: "Finished tests",
    dash_vulner_mitigated: "Mitigated vulnerabilities",
    dash_vulner_nomitigated: "Unmitigated vulnerabilities",
    dash_assess: "Assessments",
    dash_vulner: "Vulnerabilities",
    dash_product: "Products",
    dash_assess_byproduct: "By product",
    dash_assess_bystatus: "By status",
    dash_assess_bytype: "By type",
    dash_vulner_criticity: "Criticality",
    dash_vulner_state: "State",
    dash_vulner_mitigaciones: "Mitigations",
    dash_product_bytype: "By state",
    dash_export: "Export",
    dash_vulner_title: "Mitigated vs. unmitigated vulnerabilities",
    // Products
    product_newproduct: "Add new product",
    product_newtag: "Add new tag",
    product_name: "Name",
    product_version: "Version",
    product_type: "Type",
    product_description: "Description",
    product_client: "Client name",
    product_descrip: "Product description",
    product_save: "Save changes",
    product_tag: "Search Tag",
    product_addtag: "Add tag",
    product_createtag: "Create tag",
    product_color: "Color",
    product_newname: "Product name",
    // Assessment
    assess_noption: "No options",
    asess_template_name: "Template name",
    assess_status: "Status",
    assess_date_contrac: "Contract date",
    assess_date_start: "Start date",
    assess_date_end: "End date",
    assess_new: "Add new assessment",
    assess_close: "Close",
    assess_comp: "Components",
    assess_scan: "Scanned at",
    assess_quali: "Quality Score",
    assess_metric: "SBOM Quality Metrics",
    // Benchmark
    bench_text: "Choose two products to compare the vulnerabilities between them",
    bench_select: "Select Product",
    bench_sector: "Sector",
    bench_criti: "Criticity",
    // CRA compliance
    CRA_title: "Summary",
    CRA_edit: "Edit Text",
    CRA_text: "Edit the Conclusion field of the compliance",
    CRA_save: "Save",
    CRA_cancel: "Exit",
    CRA_conclusion: "Conclusion",
    CRA_quiz: "Quiz",
    CRA_details: "Details",
    CRA_total: "Total Score",
    CRA_closet: "Close Compliance",
    CRA_exit: "Are you sure you want to exit without saving changes?",
    CRA_closetext: "Cancel",
    CRA_text2: "You can't undo this operation",
    // Notification & Alerts
    alert_report: "Report Filters",
    alert_alerts: "Alerts",
    alert_when: "Receive alerts when:",
    alert_option1: "New CVEs are discovered",
    alert_option2: "CVEs are updated",
    alert_enable: "Enable",
    alert_recipient: "Recipient of the email",
    alert_frequency: "Email frequency",
    alert_cvss: "Receive alerts when CVSS is higher than:",
    // Settings
    setting_info: "User Information",
    setting_preference: "Preferences",
    setting_user: "User info",
    setting_billing: "Billing",
    setting_name: "Name",
    setting_email: "Email",
    setting_pass: "Password",
    setting_plan: "Your Plan",
    setting_license_free: "You are currently on a Free membership.",
    setting_license_pro: "You are currently on a Pro membership that will end on ",
    setting_ticket: "Ticketing",
    setting_edit: "Edit",
    setting_support: "Support",
    setting_check_subscription: "Check out our subscription plan and info in the following link",
    setting_send_email: "Or contact us through",
    setting_add_ticket: "Add new ticket",
    // Vulnerabilities
    vulner_produc: "Product",
    vulner_vers: "Version",
    vulner_comp: "Component",
    vulner_criti: "Criticity",
    vulner_revis: "Revised Score",
    vulner_score: "Score",
    vulner_vector: "Vector",
    vulner_prio: "Priority",
    vulner_epss: "EPSS",
    vulner_kev: "KEV",
    vulner_nokev: "Not included in KEV catalog",
    vulner_sikev: "Included in KEV catalog",
    vulner_desc: "Description",
    vulner_review: "Review",
    vulner_assess: "Assess",
    vulner_addre: "Address",
    vulner_stat: "Status",
    vulner_just: "Justification",
    vulner_resp: "Response",
    vulner_det: "Details",
    vulner_weak: "Weaknessess (CWES)",
    vulner_meta: "Metadata",
    vulner_cvs: "CVSS3 Calculator",
    // Hierarchy
    hierar_level: "Hierarchy level",
    hierar_option: "No options",
    hierar_manage: "Manage hierarchy",
    hierar_node: "Add New Node",
    hierar_back: "Back",
    hierar_reset: "Reset",
    // Switch
    switch: "Switch between dark and light mode"

}

export default en
