import React, { useContext, useEffect, useState } from "react"
import { Button, Grid, useTheme } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { useNavigate } from "react-router-dom"
import { GenericTable } from "@components/common/tables/index"
import { convertModelDefToGridColDef } from "@components/common/tables/utils"
import { AbilityContext, Can } from "@components/permissions/index"
import { ServicesContext } from "@context/index"
import { Template, TemplateDefinition } from "@models/index"

const TemplateList: React.FC = () => {
    const theme = useTheme()
    const templateService = useContext(ServicesContext).templateService

    const navigate = useNavigate()
    const [cols, setCols] = useState<GridColDef[]>([])

    const ability = useContext(AbilityContext)
    useEffect(() => {
        setCols(convertModelDefToGridColDef(TemplateDefinition, ability))
    }, [])

    return (
        <Grid item xs container flexDirection="column" spacing="20px">
            <Grid item container flexDirection="column" rowGap="35px">
                <GenericTable<Template> entity="Template" columns={cols}
                    dataProvider={(filter) => templateService.getAll(filter)}
                    onEdit={(elem: Template) => navigate("./" + elem.id)}
                    onDelete={(elem: Template) => templateService.delete(elem.id)}
                />
                <Can I="create" a="Template">
                    <Button size="large" fullWidth variant="contained" sx={{ color: theme.palette.secondary.contrastText }} onClick={() => navigate("./add")}>New Template</Button>
                </Can>
            </Grid >
        </Grid>
    )
}

export { TemplateList }
export default TemplateList
