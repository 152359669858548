import { Alert, Box, Button, Grid, IconButton, TextField, Tooltip, Typography, useTheme } from "@mui/material"
import React, { ChangeEvent, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import { HexColorPicker } from "react-colorful"
import { ClientSelector } from "@components/client/index"
import { ServicesContext } from "@context/index"
import { Template } from "@models/index"
import { I18nContext } from "I18nProvider"

const initValue: Template = {
    id: "",
    name: "",
    color: "#FAE733",
    logo: null,
    client: { id: "" }
}

const TemplateForm: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const templateService = useContext(ServicesContext).templateService
    const [color, setColor] = useState("#FAE733")
    const [file, setFile] = useState<File>(new File([], "logo"))
    const [previewUrl, setPreviewUrl] = useState<string | ArrayBuffer | null>(null)

    const [error, setError] = useState<Error|null>(null)
    const [fileId, _] = useState(`file-input-${Math.random().toString(36).substring(7)}`)

    // API Call form submission
    const [formData, setFormData] = useState<Template>(initValue)
    const handleInputChange = (e: any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }
    const formValid = (): boolean => {
        const isNotNullrules = [
            formData.client.id === "",
            formData.name === "",
            formData.color === "",
            formData.logo === null
        ]
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        return true
    }
    // Api call form validation
    const navigate = useNavigate()
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        try {
            await templateService.create(formData)
            setError(null)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
        }
    }

    const handleFileInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
        const input = e.target as HTMLInputElement
        input.value = "" // Clear the input value to trigger onChange even if the same file is selected
    }
    const handleFileInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files && e.target.files[0]
        if (selectedFile) {
            try {
                const reader = new FileReader()
                reader.onloadend = () => {
                    formData.logo = reader.result
                    setPreviewUrl(reader.result)
                }
                reader.readAsDataURL(selectedFile)
            } catch (e: any) {
                console.error(e)
            }
        }
    }

    useEffect(() => {
        formData.color = color
    }, [color])

    return (
        <Box sx={{ width: "100%" }}>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: "50%", alignSelf: "center", margin: "50px auto 50px auto" }}>
                {error && <Alert severity="error">{error.message}</Alert>}
                <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                    <Grid item xs={6}>
                        <ClientSelector value={formData.client.id} onSelect={(id) => handleInputChange({ target: { name: "client", value: { id } } })}></ClientSelector>
                    </Grid>
                </Grid>
                <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                    <Grid item xs={6}>
                        <TextField margin="normal" required fullWidth variant="filled" label="Template Name" name="name"
                            value={formData.name} onChange={handleInputChange}/>
                    </Grid>
                    <Grid item xs={6}>
                        <input
                            type="file"
                            accept=".jpg"
                            style={{ display: "none" }}
                            onClick={handleFileInputClick}
                            onChange={handleFileInputChange}
                            id={fileId}
                        />
                        <label htmlFor={fileId}>
                            <Typography variant="h5" color="primary" fontWeight="bolder">Custom logo (596p x 250p)</Typography>
                            <Tooltip title="Upload SBOM">
                                <IconButton size="small" component="span" onClick={(e) => e.stopPropagation()}>
                                    <CloudUploadIcon/>
                                </IconButton>
                            </Tooltip>
                        </label>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} >
                        <Typography variant="h5" color="primary" fontWeight="bolder">Color</Typography>
                        <HexColorPicker color={color} onChange={setColor} />
                    </Grid>
                    {previewUrl && (
                        <Grid item xs={6} >
                            <img style={{ width: 596, height: 250 }} src={previewUrl.toString()} alt="Preview" />
                        </Grid>
                    )}
                </Grid>
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, color: theme.palette.secondary.contrastText }} disabled={!formValid()}>
                    Add new Template
                </Button>
            </Box>
        </Box>
    )
}

export { TemplateForm }
export default TemplateForm
