enum FilterOperation {
    UnspecifiedFilter = "uf",
    StringEqual = "eq",
    StringNotEqual = "ne",
    StringContains ="ct",
    StringNotContains ="nc",

    StringArrayContains = "c",

    DateEqual="eq",
    DateBefore ="bf",
    DateAfter="af",

    NumberEqual= "eq",
    NumberNotEqual= "ne",
    NumberLessThan = "lt",
    NumberLessOrEqualThan ="le",
    NumberGreaterThan = "gt",
    NumberGreaterOrEqualThan ="ge",

    EnumEqual ="eq",
    EnumNotEqual = "ne",

    UUIDEqual ="eq",
    UUIDNotEqual = "ne"
}
const filtersByType = {
    string: [FilterOperation.StringEqual, FilterOperation.StringNotEqual, FilterOperation.StringContains, FilterOperation.StringNotContains],
    stringArray: [FilterOperation.StringArrayContains],
    date: [FilterOperation.DateEqual, FilterOperation.DateBefore, FilterOperation.DateAfter],
    number: [FilterOperation.NumberEqual, FilterOperation.NumberNotEqual, FilterOperation.NumberLessThan, FilterOperation.NumberLessOrEqualThan, FilterOperation.NumberGreaterThan, FilterOperation.NumberGreaterOrEqualThan],
    singleSelect: [FilterOperation.EnumEqual, FilterOperation.EnumNotEqual],
    struct: [FilterOperation.NumberEqual],
    uuid: [FilterOperation.UUIDEqual, FilterOperation.UUIDNotEqual]
}

const filterNameByTypeAndValue =
{
    string: { [FilterOperation.StringEqual]: "equals", [FilterOperation.StringNotEqual]: "not equals", [FilterOperation.StringContains]: "contains", [FilterOperation.StringNotContains]: "not contains" } as {[id in FilterOperation]: string},
    stringArray: { [FilterOperation.StringArrayContains]: "contains" } as {[id in FilterOperation]: string},
    date: { [FilterOperation.DateEqual]: "equals", [FilterOperation.DateBefore]: "before", [FilterOperation.DateAfter]: "after" } as {[id in FilterOperation]: string},
    number: { [FilterOperation.NumberEqual]: "=", [FilterOperation.NumberNotEqual]: "!=", [FilterOperation.NumberLessThan]: "<", [FilterOperation.NumberLessOrEqualThan]: "<=", [FilterOperation.NumberGreaterThan]: ">", [FilterOperation.NumberGreaterOrEqualThan]: ">=" } as {[id in FilterOperation]: string},
    singleSelect: { [FilterOperation.EnumEqual]: "equals", [FilterOperation.EnumNotEqual]: "not equals" } as {[id in FilterOperation]: string},
    struct: { [FilterOperation.NumberEqual]: "equals" } as {[id in FilterOperation]: string},
    uuid: { [FilterOperation.UUIDEqual]: "equals", [FilterOperation.UUIDNotEqual]: "not equals" } as {[id in FilterOperation]: string}
} as {[id in "string"|"number"|"singleSelect"|"date"|"struct"|"uuid"]: {[id in FilterOperation]: string}}

type FilterOption = {
    field: string;
    operation: FilterOperation;
    value: string;
}

type QueryParameters = {
    page_number?: number;
    page_size?: number;
    sortMode?: "asc"|"desc";
    sortField?: string;
    filters?: FilterOption[];
    orFilters?: FilterOption[]
}
export { filtersByType, filterNameByTypeAndValue, FilterOperation }
export type { FilterOption, QueryParameters }
