const es = {
    languages: {
        en: "Inglés",
        es: "Español",
        de: "Alemán"
    },
    settings: {
        languageChange: "Cambiar idioma",
        languageCurrent: "Idioma seleccionado"
    },
    addMessage: "Recibir un mensaje",
    messages: {
        one: "Tienes un mensaje",
        other: "Tienes {count} mensajes"
    },
    // dashboard
    dash_assess_finished: "Pruebas finalizadas",
    dash_vulner_mitigated: "Vulnerabilidades mitigadas",
    dash_vulner_nomitigated: "Vulnerabilidades no mitigadas",
    dash_assess: "Evaluaciones",
    dash_vulner: "Vulnerabilidades",
    dash_product: "Productos",
    dash_assess_byproduct: "Por producto",
    dash_assess_bystatus: "Por estado",
    dash_assess_bytype: "Por tipo",
    dash_vulner_criticity: "Criticidad",
    dash_vulner_state: "Estado",
    dash_vulner_mitigaciones: "Mitigaciones",
    dash_product_bytype: "Por estado",
    dash_export: "Exportar",
    dash_vulner_title: "Vulnerabilidades mitigadas frente a vulnerabilidades no mitigadas",
    // Products
    product_newproduct: "Nuevo producto",
    product_newtag: "Nueva etiqueta",
    product_name: "Nombre",
    product_version: "Version",
    product_type: "Tipo",
    product_description: "Descripcion",
    product_client: "Nombre del cliente",
    product_descrip: "Descripcion del producto",
    product_save: "Guardar cambios",
    product_tag: "Buscar por etiqueta",
    product_addtag: "Añadir etiqueta",
    product_createtag: "Crear etiqueta",
    product_color: "Color",
    product_newname: "Nombre del producto",
    // Assessment
    assess_noption: "Sin opciones",
    asess_template_name: "Nombre de la plantilla",
    assess_status: "Estado",
    assess_date_contrac: "Fecha del contrato",
    assess_date_start: "Fecha de inicio",
    assess_date_end: "Fecha de finalización",
    assess_new: "Añadir nueva evaluación",
    assess_close: "Cerrar",
    assess_comp: "Componentes",
    assess_scan: "Escaneado en",
    assess_quali: "Puntuación de calidad",
    assess_metric: "Métricas de calidad del SBOM",
    // Benchmark
    bench_text: "Elige dos productos para comparar las vulnerabilidades entre ellos",
    bench_select: "Seleccionar producto",
    bench_sector: "Sector",
    bench_criti: "Criticidad",
    // CRA compliance
    CRA_title: "Resumen",
    CRA_edit: "Editar texto",
    CRA_text: "Editar el campo Conclusión de la conformidad",
    CRA_save: "Guardar",
    CRA_cancel: "Salir",
    CRA_conclusion: "Conclusión",
    CRA_quiz: "Cuestionario",
    CRA_details: "Detalles",
    CRA_total: "Puntuación total",
    CRA_closet: "Cerrar Cumplimiento",
    CRA_exit: "¿Estás seguro de que quieres salir sin guardar los cambios?",
    CRA_closetext: "Cancelar",
    CRA_text2: "No puedes deshacer esta operación",
    // Notification & Alerts
    alert_report: "Filtros de informes",
    alert_alerts: "Alertas",
    alert_when: "Recibir alertas cuando:",
    alert_option1: "Se descubren nuevos CVE",
    alert_option2: "Se actualizan CVEs",
    alert_enable: "Activar",
    alert_recipient: "Destinatario del correo electrónico",
    alert_frequency: "Frecuencia del correo electrónico",
    alert_cvss: "Recibir alertas cuando CVSS sea superior a:",
    // Settings
    setting_info: "Información del usuario",
    setting_preference: "Preferencias",
    setting_user: "Información del usuario",
    setting_billing: "Facturación",
    setting_name: "Nombre",
    setting_email: "Correo electrónico",
    setting_pass: "Contraseña",
    setting_plan: "Su plan",
    setting_license_free: "Actualmente tienes un Plan de Subscripción gratuito",
    setting_license_pro: "Actualmente tienes un Plan de Suscripción Pro que finalizará el ",
    setting_ticket: "Ticketing",
    setting_add_ticket: "Añadir ticket",
    setting_edit: "Editar",
    setting_support: "Soporte",
    setting_check_subscription: "Consulta nuestro plan de suscripción y la información en el siguiente enlace",
    setting_send_email: "O contáctanos enviando un correo electrónico a",
    // Vulnerabilities
    vulner_produc: "Producto",
    vulner_vers: "Versión",
    vulner_comp: "Componente",
    vulner_criti: "Criticidad",
    vulner_revis: "Puntuación Revisada",
    vulner_score: "Puntuación",
    vulner_vector: "Vector",
    vulner_prio: "Prioridad",
    vulner_epss: "EPSS",
    vulner_kev: "KEV",
    vulner_nokev: "No incluido en el catálogo KEV",
    vulner_sikev: "Incluido en el catálogo KEV",
    vulner_desc: "Descripción",
    vulner_review: "Revisión",
    vulner_assess: "Evaluar",
    vulner_addre: "Dirección",
    vulner_stat: "Estado",
    vulner_just: "Justificación",
    vulner_resp: "Respuesta",
    vulner_det: "Detalles",
    vulner_weak: "Debilidades (CWES)",
    vulner_meta: "Metadatos",
    vulner_cvs: "Calculadora de CVSS3",
    // Hierarchy
    hierar_level: "Nivel jerárquico",
    hierar_option: "Sin opciones",
    hierar_manage: "Gestionar la jerarquía",
    hierar_node: "Añadir nuevo nodo",
    hierar_back: "Atrás",
    hierar_reset: "Reiniciar",
    // Switch
    switch: "Cambiar entre modo oscuro y claro"

}

export default es
