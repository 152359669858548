import React from "react"
import { Document, Font } from "@react-pdf/renderer"
import { Compliance } from "@models/Compliance"
import { BackPage } from "./sections/99_BackPage"
import { FrontPageCompliance, IndexCompliance, IntroCompliance, VulnsList } from "./Compliance/sections"
import { Conclusions } from "./Compliance/sections/3_ Conclusions"

Font.register({ family: "Griff", src: "./assets/fonts/Griff-Regular.otf" })
Font.register({ family: "Bold", src: "./assets/fonts/Griff-Bold.otf" })

const hyphenationCallback = (word: string) => {
    // Devuelve las sílabas de la palabra en un array
    // Este es solo un ejemplo simple y puede necesitar mejoras
    return word.split("-")
}

// Registra la función de separación de sílabas
Font.registerHyphenationCallback(hyphenationCallback)

interface VEXDocumentProps {
    compliance: Compliance
}

const VulnReportDocumentCompliance: React.FC<VEXDocumentProps > = ({ compliance }) => {
    return (
        <>
            <Document pageLayout="twoColumnRight">
                <FrontPageCompliance></FrontPageCompliance>
                <IndexCompliance></IndexCompliance>
                <IntroCompliance></IntroCompliance>
                <VulnsList compliance={compliance}></VulnsList>
                <Conclusions compliance={compliance}></Conclusions>
                <BackPage></BackPage>
            </Document>
        </>
    )
}

export { VulnReportDocumentCompliance }
export default VulnReportDocumentCompliance
