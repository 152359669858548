import { Product } from "@models/index"
import { MediaTypes } from "@services/HttpService"
import Service from "@services/abstract/BaseService"

class ProductService extends Service<Product> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/product", MediaTypes.JSON)
    }
}

export { ProductService }
export default ProductService
